<template>
  <div class="yp-sheet" :class="[{ 'yp-sheet--dark': mode === 'dark' }]">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "YpSheet",
  computed: {
    ...mapGetters(['mode']),
  }
}
</script>
