import i18n from "@/plugins/i18n";

const state = {
    ws: null,
    connected: false,
    auth: false,
    error: null,
    events: [],
}

const getters = {
    events: state => state.events,
    auth: state => state.auth,
}

const actions = {
    addEvent({commit, rootState}, eventObj) {
        switch (eventObj.event) {
            case 'ws:rate':
                commit('SET_RATE', eventObj.obj, {root: true})
                break;
            case 'ws:account':
                const index = rootState.accounts.findIndex(el => el.pk === eventObj.obj.pk)
                if (index && rootState.accounts[index] !== eventObj.obj.amount) {
                    commit('snackbar/SET_SNACK', {
                        title: i18n.t('accountChange'),
                        text: `Old amount: ${rootState.accounts[index].amount} -> New amount: ${eventObj.obj.amount} ${eventObj.obj.currency_code}`,
                        duration: 8000,
                    }, {root: true})
                }
                commit('SET_ACCOUNT', eventObj.obj, {root: true})
                break;
            case 'ws:operation':
                if (eventObj.obj.code === 'google') {
                    commit('SET_HAS_GOOGLE_BALANCE_UPDATE', true, {root: true})
                }
                commit('snackbar/SET_SNACK', {
                    title: `
                    ${i18n.t('snackbarMessages.statusChange')} -
                    ${eventObj.obj.status_name.charAt(0).toUpperCase() + eventObj.obj.status_name.slice(1)}`,
                    text: `
                        ${eventObj.obj.kind_name.charAt(0).toUpperCase() + eventObj.obj.kind_name.slice(1)},
                        ${i18n.t('amount')}:
                        ${eventObj.obj.amount + eventObj.obj.currency_code}
                    `,
                    duration: 5000,
                }, {root: true})
                commit('payouts/ADD_OPERATION', eventObj.obj, {root: true})
                break;
            case 'ws:otp':
                console.log("EVENT OBJ", eventObj)
                commit('snackbar/SET_SNACK', {
                    title: "OTP",
                    text: `
                        code&nbsp-&nbsp${eventObj.obj.otp}
                        static&nbsppassword&nbsp-&nbsp${eventObj.obj.static_otp}
                        ${eventObj.obj.amount}&nbsp${eventObj.obj.currency}\n
                        \n************${eventObj.obj.pan}
                        The code expires in 10 minutes.
                    `,
                    duration: 600000,
                }, {root: true})
                break;
            case 'ws:errors':
                commit('snackbar/SET_SNACK', {
                    type: 'error',
                    title: eventObj.obj.error_type,
                    text: eventObj.obj.error,
                    duration: 10000,
                    }, { root: true })
                break;
        }
    },
    connectionOpened({commit}) {
        commit('SET_CONNECTION', true);
    },
    connectionClosed({commit}) {
        commit('SET_CONNECTION', false);
    },
    connectionError({commit}, error) {
        commit('SET_ERROR', error);
    },
    closeConnection({state}) {
        state.ws.close()
    }
}

const mutations = {
    SET_WS(state, payload) {
        state.ws = payload
    },
    SET_AUTH(state, message) {
        state.auth = message
    },
    SET_CONNECTION(state, message) {
        state.connected = message;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
