<template>
  <div class="yp-app">
    <yp-notification/>
    <Header/>
    <yp-ticker :items="rates"/>
    <main class="mt-5 container yp-app__content">
      <router-view v-show="isContextLoaded" :style="contentIndex"/>
      <yp-loader class="yp-app__loader" v-if="!isContextLoaded"/>
    </main>
    <Footer v-if="!$store.getters.isMobile"/>
  </div>
</template>


<script>
import Header from "@/components/common/Header";
import Icon from "@/components/ui/Icon";
import Footer from "@/components/common/Footer";
import {mapActions, mapGetters} from 'vuex'
import YpLoader from "@/components/ui/YpLoader";
import YpTicker from "@/components/ui/Ticker";
import YpModal from "@/components/ui/YpModal";
import YpNotification from "@/components/ui/YpNotification";
import YpSnow from "@/components/Christmas/YpSnow.vue";
import YpLights from "@/components/Christmas/YpLights.vue";

export default {
  components: {YpLights, YpSnow, YpNotification, YpModal, YpTicker, YpLoader, Icon, Header, Footer},
  data() {
    return {
      isBrowserModalVisible: false,
    }
  },
  created() {
    localStorage.getItem('disableBrowserModal') ?
        this.isBrowserModalVisible = false :
        this.isBrowserModalVisible = true
  },
  computed: {
    ...mapGetters(['isContextLoaded', 'errors', 'isMobile', 'rates']),

    contentIndex() {
      return this.isMobile ? {} : {'z-index': 100}
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
  },
  watch: {
    errors() {
      this.$notify({
        group: 'internal_errors',
        title: 'Error',
        text: 'Unexpected error! Try to reload.'
      })
    }
  },
  methods: {
    ...mapActions(['getContext']), // test missing getAccounts

    onCancel() {
      localStorage.setItem('disableBrowserModal', 'true')
      this.isBrowserModalVisible = false
    }
  },
}
</script>

<style lang="scss">
.yp-app {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &__loader {
    align-self: center;
    margin: 0 auto;
  }

  &__bg--primary-blur {
    height: 50vh;
    filter: blur(100px);
    background: $primary;
    opacity: 0.1;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
  }

  .yp-error-message {
    width: 400px;
    margin: 0 auto;
    padding: 30px 50px;
    border: 6px solid $primary;
    align-self: center;
    text-align: center;
    font-weight: 600;

    @media (max-width: 790px) {
      width: 100%;
      padding: 20px 30px;
    }

    &__title {
      font-size: 76px;
      color: $primary;
      @media (max-width: 790px) {
        font-size: 70px;
      }
    }

    &__text {
      font-size: 40px;

      @media (max-width: 790px) {
        font-size: 36px;
      }
    }

    &__footer {
      font-size: 47px;
      @media (max-width: 790px) {
        font-size: 42px;
      }
    }
  }

  &__content {
    padding-bottom: 80px;
    flex-grow: 1;
    display: flex;
  }

  &__bg {
    margin-left: auto;
    width: 200px;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
