import Vue from 'vue'
import App from "./App"

import './filters'
import api, {addResponseHandler, addRequestHandler} from './plugins/axios'
import createStore from './store'
import createRouter from './routes'
import VueMask from 'v-mask'
import Notifications from 'vue-notification'

import './assets/scss/custom.scss'

import i18n from "@/plugins/i18n";

import {BootstrapVue, DropdownPlugin, IconsPlugin} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMask)
Vue.use(Notifications)

Vue.use(DropdownPlugin)

const store = createStore(api)
const router = createRouter(store)

const instance = new Vue({
    el: "#app",
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');

addRequestHandler(
    request => {
        if (request.method === 'post' || request.method === 'put') {
            store.commit('SET_FORM_SENDING_STATE', true)
        }
        return request
    },
    error => {
        console.log('error: ', error)
    }
)

addResponseHandler(
    response => {
        // обработчик ответов от api
        // if (response.status === 200 && response.data.state) {
        //     // тут будет код для успешных ответов, базовая проверка
        // }
        if (response.config.method === 'post' || response.config.method === 'put') {
            store.commit('SET_FORM_SENDING_STATE', false)
        }
        return response
    },
    // обработчик ошибок, проверка на 500
    error => {
        store.commit('SET_FORM_SENDING_STATE', false)
        if ([403, 500].indexOf(error.response.status) != -1) {
            let text = (error.response.data && error.response.data.detail) || instance.$t('snackbarMessages.serverError');
            store.commit('snackbar/SET_SNACK', {
                title: `<span class="text-danger">${instance.$t('snackbarMessages.error')}</span>`,
                type: 'error',
                text,
                duration: 60000,
            })
        }
    }
)

Vue.config.devtools = true