<template>
  <b-button
      :class="[
        'yp-btn',
        { 'yp-btn--icon': isIconBtn },
        { 'yp-btn--ghost': ghost },
        `yp-btn--${size}`,
        // `yp-btn--${variant}`
      ]"
      variant="default"
      :style="styles"
      :disabled="disabled || $store.getters.isFormSending"
      @click="throttleHandler"
  >
    <icon
        v-if="icon"
        :name="icon"
        :color="iconColor"
        :width="iconSize"
        :height="iconSize"
    />
    <span v-if="!timer"> {{ label }} </span>
    <span
        class="yp-btn__timer"
        v-else
    >
      {{ timer }}
    </span>
  </b-button>
</template>

<script>
import Icon from "@/components/ui/Icon"
import {throttle} from "utils/helpers"

export default {
  name: "YpButton",
  props: {
    size: {
      type: String,
      default: 'md'
    },
    iconSize: {
      type: String,
      default: '20px',
    },
    disabled: Boolean,
    variant: {
      type: String,
      default: 'default'
    },
    timer: Object,
    isIconBtn: Boolean,
    ghost: Boolean,
    iconColor: String,
    icon: String,
    label: String,
    outline: Boolean,
    square: Boolean,
  },
  components: {Icon},
  created() {
    this.throttleHandler = throttle((val) => {
      this.onClick()
    }, 1000)
  },
  beforeUnmount() {
    this.throttleHandler.cancel()
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  },
  computed: {
    styles() {
      let styles = {}
      if (this.isIconBtn && !this.square) {
        styles.borderRadius = '50%'
        styles.width = this.iconSize
        styles.height = this.iconSize
        styles.padding = 0
        styles.gap = 0
      }
      return styles
    }
  },
}
</script>
