var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-textarea",
      class: { "yp-textarea--outlined": _vm.outlined },
    },
    [
      _c("div", { staticClass: "yp-textarea__title" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
      _vm._v(" "),
      _c("b-textarea", {
        ref: "textarea",
        staticClass: "yp-textarea__input",
        class: [{ "yp-textarea__input--dark": _vm.mode === "dark" }],
        attrs: { formatter: _vm.formatter, placeholder: _vm.placeholder },
        on: {
          input: function ($event) {
            return _vm.$emit("update", _vm.value)
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "yp-textarea__counter",
          class: { "text-danger": _vm.errorLengthState },
        },
        [
          _vm._v(
            "\n    " + _vm._s(`${_vm.inputCount}/${_vm.maxLength}`) + "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }