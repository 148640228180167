<template>
  <div class="yp-modal" @click.self="$emit('closeModal')">
    <div class="yp-modal__body">
      <yp-button
          class="yp-modal__close"
          icon="Close"
          icon-size="16px"
          is-icon-btn
          ghost
          @click="$emit('closeModal')"
      />
      <div class="yp-modal__title" v-if="!withoutTitle">
        {{ title }}
      </div>
      <slot #modal-content />
      <div v-if="errors">
        <yp-msg v-for="(err, key) in errors" :key="key" :msg="err" />
      </div>
      <div v-if="!withoutActions" class="yp-modal__actions">
        <yp-button
            size="xl"
            variant="primary-gradient"
            :label="confirmBtn"
            :disabled="isConfirmDisabled"
            @click="$emit('confirm')"
        />
        <yp-button
            size="xl"
            :label="cancelBtn"
            @click="onCancelBtnClick()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button"
import YpMsg from "@/components/ui/YpMsg"

export default {
  name: "YpModal",
  components: {YpButton, YpMsg},
  props: {
    title: String,
    divider: Boolean,
    confirmBtn: String,
    cancelBtn: String,
    withoutActions: Boolean,
    errors: [Object, Array],
    withoutTitle: Boolean,
  },
  data() {
    return {
      isConfirmDisabled: false,
    }
  },
  updated() {
    let countErr = 0
    for(let key in this.errors) {
      if(this.errors.hasOwnProperty(key)) {
        countErr ++
      }
    }
    countErr > 0 ? this.isConfirmDisabled = true : this.isConfirmDisabled = false
  },
  computed: {
  },
  created() {
    document.body.style.overflow = 'hidden'
    window.addEventListener('keyup', this.escapeHandler)
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto'
    window.removeEventListener('keyup', this.escapeHandler)
  },
  methods: {
    onCancelBtnClick() {
      this.$emit('closeModal')
      this.$emit('cancel')
    },
    escapeHandler(event) {
      if(event.keyCode === 27) {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-modal {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  backdrop-filter: blur(10px);
  background: rgba(57, 74, 101, 0.25);

  &__body {
    position: relative;
    max-width: 450px;
    padding: 30px;
    background: $black-secondary;
    border-radius: 15px;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
</style>
