<template>
  <section class="auth-link">
    <div v-show='!isDone && !err && !isTfa' class="auth-link__loading">
      <yp-loader />
      <h2>
        Log in...
      </h2>
    </div>
    <div v-if="isTfa" class="auth-link__tfa">
      <yp-input
          :label="$t('loginPage.tfaPlaceholder')"
          mask="### ###"
          placeholder="000 000"
          @update="tfaKey = $event"
          @inputError="tfaKey = null"
      />
      <yp-message v-for="(err, key) in tfaErrors" :key="key" :msg="err" size="lg"  />
      <yp-button
          variant="primary-gradient"
          :label="$t('submit')"
          size="xl"
          @click="onTfaSubmit"
      />
    </div>
    <div class="container text-center" v-if="err">
      <icon name="LoginBlock" width="120" height="75" />
      <h1 class="mt-5">{{ $t('loginPage.loginError') }}</h1>
      <yp-button
          class="mx-auto mt-5"
          variant="gradient-outline"
          label="Update"
          size="xl"
          @click="onUpdate"
      />
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import YpLoader from "@/components/ui/YpLoader";
import Icon from "@/components/ui/Icon";
import YpButton from "@/components/ui/Button";
import YpInput from "@/components/ui/YpInput";
import YpMessage from "@/components/ui/YpMsg.vue";

export default {
  components: {YpMessage, YpInput, YpButton, Icon, YpLoader},
  data() {
    return {
      isDone: false,
      err: false,
      tfaErrors: null,
      isTfa: false,
      tfaKey: null,
    }
  },
  computed: {
    ...mapGetters(['botName'])
  },
  created() {
    this.loginWithLink(this.$route.params).then(response => {
      if(!response.state && !response.is_tfa) this.err = true
      if(response.is_tfa) this.isTfa = true
      if(response.state) this.$router.push({name: 'home'})
    })
  },
  methods: {
    ...mapActions(['loginWithLink', 'sendTfaKey']),

    onUpdate() {
      window.location.href = `https://telegram.me/${this.botName}`
    },
    onTfaSubmit() {
      const key = this.tfaKey.split(' ').join('')
      if (key) {
        this.sendTfaKey({key: this.$route.params.key, tfakey: key}).then(response => {
          if (response.errors) this.tfaErrors = response.errors
          if (response.state) this.$router.push({name: 'cabinet'})
        })
      }
    }
  }
}
</script>

<style lang="scss">
.auth-link {
  flex-grow: 1;
  display: flex;
  align-items: center;

  &__tfa {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  &__loading {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
</style>
