<template>
<div class="new-google-account">
    <div class="new-google-account__form">
        <yp-input
            type="email"
            label="Email:"
            :external-val="email"
            auto-focus
            placeholder="example@example.com"
            @update="email = $event"
            />
        <b-form-select
                class="new-google-account__selector"
                v-model="timezone"
                :options="availableTimezones"
                />
        <div class="new-google-account__form-line">
            <yp-input
                type="number"
                :label="`Amount (${currentAccount.currency_code}):`"
                :placeholder="`min value: ${currentAccount.google_amount_min || $store.getters['google/minGoogleRate']}`"
                :external-val="amount"
                :min-value="currentAccount.google_amount_min || $store.getters['google/minGoogleRate']"
                :max-value="currentAccount.amount"
                @update="amount = $event"
                />
            <yp-button
                class="new-google-account__submit"
                :disabled="!amount || !email"
                icon="Checkbox"
                :label="$t('googleAdPage.submit')"
                variant="primary-gradient"
                @click="onSubmit"
                />
        </div>
    </div>
    <div v-if="errors" class="mt-1">
        <yp-msg v-for="(err, key) in errors" :key="key" :msg="err" />
    </div>
    <div
        v-show="commission"
        class="new-google-account__commission yp-additional-text"
        >
        {{ `${$t('commission')} + (${currentAccount.fee.withdraw.percent}%): ${commission} ${currentAccount.currency_code}` }}
    </div>
    <yp-modal
        v-if="isModalVisible"
        :title="$t('googleAdPage.createAccountModal.title')"
        :confirm-btn="$t('googleAdPage.createAccountModal.confirmBtn')"
        :cancel-btn="$t('cancelBtn')"
        :errors="errors"
        @confirm="onConfirm"
        @closeModal="isModalVisible = false"
        >
        <div>
            <span class="text-info">{{ $t('email') + ': ' }}</span>
            {{ email }}
        </div>
        <div>
            <span class="text-info">{{ $t('amount') + ': ' }}</span>
            {{ amount + currentAccount.currency_code }}
        </div>
        <div>
            <span class="text-info">{{ $t('commission') + ': ' }}</span>
            {{ commission + currentAccount.currency_code }}
        </div>
        <div>
            <span class="text-info">{{ $t('total') + ': ' }}</span>
            {{ total + currentAccount.currency_code }}
        </div>
    </yp-modal>
</div>
</template>

<script>
import YpInput from "@/components/ui/YpInput"
import YpButton from "@/components/ui/Button"
import YpModal from "@/components/ui/YpModal"
import YpMsg from "@/components/ui/YpMsg"

import {mapActions, mapGetters} from "vuex";

export default {
    name: "NewAccount",
    components: {YpModal, YpButton, YpInput, YpMsg},
    data() {
        return {
            email: null,
            amount: null,
            isModalVisible: false,
            timezone: null,
            availableTimezones: [
                {
                    "value": "America/Adak",
                    "text": "UTC-10"
                },
                {
                    "value": "America/Anchorage",
                    "text": "UTC-9"
                },
                {
                    "value": "America/Ensenada",
                    "text": "UTC-8"
                },
                {
                    "value": "America/Phoenix",
                    "text": "UTC-7"
                },
                {
                    "value": "America/Denver",
                    "text": "UTC-6"
                },
                {
                    "value": "America/Chicago",
                    "text": "UTC-5"
                },
                {
                    "value": "America/New_York",
                    "text": "UTC-4"
                },
                {
                    "value": "America/Maceio",
                    "text": "UTC-3"
                },
                {
                    "value": "America/Noronha",
                    "text": "UTC-2"
                },
                {
                    "value": "Atlantic/Cape_Verde",
                    "text": "UTC-1"
                },
                {
                    "value": "Africa/Abidjan",
                    "text": "UTC+0"
                },
                {
                    "value": "Europe/London",
                    "text": "UTC+1"
                },
                {
                    "value": "Europe/Luxembourg",
                    "text": "UTC+2"
                },
                {
                    "value": "Europe/Helsinki",
                    "text": "UTC+3"
                },
                {
                    "value": "Asia/Tbilisi",
                    "text": "UTC+4"
                },
                {
                    "value": "Asia/Samarkand",
                    "text": "UTC+5"
                },
                {
                    "value": "Asia/Bishkek",
                    "text": "UTC+6"
                },
                {
                    "value": "Asia/Bangkok",
                    "text": "UTC+7"
                },
                {
                    "value": "Asia/Hong_Kong",
                    "text": "UTC+8"
                },
                {
                    "value": "Asia/Seoul",
                    "text": "UTC+9"
                },
                {
                    "value": "Asia/Vladivostok",
                    "text": "UTC+10"
                },
                {
                    "value": "Asia/Sakhalin",
                    "text": "UTC+11"
                },
                {
                    "value": "Asia/Anadyr",
                    "text": "UTC+12"
                },
                {
                    "value": "Pacific/Kanton",
                    "text": "UTC+13"
                },
                {
                    "value": "Pacific/Kiritimati",
                    "text": "UTC+14"
                }
            ],
            errors: {},
        }
    },
    created() {
        this.timezone = this.availableTimezones[11].value
    },
    watch: {
        email(newVal, oldVal) {
            if(newVal !== oldVal && this.errors.email) this.errors = []
        },
        amount: {
            handler(val) {
                if(this.total > this.currentAccount.amount) {
                    this.errors.amount = 'Insufficient funds to complete the operation'
                } else {
                    delete this.errors.amount
                }
            }
        }
    },
    computed: {
        ...mapGetters("google", ["currentAccount"]),
        commission() {
            return this.amount ? (this.amount / 100 * this.currentAccount.fee.withdraw.percent).toFixed(2) : null
        },
        total() {
            return Number(this.amount) + Number(this.commission)
        },
    },
    methods: {
        // ...mapActions(['getAccounts']),
        ...mapActions("google", ["setAccountData", "getAccountsList"]),
        onSubmit() {
            this.isModalVisible = true
        },
        async onConfirm() {
            const response = await this.setAccountData({
                amount: this.amount,
                timezone: this.timezone,
                currency_code: this.currentAccount.currency_code,
                email: this.email
            })
            if (response.state) {
                this.email = null
                this.amount = null
                this.isModalVisible = false
                await this.getAccountsList()
            } else {
                this.errors = response.errors
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.new-google-account {
    &__selector {
        width: 120px;
        option {
            background: #0e141e !important;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
            color: white !important;
            /* background: linear-gradient(75.21deg, #0E141E 0%, #394A65 100%); */
        }
    }
    &__form {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        @media (max-width: 790px) {
            flex-direction: column;
        }
    }
    &__form-line {
        display: flex;
        gap: 20px;
        align-items: center;
        width: 100%;
    }
    &__commission {
        margin-top: 20px;
        width: 290px;
        color: $primary;
        font-weight: 600;
        font-size: 13px;
        padding: 5px 0;
        background: $light;
        text-align: center;
        border-radius: 20px;
        @media (max-width: 790px) {
            width: 100%;
        }
    }
    &__submit {
        @media (max-width: 790px) {
            width: 100%;
        }
    }
}
</style>
