<template>
  <div class="yp-welcome">
    <h1 class="text-center"> {{ $t('welcomePage.title') }} </h1>
    <div class="yp-welcome__info mt-3">
<!--      <div class="yp-welcome__info-block">-->
<!--        <div class="text-info mb-3">Your usdt trc20 wallet:</div>-->
<!--        <yp-qr v-if="qrSrc" :src="`data:image/svg+xml;base64,${qrSrc}`"/>-->
<!--      </div>-->
      <div class="yp-welcome__info-block" v-show="balances.length > 0">
        <div class="text-info mb-3 text-center">Your balance:</div>
        <yp-sheet class="yp-welcome__balance">
          <div v-for="account in balances" v-show="account.amount">
            <icon class="yp-welcome__balance-icon" width="20" height="20" :name="$store.getters['catalogs/currenciesWithIcons'](account.currency_id).iconName" />
            Amount: {{ account.amount }}
            <span class="text-info">Hold: {{ account.amount_holded }}</span>
            <strong>{{ account.currency_code }}</strong>
          </div>
        </yp-sheet>
      </div>
      <div class="mobile-clue__wrapper" v-show="$store.getters.isMobile">
        <router-link
          v-for="(item, key) in mobile_clue"
          v-if="checkPermission(item) && item.name !== 'profile'"
          :key="key"
          :to="{ name: item.name }"
          exact-active-class="active-item"
          class="mobile-clue__link"
        >
          <icon class="mobile-clue__link-icon--stroke" :name="item.icon" :width="item.icon !== 'CardToCard' ? '28' : '36'" height="28"/>
          <span>{{ $t(`nav.${item.name}`) }}</span>
        </router-link>
        <span class="mobile-clue__link" @click="setMobileNav(true)"> All Services </span>
      </div>
    </div>
    <div class="yp-welcome__text">{{ $t('welcomePage.text') }}</div>
    <div class="yp-welcome__info">
      <div class="yp-welcome__info-block">
        <div>{{ $t('welcomePage.supportText') }}</div>
        <a class="yp-welcome__link" href="mailto:support@yeezypay.io">
          <Icon name="Mail"/>
          support@yeezypay.io
        </a>
      </div>
      <div class="yp-welcome__info-block">
        <div>{{ $t('welcomePage.telegram') }}</div>
        <a class="yp-welcome__link" target="_blank" href="https://t.me/yeezy_aff">
          <Icon name="Telegram"/>
          @yeezy_aff
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import YpQr from "@/components/ui/YpQr";
import {mapGetters, mapMutations} from 'vuex'
import YpSheet from "@/components/ui/Sheet";

export default {
  name: "Welcome",
  components: {YpSheet, YpQr, Icon},
  data() {
    return {
      mobile_clue: [
        {
          name: "account-deposit",
          path: "account-deposit",
          icon: "AddMoney",
          permission: "Payment"
        },
        {
          name: "exchange",
          path: "exchange",
          icon: 'Transfers',
          permission: 'Exchange'
        },
        {
          name: "payout",
          path: "payout",
          icon: "Payouts",
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['accounts', 'permissions']),

    balances() {
      return this.accounts.filter(acc => acc.amount !== 0)
    },
  },
  methods: {
    ...mapMutations(['setMobileNav']),
    checkPermission(route) {
      if (route.icon) {
        return !(route.permission && !this.permissions.includes(route.permission));
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-enter-active, .v-leave-active {
  transition: transform 0.2s ease;
}

.v-enter,
.v-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.mobile-clue {
  box-shadow: 0px 10px 20px rgba(112, 144, 176, 0.07);
  z-index: 100;

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-width: 70vw;
  }

  &--mobile-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding: 0 15px;
  }

  &--mobile-bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $black-secondary;
    height: 70px;
    align-items: center;
    z-index: 99;
    padding: 0 15px;
    justify-content: space-between;
  }

  &__mobile-nav {
    position: fixed;
    left: 0;
    overflow: hidden;
    z-index: 100;
    background: black;
    top: 0;
    width: 20%;
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 1100px) {
      width: 35%;
    }

    @media screen and (max-width: 1000px) {
      width: 40%;
    }

    @media screen and (max-width: 600px) {
      width: 80%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    height: 5.3125rem;
  }

  &__nav {
    margin: 0 auto;
    display: flex;
    gap: 40px;
  }

  &__link-icon {
    &--stroke {
    }
  }

  &__link {
    text-decoration: none;
    color: $secondary;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;

    @media (max-width: 1400px) {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 20px;
      border-bottom: 1px solid $border-color;
    }

    .icon {
      stroke: none;
      fill: $secondary;
    }

    &:hover {
      color: $primary;

      .icon {
        stroke: none;
        fill: $primary;
      }
    }

    &.active {
      color: $primary;
      fill: $primary;

      .icon {
        stroke: none;
        fill: $primary;
      }
    }
  }

  &__link:first-child {
    border-top: 1px solid #2f3447;
  }

  &__link:last-child {
    align-self: center;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    gap: 15px;
    margin-left: auto;
  }

  &__action-btn {
    border: none;
  }
}
.yp-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__text {
    margin: 3.125rem 0;
    color: $info;
    font-size: 1.25rem;
  }

  &__balance {
    max-width: 500px;
    overflow-y: auto;

    @media (max-width: 790px) {
      width: 100%;
      height: auto;
      overflow-y: auto;
    }
  }

  &__balance-icon {
    fill: #fff;
    stroke: transparent;
  }

  &__info {
    display: flex;
    gap: 50px;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  &__link {
    .icon {
      stroke: $primary;
    }

    text-decoration: none;
  }
}
</style>
