import moment from 'moment'

function getDate(date) {
    return moment.unix(date).format('DD.MM.YY')
}

function getDateAndTime(date) {
    return moment.unix(date).format('DD.MM.YY hh:mm:ss')
}

function disableAfterToday(date) {
    const today = moment()
    return moment(date).isAfter(today)
}

function getTimestampFromDate(date){
    let timezone_offset = date.getTimezoneOffset()
    return new Date(date.getTime() - timezone_offset * 60 * 1000).valueOf() / 1000
}

function toSecondsFrom1970(dates) {
    if(dates.length >= 1) {
        let result = []
        dates.forEach(date => {
            result.push(getTimestampFromDate(date))
        })
        return result
    } else {
        return getTimestampFromDate(dates)
    }
}

function isActualTimestamp(timestamp) {
    const timer = moment(moment.unix(timestamp)).add(10, 'minutes')
    return !timer.isBefore()
}

export {getDate, getDateAndTime, disableAfterToday, toSecondsFrom1970, isActualTimestamp}
