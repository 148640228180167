<template>
  <div class="yp-input yp-input--crypto">
    <b-form-input
        v-model="value"
        class="yp-input__input"
        :placeholder="placeholder"
        :autofocus="autoFocus"
        :readonly="readonly"
        :disabled="disabled"
        :style="[{ padding: '29.5px 45px 11.5px 45px' }]"
        @input="debounceHandler()"
    />
    <icon class="yp-input__logo" :name="iconName" />
    <div class="yp-input__label">
      {{ $t('cryptoInputLabel') }}:
    </div>
    <div v-if="errors.length" class="yp-input__info">
      <icon id="infoAddress" name="Info" />
      <b-tooltip class="yp-input__tooltip" target="infoAddress">
        <div class="font-weight-bold text-danger">
          Amount entered incorrectly.
        </div>
        <div class="font-weight-bold text-info" v-for="(error, key) in errors" :key="key">
          {{ error }}
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon"
import {debounce} from "utils/helpers"
import {mapGetters} from "vuex";

export default {
  name: "YpInputCrypto",
  components: {Icon},
  props: {
    autoFocus: {
      type: Boolean,
      default:() => false,
    },
    initValue: String,
    readonly: Boolean,
    disabled: {
      type: Boolean,
      default:() => false,
    }
  },
  data() {
    return {
      value: null,
      mask: [],
      errors: []
    }
  },
  watch: {
    '$store.getters.currentAccount': {
      handler(val) {
        this.validate(this.value)
      }
    }
  },
  computed: {
    ...mapGetters(['currentAccount']),
    contract() {
      return this.currentAccount.currency_code.substr(this.currentAccount.currency_code.lastIndexOf('-') + 1).toLowerCase()
    },
    iconName() {
      if (this.contract == 'ton') {
        return 'Ton';
      }
      if (this.contract == 'bnb') {
        return 'Bnb';
      }
      const currency = this.currentAccount.currency_code.substr(0, this.currentAccount.currency_code.indexOf('-')).toLowerCase()
      return currency === 'usdc' ? 'usdc' : 'Tether'
    },
    placeholder() {
      const str = 'Must start with '
      switch (this.contract) {
        case 'trc20':
          return `${str} 'T'`
        case 'bnb':
        case 'erc20':
          return `${str} '0x'`
        case 'ton':
          return `${str} 'UQ'`
      }
    }
  },
  created() {
    if(this.initValue) this.value = this.initValue
    this.debounceHandler = debounce((val) => {
      this.validate(val)
    }, 500)
  },
  beforeUnmount() {
    this.debounceHandler.cancel()
  },
  methods: {
    validate(val = this.value) {
      this.errors = []
      if (this.contract == 'ton') {
        if (val.length !== 48) {
          this.errors.push('TON address must be exactly 48 characters long.');
        }
        if (!val.startsWith('UQ')) {
          this.errors.push('Use non-bounceable TON addresses only (starting with "UQ").');
        }
      } else {
        const pattern = ['erc20', 'bnb'].includes(this.contract) ? '0x' : 'T'
        const length = ['erc20', 'bnb'].includes(this.contract) ? 42 : 34
        if(val.length !== length) {
          this.errors.push(`Value length must be ${length} characters`)
        }
        if(this.contract === 'trc20' && val[0] !== pattern) {
          this.errors.push(`First character must be "${pattern}"`)
        }
        if(['erc20', 'bnb'].includes(this.contract) && val[0] !== pattern[0] && val[1] !== pattern[1]) {
          this.errors.push(`First character must be "${pattern}"`)
        }
      }
      if(this.errors.length === 0) this.$emit('update', val)
      else this.$emit('inputError')
    }
  }
}
</script>