var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-ticker",
      class: { "yp-ticker--dark": _vm.$store.getters.mode === "dark" },
    },
    [
      _c(
        "div",
        { staticClass: "yp-ticker__list" },
        _vm._l(_vm.items, function (item, key) {
          return _c("div", { key: key, staticClass: "yp-ticker__item" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  `${item.currency_from}/${
                    item.currency_to
                  } ${item.human_rate.toFixed(item.denominator_from)}`
                ) +
                "\n    "
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }