<template>
  <section class="yp-card-to-card">
    <h2 class="text-center">
      {{ $t('cardToCard.title') }}
    </h2>
    <yp-sheet class="yp-card-to-card__content yp-content">
      <div class="yp-card-to-card__cards">
        <yp-card
            class="yp-card-to-card__card"
            :head-title="$t('cardToCard.form.fromTitle')"
            icon="FlagPlaceholder"
        />
        <icon name="ChevronRight" v-show="!$store.getters.isMobile" />
        <yp-card
            class="yp-card-to-card__card"
            :head-title="$t('cardToCard.form.toTitle')"
            icon="FlagPlaceholder"
            card-type="to"
        />
      </div>
      <yp-button
          class="yp-card-to-card__action"
          variant="primary-gradient"
          size="xl"
          :label="$t('cardToCard.form.sendMoney')"
          disabled
      />
    </yp-sheet>
  </section>
</template>

<script>
import YpSheet from "@/components/ui/Sheet";
import YpCard from "@/components/ui/YpCard";
import Icon from "@/components/ui/Icon";
import YpButton from "@/components/ui/Button";
export default {
  name: "CardToCard",
  components: {YpButton, Icon, YpCard, YpSheet}
}
</script>

<style lang="scss" scoped>
.yp-card-to-card {
  width: 100%;

  &__cards {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 790px) {
      flex-direction: column;
    }
  }
  &__action {
    margin: 30px auto 0;

    @media (max-width: 790px) {
      width: 100%;
      margin-top: 15px;
    }
  }
  &__card {
    width: 100%;
    height: 100%;
  }
}
</style>
