var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yp-progress" }, [
    _c(
      "div",
      { staticClass: "yp-progress__wrap", style: _vm.wrapColor },
      [
        !_vm.parts
          ? [
              _c("div", { staticClass: "yp-progress__text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text[0] + _vm.text.slice(1).toLowerCase()) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "yp-progress__ride",
                style: {
                  backgroundColor: _vm.progressColor,
                  width: `${_vm.current}%`,
                },
              }),
            ]
          : [
              _c(
                "div",
                {
                  staticClass: "yp-progress__text",
                  class: { "yp-progress__ride--parts": _vm.parts },
                },
                [_vm._v("\n        " + _vm._s(_vm.rideText()) + "\n      ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "yp-progress__ride",
                style: {
                  backgroundColor: _vm.progressColor,
                  width: `${_vm.totalPercent()}%`,
                },
              }),
            ],
      ],
      2
    ),
    _vm._v(" "),
    !_vm.parts
      ? _c("div", { staticClass: "yp-progress__percent" }, [
          _vm._v("\n    " + _vm._s(_vm.current + "%") + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }