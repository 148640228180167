var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "yp-profile" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("profilePage.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "yp-sheet",
        { staticClass: "yp-profile__content yp-content mx-auto" },
        [
          _vm.account
            ? _c("div", { staticClass: "mb-2" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("profilePage.accountInfo.title") + ": ")
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("profilePage.accountInfo.phone") +
                        ": " +
                        _vm.account.phone
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s("Telegram name: " + _vm.account.telegram_username)
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s("Login: " + _vm.account.username))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("yp-checkbox", {
            staticClass: "mt-3",
            attrs: {
              label: _vm.$t("profilePage.twoFactor"),
              "switch-mode": "",
              "external-val": _vm.tfaCheckbox,
            },
            on: { change: _vm.onTFAChange },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "profile-account-settings" },
            [
              _c("h3", { staticClass: "mt-3 text-center" }, [
                _vm._v("\n        Authorziation Settings\n      "),
              ]),
              _vm._v(" "),
              _vm.isLoading
                ? _c("yp-loader", {
                    staticClass: "align-self-center yp-page-content",
                    staticStyle: { left: "50%", transform: "translateX(-50%)" },
                  })
                : _c(
                    "div",
                    { staticClass: "profile-account-settings__theme" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "profile-account-settings__theme-block",
                        },
                        [
                          _c("p", [_vm._v("Login:")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.account.username,
                                expression: "account.username",
                              },
                            ],
                            staticClass:
                              "yp-input__input form-control yp-input__input--dark",
                            domProps: { value: _vm.account.username },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.account,
                                  "username",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticStyle: { "margin-top": "15px" } }, [
                            _vm._v("New password:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm.showPassword
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                    ],
                                    staticClass:
                                      "yp-input__input form-control yp-input__input--dark",
                                    attrs: {
                                      type: "text",
                                      placeholder: "********",
                                    },
                                    domProps: { value: _vm.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.password = $event.target.value
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.password,
                                        expression: "password",
                                      },
                                    ],
                                    staticClass:
                                      "yp-input__input form-control yp-input__input--dark",
                                    attrs: {
                                      type: "password",
                                      placeholder: "********",
                                    },
                                    domProps: { value: _vm.password },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.password = $event.target.value
                                      },
                                    },
                                  }),
                              _vm._v(" "),
                              _c("yp-button", {
                                staticClass: "cancelFill",
                                staticStyle: {
                                  height: "auto",
                                  "border-radius": "10px",
                                  display: "block",
                                },
                                attrs: {
                                  icon: _vm.toggleEye,
                                  variant: "primary-gradient",
                                },
                                on: { click: _vm.toggleShowPassword },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", { staticStyle: { "margin-top": "15px" } }, [
                            _vm._v("Repeat password:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm.showRepeatPassword
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.repeatPassword,
                                        expression: "repeatPassword",
                                      },
                                    ],
                                    staticClass:
                                      "yp-input__input form-control yp-input__input--dark",
                                    attrs: {
                                      type: "text",
                                      placeholder: "********",
                                    },
                                    domProps: { value: _vm.repeatPassword },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.repeatPassword = $event.target.value
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.repeatPassword,
                                        expression: "repeatPassword",
                                      },
                                    ],
                                    staticClass:
                                      "yp-input__input form-control yp-input__input--dark",
                                    attrs: {
                                      type: "password",
                                      placeholder: "********",
                                    },
                                    domProps: { value: _vm.repeatPassword },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.repeatPassword = $event.target.value
                                      },
                                    },
                                  }),
                              _vm._v(" "),
                              _c("yp-button", {
                                staticClass: "cancelFill",
                                staticStyle: {
                                  height: "auto",
                                  "border-radius": "10px",
                                  display: "block",
                                },
                                attrs: {
                                  icon: _vm.toggleEyeRepeat,
                                  variant: "primary-gradient",
                                },
                                on: { click: _vm.toggleShowRepeatPassword },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("yp-button", {
                            class: { "w-100": true, "mt-3": true },
                            attrs: {
                              disabled: _vm.isSubmitDisabled,
                              label: "Save",
                              variant: "primary-gradient",
                            },
                            on: { click: _vm.onSubmit },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h3", { staticClass: "mt-3 text-center" }, [
            _vm._v(_vm._s(_vm.$t("profilePage.sessions.title"))),
          ]),
          _vm._v(" "),
          _vm.account.sessions
            ? _c(
                "div",
                { staticClass: "yp-profile__sessions" },
                [
                  _c("div", { staticClass: "yp-profile__sessions-head" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profilePage.sessions.system")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profilePage.sessions.browser")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profilePage.sessions.action")) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.account.sessions, function (session, key) {
                    return _c("yp-session", {
                      key: key,
                      attrs: { session: session },
                      on: { endSession: _vm.onEndSession },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showQrModal && _vm.twoFactorQR
        ? _c(
            "yp-modal",
            {
              staticClass: "yp-profile__qr-modal",
              attrs: {
                title: _vm.$t("profilePage.twoFactorModal.title"),
                "confirm-btn": _vm.$t("profilePage.twoFactorModal.confirmBtn"),
                "cancel-btn": _vm.$t("profilePage.twoFactorModal.cancelBtn"),
              },
              on: {
                closeModal: _vm.onCloseQrModal,
                confirm: _vm.onQrModalConfirm,
              },
            },
            [
              _c(
                "div",
                { staticClass: "yp-profile__qr-modal-content" },
                [
                  _c("div", [
                    _c("div", { staticClass: "text-info" }, [
                      _vm._v(
                        "1." +
                          _vm._s(_vm.$t("profilePage.twoFactorModal.firstStep"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-info" }, [
                      _vm._v(
                        "2." +
                          _vm._s(
                            _vm.$t("profilePage.twoFactorModal.secondStep")
                          )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-info" }, [
                      _vm._v(
                        "2." +
                          _vm._s(_vm.$t("profilePage.twoFactorModal.lastStep"))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("yp-qr", { attrs: { src: _vm.qrSrc } }),
                  _vm._v(" "),
                  _c("yp-input", {
                    attrs: {
                      label: "Code",
                      placeholder: "000 000",
                      mask: "### ###",
                    },
                    on: {
                      update: function ($event) {
                        _vm.twoFactorKey = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDisableTfaModal && _vm.isTfaEnable
        ? _c("yp-modal", {
            attrs: {
              title: _vm.$t("profilePage.disableTwoFactorModal.title"),
              "confirm-btn": _vm.$t(
                "profilePage.disableTwoFactorModal.confirmBtn"
              ),
              "cancel-btn": _vm.$t(
                "profilePage.disableTwoFactorModal.cancelBtn"
              ),
            },
            on: {
              confirm: _vm.onConfirmDisableTFA,
              closeModal: _vm.onCloseDisableTfaModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }