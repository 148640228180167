var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yp-card" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.headTitle,
            expression: "headTitle",
          },
        ],
        staticClass: "yp-card__head",
      },
      [
        _c("div", { staticClass: "text-uppercase" }, [
          _vm._v("\n      " + _vm._s(_vm.headTitle) + "\n    "),
        ]),
        _vm._v(" "),
        _c("icon", { attrs: { name: _vm.icon } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "yp-card__body" },
      [
        _c("yp-card-input", {
          attrs: { label: _vm.$t("cardInputLabel") },
          on: {
            update: function ($event) {
              _vm.cardData.number = $event
            },
            error: function ($event) {
              _vm.cardData.number = null
            },
          },
        }),
        _vm._v(" "),
        _vm.cardType === "from"
          ? [
              _c(
                "div",
                { staticClass: "yp-card__flex" },
                [
                  _c("yp-input", {
                    attrs: {
                      label: `${_vm.$t("cardExpireDate").toUpperCase()}: `,
                      mask: "##/##",
                      placeholder: "00/00",
                    },
                    on: {
                      update: function ($event) {
                        _vm.cardData.date = $event
                      },
                      inputError: function ($event) {
                        _vm.cardData.date = null
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("yp-input", {
                    attrs: {
                      label: `${_vm.$t("cardCvv").toUpperCase()}: `,
                      mask: "###",
                      placeholder: "000",
                    },
                    on: {
                      update: function ($event) {
                        _vm.cardData.cvv = $event
                      },
                      inputError: function ($event) {
                        _vm.cerdData.cvv = null
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.cardType === "to"
          ? [
              _c("yp-input", {
                attrs: {
                  type: "number",
                  label: `${_vm.$t("amount")}: `,
                  placeholder: "0.00",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "yp-card__commission yp-additional-text text-primary",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("payoutsPage.commission") + "(2%):  20руб"
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }