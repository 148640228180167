<template>
  <div
      class="operation-item-wrap"
      @mouseover="hover = true"
      @mouseleave="hover = false"
  >
    <div
        class="operation-item"
        :class="{ 'active': isActive }"
        @click="onClick"
    >
      <div class="operation-item__info">
        <div v-show="!isMobile" class="operation-item__id">
          <span
              v-if="value.external_id"
          >
            {{ '#' + value.external_id }}
          </span>
          <span v-else>{{ '#' + value.pk }}</span>
        </div>
        <template v-if="value.amount">
          <div v-if="value.method === operation_methods['VIRTUAL_CARD'] && value.kind === operation_kinds['WITHDRAW']" class="operation-item__amount">
            {{ amount }}
            <p>
              <icon name="Card" width="24px" height="24px"/>
            </p>
          </div>
          <div v-else-if="value.method === operation_methods['VIRTUAL_CARD'] && (value.kind === operation_kinds['CARD_TOPUP'] || value.kind === operation_kinds['CARD_OPEN'] )" class="operation-item__amount">
              {{ amount.slice(2) }}
            <p>
              <icon name="Card" width="24px" height="24px"/>
              <icon style="stroke: #787f9b; fill: transparent;" name="ArrowUp" width="24px" height="24px"/>
              <icon style="stroke: #787f9b; fill: transparent;" name="Profile" width="24px" height="24px"/>
            </p>
          </div>
          <div v-else class="operation-item__amount">
            {{ amount }}
          </div>
        </template>
        <div v-else class="operation-item__kind">
          {{ value.kind_name }}
        </div>
      </div>
      <yp-progress
          class="operation-item__progress"
          :text="value.status_name"
          :current="100"
          :progress-style="value.status"
      />
      <div v-show="isMobile" class="operation-item__id">
          <div
              v-if="value.external_id"
              class="flex-shrink-0 operation-item__id-text_mobile"
          >
            {{ '#' + value.external_id }}
          </div>
          <div
              v-else
              class="flex-shrink-0 operation-item__id-text_mobile"
          >{{ '#' + value.pk }}</div>
      </div>
      <div v-if="value.comment" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.comment" class="yp-info-block__text">{{ value.comment }}</div>
      </div>
      <div v-if="value.fee" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.fee" class="yp-info-block__text">Fee {{ value.fee }} {{ value.currency_code }}</div>
      </div>
      <div v-if="txid(value) && value.method == 'CPT'" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div class="yp-info-block__text">TX hash: {{ txid(value) }}</div>
      </div>
      <div v-if="value.receiver && value.method == 'CPT'" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.receiver" class="yp-info-block__text">Receiver: {{ value.receiver }}</div>
      </div>
      <div v-else-if="value.pan" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.pan && value.method == 'PHN'" class="yp-info-block__text">Phone number: {{ value.pan }}</div>
        <div v-else="value.pan" class="yp-info-block__text">Card number: {{ value.pan }}</div>
      </div>
      <div v-else-if="value.card_number" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.card_number" class="yp-info-block__text">Card number: {{ value.card_number }}</div>
      </div>
      <div v-if="value.account_name" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div v-if="value.account_name" class="yp-info-block__text">Name: {{ value.account_name }}</div>
      </div>
      <div v-if="txid(value) && value.method == 'CPT'" class="yp-info-block mt-2">
        <icon name="Info" color="primary"/>
        <div class="yp-info-block__text">
          Link:
          <a target="_blank" :href="getCryptoLinkTemplate(value) + txid(value)">
            {{ ellipsis(value) }}
          </a>
        </div>
      </div>
    </div>
    <Transition>
      <div v-show="hover" class="operation-item__more-info yp-info-block">
        <yp-button
            icon="Info"
            :id="`info-${value.uid}`"
            variant="light"
            icon-color="white"
            ghost
            is-icon-btn
            v-b-tooltip.hover.top.noninteractive
            title="click to get more info"
            @click="$router.push(
                {
                  name: 'operation', params: { id: value.pk },
                  query: {
                    external_id: value.external_id,
                    have_tronscan: haveTronscan(value),
                    have_etherscan: haveEtherscan(value),
                    have_tonviewer: haveTonviewer(value),
                    origin: value.origin,
                    receiver: value.receiver,
                  }
                }
            )"
        />
        <yp-button
            icon="Reload"
            icon-color="white"
            v-if="statusesForRepeat.includes(value.status)"
            :id="`reload-${value.uid}`"
            ghost
            variant="light"
            is-icon-btn
            v-b-tooltip.hover.top.noninteractive
            title="click to repeat operation or select multiple operations and repeat it all"
            @click="operationToRepeat(value)"
        />
      </div>
    </Transition>
  </div>
</template>

<script>


import YpProgress from "@/components/ui/YpProgress";
import {mapGetters, mapActions, mapMutations} from "vuex";
import YpButton from "@/components/ui/Button";
import Icon from "@/components/ui/Icon";

export default {
  name: "OperationItem",
  components: {YpButton, YpProgress, Icon},
  props: {
    value: Object,
  },
  data() {
    return {
      isActive: false,
      hover: false,
      statusesForRepeat: ['C', 'F'],
      operation_kinds: {
        'CARD_OPEN': 'CO',
        'CARD_TOPUP': 'CT',
        'DEPOSIT': 'DE',
        'WITHDRAW': 'WI',
        'EXCHANGE': 'EX',
        'SERVICE': 'SE',
        'SYSTEM': 'SY',
        'ADJUSTMENT': 'AJ'
      },
      operation_methods: {
        'CARD': 'CRD',
        'PHONE': 'PHN',
        'CRYPTO': 'CPT',
        'MANUAL': 'MNL',
        'EXCHANGE': 'EXC',
        'GOOGLE': 'GGL',
        'QIWI': 'QW',
        'VIRTUAL_CARD': 'VC',
        'CLIENT_CARD': 'CC',
        'SBP': 'SBP',
        'SBERPAY': 'SBP',
        'C2C': 'C2C',
        'C2C_GROSS': 'G2T'
      },
      scannersMap: [
        {
          "checker": this.haveTronscan,
          "linkTemplate": 'https://tronscan.org/#/transaction/'
        },
        {
          "checker": this.haveEtherscan,
          "linkTemplate": 'https://etherscan.io/tx/'
        },
        {
          "checker": this.haveTonviewer,
          "linkTemplate": 'https://tonviewer.com/transaction/'
        },
        {
          "checker": this.haveBnbscan,
          "linkTemplate": "https://bscscan.com/tx/"
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    ...mapGetters('catalogs', ['isCrypto']),

    amount() {
      const amount = this.value.amount
      const currency = this.value.currency_suffix
      if (["deposit", "adjustment"].indexOf(this.value.kind_name.toLowerCase()) > -1) {
        return Math.sign(this.value.amount) !== -1 ? `+${String.fromCharCode(160)}${amount} ${currency}` : `${amount}`
      } else return `-${String.fromCharCode(160)}${amount} ${currency}`
    }
  },
  methods: {
    ...mapMutations('payouts', ['SET_SELECTED_OPERATION', 'REMOVE_SELECTED_OPERATION']),
    ...mapActions('payouts', ['getOperation', 'setRepeatOperation']),
    onClick() {
      this.statusesForRepeat.includes(this.value.status) ?
          this.isActive = !this.isActive :
          this.isActive = false
      this.isActive ?
          this.SET_SELECTED_OPERATION(this.value.uid) :
          this.REMOVE_SELECTED_OPERATION(this.value.uid)
    },
    getCryptoLinkTemplate(operation) {
      return this.scannersMap.map(cryptoView => cryptoView.checker(operation) ? cryptoView.linkTemplate : null).filter(el => el !== null)[0]
    },
    ellipsis(value) {
      const txid = this.txid(value);
      return `${txid.slice(0, 12)}...${txid.slice(value.order_id.length - 6)}`;
    },
    txid(value) {
      let txid = value.order_id || value.external_id;
      if (txid == null) {
        return null;
      }
      if (
          ['BNB', 'ETH', 'USDT-TRC20', 'USDC-TRC20'].includes(value.currency_code)
          &&
          !txid.startsWith('0x')
      ) {
        txid = '0x' + txid;
      }
      return txid;
    },
    haveTronscan(value) {
      const allowedCurrencies = ['USDT-TRC20', 'USDT-TRC20', 'TRX'];
      const allowedKinds = ['DE', 'WI'];
      return allowedCurrencies.includes(value.currency_code) && allowedKinds.includes(value.kind);
    },
    haveEtherscan(value) {
      const allowedCurrencies = ['USDT-ERC20', 'USDT-ERC20', 'ETH'];
      const allowedKinds = ['DE', 'WI'];
      return allowedCurrencies.includes(value.currency_code) && allowedKinds.includes(value.kind);
    },
    haveTonviewer(value) {
      const allowedCurrencies = ['TON'];
      const allowedKinds = ['DE', 'WI'];
      return allowedCurrencies.includes(value.currency_code) && allowedKinds.includes(value.kind);
    },
    haveBnbscan(value) {
      const allowedCurrencies = ['BNB'];
      const allowedKinds = ['DE', 'WI'];
      return allowedCurrencies.includes(value.currency_code) && allowedKinds.includes(value.kind);
    },
    operationToRepeat(operation) {
      this.setRepeatOperation(operation)
      const method_page_map_list = {
        'DE': 'account-deposit',
        'WI': {
          'CPT': 'payout-crypto',
          'CRD': 'payout-card',
          'PHN': 'payout-phone',
        },
        'EX': 'exchange'
      }
      let route_name = method_page_map_list[operation.kind]
      if (typeof route_name === 'object') {
        route_name = route_name[operation.method]
      }
      this.$router.push({name: route_name})
    },
  }
}
</script>

<style lang="scss" scoped>
.operation-item-wrap {
  position: relative;
}

.operation-item {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid $border-color;
  cursor: pointer;

  &.active {
    border-color: $primary;
  }

  &__amount svg {
    stroke: transparent;
    fill: #787f9b;
  }

  &__amount p{
    display: grid;
    justify-content: center;
  }

  &__info {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__progress {
    margin-top: 15px;
  }

  &__id {
    color: $info;
    font-size: 12px;
    text-align: center;
    max-width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;

    @media (max-width: 1400px) {
      text-align: center;
      max-width: 100%;
      margin-top: 15px;
    }

    &-text {
      &_mobile {
        max-width: 255px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: auto;
      }
    }
  }

  &__amount, &__kind {
    margin-left: auto;
    position: relative;
    @media (max-width: 1400px) {
      margin-left: unset;
    }
  }

  &__kind {
    text-transform: lowercase;
    font-size: 12px;
  }

  &__mask {
    position: absolute;
    left: 0;
    color: $info;
    max-width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1400px) {
      position: relative;
    }
  }

  &__more-info {
    position: absolute;
    z-index: 3;
    padding: 5px !important;
    display: flex;
    gap: 5px;
    border-radius: 4px;
    right: 1px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
