var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-logo",
      class: { "yp-logo--link": _vm.link },
      on: { click: _vm.onClick },
    },
    [
      _c(
        "div",
        { staticClass: "yp-logo__icon-wrap" },
        [
          _c("icon", {
            staticClass: "yp-logo__icon",
            style: _vm.iconStyles,
            attrs: { name: _vm.logo },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.mini
        ? _c("span", { staticClass: "yp-logo__text", style: _vm.textStyles }, [
            _vm._v("YeezyPay"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }