<template>
  <header class="header">
    <div v-if="!isMobile" class="header__content container">
      <logo link/>
      <div class="header__nav">
        <router-link
            v-for="(item, key) in navList"
            v-if="checkPermission(item) && item.name !== 'profile'"
            :key="key"
            :to="{ name: item.name }"
            exact-active-class="active-item"
            class="header__link"
        >
          <icon class="header__link-icon--stroke header__link-icon--stroke" :name="item.meta.icon" :width="item.meta.icon !== 'CardToCard' ? '28' : '36'" height="28"/>
          <span>{{ $t(`nav.${item.name}`) }}</span>
        </router-link>
      </div>
      <div class="header__actions">
        <yp-button
            class="header__action-btn"
            icon="Profile"
            icon-color="#A6AFD4"
            variant="light"
            is-icon-btn
            @click="$router.push({name: 'profile'})"
        />
        <!--        <Button-->
        <!--            class="header__action-btn"-->
        <!--            :icon="switchIcon"-->
        <!--            icon-color="#A6AFD4"-->
        <!--            variant="light"-->
        <!--            is-icon-btn-->
        <!--            @click="onStyleSwitcherClick"-->
        <!--        />-->
        <yp-button
            class="ml-2 header__action-btn"
            icon="exit"
            icon-color="#A6AFD4"
            variant="light"
            is-icon-btn
            @click="onLogout"
        />
      </div>
    </div>
    <template v-else>
      <div class="header--mobile-top">
        <icon name="burger" width="24px" height="24px" @click.native="setMobileNav(true)"/>
        <Transition>
          <div
              class="header__mobile-nav"
              v-if="isMobileNavVisible"
              v-click-outside="hideMobileNav"
          >
            <yp-button class="ml-auto" icon="Close" is-icon-btn ghost @click="hideMobileNav"/>
            <router-link
                v-for="(item, key) in navList"
                v-if="checkPermission(item)"
                :key="key"
                :to="{ name: item.name }"
                exact-active-class="active-item"
                class="header__link"
                @click.native="hideMobileNav"
            >
              <icon :name="item.meta.icon" width="24px" height="24px"/>
              <span>{{ $t(`nav.${item.name}`) }}</span>
            </router-link>
          </div>
        </Transition>
        <!--        <router-link-->
        <!--            :to="{ name: 'profile' }"-->
        <!--            class="header__link"-->
        <!--        >-->
        <!--          <icon :name="profileRoute.meta.icon" width="24px" height="24px" />-->
        <!--        </router-link>-->
        <logo link/>
        <yp-button
            class="ml-2 header__action-btn"
            icon="exit"
            icon-color="#A6AFD4"
            variant="light"
            is-icon-btn
            @click="onLogout"
        />
      </div>
      <!--      <div class="header&#45;&#45;mobile-bottom">-->
      <!--      </div>-->
    </template>
  </header>
</template>

<script>
import Logo from "@/components/common/Logo";
import Icon from "@/components/ui/Icon";
import YpButton from "@/components/ui/Button";
import clickOutside from "utils/directives";

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: "Header",
  components: {YpButton, Logo, Icon },
  data() {
    return {
      navList: [],
    }
  },
  directives: {clickOutside},
  created() {
    this.$router.options.routes.forEach(route => {
      if (route.name === 'cabinet') this.navList = route.children
    })
  },
  computed: {
    ...mapGetters(['mode', 'permissions', 'isMobile', 'isMobileNavVisible']),

    switchIcon() {
      return this.mode === 'dark' ? 'WeatherSun' : 'WeatherMoon'
    },
    profileRoute() {
      return this.navList.find(el => el.name === 'profile')
    }
  },
  methods: {
    ...mapMutations(['setMobileNav']),
    ...mapActions(['setMode', 'logout']),

    onStyleSwitcherClick() {
      this.mode === 'dark' ? this.setMode('light') : this.setMode('dark')
    },
    checkPermission(route) {
      if (route.meta.icon) {
        return !(route.meta.permission && !this.permissions.includes(route.meta.permission));
      }
    },
    hideMobileNav() {
      this.setMobileNav(false)
    },
    onLogout() {
      this.logout().then(response => {
        if (response.data.state) {
          this.$store.dispatch('wsEvents/closeConnection')
          this.$router.push({name: 'login'})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter-active, .v-leave-active {
  transition: transform 0.2s ease;
}

.v-enter,
.v-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.header {
  box-shadow: 0px 10px 20px rgba(112, 144, 176, 0.07);
  z-index: 100;

  &--mobile-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding: 0 15px;
  }

  &--mobile-bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $black-secondary;
    height: 70px;
    align-items: center;
    z-index: 99;
    padding: 0 15px;
    justify-content: space-between;
  }

  &__mobile-nav {
    position: fixed;
    left: 0;
    overflow: hidden;
    z-index: 100;
    background: black;
    top: 0;
    width: 20%;
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 1100px) {
      width: 35%;
    }

    @media screen and (max-width: 1000px) {
      width: 40%;
    }

    @media screen and (max-width: 600px) {
      width: 80%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    height: 5.3125rem;
  }

  &__nav {
    margin: 0 auto;
    display: flex;
    gap: 40px;
  }

  &__link-icon {
    &--stroke {
    }
  }

  &__link {
    text-decoration: none;
    color: $secondary;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: 1400px) {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 20px;
      border-bottom: 1px solid $border-color;
    }

    .icon {
      stroke: none;
      fill: $secondary;
    }

    &:hover {
      color: $primary;

      .icon {
        stroke: none;
        fill: $primary;
      }
    }

    &.active {
      color: $primary;
      fill: $primary;

      .icon {
        stroke: none;
        fill: $primary;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 15px;
    margin-left: auto;
  }

  &__action-btn {
    border: none;
  }
}
</style>
