<template>
  <div id="app" class="yp-app-wrapper" :class="[themeClasses]">
    <notifications group="internal_errors" classes="yp-notification" :position="position" />
    <router-view/>
    <div v-show="!hasNecessaryData && $route.meta.authRequired" class="yp-app__loader__wrap">
      <logo/>
      <yp-loader class="yp-app__loader yp-app__loader_main"/>
    </div>
  </div>
</template>

<script>
import DefaultLayout from "layouts/DefaultLayout";
import AuthLayout from "layouts/AuthLayout";
import YpLoader from "@/components/ui/YpLoader";
import Logo from "@/components/common/Logo";

import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  components: {DefaultLayout, AuthLayout, YpLoader, Logo},
  data() {
    return {
      preference: null,
    }
  },
  computed: {
    ...mapGetters(['mode', 'permissions', 'hasNecessaryData']),
    themeClasses() {
      return this.mode === 'dark' ? 'bg-dark dark text-light' : ''
    }
  },
  created() {
    const mode = localStorage.getItem('theme')
    const isDarkMedia = window.matchMedia('(prefers-color-scheme: dark)').matches
    if (window.matchMedia && isDarkMedia && !mode) {
      this.preference = isDarkMedia
      this.preference.addEventListener('change', this.checkPreference)
      this.setMode('dark')
    }
    // временно принудительно ставим дарк мод
    this.setMode('dark')
    // this.checkPreference()
    window.addEventListener('resize', () => {
      this.SET_RESOLUTION(screen.width)
    })
  },
  methods: {
    ...mapMutations(['SET_RESOLUTION']),
    ...mapActions(['setMode']),

    checkPreference() {
      if (this.preference) {
        this.setMode('dark')
      } else {
        this.setMode('light')
      }
    }
  }
}
</script>
<style lang="scss">
.yp-app__loader {
  &__wrap {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
}

.yp-app__loader {
  &_main {
    margin-top: 20px;
  }
}
</style>