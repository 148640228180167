import i18n from "@/plugins/i18n"

export default api => ({
    namespaced: true,
    state: {
        googleAdList: [],
        filteredAdList: [],
        hasGoogleBalanceUpdate: false,
        // currentAccount: null,
        isListLoaded: false,
        partLoading: false,
        googleAccAmount: 0,
        gotAmount: false,
        fetchGoogleAccounts: true,
        hasFetchedGoogleAccounts: false,
    },
    getters: {
        isListLoaded: state => state.isListLoaded,
        fetchGoogleAccounts: state => state.fetchGoogleAccounts,
        hasFetchedGoogleAccounts: state => state.hasFetchedGoogleAccounts,
        partLoadingState: state => state.partLoading,
        googleAccAmount: state => state.googleAccAmount,
        gotAmount: state => state.gotAmount,
        googleAdList: state => (status = []) => {
            const arr = state.filteredAdList.length > 0 ?
                state.filteredAdList :
                state.googleAdList
            const list =
                status.length ?
                    arr.filter(el => status.includes(el.status)) :
                    arr
            const filteredArr = []
            list.forEach((el, i) => {
                if (i === 0) {
                    filteredArr.push({
                        name: el.name,
                        list: [el]
                    })
                }
                if (i > 0) {
                    // проверяем есть уже элемент с таким же именем в нашем списке
                    const index = filteredArr.findIndex(item => item.name === el.name)
                    if (index !== -1) {
                        filteredArr[index].list.push(el)
                    } else {
                        filteredArr.push({
                            name: el.name,
                            list: [el]
                        })
                    }
                }
            })
            return filteredArr
        },
        currentAccount: (state, getters, rootState) => {
            return rootState.currentAccount
        },
        isActionsDisabled: state => state.isActionsDisabled,
        minGoogleRate: (state, getters, rootState) => rootState.context.settings.GOOGLE_AMOUNT_MIN,
        hasGoogleBalanceUpdate: state => state.hasGoogleBalanceUpdate,
        googleAccById: state => id => {
            return state.googleAdList.find(el => el.pk === id)
        },
        isAddBtnHidden: (state, getters) => state.googleAccAmount >= getters.currentAccount.google_account_limit,
    },
    mutations: {
        SET_HAS_FETCHED_GOOGLE_ACCOUNTS(state, payload) {
            state.hasFetchedGoogleAccounts = payload
        },
        SET_FETCH_GOOGLE_ACCOUNTS(state, payload) {
            state.fetchGoogleAccounts = payload
        },
        SET_GOOGLE_ACC_AMOUNT(state, payload) {
            state.googleAccAmount = payload
        },
        SET_GOT_GOOGLE_ACC_AMOUNT(state, payload) {
            state.gotAmount = payload
        },
        SET_LOADING_LIST(state, payload) {
          state.isListLoaded = payload
        },
        SET_FILTERED_LIST(state, payload) {
            if (payload.length === state.googleAdList.length) state.filteredAdList = []
            else state.filteredAdList = payload
        },
        SET_LOADING_PART(state, payload) {
            state.partLoading = payload
        },
        // SET_CURRENT_ACCOUNT(state, payload) {
        //     state.currentAccount = payload
        // },
        SET_HAS_GOOGLE_BALANCE_UPDATE(state, payload) {
            state.hasGoogleBalanceUpdate = payload
        },
        SET_GOOGLE_AD_LIST(state, payload) {
            state.googleAdList = payload
        },
        ADD_GOOGLE_AD_ACCOUNTS(state, payload) {
            const accounts = [...state.googleAdList]
            state.googleAdList = Array.prototype.concat(accounts, payload)
        },
        SET_GOOGLE_AD_ACCOUNT(state, payload) {
            const elIndex = state.googleAdList.findIndex(el => el.pk === payload.pk)
            if (elIndex !== -1) {
                const element = state.googleAdList[elIndex]
                element.status = payload.status
                if (payload.restore_created_at) element.restore_created_at = payload.restore_created_at
                if (payload.refund_created_at) element.refund_created_at = payload.refund_created_at
            }
        },
        SET_GOOGLE_AD_BALANCE(state, payload) {
            for (const google_acc of payload) {
                const elIndex = state.googleAdList.findIndex(el => el.pk === google_acc.pk)
                if (elIndex !== -1) state.googleAdList[elIndex].google_amount = google_acc.google_amount
            }
        },
    },
    actions: {
        async getAccountsAmount({commit, rootState}){
            commit('SET_GOT_GOOGLE_ACC_AMOUNT', false)
            return api
                .get('client/google_amount/', { params: {
                    kind: "G",
                    currencies: rootState.currentAccount.currency_code
                }})
                .then(response => {
                    commit('SET_GOOGLE_ACC_AMOUNT', response.data.google_acc_amount)
                    commit('SET_GOT_GOOGLE_ACC_AMOUNT', true)
                    return response.status === 200
                })
                .catch(error => {
                    commit('setError', 'Failed get google account amount')
                })
        },
        async getAccountsList({ state, dispatch, commit, rootState }, status) {
            if (state.fetchGoogleAccounts) {
                let items = await dispatch('getAccounts', {
                    kind: "G",
                    currencies: rootState.currentAccount.currency_code,
                    ...status,
                }, { root: true })
                let google_external_ids = items.map(account => account.external_id)
                await dispatch('getGoogleAccountBalance', {google_external_ids: google_external_ids.join()})
                commit('SET_LOADING_LIST', true)
                let offset = 0
                while (items.length === 10 && state.fetchGoogleAccounts) {
                    commit('SET_LOADING_PART', true)
                    offset += 10
                    items = await dispatch('getAccounts', {
                        kind: "G",
                        offset: offset,
                        currencies: rootState.currentAccount.currency_code,
                        ...status,
                    }, { root: true })
                    google_external_ids = items.map(account => account.external_id)
                    await dispatch('getGoogleAccountBalance', {google_external_ids: google_external_ids.join()})
                }
                offset = 0
                commit('SET_LOADING_PART', false)
            }
            if (!state.fetchGoogleAccounts) commit('SET_FETCH_GOOGLE_ACCOUNTS', true)
        },
        sendInvite({ commit}, accountId) {
            return api.put(`/client/account/${accountId}/invite`, {
                account_id: accountId
            }).then(response => {
                if (response.data.state) {
                    commit("snackbar/SET_SNACK", {
                        title: "Send Invite",
                        text: response.data.message,
                        duration: 5000,
                    }, {root: true})
                }
            })
        },
        setAccountDataById({commit}, payload) {
          return api
              .put(`client/account/${payload.account_id}`, payload)
        },
        getGoogleAccountBalance({commit}, params) {
            return api
                .get("client/google_amount/balances/", {params})
                .then(response => {
                    if (response.data.state && response.data.items) {
                        commit("SET_GOOGLE_AD_BALANCE", response.data.items)
                    }
                })
        },
        setAccountData({commit}, payload) {
            return api
                .post("/client/finance/accounts/", payload)
                .then(response => {
                    if (response.data.state) {
                        commit("snackbar/SET_SNACK", {
                            title: i18n.t("snackbarMessages.googleAccCreated"),
                            duration: 5000,
                        }, {root: true})
                    }
                    return response.data
                })
        },
        updateGoogleAccountData({commit}, payload) {
            return api
                .put("client/finance/accounts/", payload)
                .then(response => {
                    const data = response.data.account
                    if (response.data.state) {
                        if (payload.action) {
                            commit("snackbar/SET_SNACK", {
                                title: i18n.t(`snackbarMessages.${payload.action}`),
                                duration: 5000,
                            }, {root: true})
                        } else {
                            commit("snackbar/SET_SNACK", {
                                title: i18n.t("snackbarMessages.update"),
                                text: `
                                    <div>${i18n.t("email")}: ${data.name}</div>
                                    <div>${i18n.t("balance")}: ${data.google_amount + data.currency_code}</div>
                                `,
                                duration: 5000,
                            }, {root: true})
                        }
                    }
                    return response.data
                })
        },
    }
})
