<template>
  <div class="yp-select" v-click-outside="hideOptions">
    <div
        class="yp-select__selector"
        :class="{ 'yp-select__selector--open': isOptionsVisible }"
        @click="isOptionsVisible = !isOptionsVisible"
    >
      <template v-if="selected && typeof value !== 'string'">
        <icon v-if="selected.kind=='V'" class="yp-select__icon" name="Card" width="20px" height="20px"></icon>
        <icon v-else class="yp-select__icon" :name="iconName(selected.currency_code)" width="20" height="20"></icon>
        <div class="yp-select__amount">{{ `${selected.amount} ${selected.currency_code}` }}<span v-if="selected.name">{{'\xa0'}}{{selected.name}}</span></div>
      </template>
      <div v-else>{{ this.value }}</div>
      <icon v-show="list.length > 1" class="yp-select__chevron" name="ChevronDown"/>
    </div>
    <div v-show="isOptionsVisible && list.length > 1" class="yp-select__options">
      <div
          v-for="(option) in list"
          v-if="option !== selected && typeof option !== 'string' && (!filter || filter(option))"
          class="yp-select__option"
          :key="option.pk"
          @click="onOptionClick(option)"
      >
        <icon v-if="option.kind == 'V'" class="yp-select__icon" name="Card" width="24px" height="24px"></icon>
        <icon v-else class="yp-select__icon" :name="iconName(option.currency_code)"></icon>
        <div class="yp-select__amount">{{ `${option.amount} ${option.currency_code}` }} <span v-if="option.name">{{option.name}}</span></div>
      </div>
      <div
          v-if="manual && !options && typeof value !== 'string'"
          class="yp-select__option"
          @click="onClickAllAccounts"
      >
        All accounts
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from "utils/directives";
import Icon from "@/components/ui/Icon";
import {mapGetters, mapMutations} from "vuex";


export default {
  name: "YpAccountSelect",
  components: {Icon},
  props: {
    manual: Boolean,
    externalVal: Object,
    options: Array,
    filter: {
      type: Function,
      required: false
    }
  },
  directives: {clickOutside},
  watch: {
    externalVal: {
      immediate: true,
      handler(val) {
        if (val) this.value = val
      }
    }
  },
  created() {
    // console.log('Yp select created')
    if (this.manual && !this.options) {
      this.onClickAllAccounts()
    }
    // else if(!this.$route.meta.localStorageName) {
    //   this.setCurrentAccount(this.options[0])
    // }

    // TODO: здесь можно проверить текущий window.location.pathname, чтобы выбрать crypto кошелек
  },
  data() {
    return {
      value: null,
      isOptionsVisible: false,
    }
  },
  computed: {
    ...mapGetters(['currentAccount', 'accounts', 'accountsByMethods']),

    selected() {
      // if (this.options) return this.value || this.currentAccount || this.options[0]
      // else return this.value || this.currentAccount
      return this.value || this.currentAccount
    },
    list() {
      // if (this.manual && !this.options) {
      //   return this.accounts
      // } else return this.options || this.accounts
      if(this.options) {
        return this.options
      } else if (this.$route.meta.type && this.$route.meta.method) {
        let account_by_methods = this.accountsByMethods(this.$route.meta.type, this.$route.meta.method)
        this.setCurrentAccount(account_by_methods[0])
        return account_by_methods
      } else {
        return this.accounts
      }
    }
  },
  methods: {
    ...mapMutations(['setCurrentAccount']),

    onOptionClick(event) {
      if (!this.manual) this.setCurrentAccount(event)
      this.isOptionsVisible = false
      if(this.$route.meta.localStorageName) localStorage.setItem(this.$route.meta.localStorageName, event.pk)
      this.value = event
      this.$emit('accountSelected', event)
    },
    hideOptions() {
      this.isOptionsVisible = false
    },
    onClickAllAccounts() {
      this.$emit('allAccountSelected')
      this.value = 'All accounts'
      this.isOptionsVisible = false
    },
    iconName(code) {
      const value = code.toLowerCase()
      switch (value) {
        default:
          return '';
        case 'usdc':
        case 'usdc-trc20':
        case 'usdc-erc20':
          return 'usdc';
        case 'trx':
          return 'Trx';
        case 'kzt':
          return 'Kzt';
        case 'rub':
          return 'Ruble';
        case 'usd':
          return 'Dollar';
        case 'usdt':
        case 'usdt-trc20':
        case 'usdt-erc20':
          return 'Tether';
        case 'eth':
          return 'Ethereum';
        case 'btc':
          return 'Bitcoin';
        case 'eur':
          return 'Euro';
        case 'ton':
          return 'Ton';
        case 'bnb':
          return 'Bnb';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-select {
  font-weight: 700;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  position: relative;

  &__icon {
    stroke: transparent;
    position: absolute;
    left: 20px;
    fill: #fff;
  }

  &__chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    stroke: #fff;
    fill: #fff;
  }

  &__amount {
    color: #fff;
  }

  &__options {
    z-index: 99;
    background: $black;
    border-radius: 0 0 10px 10px;
    position: absolute;
    width: 100%;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 400px;
    overflow: overlay;
  }

  &__option, &__selector {
    position: relative;
    border-radius: 10px;
    padding: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 65px;
    background: linear-gradient(75.21deg, #0E141E 0%, #394A65 100%);
  }

  &__selector {
    &--open {
      border-radius: 10px 10px 0 0;
    }
  }

  &__option {
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}
</style>
