<template>
  <div class="yp-logo" :class="{ 'yp-logo--link': link }" @click="onClick">
      <div class="yp-logo__icon-wrap">
        <icon :name="logo" class="yp-logo__icon" :style="iconStyles" />
      </div>
      <span v-if="!mini" class="yp-logo__text" :style="textStyles">YeezyPay</span>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import { mapGetters } from 'vuex'

export default {
  name: "Logo",
  props: {
    mini: Boolean,
    big: {
      type: Boolean,
      default: false,
    },
    link: Boolean,
  },
  components: { Icon },
  computed: {
    ...mapGetters(['mode']),

    logo() {
      return this.mode === 'dark' ? 'LogoDark' : 'Logo'
    },
    iconStyles() {
      let styles = {
        width: '40px',
        height: '30px'
      }
      if (this.big) {
        styles.width = '65px'
        styles.height = '43px'
      }
      return styles
    },
    textStyles() {
      let styles = {
        fontSize: '1.875rem',
        fontWeight: '700'
      }
      if(this.big) {
        styles.color = 'white'
        styles.fontSize = '2.5rem'
      }
      return styles
    }
  },
  methods: {
    onClick() {
      if(this.link) {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-logo {
  display: flex;
  align-items: end;
  gap: 10px;

  &--link {
    cursor: pointer;
  }

  &__text {
    line-height: 1;
  }
  &__icon {
    stroke: transparent;
  }
  &__icon-wrap {
    position: relative;
  }
  &__icon--christmas {
    position: absolute;
    left: 18px;
    stroke: transparent;
    top: -11px;
  }
}
</style>
