var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "yp-history" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("historyPage.title")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "yp-sheet",
        {
          staticClass: "yp-history__content yp-content",
          attrs: { id: "operations-list" },
        },
        [
          _c("yp-select", {
            attrs: { manual: "" },
            on: {
              accountSelected: function ($event) {
                _vm.filter.account = [$event.pk]
              },
              allAccountSelected: function ($event) {
                _vm.filter.account = null
              },
            },
          }),
          _vm._v(" "),
          _vm.$store.getters.permissions.includes("Balance")
            ? _c("div", { staticClass: "yp-history__account-amount-by-date" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "text-info flex-shrink-0 yp-xs-text" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("historyPage.accountAmountByDate")) +
                            ":\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("date-picker", {
                      staticClass: "yp-datepicker mt-2 ml-1",
                      attrs: {
                        "default-value": new Date(),
                        "disabled-date": _vm.disableAfterToday,
                      },
                      on: { input: _vm.selectAccountAmountByDate },
                      model: {
                        value: _vm.accountAmountByDate,
                        callback: function ($$v) {
                          _vm.accountAmountByDate = $$v
                        },
                        expression: "accountAmountByDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.filterBalanceText) +
                      "\n        "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "yp-history__search" },
            [
              _c("yp-input", {
                attrs: {
                  icon: "Search",
                  placeholder: _vm.$t("historyPage.searchPlaceholder"),
                },
                on: {
                  update: function ($event) {
                    _vm.filter.query = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("yp-button", {
                staticClass: "yp-history__filters",
                attrs: {
                  icon: "Filter",
                  "is-icon-btn": "",
                  "icon-color": "white",
                  square: "",
                },
                on: {
                  click: function ($event) {
                    _vm.isFilterModalVisible = true
                  },
                },
              }),
              _vm._v(" "),
              _c("yp-button", {
                staticClass: "yp-history__file",
                attrs: {
                  icon: "FileDownload",
                  "is-icon-btn": "",
                  "icon-color": "white",
                  square: "",
                },
                on: {
                  click: function ($event) {
                    _vm.isFileModalVisible = true
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "yp-history__fast-filters" },
            [
              _c("b-checkbox-group", {
                staticClass: "w-100",
                attrs: {
                  options: _vm.fastTypes,
                  "text-field": "name",
                  "value-field": "value",
                  buttons: "",
                },
                model: {
                  value: _vm.filter.kind,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "kind", $$v)
                  },
                  expression: "filter.kind",
                },
              }),
              _vm._v(" "),
              _c("b-checkbox-group", {
                staticClass: "w-100",
                attrs: {
                  options: _vm.fastStatuses,
                  "text-field": "name",
                  "value-field": "value",
                  buttons: "",
                },
                model: {
                  value: _vm.filter.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "status", $$v)
                  },
                  expression: "filter.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.reports.length
            ? _c(
                "div",
                { staticClass: "yp-history__files" },
                [
                  _vm._l(_vm.reports.slice(0, 2), function (report, key) {
                    return _c(
                      "a",
                      {
                        key: key,
                        staticClass: "yp-history__file-report",
                        attrs: { href: report.link },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              report.link.slice(
                                report.link.lastIndexOf("/") + 1,
                                report.link.length
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "reports" } } }, [
                    _vm._v("More reports..."),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.operations, function (el, index) {
            return _c(
              "div",
              {
                key: `history-group-${index}`,
                staticClass: "yp-history__group",
              },
              [
                _c("div", { staticClass: "text-center text-info" }, [
                  _vm._v(_vm._s(_vm.getDate(el.date))),
                ]),
                _vm._v(" "),
                _c("operations-list", { attrs: { operations: el.list } }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "h3",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.isOperationsLoading &&
                    _vm.operations.length === 0 &&
                    _vm.errors.length === 0,
                  expression:
                    "!isOperationsLoading && (operations.length === 0 && errors.length === 0)",
                },
              ],
              staticClass: "text-center text-info mt-3",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("operationPage.emptyList")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isOperationsLoading && _vm.errors.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.errors, function (err, key) {
                    return _c(
                      "h3",
                      { key: key, staticClass: "text-center text-danger mt-3" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(err.message) + "\n        "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("yp-button", {
                    staticClass: "mx-auto mt-3",
                    attrs: {
                      size: "xl",
                      variant: "gradient-outline",
                      label: _vm.$t("historyPage.filterModal.cancelBtn"),
                    },
                    on: { click: _vm.resetFilter },
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("yp-loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOperationsLoading,
                expression: "isOperationsLoading",
              },
            ],
            staticClass: "yp-history__loader",
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isFilterModalVisible
        ? _c(
            "yp-modal",
            {
              staticClass: "yp-history__filter-modal",
              attrs: {
                "without-title": "",
                "confirm-btn": _vm.$t("historyPage.filterModal.confirmBtn"),
                "cancel-btn": _vm.$t("historyPage.filterModal.cancelBtn"),
              },
              on: {
                closeModal: function ($event) {
                  _vm.isFilterModalVisible = false
                },
                confirm: function ($event) {
                  _vm.isFilterModalVisible = false
                },
                cancel: _vm.resetFilter,
              },
            },
            [
              _c(
                "div",
                { staticClass: "yp-history__filter-modal-content" },
                [
                  _c("yp-input", {
                    attrs: {
                      icon: "Search",
                      placeholder: _vm.$t("historyPage.searchPlaceholder"),
                    },
                    on: {
                      update: function ($event) {
                        _vm.filter.query = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("div", { staticClass: "yp-xs-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("historyPage.filterModal.paymentTypeLabel")
                            ) +
                            ":\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-checkbox-group", {
                        attrs: {
                          options: _vm.operationTypes,
                          "text-field": "name",
                          "value-field": "value",
                        },
                        model: {
                          value: _vm.filter.kind,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "kind", $$v)
                          },
                          expression: "filter.kind",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("div", { staticClass: "yp-xs-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "historyPage.filterModal.paymentStatusLabel"
                              )
                            ) +
                            ":\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-checkbox-group", {
                        staticClass: "yp-checkbox",
                        attrs: {
                          options: _vm.operationStatuses,
                          "text-field": "name",
                          "value-field": "value",
                        },
                        model: {
                          value: _vm.filter.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "status", $$v)
                          },
                          expression: "filter.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-divider" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "yp-xs-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("historyPage.filterModal.periodLabel")
                          ) +
                          ":\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("span", { staticClass: "text-info yp-xs-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("historyPage.filterModal.periodFrom") +
                                  "/" +
                                  _vm.$t("historyPage.filterModal.periodTo")
                              ) +
                              ":\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("date-picker", {
                          staticClass: "yp-datepicker mt-2 ml-1",
                          attrs: {
                            "default-value": new Date(),
                            "disabled-date": _vm.disableAfterToday,
                            range: "",
                          },
                          model: {
                            value: _vm.filter.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "date", $$v)
                            },
                            expression: "filter.date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-divider" }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFileModalVisible
        ? _c(
            "yp-modal",
            {
              staticClass: "yp-history__download-file",
              attrs: {
                title: _vm.$t("historyPage.fileModal.title"),
                "confirm-btn": _vm.$t("historyPage.fileModal.confirmBtn"),
                "cancel-btn": _vm.$t("historyPage.fileModal.cancelBtn"),
              },
              on: {
                closeModal: function ($event) {
                  _vm.isFileModalVisible = false
                },
                confirm: _vm.onConfirmFileModal,
              },
            },
            [
              _c("div", { staticClass: "yp-divider" }),
              _vm._v(" "),
              _c("div", { staticClass: "yp-history__file-formats" }, [
                _c("div", { staticClass: "yp-xs-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("historyPage.fileModal.text")) +
                      ":\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c("b-radio-group", {
                      staticClass: "yp-checkbox",
                      attrs: { options: _vm.fileFormats },
                      model: {
                        value: _vm.selectedFormats,
                        callback: function ($$v) {
                          _vm.selectedFormats = $$v
                        },
                        expression: "selectedFormats",
                      },
                    }),
                    _vm._v(" "),
                    _c("icon", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                      attrs: {
                        name: "Info",
                        title: _vm.$t("historyPage.fileModal.infoText"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "yp-divider" }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }