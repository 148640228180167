<template>
  <section class="yp-payouts">
    <div class="yp-payouts__header">
      <yp-button
          v-show="activeMode"
          class="yp-payouts__back-btn"
          icon="BackArrow"
          ghost
          :label="$t('nav.back')"
          @click="activeMode = ''"
      />
      <h2 class="text-center">{{ title }}:</h2>
    </div>
    <yp-sheet v-show="!activeMode" class="yp-payouts__variants yp-content">
      <yp-button
          v-if="permissions.includes('PayoutCard')"
          class="yp-payouts__variant-btn"
          size="xl"
          icon="Card"
          label="Bank Cards"
          :disabled="!permissions.includes('PayoutCard')"
          @click="$router.push({name: 'payout-card'})"
      />
      <yp-button
          v-if="permissions.includes('PayoutCrypto')"
          class="yp-payouts__variant-btn"
          size="xl"
          icon="Tether"
          label="Crypto"
          @click="$router.push({name: 'payout-crypto'})"
      />
      <yp-button
          v-if="permissions.includes('PayoutSBP')"
          class="yp-payouts__variant-btn"
          size="xl"
          icon="DevicePhone"
          label="By phone number"
          @click="$router.push({name: 'payout-phone'})"
      />
    </yp-sheet>
    <component class="yp-payouts__block yp-content" v-if="activeMode" :is="activeMode" />
  </section>
</template>

<script>
import YpSheet from "@/components/ui/Sheet"
import YpButton from "@/components/ui/Button"

import PayoutCard from "@/components/payouts/Card"
import PayoutCrypto from "@/components/payouts/Crypto"
import PayoutMobile from "@/components/payouts/Phone"

import { mapGetters } from 'vuex'

export default {
  name: "Payouts",
  components: {
    YpButton,
    YpSheet,
    PayoutCard,
    PayoutCrypto,
    PayoutMobile
  },
  data() {
    return {
      activeMode: '',
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    title() {
      switch (this.activeMode) {
        case 'PayoutCard':
          return this.$t('payoutsPage.cardModeTitle')
        case "PayoutCrypto":
          return `${this.$t('payoutsPage.cryptoModeTitle')} `
        case "PayoutMobile":
          return this.$t('payoutsPage.mobileModeTitle')
        default:
          return this.$t('payoutsPage.title')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.yp-payouts {
  margin: 0 auto;
  width: 100%;

  &__header {
    position: relative;
  }
  &__back-btn {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto 0;

    @media (max-width: 1400px) {
      position: relative;
    }
  }
  &__variants {
    margin: 1.875rem auto 0;
    display: flex;
    gap: 10px;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }
  &__variant-btn {
    width: 240px;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }
}
</style>
