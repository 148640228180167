var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yp-welcome" }, [
    _c("h1", { staticClass: "text-center" }, [
      _vm._v(" " + _vm._s(_vm.$t("welcomePage.title")) + " "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "yp-welcome__info mt-3" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.balances.length > 0,
              expression: "balances.length > 0",
            },
          ],
          staticClass: "yp-welcome__info-block",
        },
        [
          _c("div", { staticClass: "text-info mb-3 text-center" }, [
            _vm._v("Your balance:"),
          ]),
          _vm._v(" "),
          _c(
            "yp-sheet",
            { staticClass: "yp-welcome__balance" },
            _vm._l(_vm.balances, function (account) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: account.amount,
                      expression: "account.amount",
                    },
                  ],
                },
                [
                  _c("icon", {
                    staticClass: "yp-welcome__balance-icon",
                    attrs: {
                      width: "20",
                      height: "20",
                      name: _vm.$store.getters["catalogs/currenciesWithIcons"](
                        account.currency_id
                      ).iconName,
                    },
                  }),
                  _vm._v(
                    "\n            Amount: " +
                      _vm._s(account.amount) +
                      "\n            "
                  ),
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v("Hold: " + _vm._s(account.amount_holded)),
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(account.currency_code))]),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$store.getters.isMobile,
              expression: "$store.getters.isMobile",
            },
          ],
          staticClass: "mobile-clue__wrapper",
        },
        [
          _vm._l(_vm.mobile_clue, function (item, key) {
            return _vm.checkPermission(item) && item.name !== "profile"
              ? _c(
                  "router-link",
                  {
                    key: key,
                    staticClass: "mobile-clue__link",
                    attrs: {
                      to: { name: item.name },
                      "exact-active-class": "active-item",
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "mobile-clue__link-icon--stroke",
                      attrs: {
                        name: item.icon,
                        width: item.icon !== "CardToCard" ? "28" : "36",
                        height: "28",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t(`nav.${item.name}`)))]),
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "mobile-clue__link",
              on: {
                click: function ($event) {
                  return _vm.setMobileNav(true)
                },
              },
            },
            [_vm._v(" All Services ")]
          ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "yp-welcome__text" }, [
      _vm._v(_vm._s(_vm.$t("welcomePage.text"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "yp-welcome__info" }, [
      _c("div", { staticClass: "yp-welcome__info-block" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("welcomePage.supportText")))]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "yp-welcome__link",
            attrs: { href: "mailto:support@yeezypay.io" },
          },
          [
            _c("Icon", { attrs: { name: "Mail" } }),
            _vm._v("\n          support@yeezypay.io\n        "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "yp-welcome__info-block" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("welcomePage.telegram")))]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "yp-welcome__link",
            attrs: { target: "_blank", href: "https://t.me/yeezy_aff" },
          },
          [
            _c("Icon", { attrs: { name: "Telegram" } }),
            _vm._v("\n          @yeezy_aff\n        "),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }