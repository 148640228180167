<template>
    <div class="yp-input">
        <icon v-if="icon" class="yp-input__icon" :class="{ 'yp-input__icon--append': appendIcon }" :name="icon"/>
        <div class="yp-input--wrap">
            <b-form-input
                :value="value"
                :autofocus="autoFocus"
                class="yp-input__input"
                :class="{
                'yp-input__input--rounded': rounded,
                'yp-input__input--dark': mode === 'dark',
                'yp-input__input--error': errors.length > 0 
                }"
                type="text"
                :disabled="disabled"
                :step="step"
                :readonly="readonly"
                :placeholder="placeholder || $t('ypCustomInput.defaultPlaceholder')"
                :style="padding"
                @input="onInput"
                @focus="showToSelect = true"
                @blur="onBlur"
            />
            <div v-show="toSelect" class="yp-input-select__wrapper" :class="{'showToSelect': showToSelect}">
                <ul class="yp-input-select__list" :class="{'showToSelect': showToSelect}">
                    <li class="yp-input-select__list-item" v-for="toSelectProp in toSelectDynamic">
                        <div class="propsList" :class="{'showPropsList': showToSelect}" @click="value = toSelectProp; onInput(value)">
                            {{ toSelectProp }}  
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="errors.length" class="yp-input__info">
            <icon id="info" name="Info"/>
            <b-tooltip class="yp-input__tooltip" target="info">
                <div class="font-weight-bold text-danger">
                {{ invalidInputHint || $t('ypCustomInput.invalidInput') }}
                </div>
                <div class="font-weight-bold text-info" v-for="(error, key) in errors" :key="key">
                {{ error }}
                </div>
            </b-tooltip>
        </div>
        <div v-if="label" class="yp-input__label">{{ label }}</div>
    </div>
</template>

<script setup>
import Icon from "@/components/ui/Icon";
import { ref, computed, watch } from "vue"

const props = defineProps({
    value: {
        type: String,
        required: false,
        default: null
    },
    label: {
        type: String,
        required: false,
        default: null
    },
    errors: {
        type: Array,
        required: false,
        default: () => []
    },
    mode: {
        type: String,
        required: false,
        default: "dark"
    },
    icon: {
        type: String,
        required: false,
        default: null
    },
    placeholder: {
        type: String,
        required: false,
    },
    invalidInputHint: {
        type: String,
        required: false,
        default: null
    },
    autoFocus: {
        type: Boolean,
        required: false,
        default: false
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    toSelect: {
        type: Array || Object,
        required: true,
        default: () => []
    },
    selectText: {
        type: String,
        required: true
    },
    selectValue: {
        required: true
    },
});

const emit = defineEmits(['input']);

const showToSelect = ref(false)

const toSelectDynamic = ref(props.toSelect.map((item) => item[props.selectText]))

watch(() => props.toSelect, (newValue, oldValue) => {
    toSelectDynamic.value = props.toSelect.map((item) => item[props.selectText])
})

const onInput = (newValue) => {
    //toSelectDynamic.value = (await props.toSelect).map((item) => item[props.selectText].toLowerCase()).map((item) => item.indexOf(newValue.toLowerCase()) >= 0 ? item : -1).filter(item => item != -1)
    toSelectDynamic.value = []
    if (newValue.length > 0) {
        for (const item of props.toSelect) {
            if (item[props.selectText].toLowerCase().indexOf(newValue.toLowerCase()) >= 0) { 
                toSelectDynamic.value.push(item[props.selectText])
            }
        }
    } else {
        toSelectDynamic.value = props.toSelect.map((item) => item[props.selectText])
    }
    const matched = props.toSelect.filter(toSelectItem => toSelectItem[props.selectText].toLowerCase() == newValue.toLowerCase())
    let matchedToSelect
    if (matched.length == 1) {
        matchedToSelect = matched[0][props.selectValue]
    } else {
        matchedToSelect = null
    }
    emit('input', matchedToSelect);
};

const onBlur = () => {
    setTimeout(() => {showToSelect.value = false}, 200)
};

</script>

<style lang="scss">

.yp-input-select {
    &__wrapper {
        position: absolute; 
        width: 100%; 
        transition: 0.4s; 
        border-bottom-left-radius: 15px; 
        border-bottom-right-radius: 15px; 
        background-color: #0e141e; 
        top: 85%; 
        opacity: 0; 
        border: 1px solid rgb(47, 52, 71); 
        z-index: 2; 
        max-height: 40vh; 
        overflow: auto;
    }
    &__list {
        list-style: none; 
        margin: 0px 10px; 
        opacity: 0; 
        transition: 0.4s; 
        text-align: center; 
        padding: 0;
    }
    &__list-item {
        border-top: 1px solid rgb(47, 52, 71);
        border-bottom: 1px solid rgb(47, 52, 71);
        margin-top: 10px;
        width: 100%;
    }
}

.propsList {
    margin: 10px;
    border-radius: 15px;
    cursor: pointer;
    display: none;
}

.propsList:hover {
    background-color: aqua;
}

.showPropsList {
    display: block;
}

.showToSelect {
    opacity: 1 !important;
}

</style>