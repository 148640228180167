<template>
  <section class="yp-profile">
    <h2 class="text-center">
      Api Settings
    </h2>
    <yp-sheet class="yp-profile__content yp-content mx-auto">
      <yp-loader style="left: 50%; transform: translateX(-50%);" v-if="apiAccountList.length === 0" class="align-self-center yp-page-content" />
      <div v-else class="profile-account-settings">
        <yp-account-select
              class="yp-exchange__selector"
              manual
              :options="apiAccountList"
              :external-val="apiAccountForm"
              @accountSelected="onAccountSelected"
          />
        <div class="profile-account-settings__theme">
          <div class="profile-account-settings__theme-block">
            <p>Currency:</p>
            <p class="yp-input__input form-control yp-input__input--dark">{{ apiAccountForm.currency_code }}</p>
            <p style="margin-top: 15px;">External_id:</p>
            <p class="yp-input__input form-control yp-input__input--dark">{{ external_id }}</p>
            <p style="margin-top: 15px;">Salt:</p>
            <p class="yp-input__input form-control yp-input__input--dark">{{ salt }}</p>
            <yp-button
                :class="{ 'w-100': true, 'mt-3': true}"
                label="Create credentials"
                variant="primary-gradient"
                @click="onSubmit"
            />
          </div>
        </div>
        <a href="https://my.yeezypay.io/api/v1/redoc" target="_blank" rel="noopener noreferrer">
          <yp-button
            :class="{ 'w-100': true, 'mt-3': true}"
            label="YeezyAPI"
            variant="primary-gradient"
          />
        </a>
      </div>
    </yp-sheet>
  </section>
</template>

<script>  
import {mapActions, mapGetters} from 'vuex'
import YpSheet from "@/components/ui/Sheet";
import YpButton from "@/components/ui/Button";
import YpAccountSelect from "@/components/ui/YpSelect";
import YpLoader from "@/components/ui/YpLoader";

export default {
  name: "Profile",
  components: {YpSheet, YpButton, YpAccountSelect, YpLoader},
  data() {
    return {
      apiAccountForm: null,
    }
  },
  computed: {
    ...mapGetters(['accounts', 'accountsCredentials']),
    ...mapGetters('catalogs', ['currencies']),

    external_id() {
      return this.apiAccountForm.external_id 
    },
    salt() {
      return this.apiAccountForm.salt
    },
    apiAccountList() {
      let apiAccountList = this.accounts.filter(account => {
          return this.currencies.find(currency => {
              return currency.code === account.currency_code && currency.kind == 'F'
          })
      })
      this.apiAccountForm = apiAccountList[0]
      return apiAccountList
    },
  },
  methods: {
    ...mapActions(['generateCredentials']),

    onAccountSelected(event) {
      this.apiAccountForm = event
    },
    onSubmit() {
      this.generateCredentials(this.apiAccountForm.pk).then(response => {
        if (response) {
          this.apiAccountForm = {
            ...this.apiAccountForm,
            external_id: response.external_id,
            salt: response.salt
          }
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/mixins";

.cancelFill svg{
  fill: none !important;
}

.profile-account-settings {
  &__theme {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &__theme-block {
    @include border-style($radius: 10px);
    flex-grow: 1;
    padding: 20px;
  }
}

.yp-profile {
  width: 100%;

  &__qr-modal-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  &__sessions-head {
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.33%);
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
    color: $info;
    font-weight: 600;
  }

  &__sessions {
    @include border-style($radius: 10px);

    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
