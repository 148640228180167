var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.iconData
    ? _c(
        "svg",
        {
          staticClass: "icon",
          class: [{ "icon--primary": _vm.color === "primary" }],
          style: [_vm.styles, _vm.isUnfillIcon],
          attrs: {
            viewBox: _vm.iconData.viewBox,
            width: _vm.width,
            height: _vm.height,
          },
        },
        [_c("use", { attrs: { "xlink:href": `${_vm.iconData.url}` } })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }