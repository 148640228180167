var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "google-ad-group" }, [
    _c(
      "div",
      { staticClass: "google-ad-group__email" },
      [
        _c("div", { staticClass: "google-ad-group__email-text" }, [
          _vm._v(_vm._s(_vm.groupData.name)),
        ]),
        _vm._v(" "),
        _c("yp-button", {
          attrs: {
            label: "Change email",
            icon: "Mail",
            variant: "primary-gradient",
          },
          on: {
            click: function ($event) {
              _vm.isEmailModalVisible = true
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "google-ad-group__list" },
      [
        _vm._l(_vm.groupData.list, function (item, index) {
          return _c("google-ad-item", {
            key: `account-${index}`,
            attrs: {
              id: item.pk,
              "updated-at": item.external_updated_at,
              "max-value": _vm.currentAccount.amount,
            },
          })
        }),
        _vm._v(" "),
        _vm.showNewCampaignForm
          ? _c("div", { staticClass: "google-ad-group__new-campaign" }, [
              _c(
                "div",
                { staticClass: "google-ad-group__campaign-form" },
                [
                  _c("yp-input", {
                    staticClass: "google-ad-group__new-campaign-input",
                    attrs: {
                      type: "number",
                      label: `Amount (${_vm.currentAccount.currency_code}):`,
                      "auto-focus": "",
                      placeholder: `min value: ${
                        _vm.currentAccount.google_amount_min ||
                        _vm.$store.getters["google/minGoogleRate"]
                      }`,
                      "min-value":
                        _vm.currentAccount.google_amount_min ||
                        _vm.$store.getters["google/minGoogleRate"],
                      "max-value": _vm.currentAccount.amount,
                    },
                    on: {
                      update: function ($event) {
                        _vm.newCampaignForm.amount = $event
                      },
                      inputError: function ($event) {
                        _vm.newCampaignForm.amount = null
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-select", {
                    staticClass: "new-google-account__selector",
                    attrs: { options: _vm.availableTimezones },
                    model: {
                      value: _vm.timezone,
                      callback: function ($$v) {
                        _vm.timezone = $$v
                      },
                      expression: "timezone",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "google-ad-group__campaign-actions",
                      class: { "w-100": _vm.$store.getters.isMobile },
                    },
                    [
                      _c("yp-button", {
                        class: { "w-100": _vm.$store.getters.isMobile },
                        attrs: {
                          disabled:
                            !_vm.newCampaignForm.amount || _vm.isSubmitDisabled,
                          label: _vm.$t("googleAdPage.submit"),
                          icon: "Checkbox",
                          variant: "primary-gradient",
                        },
                        on: { click: _vm.onSubmit },
                      }),
                      _vm._v(" "),
                      _c("yp-button", {
                        class: { "w-100": _vm.$store.getters.isMobile },
                        attrs: {
                          label: _vm.$t("googleAdPage.cancelBtn"),
                          icon: "Close",
                          variant: "danger",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showNewCampaignForm = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.commission,
                      expression: "commission",
                    },
                  ],
                  staticClass: "google-ad-group__commission yp-additional-text",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        `${_vm.$t("commission")} (${
                          _vm.currentAccount.fee.withdraw.percent
                        }): ${_vm.commission} ${
                          _vm.currentAccount.currency_code
                        }`
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isEmailModalVisible
          ? _c(
              "yp-modal",
              {
                attrs: {
                  title: _vm.$t("googleAdPage.emailModal.title"),
                  "confirm-btn": _vm.$t("googleAdPage.emailModal.confirmBtn"),
                  "cancel-btn": _vm.$t("googleAdPage.emailModal.cancelBtn"),
                },
                on: {
                  confirm: _vm.onChangeEmail,
                  closeModal: function ($event) {
                    _vm.isEmailModalVisible = false
                  },
                },
              },
              [
                _c("div", [
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v("Current email:"),
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.groupData.name) + "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("yp-input", {
                  attrs: {
                    email: "",
                    label: `New email:`,
                    placeholder: `example@example.com`,
                    "external-val": _vm.groupData.name,
                  },
                  on: {
                    update: function ($event) {
                      _vm.newEmail = $event
                    },
                    inputError: function ($event) {
                      _vm.newEmail = null
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
            },
          },
          [
            _c("yp-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showNewCampaignForm && !_vm.isAddBtnHidden,
                  expression: "!showNewCampaignForm && !isAddBtnHidden",
                },
              ],
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("googleAdPage.startNewCampaign"),
                icon: "Plus",
                disabled: _vm.isAddBtnHidden,
              },
              on: {
                click: function ($event) {
                  _vm.showNewCampaignForm = true
                  _vm.timezone = _vm.availableTimezones[11].value
                },
              },
            }),
            _vm._v(" "),
            _c("yp-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.selectedAccs.filter((group) =>
                      _vm.groupData.list.includes(group)
                    ).length !== _vm.groupData.list.length,
                  expression:
                    "selectedAccs.filter(group => groupData.list.includes(group)).length !== groupData.list.length",
                },
              ],
              staticClass: "mt-2",
              attrs: { label: "Select", icon: "Plus" },
              on: {
                click: function ($event) {
                  return _vm.selectedAccs.push(..._vm.groupData.list)
                },
              },
            }),
            _vm._v(" "),
            _c("yp-button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.selectedAccs.filter((group) =>
                      _vm.groupData.list.includes(group)
                    ).length === _vm.groupData.list.length,
                  expression:
                    "(selectedAccs.filter(group => groupData.list.includes(group)).length) === groupData.list.length",
                },
              ],
              staticClass: "mt-2",
              attrs: { label: "Selected", icon: "Checkbox" },
              on: {
                click: function ($event) {
                  return _vm.$emit("rem", _vm.groupData.list)
                },
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }