var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yp-modal",
    {
      staticClass: "yp-verify-modal",
      attrs: {
        title: _vm.$t("payoutsPage.modals.verify.title"),
        "without-actions": "",
      },
    },
    [_c("yp-loader", { staticClass: "yp-verify-modal__loader" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }