<template>
  <div class="yp-virtual-cards">
    <h2 class="text-center">{{ $t('virtualCards.title') }}</h2>
    <yp-sheet class="yp-virtual-cards__content yp-content mx-auto">
      <yp-account-select />
      <div class="yp-virtual-cards__add-new">
        <div class="d-flex justify-content-between align-items-center">
          <div>{{ $t("virtualCards.createCard") }}:</div>
          <yp-button
              v-show="!isNewFormVisible && currentAccount.hasOwnProperty('limits') && currentAccount.limits.hasOwnProperty('card_open')"
              icon="Plus"
              :label="$t('virtualCards.addBtn')"
              variant="primary-gradient"
              @click="isNewFormVisible = true"
          />
          <yp-button
              v-show="isNewFormVisible && currentAccount.hasOwnProperty('limits') && currentAccount.limits.hasOwnProperty('card_open')"
              icon="Close"
              ghost
              icon-color="red"
              variant="primary-gradient"
              @click="isNewFormVisible = false"
          />
        </div>
        <new-card v-show="isNewFormVisible && currentAccount.hasOwnProperty('limits') && currentAccount.limits.hasOwnProperty('card_open')" @update="getAccountCards(currentAccount.pk)" />
      </div>
      <yp-loader v-show="accounts.length === 0 || isDataLoading" class="align-self-center" />
      <div class="yp-virtual-cards__list">
        <yp-virtual-card
            class="yp-virtual-cards__item"
            v-for="(card, key) in cardsList"
            :card-data="card"
            :key="`card-${key}`"
            @update="getAccountCards(currentAccount.pk)"
        />
      </div>
      <h3
          v-show="cardsList.length === 0"
          class="text-center text-info"
      >
        {{ $t('virtualCards.empty') }}
      </h3>
    </yp-sheet>
  </div>
</template>

<script>
import YpSheet from "@/components/ui/Sheet"

import { mapActions, mapGetters } from "vuex"
import YpButton from "@/components/ui/Button";
import YpLoader from "@/components/ui/YpLoader";
import YpVirtualCard from "@/components/cards/Item";
import YpAccountSelect from "@/components/ui/YpSelect";
import NewCard from "@/components/cards/NewCard.vue";

export default {
  name: "VirtualCards",
  components: {NewCard, YpAccountSelect, YpVirtualCard, YpLoader, YpButton, YpSheet},
  data() {
    return {
      isDataLoading: false,
      isNewFormVisible: false,
    }
  },
  computed: {
    ...mapGetters("cards", ["cardsList"]),
    ...mapGetters(["accounts", "currentAccount"]),
  },
  watch: {
    currentAccount() {
      console.log(this.currentAccount)
      if (this.currentAccount.methods.withdraw.includes("VirtualCard")) this.getAccountCards(this.currentAccount.pk)
    }
  },
  methods: {
    ...mapActions("cards", ["getCardsList", "createCard"]),

    async getAccountCards(account) {
      this.isDataLoading = true
      await this.getCardsList({account: account})
      this.isDataLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.yp-virtual-cards {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  

  &__add-new {
    margin-top: 20px;
  }
  &__list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>