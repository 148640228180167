<template>
  <div class="yp-upload">
    <input
        ref="upload"
        class="yp-upload__input"
        type="file"
        accept=".csv, .txt"
        @input="onFileChange"
    />
    <div v-if="file" class="yp-upload__file">
      <icon name="File" />
      <span> {{ file }} </span>
    </div>
    <yp-button
        v-show="!hasError"
        :class="{ 'mt-3': file }"
        ref="btn"
        class="yp-upload__btn"
        icon="Upload"
        variant="gradient-outline"
        :label="btnText"
        @click="$refs.upload.click()"
    />
    <div v-if="hasError" class="yp-upload__error text-center">
      <div class="text-danger">
        {{ $t('payoutsPage.uploadFile.error') }}
      </div>
      <a href="#" @click="$emit('showModal')">File layout description</a>
      <yp-button
          class="yp-upload__btn mx-auto mt-3"
          icon="Upload"
          variant="gradient-outline"
          :label="$t('payoutsPage.uploadFile.new')"
          @click="$refs.upload.click()"
      />
    </div>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button";
import Icon from "@/components/ui/Icon";
import YpModal from "@/components/ui/YpModal";
export default {
  name: "YpUpload",
  components: {YpModal, Icon, YpButton},
  props: {
    validationType: {
      type: String,
      default: () => 'card',
    },
  },
  data() {
    return {
      file: null,
      fileContent: null,
      hasError: false,
    }
  },
  computed: {
    btnText() {
      return this.file ? this.$t('payoutsPage.uploadFile.another') :  this.$t('payoutsPage.uploadFile.new')
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (readerEvent) => {
        // console.log(readerEvent)
        this.file = event.target.files[0].name
        this.fileContent = reader.result
        this.$emit('fileLoaded', reader.result)
        // this.checkFile(readerEvent.currentTarget.result)
        //   .then(res => {
        //     this.hasError = false
        //   })
        //   .catch(err => {
        //     this.file = null
        //     this.fileContent = null
        //     this.hasError = true
        //     this.$emit('errorFileLoad', err)
        //   })
      }
      reader.onerror = error => {
        this.$emit('errorFileLoad', error)
      }
    },
    // checkFile(file) {
    //   let lines = file.split('\r\n')
    //   lines = lines.filter(el => el)
    //   return new Promise((resolve, reject) => {
    //     let isValid
    //     if(lines) {
    //       for (let line in lines) {
    //         const params = lines[line].split(/[;|]+/)
    //         console.log(params)
    //         const isCardValid = Number(params[0]) ? params[0].length === 16 : false
    //         const isPhoneValid = params[0] ? params[0].length >= 10 && params[0].length <= 12 : false
    //         const isAmountValid = Boolean(Number(params[1]))
    //         console.log(isAmountValid)
    //         isValid = this.validationType === 'card' ? isCardValid && isAmountValid : isPhoneValid && isAmountValid
    //       }
    //       if(isValid) resolve(isValid)
    //       reject(isValid)
    //     } else reject()
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.yp-upload {
  &__input {
    display: none;
  }
  &__file {
    font-size: 12px;
    color: $info;
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      fill: transparent;
    }
  }
}
</style>
