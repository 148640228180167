var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header" },
    [
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "header__content container" },
            [
              _c("logo", { attrs: { link: "" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header__nav" },
                _vm._l(_vm.navList, function (item, key) {
                  return _vm.checkPermission(item) && item.name !== "profile"
                    ? _c(
                        "router-link",
                        {
                          key: key,
                          staticClass: "header__link",
                          attrs: {
                            to: { name: item.name },
                            "exact-active-class": "active-item",
                          },
                        },
                        [
                          _c("icon", {
                            staticClass:
                              "header__link-icon--stroke header__link-icon--stroke",
                            attrs: {
                              name: item.meta.icon,
                              width:
                                item.meta.icon !== "CardToCard" ? "28" : "36",
                              height: "28",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t(`nav.${item.name}`))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header__actions" },
                [
                  _c("yp-button", {
                    staticClass: "header__action-btn",
                    attrs: {
                      icon: "Profile",
                      "icon-color": "#A6AFD4",
                      variant: "light",
                      "is-icon-btn": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ name: "profile" })
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("yp-button", {
                    staticClass: "ml-2 header__action-btn",
                    attrs: {
                      icon: "exit",
                      "icon-color": "#A6AFD4",
                      variant: "light",
                      "is-icon-btn": "",
                    },
                    on: { click: _vm.onLogout },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "header--mobile-top" },
              [
                _c("icon", {
                  attrs: { name: "burger", width: "24px", height: "24px" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.setMobileNav(true)
                    },
                  },
                }),
                _vm._v(" "),
                _c("Transition", [
                  _vm.isMobileNavVisible
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.hideMobileNav,
                              expression: "hideMobileNav",
                            },
                          ],
                          staticClass: "header__mobile-nav",
                        },
                        [
                          _c("yp-button", {
                            staticClass: "ml-auto",
                            attrs: {
                              icon: "Close",
                              "is-icon-btn": "",
                              ghost: "",
                            },
                            on: { click: _vm.hideMobileNav },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.navList, function (item, key) {
                            return _vm.checkPermission(item)
                              ? _c(
                                  "router-link",
                                  {
                                    key: key,
                                    staticClass: "header__link",
                                    attrs: {
                                      to: { name: item.name },
                                      "exact-active-class": "active-item",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.hideMobileNav.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: {
                                        name: item.meta.icon,
                                        width: "24px",
                                        height: "24px",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t(`nav.${item.name}`))
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("logo", { attrs: { link: "" } }),
                _vm._v(" "),
                _c("yp-button", {
                  staticClass: "ml-2 header__action-btn",
                  attrs: {
                    icon: "exit",
                    "icon-color": "#A6AFD4",
                    variant: "light",
                    "is-icon-btn": "",
                  },
                  on: { click: _vm.onLogout },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }