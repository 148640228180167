export default {
    "220001":"gazprombank",
    "220002":"rncb",
    "220003":"payment",
    "220004":"rsb",
    "220005":"rgsbank",
    "220007":"abr",
    "220008":"abr",
    "220009":"modirum",
    "220010":"vbrr",
    "220011":"cardstandard",
    "220013":"zapsibkombank",
    "220014":"cardstandard",
    "220015":"alfabank",
    "220016":"chelinvest",
    "220017":"centrinvest",
    "220018":"severgazbank",
    "220019":"uralsib",
    "220020":"minbank",
    "220022":"open",
    "220023":"avangard",
    "220024":"vtb",
    "220025":"sdm",
    "220026":"mkb",
    "220027":"cardstandard",
    "220028":"mtsbank",
    "220029":"open",
    "220030":"raiffeisen",
    "220031":"vbank",
    "220033":"bspb",
    "220037":"zenit",
    "220038":"rshb",
    "220039":"rosbank",
    "220042":"roscap",
    "220043":"open",
    "220045":"ubrr",
    "220047":"smpcards",
    "220048":"skbbank",
    "220050":"homecredit",
    "220053":"akbars",
    "220054":"dvbank",
    "220055":"albank",
    "220056":"creditural",
    "220058":"sngb",
    "220059":"cardstandard",
    "220060":"atb",
    "220061":"sbbank",
    "220062":"zenit",
    "220064":"bankofkazan",
    "220065":"mmbank",
    "220066":"cardstandard",
    "220069":"cardstandard",
    "220070":"tinkoff",
    "220071":"vostbank",
    "220074":"cardstandard",
    "220076":"rncb",
    "220077":"multicarta",
    "220078":"cardstandard",
    "220080":"open",
    "220082":"cardstandard",
    "220083":"open",
    "220084":"unicredit",
    "220087":"cardstandard",
    "220091":"modirum",
    "220093":"tatsotsbank",
    "220095":"cardstandard",
    "220096":"cardstandard",
    "220097":"cardstandard",
    "220098":"forabank",
    "220100":"koronacard",
    "220106":"aversbank",
    "220108":"multicarta",
    "220110":"zenit",
    "220112":"cardstandard",
    "220117":"cardstandard",
    "220130":"open",
    "220133":"open",
    "220140":"skbbank",
    "220147":"cardstandard",
    "220148":"modirum",
    "220149":"cardstandard",
    "220153":"payment",
    "220154":"multicarta",
    "220155":"uralsib",
    "220156":"modirum",
    "220158":"cardstandard",
    "220159":"multicarta",
    "220166":"forshtadt",
    "220170":"multicarta",
    "220172":"multicarta",
    "220186":"cardstandard",
    "220188":"bystrobank",
    "220195":"cardstandard",
    "220196":"otpbank",
    "220214":"uralfd",
    "220220":"sbrf",
    "220249":"solid",
    "220255":"cardstandard",
    "220256":"multicarta",
    "220258":"multicarta",
    "220259":"multicarta",
    "220279":"cardstandard",
    "220324":"multicarta",
    "220374":"metcom",
    "220488":"smpcards",
    "356299":"gazprombank",
    "356504":"albank",
    "356514":"rshb",
    "356546":"dvbank",
    "400079":"akbars",
    "400303":"kkb",
    "400680":"sbrf",
    "400787":"cardstandard",
    "402326":"minbank",
    "402360":"sia",
    "402728":"sbbank",
    "402811":"micb",
    "402890":"cardstandard",
    "402910":"modirum",
    "403184":"vbank",
    "403282":"sbs4u",
    "403626":"cardstandard",
    "403780":"mkb",
    "403838":"cardstandard",
    "403840":"zenit",
    "403841":"zenit",
    "403896":"avangard",
    "403897":"avangard",
    "403898":"avangard",
    "404007":"vtb",
    "404136":"gazprombank",
    "404138":"payment",
    "404242":"fortebank",
    "404266":"mtsbank",
    "404268":"mtsbank",
    "404270":"gazprombank",
    "404802":"yoomoney",
    "404807":"raiffeisen",
    "404841":"yoomoney",
    "405666":"roscap",
    "405740":"multicarta",
    "405870":"open",
    "405991":"multicarta",
    "405992":"multicarta",
    "405993":"multicarta",
    "406127":"alfabank",
    "406149":"chelinvest",
    "406296":"homecredit",
    "406364":"homecredit",
    "406380":"gorbank",
    "407528":"bpcprocessing",
    "408396":"alfabank",
    "408397":"alfabank",
    "409398":"vtb",
    "409673":"multicarta",
    "409755":"open",
    "409809":"unibank",
    "409888":"albank",
    "410447":"gazprombank",
    "411053":"secure5gw",
    "411647":"modirum",
    "411719":"modirum",
    "411727":"unistream",
    "411997":"upc",
    "412475":"forshtadt",
    "412720":"3dsecure",
    "413203":"vbrr",
    "413307":"open",
    "414298":"sbs4u",
    "414399":"zapsibkombank",
    "414656":"zenit",
    "414657":"zenit",
    "414939":"taslink",
    "414943":"privatbank",
    "414949":"privatbank",
    "414951":"upc",
    "414960":"privatbank",
    "414962":"privatbank",
    "415042":"vtb",
    "415259":"alfabank",
    "415311":"cardstandard",
    "415428":"alfabank",
    "415481":"alfabank",
    "415482":"alfabank",
    "416497":"severgazbank",
    "416598":"touchtechpayments",
    "416740":"cardstandard",
    "416741":"cardstandard",
    "416951":"bpcprocessing",
    "416958":"jysanbank",
    "416973":"kapitalbank",
    "416974":"kapitalbank",
    "416982":"rgsbank",
    "416983":"rgsbank",
    "417053":"cardstandard",
    "417250":"rs",
    "417367":"metcom",
    "417398":"sbrf",
    "417429":"open",
    "417749":"cardstandard",
    "417753":"mtbank",
    "418260":"multicarta",
    "418385":"rshb",
    "418713":"modirum",
    "418973":"post",
    "418980":"3dsecure",
    "419149":"atb",
    "419150":"atb",
    "419163":"avangard",
    "419215":"zapsibkombank",
    "419531":"bcs",
    "419532":"bcs",
    "419579":"cardstandard",
    "419804":"uralsib",
    "419805":"uralsib",
    "419841":"3dsecure",
    "419999":"creditural",
    "420244":"modirum",
    "420363":"sberbank",
    "420382":"bankofbaku",
    "420772":"multicarta",
    "420845":"citadele",
    "421170":"modirum",
    "421352":"centrum24",
    "421398":"sbbank",
    "421487":"bgpb",
    "421589":"modirum",
    "422838":"vbank",
    "423373":"zapsibkombank",
    "423569":"modirum",
    "424204":"uralsib",
    "424205":"uralsib",
    "424290":"uralsib",
    "424291":"uralsib",
    "424436":"akbars",
    "424480":"open",
    "424538":"poidem",
    "424641":"npc",
    "424671":"ing",
    "424917":"gazprombank",
    "424975":"gazprombank",
    "425125":"ipko",
    "425519":"npc",
    "425520":"npc",
    "425534":"vostbank",
    "425535":"vostbank",
    "425690":"forabank",
    "426075":"cardstandard",
    "426101":"alfabank",
    "426102":"alfabank",
    "426343":"sberbank",
    "426842":"modirum",
    "427229":"vtb",
    "427230":"vtb",
    "427242":"cardstandard",
    "427244":"cardstandard",
    "427263":"modirum",
    "427264":"modirum",
    "427400":"sbrf",
    "427416":"sbrf",
    "427417":"sbrf",
    "427427":"sbrf",
    "427432":"sbrf",
    "427451":"sbrf",
    "427499":"sbrf",
    "427601":"sbrf",
    "427602":"sbrf",
    "427603":"sbrf",
    "427604":"sbrf",
    "427605":"sbrf",
    "427606":"sbrf",
    "427607":"sbrf",
    "427608":"sbrf",
    "427609":"sbrf",
    "427610":"sbrf",
    "427611":"sbrf",
    "427612":"sbrf",
    "427613":"sbrf",
    "427616":"sbrf",
    "427617":"sbrf",
    "427618":"sbrf",
    "427620":"sbrf",
    "427622":"sbrf",
    "427625":"sbrf",
    "427626":"sbrf",
    "427627":"sbrf",
    "427628":"sbrf",
    "427629":"sbrf",
    "427630":"sbrf",
    "427631":"sbrf",
    "427632":"sbrf",
    "427633":"sbrf",
    "427635":"sbrf",
    "427636":"sbrf",
    "427637":"sbrf",
    "427638":"sbrf",
    "427639":"sbrf",
    "427640":"sbrf",
    "427641":"sbrf",
    "427642":"sbrf",
    "427643":"sbrf",
    "427644":"sbrf",
    "427645":"sbrf",
    "427646":"sbrf",
    "427648":"sbrf",
    "427649":"sbrf",
    "427650":"sbrf",
    "427651":"sbrf",
    "427652":"sbrf",
    "427653":"sbrf",
    "427654":"sbrf",
    "427655":"sbrf",
    "427656":"sbrf",
    "427659":"sbrf",
    "427660":"sbrf",
    "427661":"sbrf",
    "427662":"sbrf",
    "427663":"sbrf",
    "427664":"sbrf",
    "427666":"sbrf",
    "427667":"sbrf",
    "427668":"sbrf",
    "427669":"sbrf",
    "427670":"sbrf",
    "427672":"sbrf",
    "427674":"sbrf",
    "427675":"sbrf",
    "427676":"sbrf",
    "427677":"sbrf",
    "427680":"sbrf",
    "427681":"sbrf",
    "427682":"sbrf",
    "427683":"sbrf",
    "427684":"sbrf",
    "427685":"sbrf",
    "427686":"sbrf",
    "427687":"sbrf",
    "427699":"sbrf",
    "427831":"kapital24",
    "427832":"kapital24",
    "427843":"albank",
    "427901":"sbrf",
    "427902":"sbrf",
    "427903":"sbrf",
    "427904":"sbrf",
    "427905":"sbrf",
    "427906":"sbrf",
    "427907":"sbrf",
    "427908":"sbrf",
    "427909":"sbrf",
    "427910":"sbrf",
    "427911":"sbrf",
    "427912":"sbrf",
    "427913":"sbrf",
    "427916":"sbrf",
    "427917":"sbrf",
    "427918":"sbrf",
    "427920":"sbrf",
    "427922":"sbrf",
    "427925":"sbrf",
    "427926":"sbrf",
    "427927":"sbrf",
    "427928":"sbrf",
    "427929":"sbrf",
    "427930":"sbrf",
    "427931":"sbrf",
    "427932":"sbrf",
    "427933":"sbrf",
    "427935":"sbrf",
    "427936":"sbrf",
    "427937":"sbrf",
    "427938":"sbrf",
    "427939":"sbrf",
    "427940":"sbrf",
    "427941":"sbrf",
    "427942":"sbrf",
    "427943":"sbrf",
    "427944":"sbrf",
    "427945":"sbrf",
    "427946":"sbrf",
    "427948":"sbrf",
    "427949":"sbrf",
    "427950":"sbrf",
    "427951":"sbrf",
    "427952":"sbrf",
    "427953":"sbrf",
    "427954":"sbrf",
    "427955":"sbrf",
    "427956":"sbrf",
    "427959":"sbrf",
    "427960":"sbrf",
    "427961":"sbrf",
    "427962":"sbrf",
    "427963":"sbrf",
    "427964":"sbrf",
    "427966":"sbrf",
    "427967":"sbrf",
    "427968":"sbrf",
    "427969":"sbrf",
    "427970":"sbrf",
    "427972":"sbrf",
    "427974":"sbrf",
    "427975":"sbrf",
    "427977":"sbrf",
    "427999":"sbrf",
    "428117":"cardstandard",
    "428521":"bystrobank",
    "428522":"bystrobank",
    "428621":"bgpb",
    "428622":"bgpb",
    "429429":"multicarta",
    "429430":"multicarta",
    "429908":"multicarta",
    "429909":"multicarta",
    "430180":"ubrr",
    "430181":"ubrr",
    "430312":"centrinvest",
    "430313":"centrinvest",
    "430430":"modirum",
    "431409":"cardstandard",
    "431414":"fuib",
    "432037":"npc",
    "432158":"modirum",
    "432335":"a-bank",
    "432433":"sngb",
    "432498":"mkb",
    "432617":"secure2gw",
    "433102":"vbank",
    "433309":"zapsibkombank",
    "433592":"secure2gw",
    "434484":"npc",
    "434485":"npc",
    "434499":"sia",
    "435044":"touchtechpayments",
    "435303":"rshb",
    "435696":"maib",
    "436100":"rshb",
    "437488":"npc",
    "437524":"skbbank",
    "437557":"cardstandard",
    "437772":"tinkoff",
    "437773":"tinkoff",
    "438933":"rosbank",
    "438970":"rosbank",
    "439087":"kkb",
    "439771":"mycardplace",
    "440043":"mts",
    "440204":"sdm",
    "440503":"rosbank",
    "440520":"cardstandard",
    "440563":"kkb",
    "440666":"uralsib",
    "440682":"uralsib",
    "441318":"payment",
    "443272":"mkb",
    "443273":"mkb",
    "443302":"snbank",
    "443306":"modirum",
    "443886":"vostbank",
    "444077":"procard",
    "444111":"acdcproc",
    "444674":"kicb",
    "445433":"homecredit",
    "445452":"multicarta",
    "445453":"multicarta",
    "445636":"cardstandard",
    "445894":"cardstandard",
    "445986":"zapsibkombank",
    "445987":"priorbank",
    "446065":"open",
    "446098":"homecredit",
    "446390":"sbs4u",
    "446685":"cardstandard",
    "446915":"homecredit",
    "446916":"raiffeisen",
    "446942":"cardstandard",
    "447603":"raiffeisen",
    "447624":"raiffeisen",
    "448343":"vtb",
    "449600":"cardstandard",
    "449655":"bps-sberbank",
    "450726":"homecredit",
    "451161":"csas",
    "452443":"npc",
    "453903":"nordea",
    "455052":"sia",
    "455603":"bcs",
    "456587":"modirum",
    "456933":"touchtechpayments",
    "457825":"forabank",
    "457832":"sberbank",
    "458016":"arcot",
    "458039":"cal-online",
    "458047":"arcot",
    "458056":"arcot",
    "458410":"alfabank",
    "458443":"alfabank",
    "458520":"alfabank",
    "458521":"alfabank",
    "458522":"alfabank",
    "459083":"multicarta",
    "459654":"touchtechpayments",
    "460046":"cardstandard",
    "460122":"npc",
    "460257":"bps-sberbank",
    "462235":"vtb",
    "462703":"open",
    "462717":"creditural",
    "462718":"creditural",
    "462729":"raiffeisen",
    "462758":"raiffeisen",
    "463627":"bspb",
    "463903":"bps-sberbank",
    "464132":"bgpb",
    "464461":"arcot",
    "464837":"cardstandard",
    "464842":"vtb",
    "464915":"solid",
    "465206":"mmbank",
    "465626":"forabank",
    "466156":"modirum",
    "466720":"bcc",
    "467034":"cardstandard",
    "469362":"unicredit",
    "469382":"tatsotsbank",
    "469384":"tatsotsbank",
    "469395":"qiwi",
    "469611":"cardstandard",
    "469612":"cardstandard",
    "470434":"zenit",
    "470469":"sbs4u",
    "470550":"vkpay",
    "471226":"ubrr",
    "471326":"priorbank",
    "471420":"zapsibkombank",
    "471487":"vtb",
    "472345":"payment",
    "472879":"skbbank",
    "472934":"vostbank",
    "473118":"privatbank",
    "473121":"privatbank",
    "473829":"cardstandard",
    "474218":"rosbank",
    "474503":"cardinalcommerce",
    "475787":"sbs4u",
    "476208":"payment",
    "477032":"cardstandard",
    "477904":"modirum",
    "477908":"rosbank",
    "477964":"alfabank",
    "477986":"rosbank",
    "478390":"minbank",
    "478475":"cardstandard",
    "478476":"cardstandard",
    "479004":"alfabank",
    "479087":"alfabank",
    "479333":"multicarta",
    "479769":"bspb",
    "479789":"dvbank",
    "480938":"minbank",
    "481776":"sbrf",
    "481779":"sbrf",
    "481781":"sbrf",
    "481785":"3dsecure",
    "484691":"multicarta",
    "484891":"modirum",
    "485463":"sbrf",
    "487415":"gazprombank",
    "487416":"gazprombank",
    "487417":"gazprombank",
    "487432":"cardstandard",
    "487474":"bankmillennium",
    "488940":"3dsecure",
    "489042":"unicredit",
    "489049":"qiwi",
    "489347":"vtb",
    "489349":"vtb",
    "489350":"vtb",
    "489993":"bcc",
    "490449":"bankrbk",
    "490847":"citadele",
    "490855":"unicredit",
    "491698":"priorbank",
    "492065":"wlp-acs",
    "496680":"ukrgasbank",
    "497355":"wlp-acs",
    "498003":"cafis-paynet",
    "499001":"wlp-acs",
    "510047":"rs",
    "510069":"raiffeisen",
    "510070":"raiffeisen",
    "510092":"rs",
    "510100":"cardstandard",
    "510102":"cardstandard",
    "510126":"alfabank",
    "510218":"maib",
    "510229":"ubrr",
    "510230":"ubrr",
    "510306":"pashabank",
    "510307":"kapitalbank",
    "510621":"yoomoney",
    "511776":"unibank",
    "512273":"modirum",
    "512338":"multicarta",
    "512478":"rgsbank",
    "512722":"bgpb",
    "512762":"arcot",
    "512769":"atfbank",
    "513691":"rs",
    "514017":"tochka",
    "514093":"open",
    "514930":"centrinvest",
    "515792":"uralsib",
    "515841":"cardstandard",
    "515876":"raiffeisen",
    "516009":"otpbank",
    "516052":"sbs4u",
    "516116":"rencredit",
    "516295":"roscap",
    "516356":"forabank",
    "516473":"payment",
    "516482":"cardstandard",
    "516737":"swedbank",
    "516744":"modirum",
    "516780":"oschadbank",
    "516793":"swedbank",
    "516798":"privatbank",
    "516812":"upc",
    "516834":"sia",
    "516842":"bnpparibas",
    "516861":"rpc-raiffeisen",
    "516874":"privatbank",
    "516875":"privatbank",
    "516876":"bnpparibas",
    "516891":"gpesecure",
    "516897":"modirum",
    "516931":"ipko",
    "516936":"privatbank",
    "516949":"mts",
    "517011":"3dsecure",
    "517583":"rosbank",
    "517955":"mtsbank",
    "518033":"multicarta",
    "518111":"banksoyuz",
    "518275":"cardstandard",
    "518397":"cardstandard",
    "518449":"uralsib",
    "518487":"cardstandard",
    "518642":"cardstandard",
    "518681":"avangard",
    "518774":"cardstandard",
    "518882":"cardstandard",
    "518901":"tinkoff",
    "518916":"cardstandard",
    "518955":"arcot",
    "518996":"unicredit",
    "519333":"vbank",
    "519747":"alfabank",
    "520195":"npc",
    "520306":"arcot",
    "520348":"open",
    "520373":"payment",
    "520813":"alfabank",
    "520905":"rencredit",
    "520935":"akbars",
    "520985":"akbars",
    "521178":"alfabank",
    "521310":"rgsbank",
    "521324":"tinkoff",
    "521330":"otpbank",
    "521355":"cardstandard",
    "521801":"mkb",
    "522117":"cardstandard",
    "522199":"homecredit",
    "522223":"avangard",
    "522224":"avangard",
    "522458":"unicredit",
    "522470":"otpbank",
    "522598":"vtb",
    "522828":"alfabank",
    "522860":"sbrf",
    "522988":"gazprombank",
    "523915":"kapitalbank",
    "524375":"unibank",
    "524602":"mtsbank",
    "524614":"rosbank",
    "524639":"maib",
    "524655":"mkb",
    "524665":"modirum",
    "524853":"tatsotsbank",
    "525446":"rshb",
    "525477":"cardstandard",
    "525551":"multicarta",
    "525694":"multicarta",
    "525736":"cardstandard",
    "525752":"fortebank",
    "525784":"severgazbank",
    "525833":"gazprombank",
    "525854":"cardstandard",
    "525974":"cardstandard",
    "526210":"sia",
    "526280":"payment",
    "526483":"gazprombank",
    "526818":"rgsbank",
    "526839":"otpbank",
    "526891":"multicarta",
    "526994":"eubank",
    "527093":"vbrr",
    "527269":"bspb",
    "527346":"touchtechpayments",
    "527349":"multicarta",
    "527379":"vkpay",
    "527594":"arcot",
    "527798":"multicarta",
    "527841":"bps-sberbank",
    "528041":"tinkoff",
    "528096":"multicarta",
    "528285":"mmbank",
    "528497":"capitecbank",
    "528588":"akbars",
    "528687":"cardstandard",
    "528933":"rosbank",
    "529170":"cardstandard",
    "529476":"cardstandard",
    "529818":"eubank",
    "529864":"open",
    "529922":"bgpb",
    "530184":"vtb",
    "530403":"open",
    "530794":"aversbank",
    "530827":"alfabank",
    "530829":"sbrf",
    "531073":"uralsib",
    "531207":"uralsib",
    "531233":"vtb",
    "531236":"unicredit",
    "531305":"severgazbank",
    "531310":"sbrf",
    "531315":"rencredit",
    "531317":"multicarta",
    "531344":"unicredit",
    "531362":"zenit",
    "531484":"cardstandard",
    "531544":"siab",
    "532180":"otpbank",
    "532186":"bspb",
    "532301":"open",
    "532308":"npc",
    "532315":"modirum",
    "532336":"modirum",
    "532356":"vbrr",
    "532610":"securesuite",
    "532614":"securesuite",
    "532658":"priorbank",
    "532743":"mkb",
    "532814":"rosbank",
    "532947":"atb",
    "533151":"open",
    "533157":"cardstandard",
    "533205":"sbrf",
    "533206":"avangard",
    "533208":"sbrf",
    "533214":"zenit",
    "533317":"sia",
    "533469":"rs",
    "533594":"raiffeisen",
    "533595":"cardstandard",
    "533669":"sbrf",
    "533681":"arcot",
    "533888":"vinea",
    "533952":"cardinalcommerce",
    "534251":"modirum",
    "535082":"vtb",
    "535104":"mtbank",
    "535144":"modirum",
    "535230":"pekao24",
    "535432":"ukrsibbank",
    "535456":"touchtechpayments",
    "535460":"secure2gw",
    "535473":"bnpparibas",
    "535517":"kredobank",
    "535533":"modirum",
    "535557":"alfabank",
    "535650":"jysanbank",
    "535693":"ing",
    "535705":"bgpb",
    "536354":"privatbank",
    "536392":"raiffeisen",
    "536511":"homecredit",
    "536639":"taslink",
    "536672":"mtsbank",
    "536829":"vtb",
    "536961":"sbrf",
    "537000":"alfabank",
    "537004":"cardstandard",
    "537434":"touchtechpayments",
    "537523":"taslink",
    "537541":"acdcproc",
    "537643":"alfabank",
    "537965":"raiffeisen",
    "538110":"open",
    "538150":"sbrf",
    "538994":"tinkoff",
    "539114":"modirum",
    "539175":"vkpay",
    "539214":"alfabank",
    "539215":"sberbank",
    "539726":"arcot",
    "539838":"bspb",
    "539877":"rshb",
    "539962":"cardstandard",
    "540408":"kapitalbank",
    "540661":"arca",
    "540788":"arcot",
    "540814":"vtb",
    "541162":"multicarta",
    "541264":"citadele",
    "541279":"chelinvest",
    "541600":"bspb",
    "541715":"mmbank",
    "541913":"multicarta",
    "542033":"mkb",
    "542049":"albank",
    "542255":"gazprombank",
    "542350":"zapsibkombank",
    "542654":"atb",
    "542751":"vbrr",
    "543015":"cardstandard",
    "543211":"mkb",
    "543553":"bps-sberbank",
    "544206":"alfabank",
    "544218":"open",
    "544561":"uralfd",
    "544639":"vkpay",
    "544650":"alfabank",
    "544886":"atb",
    "544962":"open",
    "545152":"sbrf",
    "545251":"modirum",
    "545293":"vkpay",
    "545621":"sbs4u",
    "545708":"privatbank",
    "546263":"3dsecure",
    "546339":"uralsib",
    "546340":"uralsib",
    "546850":"cardstandard",
    "546901":"sbrf",
    "546902":"sbrf",
    "546903":"sbrf",
    "546904":"sbrf",
    "546905":"sbrf",
    "546906":"sbrf",
    "546907":"sbrf",
    "546908":"sbrf",
    "546909":"sbrf",
    "546910":"sbrf",
    "546911":"sbrf",
    "546912":"sbrf",
    "546913":"sbrf",
    "546916":"sbrf",
    "546917":"sbrf",
    "546918":"sbrf",
    "546920":"sbrf",
    "546922":"sbrf",
    "546925":"sbrf",
    "546926":"sbrf",
    "546927":"sbrf",
    "546928":"sbrf",
    "546929":"sbrf",
    "546930":"sbrf",
    "546931":"sbrf",
    "546932":"sbrf",
    "546933":"sbrf",
    "546935":"sbrf",
    "546936":"sbrf",
    "546937":"sbrf",
    "546938":"sbrf",
    "546939":"sbrf",
    "546940":"sbrf",
    "546941":"sbrf",
    "546942":"sbrf",
    "546943":"sbrf",
    "546944":"sbrf",
    "546945":"sbrf",
    "546946":"sbrf",
    "546947":"sbrf",
    "546948":"sbrf",
    "546949":"sbrf",
    "546950":"sbrf",
    "546951":"sbrf",
    "546952":"sbrf",
    "546953":"sbrf",
    "546954":"sbrf",
    "546955":"sbrf",
    "546956":"sbrf",
    "546959":"sbrf",
    "546960":"sbrf",
    "546961":"sbrf",
    "546962":"sbrf",
    "546963":"sbrf",
    "546964":"sbrf",
    "546966":"sbrf",
    "546967":"sbrf",
    "546968":"sbrf",
    "546969":"sbrf",
    "546970":"sbrf",
    "546972":"sbrf",
    "546974":"sbrf",
    "546975":"sbrf",
    "546976":"sbrf",
    "546977":"sbrf",
    "546998":"sbrf",
    "546999":"sbrf",
    "547087":"npc",
    "547377":"cardstandard",
    "547447":"uralsib",
    "547910":"sbrf",
    "547911":"sbrf",
    "547927":"sbrf",
    "547930":"sbrf",
    "547931":"sbrf",
    "547943":"sbrf",
    "547944":"sbrf",
    "547972":"sbrf",
    "548020":"wooricard",
    "548062":"cardstandard",
    "548291":"mts",
    "548308":"vbank",
    "548386":"skbbank",
    "548401":"sbrf",
    "548402":"sbrf",
    "548403":"sbrf",
    "548405":"sbrf",
    "548406":"sbrf",
    "548407":"sbrf",
    "548410":"sbrf",
    "548411":"sbrf",
    "548412":"sbrf",
    "548413":"sbrf",
    "548416":"sbrf",
    "548420":"sbrf",
    "548422":"sbrf",
    "548425":"sbrf",
    "548426":"sbrf",
    "548427":"sbrf",
    "548428":"sbrf",
    "548430":"sbrf",
    "548431":"sbrf",
    "548432":"sbrf",
    "548435":"sbrf",
    "548436":"sbrf",
    "548438":"sbrf",
    "548440":"sbrf",
    "548442":"sbrf",
    "548443":"sbrf",
    "548444":"sbrf",
    "548445":"sbrf",
    "548447":"sbrf",
    "548448":"sbrf",
    "548449":"sbrf",
    "548450":"sbrf",
    "548451":"sbrf",
    "548452":"sbrf",
    "548454":"sbrf",
    "548455":"sbrf",
    "548456":"sbrf",
    "548459":"sbrf",
    "548460":"sbrf",
    "548461":"sbrf",
    "548462":"sbrf",
    "548463":"sbrf",
    "548464":"sbrf",
    "548466":"sbrf",
    "548468":"sbrf",
    "548469":"sbrf",
    "548470":"sbrf",
    "548472":"sbrf",
    "548476":"sbrf",
    "548477":"sbrf",
    "548498":"sbrf",
    "548673":"alfabank",
    "548674":"alfabank",
    "548755":"cardstandard",
    "548888":"georgiancard",
    "548934":"cardstandard",
    "548952":"priorbank",
    "548999":"gazprombank",
    "549000":"gazprombank",
    "549071":"skbbank",
    "549307":"cardstandard",
    "549803":"open",
    "550025":"cardstandard",
    "550165":"modirum",
    "550575":"cardstandard",
    "550583":"mtsbank",
    "552140":"otpbank",
    "552175":"alfabank",
    "552186":"alfabank",
    "552702":"gazprombank",
    "553420":"tinkoff",
    "553608":"npc",
    "553609":"cardstandard",
    "553691":"tinkoff",
    "554384":"multicarta",
    "554386":"vtb",
    "554393":"vtb",
    "554546":"uralsib",
    "554562":"roscap",
    "554607":"roscap",
    "554759":"payment",
    "554761":"rosbank",
    "554781":"payment",
    "554782":"rosbank",
    "554832":"mtbank",
    "554863":"cardstandard",
    "555928":"ubrr",
    "555933":"alfabank",
    "555947":"alfabank",
    "555949":"alfabank",
    "555957":"alfabank",
    "557000":"sbrf",
    "557030":"zenit",
    "557031":"creditural",
    "557056":"modirum",
    "557057":"modirum",
    "557071":"minbank",
    "557072":"minbank",
    "557367":"swedbank",
    "557368":"swedbank",
    "557484":"micb",
    "557519":"centrum24",
    "557528":"arcot",
    "557761":"cardstandard",
    "557802":"micb",
    "557805":"sngb",
    "557834":"kkb",
    "557884":"npc",
    "557885":"npc",
    "557954":"cardstandard",
    "557959":"smpbank",
    "557976":"cardstandard",
    "558535":"avangard",
    "558536":"raiffeisen",
    "558620":"open",
    "558672":"payment",
    "559488":"bcs",
    "559654":"homecredit",
    "559669":"uralsib",
    "559813":"modirum",
    "559900":"yoomoney",
    "623446":"rshb",
    "639002":"sbrf",
    "671127":"npc",
    "671176":"npc",
    "671177":"npc",
    "671182":"bpcprocessing",
    "676280":"sbrf",
    "676347":"centrinvest",
    "676454":"gazprombank",
    "676463":"avangard",
    "676531":"minbank",
    "676907":"vtb",
    "677384":"chelinvest"
}
