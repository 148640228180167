var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-reports-page" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n      Reports\n    "),
      ]),
      _vm._v(" "),
      _c("yp-sheet", { staticClass: "yp-reports-page__content yp-content" }, [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v("Click to download "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "yp-reports-page__list" },
          _vm._l(_vm.reports, function (report, key) {
            return _c("a", { key: key, attrs: { href: report.link } }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    report.link.slice(
                      report.link.lastIndexOf("/") + 1,
                      report.link.length
                    )
                  ) +
                  "\n        "
              ),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }