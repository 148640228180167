var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _setup.onClickedOutside,
          expression: "onClickedOutside",
        },
      ],
      staticClass: "yp-select",
    },
    [
      _c(
        "div",
        {
          staticClass: "yp-select__selector",
          class: { "yp-select__selector--open": _setup.isOptionsVisible },
          on: { click: _setup.onSelectorClicked },
        },
        [
          _setup.selectedOption
            ? [
                _c(_setup.Icon, {
                  staticClass: "yp-select__icon",
                  attrs: {
                    name: _setup.selectedOption.icon,
                    width: "20",
                    height: "20",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "yp-select__text" }, [
                  _c("span", [
                    _vm._v(_vm._s(_setup.getOptionText(_setup.selectedOption))),
                  ]),
                ]),
              ]
            : _c("div", [_vm._v(_vm._s(_vm.placeholder))]),
          _vm._v(" "),
          _c(_setup.Icon, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.options.length > 1,
                expression: "options.length > 1",
              },
            ],
            staticClass: "yp-select__chevron",
            attrs: { name: "ChevronDown" },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isOptionsVisible && _vm.options.length > 1,
              expression: "isOptionsVisible && options.length > 1",
            },
          ],
          staticClass: "yp-select__options",
        },
        _vm._l(_vm.options, function (option) {
          return option.pk !== _vm.value
            ? _c(
                "div",
                {
                  key: option.pk,
                  staticClass: "yp-select__option",
                  on: {
                    click: function ($event) {
                      return _setup.onOptionSelected(option)
                    },
                  },
                },
                [
                  _c(_setup.Icon, {
                    staticClass: "yp-select__icon",
                    attrs: { name: option.icon },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-select__text" }, [
                    _c("span", [_vm._v(_vm._s(_setup.getOptionText(option)))]),
                  ]),
                ],
                1
              )
            : _vm._e()
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }