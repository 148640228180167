var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      class: [
        "yp-btn",
        { "yp-btn--icon": _vm.isIconBtn },
        { "yp-btn--ghost": _vm.ghost },
        `yp-btn--${_vm.size}`,
        // `yp-btn--${variant}`
      ],
      style: _vm.styles,
      attrs: {
        variant: "default",
        disabled: _vm.disabled || _vm.$store.getters.isFormSending,
      },
      on: { click: _vm.throttleHandler },
    },
    [
      _vm.icon
        ? _c("icon", {
            attrs: {
              name: _vm.icon,
              color: _vm.iconColor,
              width: _vm.iconSize,
              height: _vm.iconSize,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.timer
        ? _c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])
        : _c("span", { staticClass: "yp-btn__timer" }, [
            _vm._v("\n    " + _vm._s(_vm.timer) + "\n  "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }