<template>
  <div class="profile-settings">
    <h3 class="text-center">
      {{ $t('profilePage.settingsSection.title') }}
    </h3>
    <div class="profile-settings__theme">
      <div class="profile-settings__theme-block">
        <b-radio disabled v-model="selectedTheme" value="light" @change="setMode($event)">
          <icon name="WeatherSun"/>
          {{ $t('profilePage.settingsSection.lightTheme') }}
        </b-radio>
      </div>
      <div class="profile-settings__theme-block">
        <b-radio v-model="selectedTheme" value="dark" @change="setMode($event)">
          <icon name="WeatherMoon"/>
          {{ $t('profilePage.settingsSection.darkTheme') }}
        </b-radio>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Icon from "@/components/ui/Icon";

export default {
  name: "ProfileSettings",
  components: {Icon},
  data() {
    return {
      selectedTheme: 1,
    }
  },
  computed: {
    ...mapGetters(['mode'])
  },
  methods: {
    ...mapMutations(['setMode'])
  },
  created() {
    this.selectedTheme = this.mode
  },
}

</script>

<style lang="scss" scoped>
@import "src/assets/scss/mixins";

.profile-settings {
  &__theme {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &__theme-block {
    @include border-style($radius: 10px);
    flex-grow: 1;
    padding: 20px;
  }
}
</style>