var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.accounts.length === 0
    ? _c("yp-loader", { staticClass: "yp-app__loader" })
    : _c(
        "div",
        { staticClass: "yp-account-deposit" },
        [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("accountDeposit.title")) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "yp-sheet",
            { staticClass: "yp-account-deposit__content yp-content mx-auto" },
            [
              _c("yp-account-select", {
                attrs: {
                  "external-val": _vm.usdtAccount || _vm.accounts[0],
                  options: _vm.accounts,
                },
                on: { accountSelected: _vm.onAccountSelected },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCryptoMethod,
                      expression: "!isCryptoMethod",
                    },
                  ],
                  staticClass: "yp-account-deposit__form mt-3",
                },
                [
                  _c("yp-input", {
                    ref: "amountInput",
                    attrs: {
                      type: "number",
                      label: `Amount (${_vm.currentAccount.currency_code}):`,
                      placeholder: "0.00",
                      "external-val": _vm.amount,
                    },
                    on: {
                      update: function ($event) {
                        _vm.amount = $event
                      },
                      inputError: function ($event) {
                        _vm.amount = null
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-select", {
                    staticClass: "yp-account-deposit__selector",
                    attrs: { options: _vm.availableMethods },
                    model: {
                      value: _vm.method,
                      callback: function ($$v) {
                        _vm.method = $$v
                      },
                      expression: "method",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("yp-msg", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCryptoMethod,
                    expression: "!isCryptoMethod",
                  },
                ],
                staticClass: "mt-2",
                attrs: {
                  variant: "info",
                  msg: "Funds will be credited to your ruble account",
                },
              }),
              _vm._v(" "),
              _vm.commission && !_vm.isCryptoMethod
                ? _c(
                    "div",
                    { staticClass: "yp-commission-wrap yp-additional-text" },
                    [
                      _vm._v(
                        "\n       " +
                          _vm._s(_vm.$t("commission") + _vm.commission) +
                          "\n     "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.qrSrc
                ? _c(
                    "div",
                    { staticClass: "yp-account-deposit__qr-wrap" },
                    [
                      _c("yp-qr", {
                        attrs: {
                          src: `data:image/svg+xml;base64,${_vm.qrSrc}`,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-info" }, [
                        _vm._v(
                          "\n          Please scan the qr code to continue payment\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.method === "ClientCard"
                ? _c("yp-card", {
                    staticClass: "yp-account-deposit__card",
                    on: {
                      update: function ($event) {
                        _vm.cardData = $event
                      },
                      error: function ($event) {
                        _vm.cardData = null
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentAccount?.methods?.deposit?.includes("Crypto") &&
              _vm.currentAccount?.fee?.deposit?.percent + 1
                ? _c(
                    "div",
                    { staticClass: "yp-account-deposit__crypto" },
                    [
                      _c("yp-qr", {
                        attrs: {
                          src: `data:image/svg+xml;base64,${_vm.currentAccount.address_qr}`,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.currentAccount.address)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "yp-commission-wrap yp-additional-text mx-auto",
                        },
                        [
                          _vm._v(
                            "\n         " +
                              _vm._s(
                                `${_vm.$t("commission")}: ${
                                  _vm.currentAccount.fee.deposit.percent
                                }%`
                              ) +
                              "\n         "
                          ),
                          _vm.currentAccount.limits.deposit["Crypto"]
                            ? _c("div", [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(
                                      `Min transaction: ${
                                        _vm.currentAccount.limits.deposit[
                                          "Crypto"
                                        ].min_transaction +
                                        _vm.currentAccount.currency_code
                                      }`
                                    ) +
                                    "\n         "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentAccount.limits.deposit["Crypto"]
                            ? _c("div", [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(
                                      `Max transaction: ${
                                        _vm.currentAccount.limits.deposit[
                                          "Crypto"
                                        ].max_transaction +
                                        _vm.currentAccount.currency_code
                                      }`
                                    ) +
                                    "\n         "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("yp-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCryptoMethod,
                    expression: "!isCryptoMethod",
                  },
                ],
                staticClass: "yp-account-deposit__btn",
                attrs: {
                  label: _vm.$t("accountDeposit.submit"),
                  disabled: _vm.isSubmitDisabled,
                },
                on: { click: _vm.onSubmit },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isConfirmModalVisible && _vm.cardData
            ? _c(
                "yp-modal",
                {
                  attrs: {
                    title: _vm.$t("accountDeposit.modal.title"),
                    "confirm-btn": _vm.$t("accountDeposit.modal.confirm"),
                    "cancel-btn": _vm.$t("accountDeposit.modal.cancel"),
                    errors: _vm.errors,
                  },
                  on: {
                    confirm: _vm.onConfirm,
                    closeModal: function ($event) {
                      _vm.isConfirmModalVisible = false
                    },
                  },
                },
                [
                  _c("yp-card-input", {
                    attrs: {
                      label: "Card Number:",
                      "init-value": _vm.cardData.number,
                      readonly: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("span", { staticClass: "text-info" }, [
                      _vm._v(_vm._s(_vm.$t("amount")) + ":"),
                    ]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.amount + _vm.currentAccount.currency_code) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("span", { staticClass: "text-info" }, [
                      _vm._v(_vm._s(_vm.$t("commission")) + ":"),
                    ]),
                    _vm._v("\n       " + _vm._s(_vm.commission) + "\n     "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }