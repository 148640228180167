export function getCookie(name) {
    let re = new RegExp(name + "=([^;]+)"),
        value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}


function setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options
    };
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }


export function delCookie(name) {
    setCookie(name, '', 0)
  }

export function debounce(func, timeout = 100) {
    let timer = null
    return function () {
        clearTimeout(timer)
        const args = arguments
        const context = this
        timer = setTimeout(function () {
            func.apply(context, args)
        }, timeout)
    }
}

export function throttle(func, delay = 250) {
    let shouldWait = false
    return function () {
        if (shouldWait) return
        const args = arguments
        const context = this
        func.apply(context, args)
        shouldWait = true
        setTimeout(() => {
            shouldWait = false
        }, delay)
    }
}
