<template>
    <div
        class="yp-message"
        :class="[`yp-message--${variant}`, `yp-message--size-${size}`]"
    >
        *{{ msg }}
    </div>
</template>

<script>
    export default {
        name: "YpMessage",
        props: {
            msg: String,
            variant: {
                type: String,
                default: () => 'err',
            },
            size: String,
        },
    }
</script>

<style lang="scss" scoped>
.yp-message {
    font-size: 10px;

  &--size-lg {
    font-size: 16px;
  }

    &--err {
        color: $danger;
    }
}
</style>