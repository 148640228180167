export default api => ({
    namespaced: true,
    state: {
        snack: null,
    },
    getters: {
        snack: state => state.snack
    },
    mutations: {
        SET_SNACK(state, payload) {
            state.snack = payload
        }
    }
})