import axios from "axios"
import qs from "qs"

const instance = axios.create({
    baseURL: process.env.API_URL || "/api",
    timeout: 60000,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: "repeat", skipNulls: true,})
    }
})

export function addResponseHandler(onSuccess, onError) {
    instance.interceptors.response.use(onSuccess, onError)
}

export function addRequestHandler(onSuccess, onError) {
    instance.interceptors.request.use(onSuccess, onError)
}

export default instance
