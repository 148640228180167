<template>
  <notifications classes="yp-notification" :position="position">
    <template slot="body" slot-scope="props">
      <div class="yp-notification">
        <div>
          <div v-html="props.item.title"></div>
          <div v-html="props.item.text"></div>
        </div>
        <div>
          <yp-button
            class="yp-notification__close"
            icon="Close"
            @click="props.close"
          />
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import { mapGetters } from "vuex";
import YpButton from "@/components/ui/Button";

export default {
  name: "YpNotification",
  components: {YpButton},
  computed: {
    ...mapGetters('snackbar', ['snack']),

    position() {
      return this.$store.getters.isMobile ? 'top center' : 'top right'
    }
  },
  watch: {
    snack: {
      handler(val) {
        this.$notify(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.yp-notification {
  background: $black;
  font-size: 14px;
  border: 1px solid $primary;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;

  &__close {
    border: none !important;
  }
}

</style>