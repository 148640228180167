import i18n from "@/plugins/i18n";

export default api => ({
    namespaced: true,
    state: {
        cardsList: [],
    },
    getters: {
        cardsList: state => state.cardsList,
    },
    mutations: {
        SET_CARDS_LIST(state, payload) {
            state.cardsList = payload
        }
    },
    actions: {
        getCardsList({ commit }, params) {
            return api
                .get('client/cards', {params: params})
                .then(response => commit('SET_CARDS_LIST', response.data))
        },
        createCard({ commit }, data) {
           return api
               .post('client/cards/create', data)
               .then(response => {
                   if (response.status === 200) {
                       commit("snackbar/SET_SNACK", {
                           title: i18n.t("snackbarMessages.virtualCards.create.success"),
                           duration: 5000,
                       }, { root: true })
                       return response.data
                   } else {
                       commit("snackbar/SET_SNACK", {
                           type: 'error',
                           title: i18n.t("snackbarMessages.virtualCards.create.failed"),
                           duration: 5000,
                       }, { root: true })
                   }
               })
        },
        restoreCard({commit}, data) {
            return api
               .put(`client/cards/${data.id}/restore`, data.payload)
               .then(response => {
                   if(response.status === 200) {
                       commit("snackbar/SET_SNACK", {
                           title: i18n.t("snackbarMessages.virtualCards.restore.success"),
                           duration: 5000,
                       }, { root: true })
                   } else {
                       commit("snackbar/SET_SNACK", {
                           type: 'error',
                           title: i18n.t("snackbarMessages.virtualCards.restore.failed"),
                           duration: 5000,
                       }, { root: true })
                   }
                   return response.status === 200
               })
        },
        blockCard({commit}, data) {
           return api
               .put(`client/cards/${data.id}/block`, data.payload)
               .then(response => {
                   if(response.status === 200) {
                       commit("snackbar/SET_SNACK", {
                           title: i18n.t("snackbarMessages.virtualCards.block.success"),
                           duration: 5000,
                       }, { root: true })
                   } else {
                       commit("snackbar/SET_SNACK", {
                           type: 'error',
                           title: i18n.t("snackbarMessages.virtualCards.block.failed"),
                           duration: 5000,
                       }, { root: true })
                   }
                   return response.status === 200
               })
        },
        topUpCard({commit}, data) {
            return api
                .put(`client/cards/${data.id}/topup`, data.payload)
                .then(response => {
                    if(response.status === 200) {
                        commit("snackbar/SET_SNACK", {
                            title: i18n.t("snackbarMessages.virtualCards.topUp.success"),
                            duration: 5000,
                        }, { root: true })
                    } else {
                        commit("snackbar/SET_SNACK", {
                            type: 'error',
                            title: i18n.t("snackbarMessages.virtualCards.topUp.failed"),
                            duration: 5000,
                        }, { root: true })
                    }
                    return response.status === 200
                })
        },

        getCard({ commit }, id) {
            return api
                .get(`client/cards/${id}`)
        }
    }
})
