<template>
  <yp-sheet class="yp-payout-card">
    <yp-account-select
        @accountSelected="onAccountSelected"
        :external-val="accounts[0]"
        :filter="account => !account.has_parent"
    />
    <div class="yp-payout-card__inputs">
      <slot name="sourceInput">
      </slot>
      <yp-input
          :label="`Amount (${currentAccount.currency_code}):`"
          ref="amountInput"
          :disabled="!!file"
          :max-value="currentAccount.amount_with_overdraft || currentAccount.amount"
          :fee-rules="feeRules"
          is-max-mode
          placeholder="0.00"
          type="number"
          step="0.01"
          :init-value="amount"
          :limits="currentAccount.limits['withdraw']"
          @update="amountUpdate"
          @inputError="amountUpdate(null)"
      />
    </div>
    <div class="yp-payout-card__info">
      <div
          v-if="commission"
          class="yp-payout-card__additional-text yp-additional-text"
          :class="{ 'opacity-03': file }"
      >
        {{ $t('payoutsPage.commission') + ' ' + commission }}
      </div>
    </div>
    <div class="yp-payout-card__actions">
      <yp-button
          size="xl"
          icon="Chat"
          :label="$t('payoutsPage.commentBtn')"
          @click="onCommentBtnClick"
      />
      <yp-button
          v-if="haveFileInput"
          size="xl"
          icon="Plus"
          :label="$t('payoutsPage.addPaymentBtn')"
          @click="onAddPaymentBtnClick"
      />
      <slot name="cardActions">
      </slot>
    </div>
    <div v-show="isActionsFieldVisible" class="yp-payout-card__actions-field">
      <icon
          class="yp-payout-card__actions-field-close"
          name="Close"
          width="10px"
          height="10px"
          @click.native="onCloseActionsField"
      />
      <div class="yp-payout-card__actions-field-wrap theme-border">
        <yp-textarea
            v-if="actionFieldComponent === 'comment'"
            label="Comment"
            :max-length="250"
            @update="updateComment"
        />
        <yp-upload
            v-else
            @fileLoaded="setFile($event)"
            @showModal="showFileErrorModal = true"
            @errorFileLoad="setFile(null)"
        />
      </div>
      <div class="yp-payout-card__actions-field-additional yp-info-block">
        <icon name="Info" color="primary" />
        <div v-if="actionFieldComponent === 'comment'">
          Your comments will only be visible to you.
          Comments are an Optional field, not required for payment.
        </div>
        <div v-else>
          Each payment must be on a separate line. File must not contain more than 100 lines. Copy payment details or upload a file with the following layout:<br/>
          <div class="text-info font-weight-bold">Card number; Amount; Comments (optional)</div>
	  <div class="text-info font-weight-bold">Card number; Amount; </div>
	  Example:
	  <div class="text-info font-weight-bold">4111000011110000;100;</div>
	  <div class="text-info font-weight-bold">4111000011110000;100;my-comment;</div>
        </div>
      </div>
    </div>
    <slot name="modal">
    </slot>
    <yp-modal
        v-if="showFileErrorModal"
        without-actions
        :title="$t('payoutsPage.modals.fileLayout.title')"
        @closeModal="showFileErrorModal = false"
    >
      <span class="text-info" v-html="$t('payoutsPage.modals.fileLayout.text')" />
    </yp-modal>
    <verify-modal v-if="isFormSending" />
  </yp-sheet>
</template>

<script>
import YpSheet from "@/components/ui/Sheet";
import YpInput from "@/components/ui/YpInput";
import YpCardInput from "@/components/ui/YpCardInput";
import Icon from "@/components/ui/Icon";
import YpTextarea from "@/components/ui/YpTextarea";
import YpButton from "@/components/ui/Button";
import YpCheckbox from "@/components/ui/YpCheckbox";

import {mapGetters} from 'vuex'
import YpModal from "@/components/ui/YpModal";
import VerifyModal from "@/components/payouts/VerifyModal";
import YpUpload from "@/components/ui/YpUpload";

import YpAccountSelect from "@/components/ui/YpSelect";

export default {
  name: "PayoutCard",
  components: {
    YpAccountSelect,
    YpUpload,
    VerifyModal,
    YpModal,
    YpTextarea,
    Icon,
    YpCheckbox,
    YpButton,
    YpInput,
    YpSheet,
    YpCardInput
  },
  mixins: [],
  props: {
    isFormSending: Boolean,
    currentAccount: {},
    file: {},
    limits: {},
    accountsMethod: {},
    haveFileInput: {
      default: false
    },
    amount: {},
    comment: {},
  },
  data() {
    return {
      actionFieldComponent: 'comment',
      isActionsFieldVisible: false,
      showFileErrorModal: false,
      maxValueWithCommission: null,
    }
  },
  computed: {
    ...mapGetters(['accountsByMethods', 'currentAccount']),
    accounts() {
      return this.accountsByMethods('withdraw', this.accountsMethod)
    },
    feeRules() {
      const rules = this.currentAccount.fee?.withdraw;
      if (rules == null) {
        this.$store.commit('snackbar/SET_SNACK', {
          title: "Internal Error",
          text: `Contact the support: missing withdraw tarifline (${this.currentAccount.pk}).`,
          duration: 1000 * 60,
        });
        throw new Error(`No withdraw tarifline: ${JSON.stringify(this.currentAccount)}`);
      } else {
        return rules;
      }
    },
    commission() {
      const feeRules = this.feeRules;
      const feePercent = feeRules.percent;
      const feeMinimal = feeRules.minimal;
      const feeFixed = feeRules.fixed;
      const currency = this.currentAccount.currency_symbol
      const amount = Number(this.amount)
      const minCommission = `(${feeMinimal ? feeMinimal: feePercent+"%"} + ${feeFixed}): ${feeMinimal + feeFixed + currency}`
      const commission =  `(${feePercent}% + ${feeFixed}): ${((amount / 100 * feePercent) + feeFixed).toFixed(2) + currency}`
      return (amount / 100 * feePercent) <= feeMinimal ?
          minCommission :
          commission
    },
  },
  methods: {
    onAccountSelected() {
      const input = this.$refs.amountInput
      if (input) this.$nextTick(() => {
        input.validate()
      })
    },
    amountUpdate(event) {
      this.$emit('amount-update', event);
    },
    onCommentBtnClick() {
      this.actionFieldComponent = 'comment'
      this.isActionsFieldVisible = true
    },
    onAddPaymentBtnClick() {
      this.actionFieldComponent = 'add-payment'
      this.isActionsFieldVisible = true
    },
    onCloseActionsField() {
      this.isActionsFieldVisible = false
      if (this.actionFieldComponent === 'add-payment') {
        this.setFile(null);
      }
    },
    setFile(event) {
      this.$emit('set-file', event)
    },
    updateComment(event) {
      this.$emit('update-comment', event)
    }
  }
}
</script>

<style>
.yp-payout-card {
  margin: 0 auto;
}
</style>