<template>
  <div class="operations-list">
    <operation-item
        class="operations-list__item"
        v-for="operation in operations"
        :key="operation.uid"
        :value="operation"
    />
  </div>
</template>

<script>
import OperationItem from "@/components/history/OperationItem";
export default {
  name: "OperationsList",
  components: { OperationItem },
  props: {
    operations: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style lang="scss" scoped>
.operations-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
