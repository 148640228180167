<template>
  <section class="yp-history">
    <h2 class="text-center">
      {{ $t('historyPage.title') }}
    </h2>
    <yp-sheet id="operations-list" class="yp-history__content yp-content">
      <yp-select
          manual
          @accountSelected="filter.account = [ $event.pk ]"
          @allAccountSelected="filter.account = null"
      />
      <div
          v-if="$store.getters.permissions.includes('Balance')"
          class="yp-history__account-amount-by-date"
      >
        <div class="d-flex justify-content-between align-items-center">
           <span class="text-info flex-shrink-0 yp-xs-text">
            {{ $t('historyPage.accountAmountByDate') }}:
          </span>
          <date-picker
              class="yp-datepicker mt-2 ml-1"
              v-model="accountAmountByDate"
              :default-value="new Date()"
              :disabled-date="disableAfterToday"
              @input="selectAccountAmountByDate"
          />
        </div>
        <span>
          {{ filterBalanceText }}
        </span>
      </div>
      <div class="yp-history__search">
        <yp-input
            icon="Search"
            :placeholder="$t('historyPage.searchPlaceholder')"
            @update="filter.query = $event"
        />
        <yp-button
            class="yp-history__filters"
            icon="Filter"
            is-icon-btn
            icon-color="white"
            square
            @click="isFilterModalVisible = true"
        />
        <yp-button
            class="yp-history__file"
            icon="FileDownload"
            is-icon-btn
            icon-color="white"
            square
            @click="isFileModalVisible = true"
        />
      </div>
      <div class="yp-history__fast-filters">
        <b-checkbox-group
            v-model="filter.kind"
            class="w-100"
            :options="fastTypes"
            text-field="name"
            value-field="value"
            buttons
        />
        <b-checkbox-group
            v-model="filter.status"
            :options="fastStatuses"
            class="w-100"
            text-field="name"
            value-field="value"
            buttons
        />
      </div>
      <div class="yp-history__files" v-if="reports.length">
        <a
            v-for="(report, key) in reports.slice(0, 2)"
            :key="key"
            :href="report.link"
            class="yp-history__file-report"
        >
          {{ report.link.slice(report.link.lastIndexOf('/')+1, report.link.length) }}
        </a>
        <router-link :to="{ name: 'reports'}">More reports...</router-link>
      </div>
      <div
          class="yp-history__group"
          v-for="(el, index) in operations"
          :key="`history-group-${index}`"
      >
        <div class="text-center text-info">{{ getDate(el.date) }}</div>
        <operations-list :operations="el.list"/>
      </div>
      <h3 v-show="!isOperationsLoading && (operations.length === 0 && errors.length === 0)"
          class="text-center text-info mt-3">
        {{ $t('operationPage.emptyList') }}
      </h3>
      <div v-if="!isOperationsLoading && errors.length > 0">
        <h3 v-for="(err, key) in errors" :key="key" class="text-center text-danger mt-3">
          {{ err.message }}
        </h3>
        <yp-button
            class="mx-auto mt-3"
            size="xl"
            variant="gradient-outline"
            :label="$t('historyPage.filterModal.cancelBtn')"
            @click="resetFilter"
        />
      </div>
      <yp-loader v-show="isOperationsLoading" class="yp-history__loader"/>
    </yp-sheet>
    <yp-modal
        class="yp-history__filter-modal"
        v-if="isFilterModalVisible"
        without-title
        :confirm-btn="$t('historyPage.filterModal.confirmBtn')"
        :cancel-btn="$t('historyPage.filterModal.cancelBtn')"
        @closeModal="isFilterModalVisible = false"
        @confirm="isFilterModalVisible = false"
        @cancel="resetFilter"
    >
      <div class="yp-history__filter-modal-content">
        <yp-input
            icon="Search"
            :placeholder="$t('historyPage.searchPlaceholder')"
            @update="filter.query = $event"
        />
        <div class="yp-divider"/>
        <div class="mt-3">
          <div class="yp-xs-title">
            {{ $t('historyPage.filterModal.paymentTypeLabel') }}:
          </div>
          <b-checkbox-group
              v-model="filter.kind"
              :options="operationTypes"
              text-field="name"
              value-field="value"
          />
        </div>
        <div class="yp-divider"/>
        <div class="mt-3">
          <div class="yp-xs-title">
            {{ $t('historyPage.filterModal.paymentStatusLabel') }}:
          </div>
          <b-checkbox-group
              class="yp-checkbox"
              v-model="filter.status"
              :options="operationStatuses"
              text-field="name"
              value-field="value"
          />
        </div>
        <div class="yp-divider"/>
        <div class="mt-3">
          <div class="yp-xs-title">
            {{ $t('historyPage.filterModal.periodLabel') }}:
          </div>
          <div class="d-flex align-items-center">
            <span class="text-info yp-xs-text">
              {{
                $t('historyPage.filterModal.periodFrom') +
                '/' +
                $t('historyPage.filterModal.periodTo')
              }}:
            </span>
            <date-picker
                class="yp-datepicker mt-2 ml-1"
                v-model="filter.date"
                :default-value="new Date()"
                :disabled-date="disableAfterToday"
                range
            />
          </div>
        </div>
        <div class="yp-divider"/>
      </div>
    </yp-modal>
    <yp-modal
        v-if="isFileModalVisible"
        class="yp-history__download-file"
        :title="$t('historyPage.fileModal.title')"
        :confirm-btn="$t('historyPage.fileModal.confirmBtn')"
        :cancel-btn="$t('historyPage.fileModal.cancelBtn')"
        @closeModal="isFileModalVisible = false"
        @confirm="onConfirmFileModal"
    >
      <div class="yp-divider"/>
      <div class="yp-history__file-formats">
        <div class="yp-xs-title">
          {{ $t('historyPage.fileModal.text') }}:
        </div>
        <div class="d-flex justify-content-between">
          <b-radio-group
              class="yp-checkbox"
              v-model="selectedFormats"
              :options="fileFormats"
          />
          <icon
              name="Info"
              v-b-tooltip.hover.top
              :title="$t('historyPage.fileModal.infoText')"
          />
        </div>
      </div>
      <div class="yp-divider"/>
    </yp-modal>
<!--    <yp-button-->
<!--        v-show="selectedOperations.length > 1"-->
<!--        class="yp-history__repeat"-->
<!--        icon="Reload"-->
<!--        id="repeat"-->
<!--        variant="light"-->
<!--        is-icon-btn-->
<!--    />-->
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {disableAfterToday, getDate, toSecondsFrom1970} from '@/utils/dateConverter'
import {debounce} from "utils/helpers";

import OperationsList from "@/components/history/OperationsList";
import YpSheet from "@/components/ui/Sheet";
import YpSelect from "@/components/ui/YpSelect";
import YpLoader from "@/components/ui/YpLoader";
import YpInput from "@/components/ui/YpInput";
import YpButton from "@/components/ui/Button";
import Icon from "@/components/ui/Icon";
import YpCheckbox from "@/components/ui/YpCheckbox";
import YpModal from "@/components/ui/YpModal";
import DatePicker from 'vue2-datepicker'
import reports from "@/views/Reports.vue";

export default {
  name: "History",
  components: {
    DatePicker,
    YpModal,
    YpCheckbox,
    Icon,
    YpButton,
    YpInput,
    YpLoader,
    OperationsList,
    YpSelect,
    YpSheet
  },
  data() {
    return {
      operationsOffset: 0,
      bottomListPosition: null,
      selectedFormats: 'csv',
      fileFormats: [
        'csv',
        'xlsx'
      ],
      operationTypes: [
        {
          name: 'Deposit',
          value: 'DE',
        },
        {
          name: 'Payout',
          value: 'WI',
        },
        {
          name: 'Exchange',
          value: 'EX',
        },
        {
          name: 'Card Topup',
          value: 'CT',
        },
        {
          name: 'Card Open',
          value: 'CO',
        },
      ],
      operationStatuses: [
        {
          name: 'Pending',
          value: 'P',
        },
        {
          name: 'Complete',
          value: 'C',
        },
        {
          name: 'Operating',
          value: 'O',
        },
        {
          name: 'Failed',
          value: 'F',
        },
      ],
      filter: {
        since: null,
        until: null,
        query: null,
        account: null,
        amount: null,
        kind: [],
        status: [],
        date: null,
        type: null,
      },
      isFileModalVisible: false,
      isFilterModalVisible: false,
      hasActiveFilter: {
        kindWi: false,
        kindDe: false,
        kindExchange: false,
        statusC: false,
        statusF: false,
      },
      isOperationsLoading: false,
      errors: [],
      filterBalance: null,
      filterBalanceDate: null,
      accountAmountByDate: null,
    }
  },
  computed: {
    ...mapGetters('payouts', ['operations', 'selectedOperations', 'reports']),
    ...mapGetters(['accounts', 'currentAccount', 'isMobile']),

    fastTypes() {
      return [this.operationTypes[0], this.operationTypes[1]]
    },
    filterBalanceText() {
      return this.filterBalance ?
          `Balance: ${this.filterBalance.balance} Holded Balance: ${this.filterBalance.holded}` :
          this.$t('pleaseSelectAccount')
    },
    fastStatuses() {
      return [this.operationStatuses[1], this.operationStatuses[3]]
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      handler(val) {
        this.getOperations(val)
      }
    },
    'filter.account': {
      handler(val) {
        if(val && this.filterBalanceDate) {
          this.selectAccountAmountByDate(this.filterBalanceDate)
        }
      }
    },
    filter: {
      deep: true,
      handler: debounce(function (val) {
        // после каждого обновления фильтра сбрасываем сдвиг, чтоб получить первые 10 элементов
        this.operationsOffset = 0
        window.addEventListener('scroll', this.onScroll)
        let query = {}
        for (let prop in val) {
          if (val.hasOwnProperty(prop) && val[prop]) {
            if (prop === 'date' && val.date.every(el => el) && val.date.length > 0) {
              const dates = toSecondsFrom1970(val.date)
              query.since = dates[0]
              query.until = dates[1] + 86400
            } else {
              query[prop] = val[prop]
            }
          }
        }
        if (Object.keys(query).length !== 0) {
          this.$router.push({query})
        }
      }, 800)
    }
  },
  methods: {
    reports() {
      return reports
    },
    ...mapActions('payouts', ['getPayouts', 'orderReport', 'getReports', 'getReportByName']),
    ...mapActions(['getAccountBalance']),
    getDate,
    disableAfterToday,

    async onClickFileDownload(name) {
      const file = await this.getReportByName(name)
      const blob = new Blob([file])
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `history.${name.substr(name.indexOf('.'), name.length - 1)}`
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async getOperations(val) {
      this.isOperationsLoading = true
      const query = {
        ...val,
        offset: this.operationsOffset || null,
      }
      await this.getPayouts(query).then(response => {
        if (response.status && response.length === 10) {
          // если получили новые операции, в достаточном количестве только тогда увеличиваем сдвиг на 10
          this.operationsOffset += 10
        } else {
          // если нет значит добрались до конца списка и отключаем подгрузку старых операций
          window.removeEventListener('scroll', this.onScroll)
          window.removeEventListener('touchmove', this.onScroll)
        }
      }).catch(err => this.errors.push(err))
      this.isOperationsLoading = false
      this.bottomListPosition = document.querySelector('#operations-list').getBoundingClientRect().bottom
    },
    async selectAccountAmountByDate(event) {
      this.filterBalanceDate = event
      if (this.filter.account) {
        const response = await this.getAccountBalance({
          date: toSecondsFrom1970(event),
          params: {
            account_id: this.filter.account,
          }
        })
        this.filterBalance = response
      }
    },
    onScroll: debounce(function () {
      // временная закладка пока быстро делаю адаптивы
      const updateTrigger = (window.scrollY + window.innerHeight) >= Math.floor(this.bottomListPosition)
      const mobileTrigger = (window.scrollY + window.innerHeight) >= Math.floor(this.bottomListPosition) - 200
      if (this.isMobile && mobileTrigger) {
        this.getOperations(this.$route.query)
      } else if (updateTrigger) {
        this.getOperations(this.$route.query)
      }
    }, 500),
    onCheckFilter(field, event) {
      const index = this.filter[field].indexOf(event.value)
      if (event.isChecked && index === -1) {
        this.filter[field].push(event.value)
      } else {
        if (index !== -1) {
          this.filter[field].splice(index, 1)
        }
      }
    },
    async onConfirmFileModal() {
      this.isFileModalVisible = false
      let query = { filters: {} }
      for (let prop in this.filter) {
        if (this.filter.hasOwnProperty(prop) && this.filter[prop]) {
          if (prop === 'date' && this.filter.date.every(el => el)) {
            const dates = toSecondsFrom1970(this.filter.date)
            query.filters.since = dates[0]
            query.filters.until = dates[1] + 86400
          } else {
            if (this.filter[prop].length)  {
              query.filters[prop] = this.filter[prop]
            }
          }
        }
      }
      if (Object.keys(query.filters).length === 0) {
        delete query.filters
      }
      query.type = this.selectedFormats
      this.orderReport(query)
    },
    resetFilter() {
      for (let prop in this.filter) {
        if (Array.isArray(this.filter[prop])) this.filter[prop] = []
        else this.filter[prop] = null
      }
      this.errors = []
    }
  },
  created() {
    this.getReports()
    if (!this.isMobile) window.addEventListener('scroll', this.onScroll)
    else window.addEventListener('touchmove', this.onScroll)
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      for (let prop in query) {
        if (query.hasOwnProperty(prop)) {
          if (Array.isArray(this.filter[prop]) && !Array.isArray(query[prop])) this.filter[prop].push(query[prop])
          else this.filter[prop] = query[prop]
        }
      }
    } else this.getOperations()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('touchmove', this.onScroll)
  },
}
</script>

<style lang="scss" scoped>
.yp-history {
  margin: 0 auto;

  &__account-amount-by-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;

    @media (max-width: 1400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__file-formats {
    margin-top: 20px;
  }
  &__files {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__filter-modal-content {
    width: 380px;
  }

  &__filters, &__file {
    width: 60px;
    height: 60px;
    background: #1A2332;
    border: none;
    flex-shrink: 0;
    border-radius: 10px;
  }

  &__fast-filters {
    display: flex;
    gap: 10px;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__search {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__loader {
    align-self: center;
  }

  &__repeat {
    position: fixed;
    top: 50%;
    margin-left: 950px;
    transform: translate(0, -50%)
  }

  &__group {
    margin-top: 30px;
  }
}
</style>
