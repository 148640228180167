<template>
  <div class="yp-textarea" :class="{ 'yp-textarea--outlined': outlined }">
    <div class="yp-textarea__title">
      {{ label }}
    </div>
    <b-textarea
        ref="textarea"
        v-model="value"
        class="yp-textarea__input"
        :class="[{ 'yp-textarea__input--dark': mode === 'dark' }]"
        :formatter="formatter"
        :placeholder="placeholder"
        @input="$emit('update', value)"
    />
    <div class="yp-textarea__counter" :class="{ 'text-danger': errorLengthState }">
      {{ `${inputCount}/${maxLength}`  }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "YpTextarea",
  props: {
    label: String,
    maxLength: Number,
    externalValue: String,
    outlined: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: () => "Enter text"
    }
  },
  data() {
    return {
      value: '',
    }
  },
  created() {
    if(this.externalValue) this.value = this.externalValue
  },
  computed: {
    ...mapGetters(['mode']),

    inputCount() {
      return this.value.length || 0
    },
    errorLengthState() {
      return this.value.length > this.maxLength
    }
  },
  methods: {
    // не даем вводить текст больше разрешенной длинны
    formatter(value) {
      if (this.maxLength && value.length > this.maxLength) {
        return value.substring(0, this.maxLength)
      }
      return value
    },
  }
}
</script>
