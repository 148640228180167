var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "yp-profile" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    Api Settings\n  "),
      ]),
      _vm._v(" "),
      _c(
        "yp-sheet",
        { staticClass: "yp-profile__content yp-content mx-auto" },
        [
          _vm.apiAccountList.length === 0
            ? _c("yp-loader", {
                staticClass: "align-self-center yp-page-content",
                staticStyle: { left: "50%", transform: "translateX(-50%)" },
              })
            : _c(
                "div",
                { staticClass: "profile-account-settings" },
                [
                  _c("yp-account-select", {
                    staticClass: "yp-exchange__selector",
                    attrs: {
                      manual: "",
                      options: _vm.apiAccountList,
                      "external-val": _vm.apiAccountForm,
                    },
                    on: { accountSelected: _vm.onAccountSelected },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "profile-account-settings__theme" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "profile-account-settings__theme-block",
                        },
                        [
                          _c("p", [_vm._v("Currency:")]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "yp-input__input form-control yp-input__input--dark",
                            },
                            [_vm._v(_vm._s(_vm.apiAccountForm.currency_code))]
                          ),
                          _vm._v(" "),
                          _c("p", { staticStyle: { "margin-top": "15px" } }, [
                            _vm._v("External_id:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "yp-input__input form-control yp-input__input--dark",
                            },
                            [_vm._v(_vm._s(_vm.external_id))]
                          ),
                          _vm._v(" "),
                          _c("p", { staticStyle: { "margin-top": "15px" } }, [
                            _vm._v("Salt:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "yp-input__input form-control yp-input__input--dark",
                            },
                            [_vm._v(_vm._s(_vm.salt))]
                          ),
                          _vm._v(" "),
                          _c("yp-button", {
                            class: { "w-100": true, "mt-3": true },
                            attrs: {
                              label: "Create credentials",
                              variant: "primary-gradient",
                            },
                            on: { click: _vm.onSubmit },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://my.yeezypay.io/api/v1/redoc",
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                    [
                      _c("yp-button", {
                        class: { "w-100": true, "mt-3": true },
                        attrs: {
                          label: "YeezyAPI",
                          variant: "primary-gradient",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }