var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "yp-sheet",
    { staticClass: "yp-payout-card" },
    [
      _c("yp-account-select", {
        attrs: {
          "external-val": _vm.accounts[0],
          filter: (account) => !account.has_parent,
        },
        on: { accountSelected: _vm.onAccountSelected },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-payout-card__inputs" },
        [
          _vm._t("sourceInput"),
          _vm._v(" "),
          _c("yp-input", {
            ref: "amountInput",
            attrs: {
              label: `Amount (${_vm.currentAccount.currency_code}):`,
              disabled: !!_vm.file,
              "max-value":
                _vm.currentAccount.amount_with_overdraft ||
                _vm.currentAccount.amount,
              "fee-rules": _vm.feeRules,
              "is-max-mode": "",
              placeholder: "0.00",
              type: "number",
              step: "0.01",
              "init-value": _vm.amount,
              limits: _vm.currentAccount.limits["withdraw"],
            },
            on: {
              update: _vm.amountUpdate,
              inputError: function ($event) {
                return _vm.amountUpdate(null)
              },
            },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "yp-payout-card__info" }, [
        _vm.commission
          ? _c(
              "div",
              {
                staticClass:
                  "yp-payout-card__additional-text yp-additional-text",
                class: { "opacity-03": _vm.file },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("payoutsPage.commission") + " " + _vm.commission
                    ) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-payout-card__actions" },
        [
          _c("yp-button", {
            attrs: {
              size: "xl",
              icon: "Chat",
              label: _vm.$t("payoutsPage.commentBtn"),
            },
            on: { click: _vm.onCommentBtnClick },
          }),
          _vm._v(" "),
          _vm.haveFileInput
            ? _c("yp-button", {
                attrs: {
                  size: "xl",
                  icon: "Plus",
                  label: _vm.$t("payoutsPage.addPaymentBtn"),
                },
                on: { click: _vm.onAddPaymentBtnClick },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("cardActions"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActionsFieldVisible,
              expression: "isActionsFieldVisible",
            },
          ],
          staticClass: "yp-payout-card__actions-field",
        },
        [
          _c("icon", {
            staticClass: "yp-payout-card__actions-field-close",
            attrs: { name: "Close", width: "10px", height: "10px" },
            nativeOn: {
              click: function ($event) {
                return _vm.onCloseActionsField.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "yp-payout-card__actions-field-wrap theme-border" },
            [
              _vm.actionFieldComponent === "comment"
                ? _c("yp-textarea", {
                    attrs: { label: "Comment", "max-length": 250 },
                    on: { update: _vm.updateComment },
                  })
                : _c("yp-upload", {
                    on: {
                      fileLoaded: function ($event) {
                        return _vm.setFile($event)
                      },
                      showModal: function ($event) {
                        _vm.showFileErrorModal = true
                      },
                      errorFileLoad: function ($event) {
                        return _vm.setFile(null)
                      },
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "yp-payout-card__actions-field-additional yp-info-block",
            },
            [
              _c("icon", { attrs: { name: "Info", color: "primary" } }),
              _vm._v(" "),
              _vm.actionFieldComponent === "comment"
                ? _c("div", [
                    _vm._v(
                      "\n          Your comments will only be visible to you.\n          Comments are an Optional field, not required for payment.\n        "
                    ),
                  ])
                : _c("div", [
                    _vm._v(
                      "\n          Each payment must be on a separate line. File must not contain more than 100 lines. Copy payment details or upload a file with the following layout:"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-info font-weight-bold" }, [
                      _vm._v("Card number; Amount; Comments (optional)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-info font-weight-bold" }, [
                      _vm._v("Card number; Amount; "),
                    ]),
                    _vm._v("\n\t  Example:\n\t  "),
                    _c("div", { staticClass: "text-info font-weight-bold" }, [
                      _vm._v("4111000011110000;100;"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-info font-weight-bold" }, [
                      _vm._v("4111000011110000;100;my-comment;"),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("modal"),
      _vm._v(" "),
      _vm.showFileErrorModal
        ? _c(
            "yp-modal",
            {
              attrs: {
                "without-actions": "",
                title: _vm.$t("payoutsPage.modals.fileLayout.title"),
              },
              on: {
                closeModal: function ($event) {
                  _vm.showFileErrorModal = false
                },
              },
            },
            [
              _c("span", {
                staticClass: "text-info",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("payoutsPage.modals.fileLayout.text")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isFormSending ? _c("verify-modal") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }