<template>
  <yp-modal class="yp-verify-modal" :title="$t('payoutsPage.modals.verify.title')" without-actions>
    <yp-loader class="yp-verify-modal__loader" />
  </yp-modal>
</template>

<script>
import YpModal from "@/components/ui/YpModal";
import YpLoader from "@/components/ui/YpLoader";
export default {
name: "VerifyModal",
  components: {YpLoader, YpModal}
}
</script>

<style lang="scss" scoped>
.yp-verify-modal {
  &__loader {
    display: flex;
    justify-content: center;
  }
}
</style>
