var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "yp-wallet-crypto" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    " + _vm._s(_setup.$t("walletCryptoPage.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(_setup.YpSheet, [
        _c("h3", { staticClass: "mt-3 text-center" }, [
          _vm._v(
            "\n      " +
              _vm._s(_setup.$t("walletCryptoPage.create.title")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "yp-wallet-crypto__block" },
          [
            _setup.loadingWalletTypes
              ? _c(_setup.YpLoader, { staticClass: "align-self-center" })
              : [
                  _c(_setup.YpCustomSelect, {
                    attrs: {
                      options: _setup.walletOptions,
                      placeholder: _setup.$t(
                        "walletCryptoPage.create.selectWalletType"
                      ),
                    },
                    model: {
                      value: _setup.selectedWalletType,
                      callback: function ($$v) {
                        _setup.selectedWalletType = $$v
                      },
                      expression: "selectedWalletType",
                    },
                  }),
                  _vm._v(" "),
                  _c(_setup.YpCustomInput, {
                    attrs: {
                      placeholder:
                        _setup.$t(
                          "walletCryptoPage.create.nameInputPlaceholder"
                        ) + _setup.walletTypeHint,
                    },
                    model: {
                      value: _setup.walletName,
                      callback: function ($$v) {
                        _setup.walletName = $$v
                      },
                      expression: "walletName",
                    },
                  }),
                  _vm._v(" "),
                  _c(_setup.YpButton, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _setup.selectedWalletType != null,
                        expression: "selectedWalletType != null",
                      },
                    ],
                    staticClass: "yp-wallet-crypto__create-wallet",
                    attrs: {
                      icon: "Plus",
                      label: _setup.$t("walletCryptoPage.create.addBtn"),
                      variant: "primary-gradient",
                      disabled: !_setup.canAddWallet,
                    },
                    on: { click: _setup.onAddNewWalletClicked },
                  }),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }