var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-google-account" },
    [
      _c(
        "div",
        { staticClass: "new-google-account__form" },
        [
          _c("yp-input", {
            attrs: {
              type: "email",
              label: "Email:",
              "external-val": _vm.email,
              "auto-focus": "",
              placeholder: "example@example.com",
            },
            on: {
              update: function ($event) {
                _vm.email = $event
              },
            },
          }),
          _vm._v(" "),
          _c("b-form-select", {
            staticClass: "new-google-account__selector",
            attrs: { options: _vm.availableTimezones },
            model: {
              value: _vm.timezone,
              callback: function ($$v) {
                _vm.timezone = $$v
              },
              expression: "timezone",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "new-google-account__form-line" },
            [
              _c("yp-input", {
                attrs: {
                  type: "number",
                  label: `Amount (${_vm.currentAccount.currency_code}):`,
                  placeholder: `min value: ${
                    _vm.currentAccount.google_amount_min ||
                    _vm.$store.getters["google/minGoogleRate"]
                  }`,
                  "external-val": _vm.amount,
                  "min-value":
                    _vm.currentAccount.google_amount_min ||
                    _vm.$store.getters["google/minGoogleRate"],
                  "max-value": _vm.currentAccount.amount,
                },
                on: {
                  update: function ($event) {
                    _vm.amount = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("yp-button", {
                staticClass: "new-google-account__submit",
                attrs: {
                  disabled: !_vm.amount || !_vm.email,
                  icon: "Checkbox",
                  label: _vm.$t("googleAdPage.submit"),
                  variant: "primary-gradient",
                },
                on: { click: _vm.onSubmit },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errors
        ? _c(
            "div",
            { staticClass: "mt-1" },
            _vm._l(_vm.errors, function (err, key) {
              return _c("yp-msg", { key: key, attrs: { msg: err } })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.commission,
              expression: "commission",
            },
          ],
          staticClass: "new-google-account__commission yp-additional-text",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                `${_vm.$t("commission")} + (${
                  _vm.currentAccount.fee.withdraw.percent
                }%): ${_vm.commission} ${_vm.currentAccount.currency_code}`
              ) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("googleAdPage.createAccountModal.title"),
                "confirm-btn": _vm.$t(
                  "googleAdPage.createAccountModal.confirmBtn"
                ),
                "cancel-btn": _vm.$t("cancelBtn"),
                errors: _vm.errors,
              },
              on: {
                confirm: _vm.onConfirm,
                closeModal: function ($event) {
                  _vm.isModalVisible = false
                },
              },
            },
            [
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("email") + ": ")),
                ]),
                _vm._v("\n            " + _vm._s(_vm.email) + "\n        "),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("amount") + ": ")),
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.amount + _vm.currentAccount.currency_code) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("commission") + ": ")),
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.commission + _vm.currentAccount.currency_code) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("total") + ": ")),
                ]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.total + _vm.currentAccount.currency_code) +
                    "\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }