<template>
  <footer class="footer">
    <div class="footer__content container">
      <div class="footer__block text-info">
        {{ $t('footer.text') }}
      </div>
      <a class="text-info" target="_blank" href="https://yeezypay.io/agree.html">
        {{ $t('footer.agreement') }}
      </a>
      <a class="text-info" target="_blank" href="https://yeezypay.io/privacy.html">
        {{ $t('footer.policy') }}
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style lang="scss" scoped>
.footer {
  backdrop-filter: blur(22.5px);
  margin-top: auto;
  width: 100%;
  padding: 24.5px 0;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1400px) {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }
}
</style>
