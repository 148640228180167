import Vue from "vue"
import Vuex from "vuex"

import wsEvents from "./wsEvents"
import createPayouts from "@/store/payouts"
import createCards from "@/store/cards"
import createProfile from "@/store/profile"
import createSnackbar from "@/store/snackbar"
import createGoogleAd from "@/store/googleAd"
import createDeposits from "@/store/deposits"
import createCatalog from "@/store/catalogs"

import websocket from "@/plugins/websocket"
import {getCookie, delCookie} from "utils/helpers"
import i18n from "@/plugins/i18n";

Vue.use(Vuex)

export default api => {
    return new Vuex.Store({
        modules: {
            wsEvents,
            payouts: createPayouts(api),
            cards: createCards(api),
            profile: createProfile(api),
            snackbar: createSnackbar(api),
            google: createGoogleAd(api),
            deposits: createDeposits(api),
            catalogs: createCatalog(api),
        },
        plugins: [websocket],
        state: {
            screenResolution: screen.width,
            mode: localStorage.getItem('theme'),
            context: null,
            hasSession: !!getCookie('sessionid'),
            accounts: [],
            currentAccount: null,
            isDataLoaded: false,
            isContextLoaded: false,
            errors: [],
            rates: [],
            isFormSending: false,
            accountList: [],
            filteredList: [],
            isMobileNavVisible: false,
        },
        getters: {
            accountList: state => (status = []) => {
                const arr = state.filteredAdList.length > 0 ?
                    state.filteredList :
                    state.accountList
                const list = arr
                const filteredArr = []
                list.forEach((el, i) => {
                    if (i === 0) {
                        filteredArr.push({
                            name: el.name,
                            list: [el]
                        })
                    }
                    if (i > 0) {
                        const index = filteredArr.findIndex(item => item.name === el.name)
                        if (index !== -1) {
                            filteredArr[index].list.push(el)
                        } else {
                            filteredArr.push({
                                name: el.name,
                                list: [el]
                            })
                        }
                    }
                })
                return filteredArr
            },
            isMobile: state => state.screenResolution < 1400,
            hasNecessaryData: state => state.context && Boolean(state.accounts.length) && Boolean(state.rates.length),
            mode: state => state.mode,
            isFormSending: state => state.isFormSending,
            botName: state => state.context.bot_name || null,
            account: state => state.context || null,
            settings: state => state.context ? state.context.settings : null,
            rates: state => state.rates,
            isAuth: state => state.hasSession,
            isTfaEnable: state => state.context['2fa'],
            accounts: state => state.accounts,
            accountsByMethods: state => (type = 'withdraw', method = '') => {
                // console.log('Hii: ', type, method)
                if (Array.isArray(method)) {
                    let accounts = []
                    for(let key in method) {
                        if(method.hasOwnProperty(key)) {
                            state.accounts.forEach(item => {
                                const isItemIncludes = item.methods[type].includes(method[key])
                                if(isItemIncludes && !accounts.includes(item)) {
                                    accounts.push(item)
                                }
                            })
                        }
                    }
                    return accounts
                } else {
                    return state.accounts.filter(account => account.methods[type].includes(method))
                }
            },
            permissions: state => {
                if (state.context) {
                    return state.context.permissions;
                } else {
                    return null;
                }
            },
            currentAccount: state => {
                const currentAccount = state.currentAccount || state.accounts[0];
                return currentAccount;
            },
            isDataLoaded: state => state.isDataLoaded,
            isContextLoaded: state => state.isContextLoaded,
            errors: state => state.errors,
            isMobileNavVisible: state => state.isMobileNavVisible,
        },
        mutations: {
            setLoading(state,loading){
                state.loading = loading;
            },
            SET_FORM_SENDING_STATE(state, payload) {
                state.isFormSending = payload
            },
            setMode(state, payload) {
                state.mode = payload
            },
            setDataLoaded(state, payload) {
                state.isDataLoaded = payload
            },
            setContextLoaded(state, payload) {
                state.isContextLoaded = payload
            },
            setCurrentAccount(state, payload) {
                state.currentAccount = payload
                // console.log('setCurrentAccount: ', state.currentAccount)
            },
            setSession(state, payload) {
                state.hasSession = payload
            },
            setSettings(state, payload) {
                state.context = payload
            },
            SET_RESOLUTION(state, payload) {
                state.screenResolution = payload
            },
            SET_RATE(state, rate) {
                const index = state.rates.findIndex(el => el.currency_from === rate.currency_from && el.currency_to === rate.currency_to)
                const arr = [...state.rates]
                if (index !== -1) arr[index] = rate
                else arr.push(rate)
                state.rates = arr
            },
            SET_ACCOUNT(state, payload) {
                const accountIndex = state.accounts.findIndex(el => el.pk === payload.pk)
                let newAccountsData = [...state.accounts]
                for (let key in payload) {
                    if (payload.hasOwnProperty(key) && newAccountsData[accountIndex][key]) {
                        newAccountsData[accountIndex][key] = payload[key]
                    }
                }
                state.accounts = newAccountsData
            },
            ADD_ACCOUNTS(state, payload) {
                const accounts = [...state.accounts]
                state.accounts = Array.prototype.concat(accounts, payload)
            },
            SET_RATES(state, payload) {
                state.rates = payload
            },
            setAccounts(state, payload) {
                state.accounts = payload
            },
            setError(state, payload) {
                state.errors.push(payload)
            },
            setTfaState(state, payload) {
                state.context['2fa'] = payload
            },
            setMobileNav(state, payload) {
                state.isMobileNavVisible = payload
            }
        },
        actions: {
            async getIssuersByType(_, type) {
                return api.get('client/issuer/', { params: {'issuer_type': type} })
                .then(response => {
                    if (response.data.state) {
                        return response.data?.issuers
                    } else {
                        return []
                    }
                })
            },
            async createNewCryptoWallet(_, requestBody) {
                const response = await api.post('client/wallets/create', requestBody);
                return response?.data;
            },
            async getAvailableCryptoWalletTypes() {
                const response = await api.get('client/wallets/list/wallet-types');
                return response?.data?.wallet_types || [];
            },
            setUsernameAndPassword({commit}, data) {
                return api
                .patch(`clients/`, {...data, 'session_id': getCookie('sessionid')})
                .then(response => {
                    return response.data
                })
            },
            generateCredentials(_, account_id) {
                return api
                .post(`client/account/${account_id}/credentials`)
                .then(response => {
                    return response.data
                })
            },
            deleteCookie(_, name) {
                delCookie(name)
            },
            getAccountBalance(_, data) {
                return api.get(`client/balance/${data.date}`, { params: data.params }).then(response => {
                    return response.data
                })
            },
            getNecessaryData({commit, dispatch}) {
                commit('setDataLoaded', false)
                return Promise.all([
                    dispatch('catalogs/getCurrencyCatalog'),
                    dispatch('getRates'),
                ]).then(responses => {
                    if (responses.every((el) => el)) commit('setDataLoaded', true)
                }).catch(err => {
                    console.log(err)
                })
            },
            getContext({commit, state}) {
                return api
                    .get('clients/context/')
                    .then(response => {
                        commit('setSettings', response.data)
                        return response.status === 200
                    })
                    .catch(error => {
                        commit('setError', 'Failed get context data')
                    })
            },
            getTwoFactorQr() {
                return api.get('clients/tfakey/').then(response => {
                    return response.data.qr_image
                })
            },
            setTwoFactorAuth(_, data) {
                return api
                    .post('clients/tfakey/', data)
                    .then(response => response.data)
            },
            sendTfaKey({commit}, data) {
                return api
                    .post('clients/authlink/', data)
                    .then(response => {
                        if (response.data.state) {
                            commit('setSession', response.data.state)
                        } else if (!response.data.state) {
                            commit('snackbar/SET_SNACK', {
                                title: i18n.t('loginPage.errors.login.title'),
                                text: i18n.t('loginPage.errors.login.text'),
                                type: 'error',
                                duration: 5000,
                            }, {root: true})
                        }
                        return response.data
                    })
            },
            getAccounts({commit}, params) {
                return api
                    .get('client/account/', {params, timeout: 35000})  // 'client/finance/accounts/'
                    .then(response => {
                        if (params && response.data.items) {
                            if (params.offset) {
                                if (params.kind === "G") {
                                    commit("google/ADD_GOOGLE_AD_ACCOUNTS", response.data.items)
                                } else {
                                commit("ADD_ACCOUNTS", response.data.items)
                                }
                            } else if (params.kind != "G") {
                                commit('setAccounts', response.data.items)
                            } else if (params.pk) {
                                commit("google/SET_GOOGLE_AD_ACCOUNT", ...response.data.items)
                            } else {
                                commit("google/SET_GOOGLE_AD_LIST", response.data.items)
                            }
                            return response.data.items
                        }
                        if (!params) {
                            commit('setAccounts', response.data.items)
                        }
                        return response.data.items
                    })
                    .catch(error => {
                        commit('setError', 'Failed get accounts data')
                    })
            },
            async getAccountsList({dispatch, commit}, params) {
                let items = await dispatch('getAccounts', params)
                let offset = 0
                let offset_step = 10
                if (params.offset_step) {
                    offset_step = params.offset_step
                }
                while (items.length === offset_step) {
                    offset += offset_step
                    items = await dispatch('getAccounts', {
                        ...params,
                        offset: offset,
                    })
                }
                offset = 0
            },
            async getAccountsWithCardsList({ dispatch }, params) {
                let items = await dispatch('getAccounts', {
                    ...params,
                    kind: "V,D",
                })
                let offset = 0
                while (items.length === 10) {
                    offset += 10
                    items = await dispatch('getAccounts', {
                        ...params,
                        kind: "V,D",
                        offset: offset,
                    })
                }
                offset = 0
            },
            loginWithLink({commit}, params) {
                return api.post('clients/authlink/', params)
                    .then(response => {
                        if (response.data.state) {
                            commit('setSession', response.data.state)
                        }
                        return response.data
                    })
            },
            login({commit}, params) {
                return api
                    .post('clients/login/', params)
                    .then(response => {
                        if (response.data.state) commit('setSession', response.data.state)
                        else if (!response.data.state) {
                            commit('snackbar/SET_SNACK', {
                                title: i18n.t('loginPage.errors.login.title'),
                                text: i18n.t('loginPage.errors.login.text'),
                                type: 'error',
                                duration: 5000,
                            }, {root: true})
                            return { errors: response.data.errors }
                        }
                    })
            },
            logout() {
                return api.post('clients/logout/')
            },
            getAccountInfo() {
                // пока жестко запишу, потом когда будет необходимость перепишу под переменную с query
                return api.get('/client/account/?currencies=USDT').then(response => response.data) // '/client/finance/accounts/?currencies=USDT'
            },
            setMode({commit}, payload) {
                localStorage.setItem('theme', payload)
                commit('setMode', payload)
            },
            getRates({commit}) {
                return api
                    .get('/client/finance/rates/')
                    .then(response => {
                        commit('SET_RATES', response.data.items)
                        return response.status === 200
                    })
            },
            setClientGroup(_, payload) {
                return api.post('client/clients/groups/', {
                    code: payload,
                })
            },
            doExchange(_, payload) {
                return api
                    .post('/client/finance/exchange/', payload)
                    .then(response => response.data)
            }
        },
        strict: process.env.NODE_ENV !== 'production'
    })

};
