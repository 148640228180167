var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideOptions,
          expression: "hideOptions",
        },
      ],
      staticClass: "yp-select",
    },
    [
      _c(
        "div",
        {
          staticClass: "yp-select__selector",
          class: { "yp-select__selector--open": _vm.isOptionsVisible },
          on: {
            click: function ($event) {
              _vm.isOptionsVisible = !_vm.isOptionsVisible
            },
          },
        },
        [
          _vm.selected && typeof _vm.value !== "string"
            ? [
                _vm.selected.kind == "V"
                  ? _c("icon", {
                      staticClass: "yp-select__icon",
                      attrs: { name: "Card", width: "20px", height: "20px" },
                    })
                  : _c("icon", {
                      staticClass: "yp-select__icon",
                      attrs: {
                        name: _vm.iconName(_vm.selected.currency_code),
                        width: "20",
                        height: "20",
                      },
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "yp-select__amount" }, [
                  _vm._v(
                    _vm._s(
                      `${_vm.selected.amount} ${_vm.selected.currency_code}`
                    )
                  ),
                  _vm.selected.name
                    ? _c("span", [
                        _vm._v(_vm._s("\xa0") + _vm._s(_vm.selected.name)),
                      ])
                    : _vm._e(),
                ]),
              ]
            : _c("div", [_vm._v(_vm._s(this.value))]),
          _vm._v(" "),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.list.length > 1,
                expression: "list.length > 1",
              },
            ],
            staticClass: "yp-select__chevron",
            attrs: { name: "ChevronDown" },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOptionsVisible && _vm.list.length > 1,
              expression: "isOptionsVisible && list.length > 1",
            },
          ],
          staticClass: "yp-select__options",
        },
        [
          _vm._l(_vm.list, function (option) {
            return option !== _vm.selected &&
              typeof option !== "string" &&
              (!_vm.filter || _vm.filter(option))
              ? _c(
                  "div",
                  {
                    key: option.pk,
                    staticClass: "yp-select__option",
                    on: {
                      click: function ($event) {
                        return _vm.onOptionClick(option)
                      },
                    },
                  },
                  [
                    option.kind == "V"
                      ? _c("icon", {
                          staticClass: "yp-select__icon",
                          attrs: {
                            name: "Card",
                            width: "24px",
                            height: "24px",
                          },
                        })
                      : _c("icon", {
                          staticClass: "yp-select__icon",
                          attrs: { name: _vm.iconName(option.currency_code) },
                        }),
                    _vm._v(" "),
                    _c("div", { staticClass: "yp-select__amount" }, [
                      _vm._v(
                        _vm._s(`${option.amount} ${option.currency_code}`) + " "
                      ),
                      option.name
                        ? _c("span", [_vm._v(_vm._s(option.name))])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.manual && !_vm.options && typeof _vm.value !== "string"
            ? _c(
                "div",
                {
                  staticClass: "yp-select__option",
                  on: { click: _vm.onClickAllAccounts },
                },
                [_vm._v("\n      All accounts\n    ")]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }