var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-img", {
    staticClass: "yp-qr",
    attrs: { src: _vm.src, alt: "QR" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }