var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "operations-list" },
    _vm._l(_vm.operations, function (operation) {
      return _c("operation-item", {
        key: operation.uid,
        staticClass: "operations-list__item",
        attrs: { value: operation },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }