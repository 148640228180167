import { mapGetters, mapMutations, mapActions } from "vuex";
import da from "vue2-datepicker/locale/es/da";

export default {
    data() {
        return {
            amount: null,
            file: null,
            receiver: null,
            showConfirmModal: false,
            confirmData: [],
            confirmTotal: null,
            isFormSending: false,
            comment: null,
            express: false,
        }
    },
    computed: {
        ...mapGetters(['currentAccount', 'mode', 'settings']),
    },
    methods: {
        ...mapMutations(['setCurrentAccount']),
        ...mapActions('payouts', ['sendPayout']),

        onSubmit(approved = false, method, bank_code) {
            this.showConfirmModal = false
            this.isFormSending = true
            const data = {
                amount: this.amount,
                approved,
                account_id: this.currentAccount.pk,
            }
            if (method) {
                data.method = method
            }
            if (method === 'Phone') {
                data.receiver = this.receiver.split('+')[1]
                data.bank_code = bank_code
            }
            data.receiver = this.receiver
            if (this.file) {
                data.file_text = this.file
                delete data.amount
                delete data.receiver
            }
            if (this.express) data.express = "on"
            if (this.comment) data.comment = this.comment
            this.sendPayout(data).then(response => {
                this.isFormSending = false
                if(!approved) {
                    this.confirmData = response.cards
                    this.confirmTotal = response.valid
                    this.showConfirmModal = true
                } else if(response.state) {
                    this.$router.push({ name: 'history' })
                } else if (!response.state && data.method === 'Crypto') {
                    this.errors = response.errors || "Failed"
                } else if (!response.state && response.message) {
                    this.errors = response.message
                } else {
                    this.confirmData = response.cards
                    this.showConfirmModal = true
                }
            })
        }
    }
}
