var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-light-wrap" },
    _vm._l(40, function (i) {
      return _c("div", { key: `light-item-${i}`, staticClass: "yp-light" })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }