<template>
  <div class="yp-card">
    <div class='yp-card__top-block'>
<!--      <div class="yp-card__brand">-->
<!--        <logo mini class="yp-card__logo" />-->
<!--        <span>Virtual Card</span>-->
<!--      </div>-->
    </div>
    <div class="yp-card__info-row" :class="{ 'yp-card--blocked' : cardData.status !== 'A'}">
      <div class='yp-card__info'>
        Card number: {{ number }}
      </div>
      <div class="yp-card__info">
        CVV: {{ cvv }}
      </div>
      <div class="yp-card__info">
        Static: {{ this.cardData.credentials.secret_otp_password }}
      </div>
      <div class="yp-card__info">
        Expires: {{ date }}
      </div>
      <yp-button
          ref="card-copy-btn"
          class="yp-card__copy-btn"
          :disabled="cardData.status !== 'A'"
          ghost
          is-icon-btn
          icon="CopyClip"
          @click="copyToClipboard({ date, number, cvv: cvv })"
      />
    </div>
    <div class='yp-card__bottom-block'>
      <div class='yp-card__balance' :class="{ 'yp-card--blocked' : cardData.status !== 'A'}">
        <div>Balance: </div>
        <div class='yp-card__balance-amount'>
          {{ currency.symbol }} {{balance}}
        </div>
      </div>
      <div class="yp-card__actions">
        <yp-input
            type="string"
            class="yp-card__amount-input"
            label="Name:"
            :external-val="cardData.name"
            placeholder="Name"
            @update="cardData.name = $event"
            @inputError="cardData.name = null"
            :class="{ 'yp-card--blocked' : cardData.status !== 'A'}"
        />
        <yp-input
            type="number"
            class="yp-card__amount-input"
            :label="`Amount (${currentAccount.currency_code}):`"
            :external-val="topUpAmount"
            :max-value="currentAccount.amount_with_overdraft || currentAccount.amount"
            :min-value="currentAccount.limits.card_topup['min_transaction']"
            :limits="currentAccount.limits.card_topup"
            :fee-rules="currentAccount.fee.card_topup"
            is-max-mode
            :class="{ 'yp-card--blocked' : cardData.status !== 'A'}" 
            placeholder="0.00"
            @update="topUpAmount = $event"
            @inputError="topUpAmount = null"
        />
        <div class="yp-card-flex-name">
          <yp-button
              icon="Plus"
              :ghost="$store.getters.isMobile"
              :disabled="cardData.status !== 'A' || !topUpAmount"
              :label="$store.getters.isMobile ? '' : 'Top up'"
              @click="isTopUpModalVisible = true"
              :class="{ 'yp-card--blocked' : cardData.status !== 'A'}"
          />
          <yp-button
              label="Block Card"
              :disabled="cardData.status !== 'A'"
              @click="isBlockModalVisible = true"
              :class="{ 'yp-card--blocked' : cardData.status !== 'A'}"
          />
          <yp-button
              label="Restore Card"
              :disabled="cardData.status !== 'C'"
              @click="isRestoreModalVisible = true"
              :class="{ 'yp-card--restore' : cardData.status !== 'A'}"
          />
        </div>
      </div>
<!--      <icon-->
<!--          v-if="cardType"-->
<!--          class="yp-card__type"-->
<!--          width="70px"-->
<!--          height="40px"-->
<!--          :name="`card-type-${cardType}`"-->
<!--      />-->
    </div>
    <yp-modal
        v-if="isBlockModalVisible"
        :title="$t('virtualCard.blockModal.title')"
        :confirm-btn="$t('virtualCard.blockModal.confirm')"
        :cancel-btn="$t('virtualCard.blockModal.cancel')"
        :errors="topUpErrors"
        @confirm="onBlockConfirm"
        @closeModal="isBlockModalVisible = false"
    >
      <yp-card-input
          :init-value="number"
          readonly
      />
      <div class="mt-2">
        <span class="text-info">Current balance:</span>
        {{ balance + currentAccount.currency_code }}
      </div>
    </yp-modal>
    <yp-modal
        v-if="isRestoreModalVisible"
        :title="$t('virtualCard.restoreModal.title')"
        :confirm-btn="$t('virtualCard.restoreModal.confirm')"
        :cancel-btn="$t('virtualCard.restoreModal.cancel')"
        :errors="topUpErrors"
        @confirm="onRestoreConfirm"
        @closeModal="isRestoreModalVisible = false"
    >
      <yp-card-input
          :init-value="number"
          readonly
      />
    </yp-modal>
    <yp-modal
        v-if="isTopUpModalVisible"
        :title="$t('virtualCard.topUpModal.title')"
        :confirm-btn="$t('virtualCard.topUpModal.confirm')"
        :cancel-btn="$t('virtualCard.topUpModal.cancel')"
        :errors="topUpErrors"
        @confirm="onTopUpConfirm"
        @closeModal="isTopUpModalVisible = false"
    >
      <yp-card-input
          :init-value="number"
          readonly
      />
      <yp-input
          type="number"
          class="mt-2"
          :label="`Amount (${currentAccount.currency_code}):`"
          :external-val="topUpAmount"
          :max-value="currentAccount.limits.card_topup['max_transaction']"
          :min-value="currentAccount.limits.card_topup['min_transaction']"
          :limits="currentAccount.limits.card_topup"
          placeholder="0.00"
          @update="topUpAmount = $event"
          @inputError="topUpAmount = null"
      />
    </yp-modal>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon"
import Logo from "@/components/common/Logo"

import {mapGetters, mapActions} from "vuex"
import YpButton from "@/components/ui/Button"
import YpModal from "@/components/ui/YpModal.vue"
import YpCardInput from "@/components/ui/YpCardInput.vue"
import YpInput from "@/components/ui/YpInput.vue"

export default {
name: "YpVirtualCard",
  components: {YpInput, YpModal, YpButton, Logo, Icon, YpCardInput},
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isTopUpModalVisible: false,
      topUpErrors: {},
      topUpAmount: null,
      isBlockModalVisible: false,
      isRestoreModalVisible: false,
    }
  },
  watch: {
    topUpAmount: {
      handler(val) {
        if (this.isTopUpModalVisible) {
          if (!val) {
            this.topUpErrors.emptyAmount =  'Input the correct amount for top up'
          } else if (val && val > this.currentAccount.amount) {
            this.topUpErrors.amount = 'You don\'t have enough funds to complete the operation'
          } else {
            this.topUpErrors = {}
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("catalogs", ["currencyById"]),
    ...mapGetters(["currentAccount"]),

    number() {
      return this.cardData.credentials.card_number.replace(/\B(?=(\d{4})+(?!\d))/g, " ")
    },
    cvv() {
      const cvv_key = Object.keys(this.cardData.credentials).find(key => key.match(/cvv/))
      return this.cardData.credentials[cvv_key]
    },
    cardType() {
      const firstSymbol = this.cardData.credentials.card_number[0]
      switch (firstSymbol) {
        case '2':
          return 'world'
        case '3':
          return 'american express'
        case '4':
          return 'visa'
        case '5':
          return 'mc'
      }
    },
    date() {
      const data = this.cardData.credentials
      return `${data.expiry_month}/${data.expiry_year}`
    },
    id() {
      return this.cardData.id
    },
    currency() {
      return this.currencyById(this.cardData.currency_id)
    },
    balance() {
      return this.cardData.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  },
  methods: {
    ...mapActions("cards", ["topUpCard", "blockCard", "restoreCard"]),

    copyToClipboard(data) {
      navigator.clipboard.writeText(`${data.number} ${data.date} ${data.cvv}`).then(() => {
        if(this.$refs[`card-copy-btn`].$el.classList.contains('slide-out')) {
          this.$refs[`card-copy-btn`].$el.classList.remove('slide-out')
        }
        this.$refs[`card-copy-btn`].$el.classList.add('slide-in')
        setTimeout(() => {
          this.$refs[`card-copy-btn`].$el.classList.remove('slide-in')
          this.$refs[`card-copy-btn`].$el.classList.add('slide-out')
        }, 2000)
      })
    },
    async onTopUpConfirm() {
      this.isTopUpModalVisible = false
      const data = {
        id: this.cardData.id,
        payload: {
          amount: this.topUpAmount,
          card_id: this.cardData.id,
          account_id: this.currentAccount.pk,
          name: this.cardData.name
        }
      }
      const response = await this.topUpCard(data)
      if (response) {
        this.topUpAmount = null
        this.$emit('update')
      }
    },
    async onBlockConfirm() {
      this.isBlockModalVisible = false
      await this.blockCard({ id: this.cardData.id, payload: { card_id: this.cardData.id } })
      this.$emit('update')
    },
    async onRestoreConfirm() {
      this.isRestoreModalVisible = false
      await this.restoreCard({ id: this.cardData.id, payload: { card_id: this.cardData.id } })
      this.$emit('update')
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-card-flex-name {
  display: flex; 
  align-items: center; 
  justify-content: 
  space-between; 
  gap: 10px;
}

.yp-card {
  width: 100%;
  border: 1px solid $border-color;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  color: white;
  font-size: 16px;

  &--blocked {
    opacity: 0.3;
  }

  &--restore {
    opacity: 1 !important;
  }

  &__top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__amount-input {
    max-width: 240px;

    @media (max-width: 1400px) {
      max-width: 250px;
    }
  }
  &__copy-btn {
    margin-left: auto;

    @media (max-width: 1400px) {
      position: absolute;
      right: 0;
      top: 0;
    }
    &:before {
      content: 'Copied to clipboard';
      position: absolute;
      font-size: 10px;
      color: $info;
      font-weight: bold;
      padding: 5px;
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      visibility: hidden;
    }
    &.slide-in {
      &:before {
        visibility: visible;
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
      }
    }
    &.slide-out {
      &:before {
        visibility: visible;
        animation: slide-out 0.5s forwards;
        -webkit-animation: slide-out 0.5s forwards;
      }
    }
  }
  @keyframes slide-in {
    100% { transform: translateX(-60%) }
  }
  @keyframes slide-out {
    0% { transform: translateX(-60%); }
    100% { transform: translateX(-100%); opacity: 0 }
  }
  &__balance {
    display: flex;
    gap: 10px;
    font-size: 22px;
    align-items: flex-end;

    @media (max-width: 1400px) {
      font-size: 16px;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 1400px) {
      width: 100%;
      justify-content: left;
    }

    @media (max-width: 790px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__info {
    font-size: 18px;
    color: $info;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }
  &__info-row {
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 1400px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  &__bottom-block{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1400px) {
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  &__type {
    stroke: transparent;
  }
  &__brand {
    margin-left: auto;
    font-size: 20px;
    display: flex;
    font-weight: 600;
    gap: 10px;
    align-items: center;
  }
  &__number{
    font-size: 20px;
  }
}
.yp-tooltip {
  position: absolute;
  font-size: 10px;
  color: $success;
  font-weight: bold;
  padding: 5px;
  right: -50px;
  top: -15px;
}
.card-balance{
  font-weight:700;
  font-size: 30px;
  margin-top:10px;
}
.card-icon{
  float:right;
  margin: 75px 20px 0 0;
}
.circle-left{
  margin-right: -15px;
  opacity:0.7;
}
</style>
