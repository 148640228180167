var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-input" },
    [
      _vm.icon
        ? _c("icon", {
            staticClass: "yp-input__icon",
            class: { "yp-input__icon--append": _vm.appendIcon },
            attrs: { name: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-input--wrap" },
        [
          _c("b-form-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            staticClass: "yp-input__input",
            class: [
              { "yp-input__input--rounded": _vm.rounded },
              { "yp-input__input--dark": _vm.mode === "dark" },
              { "yp-input__input--error": _vm.errors.length > 0 },
            ],
            style: _vm.padding,
            attrs: {
              autofocus: _vm.autoFocus,
              disabled: _vm.disabled,
              type: _vm.type,
              step: _vm.step,
              readonly: _vm.readonly,
              placeholder: _vm.dynamicPlaceholder,
            },
            on: { input: _vm.debouceHandler },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm._v(" "),
          _vm.isMaxMode && _vm.maxValue !== 0
            ? _c("yp-button", {
                staticClass: "yp-input__btn",
                style: _vm.padding,
                attrs: { ghost: "", label: "MAX" },
                on: { click: _vm.onClickMaxBtn },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errors.length
        ? _c(
            "div",
            { staticClass: "yp-input__info" },
            [
              _c("icon", { attrs: { id: "info", name: "Info" } }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                { staticClass: "yp-input__tooltip", attrs: { target: "info" } },
                [
                  _c("div", { staticClass: "font-weight-bold text-danger" }, [
                    _vm._v("\n        Amount entered incorrectly.\n      "),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function (error, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "font-weight-bold text-info" },
                      [_vm._v("\n        " + _vm._s(error) + "\n      ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("div", { staticClass: "yp-input__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }