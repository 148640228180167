import { getCurrentInstance } from "vue";

export const useI18n = () => {
	const vm = getCurrentInstance();
	if (!vm) {
		throw new Error("useI18n can only be used inside a Composition API setup context");
	}

	const $i18n = vm.proxy.$i18n;

  // Since the functions each use the **this** property, we need to bind them to the $i18n object
	return {
		$t: $i18n.t.bind($i18n),
		$tc: $i18n.tc.bind($i18n),
		$te: $i18n.tc.bind($i18n),
		$d: $i18n.d.bind($i18n),
		$n: $i18n.n.bind($i18n),
	};
};