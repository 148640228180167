var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-exchange" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("exchangePage.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("yp-sheet", { staticClass: "yp-exchange__content yp-content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading",
              },
            ],
            staticClass: "yp-exchange__loader",
          },
          [
            _c("yp-loader"),
            _vm._v(" "),
            _c("h3", { staticClass: "text-info" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("loaderText")) + "\n      "),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        !(_vm.accounts.length === 0) && _vm.isLoading
          ? _c(
              "div",
              [
                _c("div", { staticClass: "yp-exchange__row" }, [
                  _c(
                    "div",
                    { staticClass: "yp-exchange__col yp-exchange__col--left" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("exchangePage.fromLabel")) + ":"),
                      ]),
                      _vm._v(" "),
                      _c("yp-account-select", {
                        staticClass: "yp-exchange__selector",
                        attrs: {
                          manual: "",
                          options: _vm.fromAccountList,
                          "external-val": _vm.exchangeForm.fromPart.account,
                          filter: (account) => !account.has_parent,
                        },
                        on: { accountSelected: _vm.onFromSelected },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "yp-exchange__col" },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("exchangePage.amountLabel")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("yp-input", {
                        ref: "amountFrom",
                        staticClass: "yp-exchange__input",
                        attrs: {
                          type: "number",
                          "zero-length": _vm.currentRate.denominator_from,
                          "external-val": _vm.exchangeForm.fromPart.amount,
                          "is-max-mode": "",
                          "max-value": _vm.exchangeForm.fromPart.account.amount,
                        },
                        on: { update: _vm.onChangeAmountFrom },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("yp-button", {
                  staticClass: "yp-exchange__swap-btn",
                  class: { "w-100": _vm.isMobile },
                  attrs: {
                    size: "xl",
                    icon: "Transfers",
                    label: _vm.$t("exchangePage.swapBtn"),
                  },
                  on: { click: _vm.onClickSwapBtn },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "yp-exchange__row" }, [
                  _c(
                    "div",
                    { staticClass: "yp-exchange__col yp-exchange__col--left" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("exchangePage.toLabel")) + ":"),
                      ]),
                      _vm._v(" "),
                      _c("yp-account-select", {
                        staticClass: "yp-exchange__selector",
                        attrs: {
                          manual: "",
                          options: _vm.toAccountList,
                          "external-val": _vm.exchangeForm.toPart.account,
                          filter: (account) => !account.has_parent,
                        },
                        on: { accountSelected: _vm.onToSelected },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "yp-exchange__col" },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("exchangePage.amountLabel")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("yp-input", {
                        staticClass: "yp-exchange__input",
                        attrs: {
                          type: "number",
                          "zero-length": _vm.currentRate.denominator_to,
                          "external-val": _vm.exchangeForm.toPart.amount,
                        },
                        on: { update: _vm.onChangeAmountTo },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "yp-divider" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "yp-exchange__rate" },
                  [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("exchangePage.rates")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c("yp-input", {
                      staticClass: "yp-exchange__input",
                      attrs: {
                        type: "number",
                        "external-val": _vm.currentRate.human_rate.toFixed(2),
                        readonly: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("yp-button", {
                      attrs: {
                        icon: "Refresh",
                        "icon-color": "primary",
                        "is-icon-btn": "",
                        disabled: _vm.isUpdateRatesBtnDisabled,
                      },
                      on: { click: _vm.onUpdateRates },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("yp-button", {
                  staticClass: "mt-3 mx-auto",
                  class: { "w-100": _vm.isMobile },
                  attrs: {
                    size: "xl",
                    label: _vm.$t("exchangePage.confirmBtn"),
                    variant: "primary-gradient",
                    disabled: _vm.isSubmitDisabled,
                  },
                  on: { click: _vm.onSubmit },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.isConfirmModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("exchangePage.confirmModal.title"),
                "confirm-btn": _vm.$t("exchangePage.confirmModal.confirmBtn"),
                "cancel-btn": _vm.$t("exchangePage.confirmModal.cancelBtn"),
              },
              on: {
                confirm: _vm.onConfirm,
                closeModal: function ($event) {
                  _vm.isConfirmModalVisible = false
                },
              },
            },
            [
              _c("div", { staticClass: "yp-divider" }),
              _vm._v(" "),
              _c("div", { staticClass: "yp-exchange__confirm-modal-content" }, [
                _c("div", [
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("exchangePage.confirmModal.give") + ": "
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.exchangeForm.fromPart.amount +
                            _vm.exchangeForm.fromPart.account.currency_symbol
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("exchangePage.confirmModal.get") + ": ") +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.exchangeForm.toPart.amount +
                          _vm.exchangeForm.toPart.account.currency_symbol
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "text-info" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("exchangePage.confirmModal.byRate") + ": "
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentRate.human_rate.toFixed(
                            _vm.currentRate.denominator_from
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "yp-divider" }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }