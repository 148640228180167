import {getCookie} from "utils/helpers";


function createWebsocket(store) {
    const ws = new WebSocket(`${location.protocol.startsWith("https") ? "wss" : "ws"}://${location.host}/ws/`)
    const sessionId = getCookie("sessionid")
    ws.onmessage = function (event) {
        let msg = JSON.parse(event.data)
        if (msg.auth) {
            store.commit('wsEvents/SET_AUTH', msg.auth)
        }
        if (msg.command == "pong") {
            setTimeout(() => ws.send(JSON.stringify({"command": "ping"})), 5000)
        }
        if (store.getters['wsEvents/auth'] && msg.event) {
            store.dispatch('wsEvents/addEvent', msg)
        }
        // $emit(msg.event, msg.obj)
    }
    ws.onopen = (event) => {
        store.dispatch('wsEvents/connectionOpened', event)
        ws.send(JSON.stringify({"command": "auth", "sessionid": sessionId}))
        ws.send(JSON.stringify({"command": "ping"}))
    }
    ws.onclose = () => {
        if (sessionId) {
            setTimeout(() => {
                createWebsocket(store)
            }, 1000)
        } else {
            store.commit('setSession', false)
        }
    }
    ws.onerror = () => {
        ws.close()
    };
    store.commit('wsEvents/SET_WS', ws)
    return ws
}

export default store => {
    if (getCookie('sessionid')) {
        createWebsocket(store)
    } else {
        store.subscribe((mutation) => {
            if (mutation.type === 'setSession' && mutation.payload) {
                createWebsocket(store)
            }
        })
    }
}
