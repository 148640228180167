<template>
  <div class="google-ad-group">
    <div class="google-ad-group__email">
      <div class="google-ad-group__email-text">{{ groupData.name }}</div>
      <yp-button
          label="Change email"
          icon="Mail"
          variant="primary-gradient"
          @click="isEmailModalVisible = true"
      />
    </div>
    <div class="google-ad-group__list">
      <google-ad-item
          v-for="(item, index) in groupData.list"
          :key="`account-${index}`"
          :id="item.pk"
          :updated-at="item.external_updated_at"
          :max-value="currentAccount.amount"
      />
      <div
          v-if="showNewCampaignForm"
          class="google-ad-group__new-campaign"
      >
        <div class="google-ad-group__campaign-form">
          <yp-input
              type="number"
              :label="`Amount (${currentAccount.currency_code}):`"
              auto-focus
              :placeholder="`min value: ${currentAccount.google_amount_min || $store.getters['google/minGoogleRate']}`"
              :min-value="currentAccount.google_amount_min || $store.getters['google/minGoogleRate']"
              :max-value="currentAccount.amount"
              class="google-ad-group__new-campaign-input"
              @update="newCampaignForm.amount = $event"
              @inputError="newCampaignForm.amount = null"
          />
          <b-form-select
                class="new-google-account__selector"
                v-model="timezone"
                :options="availableTimezones"
          />
          <div class="google-ad-group__campaign-actions" :class="{ 'w-100': $store.getters.isMobile }">
            <yp-button
                :disabled="!newCampaignForm.amount || isSubmitDisabled"
                :class="{ 'w-100': $store.getters.isMobile }"
                :label="$t('googleAdPage.submit')"
                icon="Checkbox"
                variant="primary-gradient"
                @click="onSubmit"
            />
            <yp-button
                :class="{ 'w-100': $store.getters.isMobile }"
                :label="$t('googleAdPage.cancelBtn')"
                icon="Close"
                variant="danger"
                @click="showNewCampaignForm = false"
            />
          </div>
        </div>
        <div
            v-show="commission"
            class="google-ad-group__commission yp-additional-text"
        >
          {{`${$t('commission')} (${currentAccount.fee.withdraw.percent}): ${commission} ${currentAccount.currency_code}`}}
        </div>
      </div>
      <yp-modal
          v-if="isEmailModalVisible"
          :title="$t('googleAdPage.emailModal.title')"
          :confirm-btn="$t('googleAdPage.emailModal.confirmBtn')"
          :cancel-btn="$t('googleAdPage.emailModal.cancelBtn')"
          @confirm="onChangeEmail"
          @closeModal="isEmailModalVisible = false"
      >
        <div>
          <span class="text-info">Current email:</span>
          {{ groupData.name }}
        </div>
        <yp-input
            email
            :label="`New email:`"
            :placeholder="`example@example.com`"
            :external-val="groupData.name"
            @update="newEmail = $event"
            @inputError="newEmail = null"
        />
      </yp-modal>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <yp-button
            v-show="!showNewCampaignForm && !isAddBtnHidden"
            :label="$t('googleAdPage.startNewCampaign')"
            class="mt-2"
            icon="Plus"
            :disabled="isAddBtnHidden"
            @click="showNewCampaignForm = true; timezone = availableTimezones[11].value"
        />
        <yp-button
            v-show="selectedAccs.filter(group => groupData.list.includes(group)).length !== groupData.list.length"
            label="Select"
            icon="Plus"
            class="mt-2"
            @click="selectedAccs.push(...groupData.list)"
        />
        <yp-button
            v-show="(selectedAccs.filter(group => groupData.list.includes(group)).length) === groupData.list.length"
            label="Selected"
            icon="Checkbox"
            class="mt-2"
            @click="$emit('rem', groupData.list)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button";
import GoogleAdItem from "@/components/googleAd/Item";
import YpInput from "@/components/ui/YpInput";
import {mapActions, mapGetters} from "vuex";
import YpModal from "@/components/ui/YpModal.vue";
import i18n from "@/plugins/i18n";

export default {
  name: "GroupList",
  components: {YpModal, YpInput, GoogleAdItem, YpButton},
  props: {
    groupData: Object,
    selectedAccs: Array,
  },
  data() {
    return {
      amount: null,
      newEmail: null,
      email: null,
      newCampaignForm: {
        amount: null,
      },
      isEmailModalVisible: false,
      showNewCampaignForm: false,
      timezone: null,
      availableTimezones: [
        {
            "value": "America/Adak",
            "text": "UTC-10"
        },
        {
            "value": "America/Anchorage",
            "text": "UTC-9"
        },
        {
            "value": "America/Ensenada",
            "text": "UTC-8"
        },
        {
            "value": "America/Phoenix",
            "text": "UTC-7"
        },
        {
            "value": "America/Denver",
            "text": "UTC-6"
        },
        {
            "value": "America/Chicago",
            "text": "UTC-5"
        },
        {
            "value": "America/New_York",
            "text": "UTC-4"
        },
        {
            "value": "America/Maceio",
            "text": "UTC-3"
        },
        {
            "value": "America/Noronha",
            "text": "UTC-2"
        },
        {
            "value": "Atlantic/Cape_Verde",
            "text": "UTC-1"
        },
        {
            "value": "Africa/Abidjan",
            "text": "UTC+0"
        },
        {
            "value": "Europe/London",
            "text": "UTC+1"
        },
        {
            "value": "Europe/Luxembourg",
            "text": "UTC+2"
        },
        {
            "value": "Europe/Helsinki",
            "text": "UTC+3"
        },
        {
            "value": "Asia/Tbilisi",
            "text": "UTC+4"
        },
        {
            "value": "Asia/Samarkand",
            "text": "UTC+5"
        },
        {
            "value": "Asia/Bishkek",
            "text": "UTC+6"
        },
        {
            "value": "Asia/Bangkok",
            "text": "UTC+7"
        },
        {
            "value": "Asia/Hong_Kong",
            "text": "UTC+8"
        },
        {
            "value": "Asia/Seoul",
            "text": "UTC+9"
        },
        {
            "value": "Asia/Vladivostok",
            "text": "UTC+10"
        },
        {
            "value": "Asia/Sakhalin",
            "text": "UTC+11"
        },
        {
            "value": "Asia/Anadyr",
            "text": "UTC+12"
        },
        {
            "value": "Pacific/Kanton",
            "text": "UTC+13"
        },
        {
            "value": "Pacific/Kiritimati",
            "text": "UTC+14"
        }
      ],
    }
  },
  computed: {
    ...mapGetters(["currentAccount"]),
    ...mapGetters("google", ["isAddBtnHidden"]),

    commission() {
      return this.newCampaignForm.amount ? (this.newCampaignForm.amount / 100 * this.currentAccount.fee.withdraw.percent ).toFixed(2) : null
    },
    test() {
      return this.newCampaignForm.amount + this.commission
    },
    isSubmitDisabled() {
      return Number(this.newCampaignForm.amount) + Number(this.commission) > this.currentAccount.amount
    }
  },
  methods: {
    // ...mapActions(["getAccounts"]),
    ...mapActions("google", ["setAccountData", "getAccountsList", "setAccountDataById"]),

    onSubmit() {
      this.showNewCampaignForm = false
      this.setAccountData({
        amount: this.newCampaignForm.amount,
        timezone: this.timezone,
        currency_code: this.currentAccount.currency_code,
        email: this.groupData.name
      }).then(response => {
        this.errors = {}
        if (!response.state) this.errors = response.data.errors
        else this.getAccountsList()
      })
    },
    onChangeEmail() {
      if(this.newEmail) {
        this.isEmailModalVisible = false
        this.groupData.list.forEach(account => {
          this.setAccountDataById({
            account_id: account.pk,
            email: this.newEmail
          }).then(response => {
            this.$store.commit('snackbar/SET_SNACK', {
              title: response.data.message,
              text: `on account ${account.external_id}`,
              duration: 5000,
            })
          })
        })
        this.getAccountsList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-google-account {
    &__selector {
        width: 120px;
        margin-left: 10px;
        option {
            background: #0e141e !important;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
            color: white !important;
        }
    }
}

.google-ad-group {
  position: relative;
  margin-top: 20px;
  border: 3px solid $border-color;
  border-radius: 20px;
  padding: 45px 10px 10px;

  &__email-text {
    color: $info;
    font-size: 18px;
  }
  &__email {
    position: absolute;
    top: -16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
  }

  &__commission {
    margin-top: 20px;
    width: 290px;
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    padding: 5px 0;
    background: $light;
    text-align: center;
    border-radius: 20px;

    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__submit {
    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__campaign-form {
    display: flex;
    margin-top: 20px;
    align-items: center;

    @media (max-width: 790px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__new-campaign-input {
    width: 25%;

    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__campaign-actions {
    margin-left: auto;
    display: flex;
    gap: 20px;

    @media (max-width: 790px) {
      margin-left: 0;
    }
  }
}
</style>