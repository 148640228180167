var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer__content container" }, [
      _c("div", { staticClass: "footer__block text-info" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("footer.text")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "text-info",
          attrs: { target: "_blank", href: "https://yeezypay.io/agree.html" },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("footer.agreement")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "text-info",
          attrs: { target: "_blank", href: "https://yeezypay.io/privacy.html" },
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("footer.policy")) + "\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }