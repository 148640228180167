var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-upload" },
    [
      _c("input", {
        ref: "upload",
        staticClass: "yp-upload__input",
        attrs: { type: "file", accept: ".csv, .txt" },
        on: { input: _vm.onFileChange },
      }),
      _vm._v(" "),
      _vm.file
        ? _c(
            "div",
            { staticClass: "yp-upload__file" },
            [
              _c("icon", { attrs: { name: "File" } }),
              _vm._v(" "),
              _c("span", [_vm._v(" " + _vm._s(_vm.file) + " ")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("yp-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hasError,
            expression: "!hasError",
          },
        ],
        ref: "btn",
        staticClass: "yp-upload__btn",
        class: { "mt-3": _vm.file },
        attrs: {
          icon: "Upload",
          variant: "gradient-outline",
          label: _vm.btnText,
        },
        on: {
          click: function ($event) {
            return _vm.$refs.upload.click()
          },
        },
      }),
      _vm._v(" "),
      _vm.hasError
        ? _c(
            "div",
            { staticClass: "yp-upload__error text-center" },
            [
              _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("payoutsPage.uploadFile.error")) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("showModal")
                    },
                  },
                },
                [_vm._v("File layout description")]
              ),
              _vm._v(" "),
              _c("yp-button", {
                staticClass: "yp-upload__btn mx-auto mt-3",
                attrs: {
                  icon: "Upload",
                  variant: "gradient-outline",
                  label: _vm.$t("payoutsPage.uploadFile.new"),
                },
                on: {
                  click: function ($event) {
                    return _vm.$refs.upload.click()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }