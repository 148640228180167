<template>
  <div class="yp-operation">
    <yp-sheet v-if="operationData" class="yp-operation__content mt-0 yp-content">
      <div class="yp-operation__main">
        <yp-button
            class="yp-operation__back-btn"
            icon="BackArrow"
            ghost
            :label="$t('nav.back')"
            @click="$router.go(-1)"
        />
        <icon
            class="yp-operation__icon"
            :name="settings.icon"
            :class="[{ 'icon--danger': operationData.status === 'F' }, {'icon--warning': operationData.status === 'P' }]"
        />
        <h2>{{ `${$t('operationPage.title')} ${settings.name}` }}</h2>
        <div class="text-info">{{ `#${operationData.uid}` }}</div>
        <yp-card-input
            v-if="operationData.pan && !isCryptoOperation && operationData.method !== 'PHN'"
            class="yp-operation__card-input"
            :init-value="operationData.pan"
            readonly
            label="Card number:"
        />
        <yp-phone-input
            v-if="operationData.pan && !isCryptoOperation && operationData.method == 'PHN'"
            class="yp-operation__card-input"
            :init-value="operationData.pan"
            readonly
            label="Phone number:"
        />
        <yp-progress
            class="yp-operation__progress"
            :parts="operationData.parts"
            :progress-style="operationData.status"
            :total="operationData.amount"
        />
      </div>
      <div class="yp-operation__additional">
        <div class="yp-operation__additional-item">
          <span>{{ $t('operationPage.type') }}:</span>
          <span>{{ type }}</span>
        </div>
        <div class="yp-operation__additional-item">
          <span>{{$t('amount')}}:</span>
          <span>
            {{ operationData.amount }}{{operationData.currency_suffix }}
          </span>
        </div>
        <div class="yp-operation__additional-item">
          <span>{{$t('Fee')}}:</span>
          <span>
            {{ operationData.fee }}{{ operationData.currency_suffix }}
          </span>
        </div>
        <div class="yp-operation__additional-item">
          <span>{{$t('operationPage.date')}}:</span>
          <span>{{ getDateAndTime(operationData.created_at) }}</span>
        </div>
        <div v-if="operationData.comment" class="yp-operation__additional-item">
          <span>{{$t('operationPage.comment')}}:</span>
          <span>{{ operationData.comment }}</span>
        </div>
        <div
            v-if="haveTronscan && operationData.order_id"
            class="yp-operation__additional-item"
        >
          <span>{{$t('operationPage.tronscanLink')}}:</span>
          <span>
            <a target="_blank" :href="'https://tronscan.org/#/transaction/' + operationData.order_id">
              {{ operationData.order_id.slice(0, 12) }}...{{ operationData.order_id.slice(operationData.order_id.length - 6) }}
            </a>
          </span>
        </div>
        <div
            v-if="haveEtherscan && operationData.order_id"
            class="yp-operation__additional-item"
        >
          <span>{{$t('operationPage.etherscanLink')}}:</span>
          <span>
            <a target="_blank" :href="`https://etherscan.io/tx/0x${operationData.order_id}`">
              {{ operationData.order_id.slice(0, 16) }}...{{ operationData.order_id.slice(operationData.order_id.length - 4) }}
            </a>
          </span>
        </div>
        <div
          v-if="haveTonviewer && operationData.order_id"
          class="yp-operation__additional-item"
        >
          <span>{{$t('operationPage.tonviewerLink')}}:</span>
          <span>
            <a target="_blank" :href="`https://tonviewer.com/transaction/${operationData.order_id}`">
              {{ operationData.order_id.slice(0, 8) }}...{{ operationData.order_id.slice(operationData.order_id.length - 8) }}
            </a>
          </span>
        </div>
        <div
            v-if="isCryptoOperation && origin"
            class="yp-operation__additional-item"
        >
          <span>{{$t('operationPage.from')}}:</span>
          <span>
            {{ origin }}
          </span>
        </div>
        <div
            v-if="isCryptoOperation && receiver"
            class="yp-operation__additional-item"
        >
          <span>{{$t('operationPage.to')}}:</span>
          <span>
            {{ receiver }}
          </span>
        </div>
      </div>
    </yp-sheet>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Icon from "@/components/ui/Icon";
import YpSheet from "@/components/ui/Sheet";
import YpCardInput from "@/components/ui/YpCardInput";
import { getDateAndTime } from "utils/dateConverter";
import YpButton from "@/components/ui/Button";
import YpProgress from "@/components/ui/YpProgress";
import YpPhoneInput from "@/components/ui/YpPhoneInput";


export default {
  name: "Operation",
  components: {YpProgress, YpButton, YpSheet, Icon, YpCardInput, YpPhoneInput},
  data() {
    return {
      operationData: null,
    }
  },
  computed: {
    ...mapGetters("catalogs", ["isCrypto"]),

    isCryptoOperation() {
      return this.isCrypto(this.operationData.currency_id)
    },
    settings() {
      switch (this.operationData.status.toLowerCase()) {
        case 'c':
          return {
            name: this.$t('operationPage.status.complete'),
            icon: 'Checkbox'
          }
        case 'f':
          return {
            name: this.$t('operationPage.status.failed'),
            icon: 'Close'
          }
        case 'o':
        case 'p':
          return {
            name: this.$t('operationPage.status.progress'),
            icon: 'Time'
          }
      }
    },
    type() {
      switch (this.operationData.kind.toLowerCase()) {
        case 'wi':
          return this.$t('operationType.withdraw')
        case 'de':
          return this.$t('operationType.deposit')
        case 'ex':
          return this.$t('operationType.exchange')
        case 'ct':
          return this.$t('operationType.card_topup')
        case 'co': 
          return this.$t('operationType.card_open')
      } 
    },
    externalId() {
      return this.$route.query.external_id;
    },
    haveTronscan() {
      return this.checkHaveParam('have_tronscan');
    },
    haveEtherscan() {
      return this.checkHaveParam('have_etherscan');
    },
    haveTonviewer() {
      return this.checkHaveParam("have_tonviewer");
    },
    origin() {
      return this.$route.query.origin;
    },
    receiver() {
      return this.$route.query.receiver;
    },
  },
  created() {
    this.getOperation(this.$route.params.id).then(response => this.operationData = response)
  },
  methods: {
    ...mapActions('payouts', ['getOperation']),
    getDateAndTime,
    checkHaveParam(paramName) {
      const param = this.$route.query[paramName];
      if (typeof param === 'boolean') {
        return param;
      } else {
        return param == 'true';
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.yp-operation {
  margin: 0 auto;

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__back-btn {
    position: absolute;
    left: 0;
  }

  &__progress {
    width: 360px;
  }

  &__card-input {
    width: 360px;
  }

  &__additional {
    margin-top: 20px;
    color: $info;
  }
  &__additional-item {
    padding: 20px 0;
    border-top: 1px dashed #1D2737;
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    width: 75px;
    height: 75px;
    fill: $success;
    stroke: $success;
  }
}
</style>
