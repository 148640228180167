<template>
  <section
      class="yp-google-ad"
      v-if="permissions.includes('GoogleAd')"
  >
    <h2 class="text-center">
      Google AD TEST 2
    </h2>
    <yp-sheet id="google_accounts_list" class="yp-google-ad__content yp-content mx-auto">
      <div
        class="yp-google-ad__sum-selected"
        :class="{'yp-google-ad__sum-selected__show': selectedAccs.length !== 0}"
      >
        Sum of selected: {{ this.selectedAccs.reduce((accumulator, currentAccount) =>  accumulator + Number(currentAccount.google_amount), 0)}}
      </div>
      <yp-account-select
          @accountSelected="changeAccount"
          :options="accounts"
      />
      <b-checkbox-group
          v-model="filter.status"
          :options="fastStatuses"
          class="w-100"
          text-field="name"
          value-field="value"
          buttons
      />
      <b-checkbox-group
          v-model="filter.order_by"
          :options="fastOrdering"
          class="w-100 checkbox-column-direction"
          text-field="name"
          value-field="value"
          buttons
      />
      <div class="yp-google-ad__filters mt-3" @keyup.enter="getGoogleAccount">
        <yp-checkbox
            switch-mode
            :external-val="autoLoad"
            :label="$t('googleAdPage.autoLoad')"
            @change="switchAutoLoading($event.isChecked)"
        />
        <div class="search-input-wrapper" style="display: flex; align-items: center; gap: 15px; margin-bottom: 20px; margin-top: 15px;">
          <yp-button
                icon="Search"
                icon-color="primary"
                @click="getGoogleAccount"
                :disabled="!isListLoaded || partLoadingState"
            />
          <yp-input
              class="yp-google-ad__search-input"
              placeholder="000-000-0000 or e-mail"
              :disabled="!isListLoaded || partLoadingState"
              @update="searchInput = $event"
          />
        </div>
        <yp-button
          class="yp-google-ad__select-all"
          v-show="!selected_all && groups.length !== 0"
          icon="Plus"
          icon-color="primary"
          label="Select All"
          @click="selectAll"
        />
        <yp-button
          class="yp-google-ad__select-all"
          v-show="selected_all && groups.length !== 0"
          icon="Checkbox"
          icon-color="primary"
          label="Unselect"
          @click="unselectAll"
        />
      </div>
      <yp-checkbox
        switch-mode
        :external-val="filter.with_old"
        :label="$t('googleAdPage.withOld')"
        @change="switchOldLoading($event.isChecked)"
      />
      <div class="yp-google-ad__add-new" v-if="!isAddBtnHidden && gotAmount">
        <div class="d-flex justify-content-between align-items-center">
          <div>{{ $t('googleAdPage.addNewAccount') + ":" }}</div>
          <yp-button
              v-show="!isNewAccountFormVisible"
              icon="Plus"
              :label="$t('googleAdPage.addBtn')"
              variant="primary-gradient"
              :disabled="isAddBtnHidden && !gotAmount"
              @click="isNewAccountFormVisible = true"
          />
          <yp-button
              v-show="isNewAccountFormVisible"
              icon="Close"
              ghost
              icon-color="red"
              @click="isNewAccountFormVisible = false"
          />
        </div>
        <new-google-account v-show="isNewAccountFormVisible" />
      </div>
      <yp-loader v-if="!isListLoaded" class="mx-auto mt-5"/>
      <h2 v-show="isFilteredListEmpty" class="text-info mt-5 text-center">
        There were no results found for {{ `"${searchInput}"` }}
      </h2>
      <div v-if="isListLoaded && !isFilteredListEmpty" class="yp-google-ad__list-wrap">
        <google-group-list
            v-for="(el, key) in groups"
            :key="`ad-group-${key}`"
            :group-data="el"
            :account="account"
            :selectedAccs="selectedAccs"
            @rem="remSelected"
        />
        <yp-loader v-if="partLoadingState" class="mx-auto mt-5 loader_center"/>
      </div>
    </yp-sheet>
  </section>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import {debounce} from "utils/helpers";
import YpSheet from "@/components/ui/Sheet"
import YpAccountSelect from "@/components/ui/YpSelect"
import YpButton from "@/components/ui/Button"
import YpLoader from "@/components/ui/YpLoader"
import NewGoogleAccount from "@/components/googleAd/NewAccount"
import YpCheckbox from "@/components/ui/YpCheckbox"
import GoogleGroupList from "@/components/googleAd/GroupList"
import YpInput from "@/components/ui/YpInput.vue";

export default {
  components: {
    YpInput,
    GoogleGroupList,
    YpCheckbox,
    NewGoogleAccount,
    YpLoader,
    YpButton,
    YpAccountSelect,
    YpSheet
  },
  data() {
    return {
      account: null,
      showClosedAccounts: true,
      itemsOffset: 0,
      isNewAccountFormVisible: false,
      isUpdateForm: false,
      isFilteredListEmpty: false,
      searchInput: null,
      items: null,
      autoLoad: false,
      googleAccountsOffset: 0,
      selectedAcc: '',
      selectedAccs: [],
      scrollActive: true,
      fastStatuses: [
        {
          name: "Active",
          value: "A",
        },
        {
          name: "Purged",
          value: "P",
        },
        {
          name: "Closed",
          value: "C",
        },
        {
          name: "Draft",
          value: "D",
        },
        {
          name: "Restored",
          value: "R",
        },
      ],
      fastOrdering: [
        {
          name: "Created at - new",
          value: "-created_at",
        },
        {
          name: "Created at - old",
          value: "created_at",
        },
        {
          name: "Balance increase",
          value: "-external_amount_db",
        },
        {
          name: "Balance decrease",
          value: "external_amount_db",
        },
        {
          name: "Recently updated balance",
          value: "-external_updated_at",
        },
      ],
      filter: {
        status: "",
        order_by: "",
        with_old: false,
      },
    }
  },
  created() {
    // const googleAcc = this.accounts.find(el => el.pk === localStorage.getItem("google_account"))
    // this.SET_CURRENT_ACCOUNT(googleAcc || this.accounts[0])
    // console.log('Google AD created')
    this.getAccountsAmount()
    this.isListLoaded = false
    const allAccounts = localStorage.getItem('showClosedAccounts')
    if(allAccounts) this.showClosedAccounts =  allAccounts === 'true'
    if (!this.isMobile) window.addEventListener('scroll', this.onScroll)
    else window.addEventListener('touchmove', this.onScroll)
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.getAccountsList(query)
    } else this.getAccountsList()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('touchmove', this.onScroll)
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.isListLoaded || this.partLoadingState) {
      this.SET_FETCH_GOOGLE_ACCOUNTS(false)
    }
    this.SET_GOOGLE_AD_LIST([])
    this.selectedAccs = []
    next()
  },
  watch: {
    '$route.query': {
      deep: true,
      handler(val) {
        this.getAccountsAmount()
        this.googleAccountsOffset = 0
        if (this.fetchGoogleAccounts) this.getAccountsList(val)
      }
    },
    fetchGoogleAccounts() {
      this.googleAccountsOffset = 0
      if (this.fetchGoogleAccounts) this.getAccountsList(this.$route.query)
    },
    currentAccount() {
      if (this.selectedAcc){
        this.setCurrentAccount(this.selectedAcc)
      }
    },
    filter: {
      deep: true,
      handler: debounce(function (val) {
        this.googleAccountsOffset = 0
        this.autoLoad = false
        if (!this.isMobile) window.addEventListener('scroll', this.onScroll)
        else window.addEventListener('touchmove', this.onScroll)
        let query = {}
        for (let prop in val) {
          if (val.hasOwnProperty(prop) && val[prop]) {
            if (prop === 'date' && val.date.every(el => el) && val.date.length > 0) {
              const dates = toSecondsFrom1970(val.date)
              query.since = dates[0]
              query.until = dates[1] + 86400
            } else {
              query[prop] = val[prop]
            }
          }
        }
        this.selectedAcc = this.currentAccount
        this.$router.push({query})
      }, 100)
    },
    hasGoogleBalanceUpdate: {
      handler(val) {
        if (val) {
          this.getGoogleAccountBalance({
            kind: "G",
            currencies: this.currentAccount.currency_code,
            pk: val.pk
          })
          this.SET_HAS_GOOGLE_BALANCE_UPDATE(false)
        }
      }
    },
  },
  computed: {
    ...mapState("google", { googleList: "googleAdList" }),
    ...mapGetters("google", [
      "googleAdList",
      "hasGoogleBalanceUpdate",
      // "currentAccount",
      "isAddBtnHidden",
      "isListLoaded",
      "gotAmount",
      "partLoadingState",
      "hasFetchedGoogleAccounts",
      "fetchGoogleAccounts"
    ]),
    ...mapGetters([
      "currentAccount",
      "accountsByMethods",
      "permissions",
    ]),
    selected_all() {
      return this.selectedAccs.filter(group => this.groups.filter(accs => accs.list.includes(group))).length === this.groups.flatMap(({list}) => list).length
    },
    groups() {
      // переписать геттер под массив допустимых статусов
      const allowedStatuses = ['A', 'D', 'R']
      return this.showClosedAccounts ? this.googleAdList() : this.googleAdList(allowedStatuses)
    },
    accounts() {
      return this.accountsByMethods("withdraw", "Google")
    },
  },
  methods: {
    ...mapActions(["getAccounts"]),
    ...mapActions("google", ["getGoogleAccountBalance", "getAccountsAmount"]), //"getAccountsList" old version of loading
    ...mapMutations("google", [
      "SET_HAS_GOOGLE_BALANCE_UPDATE",
      "SET_FILTERED_LIST",
      "SET_CURRENT_ACCOUNT",
      "SET_FETCH_GOOGLE_ACCOUNTS",
      "SET_GOOGLE_AD_LIST",
      "SET_LOADING_LIST",
      "SET_LOADING_PART"
    ]),
    ...mapMutations(['setCurrentAccount']),
    switchOldLoading(value) {
      if (value != this.filter.with_old) this.filter.with_old = value
    },
    selectAll() {
      this.selectedAccs = this.groups.flatMap(({list}) => list)
    },
    unselectAll() {
      this.selectedAccs = []
    },
    remSelected(googleGroup) {
      this.selectedAccs = this.selectedAccs.filter(group => !googleGroup.includes(group))
    },
    async getGoogleAccount() {
      this.SET_GOOGLE_AD_LIST([])
      await this.switchAutoLoading(false)
      let input = ''
      if (this.searchInput) {
        input = this.searchInput.replace(/-+/g, '')
      }
      let query = null
      if (Number(input)) {
        query = {
          "external_id": input
        }
      } else if (input.length !== 0) {
        query = {
          "email": input
        }
      }
      this.SET_FETCH_GOOGLE_ACCOUNTS(true)
      this.googleAccountsOffset = 0
      await this.getAccountsList(this.$route.query, query)
    },
    onScroll: debounce(function () {
      const updateTrigger = (window.scrollY + window.innerHeight) >= Math.floor(this.bottomListPosition)
      const mobileTrigger = (window.scrollY + window.innerHeight) >= Math.floor(this.bottomListPosition) - 200
      if (this.scrollActive) {
        if (this.isMobile && mobileTrigger) {
          this.getAccountsList(this.$route.query)
        } else if (updateTrigger) {
          this.getAccountsList(this.$route.query)
        }
      }
    }, 200),
    async getAccountsList(status, query=null) {
      if (this.fetchGoogleAccounts) {
        this.SET_LOADING_PART(true)
        this.scrollActive = false
        let items = await this.getAccounts({
          ...status,
          ...query,
          currencies: this.currentAccount.currency_code,
          kind: "G",
          offset: this.googleAccountsOffset || null,
        })
        let google_external_ids = items.map(account => account.external_id)
        await this.getGoogleAccountBalance({google_external_ids: google_external_ids.join()})
        this.SET_LOADING_LIST(true)
        this.SET_LOADING_PART(false)
        if (items.length === 10) {
          this.scrollActive = true
          this.googleAccountsOffset += 10
        } else {
          window.removeEventListener('scroll', this.onScroll)
          window.removeEventListener('touchmove', this.onScroll)
        }
      } else this.SET_FETCH_GOOGLE_ACCOUNTS(true)
      this.bottomListPosition = document.querySelector('#google_accounts_list').getBoundingClientRect().bottom
    },
    async startAutoLoad(status) {
      if (this.fetchGoogleAccounts) {
        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('touchmove', this.onScroll)
        this.SET_LOADING_PART(true)
        let items = await this.getAccounts({
          ...status,
          currencies: this.currentAccount.currency_code,
          kind: "G",
          offset: this.googleAccountsOffset || null,
        })
        let google_external_ids = items.map(account => account.external_id)
        await this.getGoogleAccountBalance({google_external_ids: google_external_ids.join()})
        this.SET_LOADING_LIST(true)
        this.googleAccountsOffset += 10
        while (items.length === 10 && this.fetchGoogleAccounts && this.autoLoad) {
          this.SET_LOADING_PART(true)
          items = await this.getAccounts({
            ...status,
            currencies: this.currentAccount.currency_code,
            kind: "G",
            offset: this.googleAccountsOffset || null,
          })
          google_external_ids = items.map(account => account.external_id)
          await this.getGoogleAccountBalance({google_external_ids: google_external_ids.join()})
          this.googleAccountsOffset += 10
        }
        this.SET_LOADING_PART(false)
      }
      if (!this.fetchGoogleAccounts) this.SET_FETCH_GOOGLE_ACCOUNTS(true)
      this.bottomListPosition = document.querySelector('#google_accounts_list').getBoundingClientRect().bottom
    },
    async changeAccount(event) {
      // localStorage.setItem("google_account", event.pk)
      // this.SET_CURRENT_ACCOUNT(event)
      this.selectedAccs = []
      this.selectedAcc = ''
      this.SET_GOOGLE_AD_LIST([])
      await this.getAccountsAmount()
      this.isListLoaded = false
      this.googleAccountsOffset = 0
      await this.switchAutoLoading(false)
      await this.getAccountsList(this.$route.query)
      this.isListLoaded = true
    },
    async switchAutoLoading(event) {
      this.autoLoad = event
      if (!event) {
        if (!this.isMobile) window.addEventListener('scroll', this.onScroll)
        else window.addEventListener('touchmove', this.onScroll)
      } else await this.startAutoLoad(this.$route.query)
    },
  }
}
</script>

<style lang="scss" scoped>

.search-input-wrapper {
  @media (max-width: 790px) {
    width: 100%;
  }
}
.yp-google-ad {
  margin: 0 auto;
  width: 100%;

  &__add-new {
    margin-top: 20px;

    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  &__select-all {
    margin-left: 15px;
  }
  
  &__sum-selected {
    position: fixed;
    left: 10%;
    transform: translate(-50%, -50%);
    top: 50%;
    background: #2f3447;
    padding: 20px;
    border-radius: 15px;
    border: 2px solid #26c7bc;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.4, 0, 1, 1);
    
    @media (max-width: 1400px) {
      left: 50%;
      top: 12%;
      z-index: 2;
    }

    &__show {
      opacity: 1 !important;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 790px) {
      flex-direction: column-reverse;
    }
  }

  &__search-input {
    width: 360px;
  }

  &__list-wrap {
    position: relative;
  }
  &__part-loading {
    width: 100%;
    position: absolute;
    display: flex;
    border-radius: 10px;
    padding-top: 200px;
    top: 0;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
.loader_center {
  left: 50%;
  transform: translateX(-50%);
}
.checkbox-column-direction {
  @media (max-width: 790px) {
      flex-direction: column;
    }
}
</style>
