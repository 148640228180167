var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permissions.includes("GoogleAd")
    ? _c(
        "section",
        { staticClass: "yp-google-ad" },
        [
          _c("h2", { staticClass: "text-center" }, [
            _vm._v("\n    Google AD TEST 2\n  "),
          ]),
          _vm._v(" "),
          _c(
            "yp-sheet",
            {
              staticClass: "yp-google-ad__content yp-content mx-auto",
              attrs: { id: "google_accounts_list" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "yp-google-ad__sum-selected",
                  class: {
                    "yp-google-ad__sum-selected__show":
                      _vm.selectedAccs.length !== 0,
                  },
                },
                [
                  _vm._v(
                    "\n      Sum of selected: " +
                      _vm._s(
                        this.selectedAccs.reduce(
                          (accumulator, currentAccount) =>
                            accumulator + Number(currentAccount.google_amount),
                          0
                        )
                      ) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("yp-account-select", {
                attrs: { options: _vm.accounts },
                on: { accountSelected: _vm.changeAccount },
              }),
              _vm._v(" "),
              _c("b-checkbox-group", {
                staticClass: "w-100",
                attrs: {
                  options: _vm.fastStatuses,
                  "text-field": "name",
                  "value-field": "value",
                  buttons: "",
                },
                model: {
                  value: _vm.filter.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "status", $$v)
                  },
                  expression: "filter.status",
                },
              }),
              _vm._v(" "),
              _c("b-checkbox-group", {
                staticClass: "w-100 checkbox-column-direction",
                attrs: {
                  options: _vm.fastOrdering,
                  "text-field": "name",
                  "value-field": "value",
                  buttons: "",
                },
                model: {
                  value: _vm.filter.order_by,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "order_by", $$v)
                  },
                  expression: "filter.order_by",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "yp-google-ad__filters mt-3",
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.getGoogleAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("yp-checkbox", {
                    attrs: {
                      "switch-mode": "",
                      "external-val": _vm.autoLoad,
                      label: _vm.$t("googleAdPage.autoLoad"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.switchAutoLoading($event.isChecked)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "search-input-wrapper",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        gap: "15px",
                        "margin-bottom": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c("yp-button", {
                        attrs: {
                          icon: "Search",
                          "icon-color": "primary",
                          disabled: !_vm.isListLoaded || _vm.partLoadingState,
                        },
                        on: { click: _vm.getGoogleAccount },
                      }),
                      _vm._v(" "),
                      _c("yp-input", {
                        staticClass: "yp-google-ad__search-input",
                        attrs: {
                          placeholder: "000-000-0000 or e-mail",
                          disabled: !_vm.isListLoaded || _vm.partLoadingState,
                        },
                        on: {
                          update: function ($event) {
                            _vm.searchInput = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("yp-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.selected_all && _vm.groups.length !== 0,
                        expression: "!selected_all && groups.length !== 0",
                      },
                    ],
                    staticClass: "yp-google-ad__select-all",
                    attrs: {
                      icon: "Plus",
                      "icon-color": "primary",
                      label: "Select All",
                    },
                    on: { click: _vm.selectAll },
                  }),
                  _vm._v(" "),
                  _c("yp-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selected_all && _vm.groups.length !== 0,
                        expression: "selected_all && groups.length !== 0",
                      },
                    ],
                    staticClass: "yp-google-ad__select-all",
                    attrs: {
                      icon: "Checkbox",
                      "icon-color": "primary",
                      label: "Unselect",
                    },
                    on: { click: _vm.unselectAll },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("yp-checkbox", {
                attrs: {
                  "switch-mode": "",
                  "external-val": _vm.filter.with_old,
                  label: _vm.$t("googleAdPage.withOld"),
                },
                on: {
                  change: function ($event) {
                    return _vm.switchOldLoading($event.isChecked)
                  },
                },
              }),
              _vm._v(" "),
              !_vm.isAddBtnHidden && _vm.gotAmount
                ? _c(
                    "div",
                    { staticClass: "yp-google-ad__add-new" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("googleAdPage.addNewAccount") + ":")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("yp-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isNewAccountFormVisible,
                                expression: "!isNewAccountFormVisible",
                              },
                            ],
                            attrs: {
                              icon: "Plus",
                              label: _vm.$t("googleAdPage.addBtn"),
                              variant: "primary-gradient",
                              disabled: _vm.isAddBtnHidden && !_vm.gotAmount,
                            },
                            on: {
                              click: function ($event) {
                                _vm.isNewAccountFormVisible = true
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("yp-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isNewAccountFormVisible,
                                expression: "isNewAccountFormVisible",
                              },
                            ],
                            attrs: {
                              icon: "Close",
                              ghost: "",
                              "icon-color": "red",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isNewAccountFormVisible = false
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("new-google-account", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isNewAccountFormVisible,
                            expression: "isNewAccountFormVisible",
                          },
                        ],
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isListLoaded
                ? _c("yp-loader", { staticClass: "mx-auto mt-5" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h2",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFilteredListEmpty,
                      expression: "isFilteredListEmpty",
                    },
                  ],
                  staticClass: "text-info mt-5 text-center",
                },
                [
                  _vm._v(
                    "\n      There were no results found for " +
                      _vm._s(`"${_vm.searchInput}"`) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isListLoaded && !_vm.isFilteredListEmpty
                ? _c(
                    "div",
                    { staticClass: "yp-google-ad__list-wrap" },
                    [
                      _vm._l(_vm.groups, function (el, key) {
                        return _c("google-group-list", {
                          key: `ad-group-${key}`,
                          attrs: {
                            "group-data": el,
                            account: _vm.account,
                            selectedAccs: _vm.selectedAccs,
                          },
                          on: { rem: _vm.remSelected },
                        })
                      }),
                      _vm._v(" "),
                      _vm.partLoadingState
                        ? _c("yp-loader", {
                            staticClass: "mx-auto mt-5 loader_center",
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }