function round (number, ndigits) {
    return parseFloat(parseFloat(number).toFixed(ndigits));
}

function calcHumanRate(rate) {
    const ratio = 1 / rate
    return ratio >= 1 ? ratio : 1 / ratio
}

function calcCommission(rules, amount) {
    const feePercent = rules.percent
    const feeMinimal = rules.minimal
    const feeFixed = rules['fixed']
    // amount = Number(this.amount)
    const minCommission = feeMinimal + feeFixed
    const commission =  amount / 100 * feePercent + feeFixed
    // console.log(minCommission, amount, amount / 100)
    return (amount / 100 * feePercent) <= feeMinimal ?
        round(minCommission, 2) :
        round(commission, 2)
}

module.exports = { round, calcHumanRate, calcCommission };
