<template>
  <div class="yp-input">
    <b-form-input
        v-model="value"
        :disabled="disabled"
        v-mask="'#### #### #### ####'"
        class="yp-input__input"
        :class="[{ 'yp-input__input--rounded': rounded }, {'yp-input__input--dark': mode === 'dark' }]"
        placeholder="0000 0000 0000 0000"
        :style="[inputStyle, borderColor, { padding: '29.5px 20px 11.5px' }]"
        :readonly="readonly"
        @input="onChange"
    />
    <icon
        v-if="cardBank"
        class="yp-input__logo"
        :name="`bank-${cardBank}`"
    />
    <div class="yp-input__label">{{ $t('cardInputLabel') }}:</div>
    <icon v-if="cardType" class="yp-input__card-type" :name="`card-type-${cardType}`" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cards from '@/plugins/cards'
import Icon from "@/components/ui/Icon"

export default {
  name: "YpInput",
  components: {Icon},
  props: {
    disabled: Boolean,
    type: String,
    rounded: Boolean,
    initValue: String,
    readonly: Boolean,
  },
  data() {
    return {
      value: ''
    }
  },
  created() {
    if(this.initValue) this.value = this.initValue
  },
  computed: {
    ...mapGetters(['mode']),

    inputStyle() {
      return this.cardBank ? { paddingLeft: '45px' } : null
    },
    cardType() {
      const firstSymbol = this.value[0]
      switch (firstSymbol) {
        case '2':
          return 'world'
        case '3':
          return 'american express'
        case '4':
          return 'visa'
        case '5':
          return 'mc'
      }
    },
    borderColor() {
      switch (this.cardBank) {
        case 'open':
          return { borderColor: '#00BEF0'}
        case 'vtb':
          return { borderColor: '#009FDF'}
        case 'bps-sberbank':
        case 'sberbank':
          return { borderColor: '#21A038'}
        case 'tinkoff':
          return { borderColor: '#FFDD2D'}
        case 'gazprombank':
          return { borderColor: '#476BF0'}
      }
    },
    cardBank() {
      let value = ''
      if (this.value.length >= 7) value = this.value.substring(0, 7).split(' ').join('')
      if (cards[value] === 'bps-sberbank') return 'sberbank'
      return cards[value] || ''
    },
  },
  methods: {
    onChange(val) {
      const number = val.split(' ').join('')
      if(number.length === 16) this.$emit('update', number)
      else this.$emit('error')
    }
  }
}
</script>
