var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "operation-item-wrap",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "operation-item",
          class: { active: _vm.isActive },
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            { staticClass: "operation-item__info" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isMobile,
                      expression: "!isMobile",
                    },
                  ],
                  staticClass: "operation-item__id",
                },
                [
                  _vm.value.external_id
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s("#" + _vm.value.external_id) +
                            "\n        "
                        ),
                      ])
                    : _c("span", [_vm._v(_vm._s("#" + _vm.value.pk))]),
                ]
              ),
              _vm._v(" "),
              _vm.value.amount
                ? [
                    _vm.value.method ===
                      _vm.operation_methods["VIRTUAL_CARD"] &&
                    _vm.value.kind === _vm.operation_kinds["WITHDRAW"]
                      ? _c("div", { staticClass: "operation-item__amount" }, [
                          _vm._v(
                            "\n          " + _vm._s(_vm.amount) + "\n          "
                          ),
                          _c(
                            "p",
                            [
                              _c("icon", {
                                attrs: {
                                  name: "Card",
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm.value.method ===
                          _vm.operation_methods["VIRTUAL_CARD"] &&
                        (_vm.value.kind === _vm.operation_kinds["CARD_TOPUP"] ||
                          _vm.value.kind === _vm.operation_kinds["CARD_OPEN"])
                      ? _c("div", { staticClass: "operation-item__amount" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.amount.slice(2)) +
                              "\n          "
                          ),
                          _c(
                            "p",
                            [
                              _c("icon", {
                                attrs: {
                                  name: "Card",
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon", {
                                staticStyle: {
                                  stroke: "#787f9b",
                                  fill: "transparent",
                                },
                                attrs: {
                                  name: "ArrowUp",
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon", {
                                staticStyle: {
                                  stroke: "#787f9b",
                                  fill: "transparent",
                                },
                                attrs: {
                                  name: "Profile",
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "operation-item__amount" }, [
                          _vm._v(
                            "\n          " + _vm._s(_vm.amount) + "\n        "
                          ),
                        ]),
                  ]
                : _c("div", { staticClass: "operation-item__kind" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.value.kind_name) + "\n      "
                    ),
                  ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("yp-progress", {
            staticClass: "operation-item__progress",
            attrs: {
              text: _vm.value.status_name,
              current: 100,
              "progress-style": _vm.value.status,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMobile,
                  expression: "isMobile",
                },
              ],
              staticClass: "operation-item__id",
            },
            [
              _vm.value.external_id
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-shrink-0 operation-item__id-text_mobile",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s("#" + _vm.value.external_id) +
                          "\n        "
                      ),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "flex-shrink-0 operation-item__id-text_mobile",
                    },
                    [_vm._v(_vm._s("#" + _vm.value.pk))]
                  ),
            ]
          ),
          _vm._v(" "),
          _vm.value.comment
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.comment
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v(_vm._s(_vm.value.comment)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value.fee
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.fee
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v(
                          "Fee " +
                            _vm._s(_vm.value.fee) +
                            " " +
                            _vm._s(_vm.value.currency_code)
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.txid(_vm.value) && _vm.value.method == "CPT"
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-info-block__text" }, [
                    _vm._v("TX hash: " + _vm._s(_vm.txid(_vm.value))),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value.receiver && _vm.value.method == "CPT"
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.receiver
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v("Receiver: " + _vm._s(_vm.value.receiver)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm.value.pan
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.pan && _vm.value.method == "PHN"
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v("Phone number: " + _vm._s(_vm.value.pan)),
                      ])
                    : _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v("Card number: " + _vm._s(_vm.value.pan)),
                      ]),
                ],
                1
              )
            : _vm.value.card_number
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.card_number
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v("Card number: " + _vm._s(_vm.value.card_number)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.value.account_name
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _vm.value.account_name
                    ? _c("div", { staticClass: "yp-info-block__text" }, [
                        _vm._v("Name: " + _vm._s(_vm.value.account_name)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.txid(_vm.value) && _vm.value.method == "CPT"
            ? _c(
                "div",
                { staticClass: "yp-info-block mt-2" },
                [
                  _c("icon", { attrs: { name: "Info", color: "primary" } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "yp-info-block__text" }, [
                    _vm._v("\n        Link:\n        "),
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href:
                            _vm.getCryptoLinkTemplate(_vm.value) +
                            _vm.txid(_vm.value),
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.ellipsis(_vm.value)) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("Transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hover,
                expression: "hover",
              },
            ],
            staticClass: "operation-item__more-info yp-info-block",
          },
          [
            _c("yp-button", {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.top.noninteractive",
                  modifiers: { hover: true, top: true, noninteractive: true },
                },
              ],
              attrs: {
                icon: "Info",
                id: `info-${_vm.value.uid}`,
                variant: "light",
                "icon-color": "white",
                ghost: "",
                "is-icon-btn": "",
                title: "click to get more info",
              },
              on: {
                click: function ($event) {
                  _vm.$router.push({
                    name: "operation",
                    params: { id: _vm.value.pk },
                    query: {
                      external_id: _vm.value.external_id,
                      have_tronscan: _vm.haveTronscan(_vm.value),
                      have_etherscan: _vm.haveEtherscan(_vm.value),
                      have_tonviewer: _vm.haveTonviewer(_vm.value),
                      origin: _vm.value.origin,
                      receiver: _vm.value.receiver,
                    },
                  })
                },
              },
            }),
            _vm._v(" "),
            _vm.statusesForRepeat.includes(_vm.value.status)
              ? _c("yp-button", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top.noninteractive",
                      modifiers: {
                        hover: true,
                        top: true,
                        noninteractive: true,
                      },
                    },
                  ],
                  attrs: {
                    icon: "Reload",
                    "icon-color": "white",
                    id: `reload-${_vm.value.uid}`,
                    ghost: "",
                    variant: "light",
                    "is-icon-btn": "",
                    title:
                      "click to repeat operation or select multiple operations and repeat it all",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.operationToRepeat(_vm.value)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }