import Vue from 'vue'
import VueRouter from "vue-router"

import NotFound from "@/components/NotFound"
import AuthLink from "@/components/AuthLink"

import AuthLayout from "layouts/AuthLayout"
import DefaultLayout from "layouts/DefaultLayout"

import Login from "@/views/Login"

import Welcome from "@/views/Welcome"

import Payouts from "@/views/Payouts"
import PayoutCard from "@/views/PayoutCard.vue"
import PayoutCrypto from "@/views/PayoutCrypto.vue"
import PayoutPhone from "@/views/PayoutPhone.vue"
import WalletCrypto from '@/views/WalletCrypto.vue'

import Profile from "@/views/Profile"

import History from "@/views/History"
import Reports from "@/views/Reports.vue"
import Operation from "@/views/Operation"

import Exchange from "@/views/Exchange"

import CardToCard from "@/views/CardToCard"

import GoogleAd from "@/views/GoogleAd"

import VirtualCards from "@/views/VirtualCards"

import AccountDeposit from "@/views/AccountDeposit"

import ApiCredentials from "@/views/ApiCredentials"

import {getCookie} from "utils/helpers"

Vue.use(VueRouter)

export default store => {
    let routes = [
        {
            path: '/',
            name: 'main',
            component: AuthLayout,
            children: [
                {
                    path: "",
                    name: "login",
                    component: Login,
                    meta: {
                        title: 'Login',
                        type: 'loginViaTg'
                    },
                },
                {
                    path: '/login',
                    name: 'loginViaPassword',
                    component: Login,
                    meta: {
                        title: 'Login',
                        type: 'loginViaPassword'
                    }
                },
                {
                    path: "auth/:key",
                    name: "auth",
                    component: AuthLink,
                    meta: {
                        title: 'Auth',
                    },
                },
                {
                    path: "invite/:groupId",
                    name: 'invite',
                    component: Login,
                    meta: {
                        title: 'Invite',
                        type: 'loginViaTg'
                    }
                }
            ],
        },
        {
            path: '/cabinet',
            name: 'cabinet',
            component: DefaultLayout,
            meta: {
                authRequired: true,
            },
            children: [
                {
                    path: 'home',
                    name: 'home',
                    component: Welcome,
                    meta: {
                        title: 'Welcome',
                    }
                },
                {
                    path: 'account-deposit',
                    name: 'account-deposit',
                    component: AccountDeposit,
                    meta: {
                        title: 'Deposit',
                        icon: 'AddMoney',
                        localStorageName: 'depositAccount',
                        permission: 'Payment',
                        type: "deposit",
                        method: ["ClientCard", "SBP", "Crypto", "C2C"]
                    }
                },
                {
                    path: "exchange",
                    name: "exchange",
                    component: Exchange,
                    meta: {
                        title: 'Exchange',
                        permission: 'Exchange',
                        icon: 'Transfers'
                    },
                },
                {
                    path: "payout",
                    name: "payout",
                    component: Payouts,
                    meta: {
                        title: 'Payout',
                        icon: 'Payouts'
                    },
                },
                {
                    path: "payout/crypto",
                    name: "payout-crypto",
                    component: PayoutCrypto,
                    meta: {
                        title: 'Payout',
                        localStorageName: 'cryptoAccount',
                        type: 'withdraw',
                        method: "Crypto",
                    },
                },
                {
                    path: "payout/card",
                    name: "payout-card",
                    component: PayoutCard,
                    meta: {
                        title: 'Payout',
                        localStorageName: 'cardAccount',
                        type: 'withdraw',
                        method: "Card",
                    },
                },
                {
                    path: "payout/phone",
                    name: "payout-phone",
                    component: PayoutPhone,
                    meta: {
                        title: 'Payout',
                        localStorageName: 'phoneAccount',
                        type: 'withdraw',
                        method: "Phone",
                    },
                },
                {
                    path: "history",
                    name: "history",
                    component: History,
                    meta: {
                        title: 'History',
                        permission: 'History',
                        icon: 'TimeLoad',
                    },
                },
                {
                    path: "history/:id",
                    name: "operation",
                    component: Operation,
                    meta: {
                        title: 'Operation',
                        permission: 'History'
                    },
                },
                {
                    path: "reports",
                    name: "reports",
                    component: Reports,
                    meta: {
                        title: "Reports",
                    }
                },
                {
                    path: "google",
                    name: "google",
                    component: GoogleAd,
                    meta: {
                        title: 'Google',
                        icon: 'GoogleAdIcon',
                        permission: 'GoogleAd',
                        localStorageName: 'googleAccount',
                        type: 'withdraw',
                        method: 'Google',
                    },
                },
                {
                    path: "card-to-card",
                    name: "card-to-card",
                    component: CardToCard,
                    meta: {
                        title: 'Card to card',
                        icon: 'CardToCard',
                        permission: 'CardToCard',
                    },
                },
                {
                    path: 'virtual-cards',
                    name: 'virtual-cards',
                    component: VirtualCards,
                    meta: {
                        title: 'Virtual Cards',
                        icon: 'Card',
                        permission: 'VirtualCards',
                        localStorageName: 'vcardsAccount',
                        type: 'withdraw',
                        method: "VirtualCard",
                    }
                },
                {
                    path: "api",
                    name: "api",
                    component: ApiCredentials,
                    meta: {
                        title: 'Api credentials',
                        icon: 'Api',
                        permission: 'Api',
                    },
                },
                {
                    path: "profile",
                    name: "profile",
                    component: Profile,
                    meta: {
                        title: 'Profile',
                        icon: 'Profile',
                    },
                },
                {
                    path: "wallet-crypto",
                    name: "wallet-crypto",
                    component: WalletCrypto,
                    meta: {
                        title: 'Crypto Wallets',
                        icon: 'WalletCrypto',
                        permission: 'WalletCrypto'
                    }
                },
                {
                    path: '*',
                    component: NotFound,
                    meta: {
                        title: '404'
                    }
                },
            ]
        },
        {
            path: '*',
            component: NotFound,
            meta: {
                title: '404'
            }
        }

    ]

    const router = new VueRouter({
        mode: 'history',
        linkActiveClass: 'active',
        scrollBehavior(to, from, savedPosition) {
            return {x: 0, y: 0}
        },
        routes
    })

    function checkLocalStorageAccount(localStorageName, accounts) {
        const lsAccountPk = localStorage.getItem(localStorageName)
        return accounts.find(acc => acc.pk === lsAccountPk)
    }

    router.beforeEach(async (to, from, next) => {
        document.title = `${document.title.split('|')[0]} | ${to.meta.title || to.name}`
        if (to.matched.some(record => record.meta.authRequired)) {
            const token = getCookie('sessionid')
            if (token) {
                // получаем все необходимые данные
                //if (from.name !== to.name) await store.dispatch('getNecessaryData')
                store.commit('setContextLoaded', false)
                if (from.name !== to.name) await store.dispatch('getContext')
                if (to.meta.title === 'Google') {
                    await store.dispatch('getAccountsList', {req_path: to.name})
                }
                store.commit('setContextLoaded', true)
                // console.log('GetNessasaryData')
                // смотрим права пользователя
                const permissions = store.getters['permissions']
                // проверяем ограничения для данного роута
                const routePermission = to.meta.permission
                // если у роута есть ограничения проверяем есть ли у пользователя права, если все ок пускаем
                if (routePermission) {
                    if (permissions.includes(routePermission)) {
                        next()
                    } else next({name: 'cabinet'})
                } else next()
                // проверяем нужно ли по данному роуту доставать аккаунт из локалсторейджа
                if(to.meta.type && to.meta.method) {
                    // console.log('Есть тип и метод аккаунта: ', to.meta.type, to.meta.method)
                    const accountsByMethod = store.getters.accountsByMethods(to.meta.type, to.meta.method)
                    // console.log(accountsByMethod)
                    if (to.meta.localStorageName) {
                        // console.log('Указан локалсторейдж для аккаунта')
                        const account = checkLocalStorageAccount(to.meta.localStorageName, accountsByMethod)
                        if (account) {
                            // console.log('Аккаунт из локалсторейджа найден')
                            store.commit('setCurrentAccount', account)
                        } else {
                            // console.log('Аккаунт из локалсторейджа не найден', accountsByMethod)
                            store.commit('setCurrentAccount', accountsByMethod[0])
                        }
                    } else {
                        // console.log('Не указан локалсторейдж для аккауеиа')
                        store.commit('setCurrentAccount', store.commit('setCurrentAccount', accountsByMethod[0]))
                    }
                } else {
                    // console.log('Нету метода и типа аккаунта, сеттим первый из списка всех акков')
                    store.commit('setCurrentAccount', store.getters['accounts'][0])
                }
            } else next({name: 'login'})
        }
        next()
    })
    router.afterEach(async (to, from) => { 
        if (to.meta.title == 'Deposit') {
            await store.dispatch('getAccountsList', {req_path: to.name, limit: 30, offset_step: 30})
        }
        else if (to.meta.title != 'Google' && to.meta.title != 'History') {
            await store.dispatch('getAccountsList', {req_path: to.name})
        }
        if (to.meta.title == 'History') {
            await store.dispatch('getAccountsWithCardsList', {req_path: to.name})
        }
        await store.dispatch('getNecessaryData')
    })

    return router
}
