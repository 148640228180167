var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-operation" },
    [
      _vm.operationData
        ? _c(
            "yp-sheet",
            { staticClass: "yp-operation__content mt-0 yp-content" },
            [
              _c(
                "div",
                { staticClass: "yp-operation__main" },
                [
                  _c("yp-button", {
                    staticClass: "yp-operation__back-btn",
                    attrs: {
                      icon: "BackArrow",
                      ghost: "",
                      label: _vm.$t("nav.back"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("icon", {
                    staticClass: "yp-operation__icon",
                    class: [
                      { "icon--danger": _vm.operationData.status === "F" },
                      { "icon--warning": _vm.operationData.status === "P" },
                    ],
                    attrs: { name: _vm.settings.icon },
                  }),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        `${_vm.$t("operationPage.title")} ${_vm.settings.name}`
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-info" }, [
                    _vm._v(_vm._s(`#${_vm.operationData.uid}`)),
                  ]),
                  _vm._v(" "),
                  _vm.operationData.pan &&
                  !_vm.isCryptoOperation &&
                  _vm.operationData.method !== "PHN"
                    ? _c("yp-card-input", {
                        staticClass: "yp-operation__card-input",
                        attrs: {
                          "init-value": _vm.operationData.pan,
                          readonly: "",
                          label: "Card number:",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.operationData.pan &&
                  !_vm.isCryptoOperation &&
                  _vm.operationData.method == "PHN"
                    ? _c("yp-phone-input", {
                        staticClass: "yp-operation__card-input",
                        attrs: {
                          "init-value": _vm.operationData.pan,
                          readonly: "",
                          label: "Phone number:",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("yp-progress", {
                    staticClass: "yp-operation__progress",
                    attrs: {
                      parts: _vm.operationData.parts,
                      "progress-style": _vm.operationData.status,
                      total: _vm.operationData.amount,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "yp-operation__additional" }, [
                _c("div", { staticClass: "yp-operation__additional-item" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("operationPage.type")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.type))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "yp-operation__additional-item" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("amount")) + ":")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.operationData.amount) +
                        _vm._s(_vm.operationData.currency_suffix) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "yp-operation__additional-item" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("Fee")) + ":")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.operationData.fee) +
                        _vm._s(_vm.operationData.currency_suffix) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "yp-operation__additional-item" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("operationPage.date")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.getDateAndTime(_vm.operationData.created_at))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.operationData.comment
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operationPage.comment")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.operationData.comment))]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.haveTronscan && _vm.operationData.order_id
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("operationPage.tronscanLink")) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://tronscan.org/#/transaction/" +
                                  _vm.operationData.order_id,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(0, 12)
                                  ) +
                                  "..." +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(
                                      _vm.operationData.order_id.length - 6
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.haveEtherscan && _vm.operationData.order_id
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("operationPage.etherscanLink")) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: `https://etherscan.io/tx/0x${_vm.operationData.order_id}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(0, 16)
                                  ) +
                                  "..." +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(
                                      _vm.operationData.order_id.length - 4
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.haveTonviewer && _vm.operationData.order_id
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("operationPage.tonviewerLink")) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: `https://tonviewer.com/transaction/${_vm.operationData.order_id}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(0, 8)
                                  ) +
                                  "..." +
                                  _vm._s(
                                    _vm.operationData.order_id.slice(
                                      _vm.operationData.order_id.length - 8
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isCryptoOperation && _vm.origin
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operationPage.from")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.origin) + "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isCryptoOperation && _vm.receiver
                  ? _c(
                      "div",
                      { staticClass: "yp-operation__additional-item" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("operationPage.to")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n          " + _vm._s(_vm.receiver) + "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }