var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PayoutLayout", {
    attrs: {
      limits: _vm.currentAccount.limits.withdraw["Phone"],
      "accounts-method": "Phone",
      "have-file-input": true,
      file: _vm.file,
      "current-account": _vm.currentAccount,
      amount: _vm.amount,
      "is-form-sending": _vm.isFormSending,
      comment: _vm.comment,
    },
    on: {
      "amount-update": function ($event) {
        _vm.amount = $event
      },
      "set-file": function ($event) {
        _vm.file = $event
      },
      "update-comment": function ($event) {
        _vm.comment = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "sourceInput",
        fn: function () {
          return [
            _c("yp-phone-input", {
              on: {
                change: function ($event) {
                  _vm.receiver = $event
                },
                inputError: function ($event) {
                  _vm.receiver = null
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "cardActions",
        fn: function () {
          return [
            _c("yp-button", {
              attrs: {
                size: "xl",
                icon: "Keeper",
                variant: "primary-gradient",
                label: _vm.$t("payoutsPage.sendBtn"),
                disabled: _vm.submitDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit(false, "Phone")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "modal",
        fn: function () {
          return [
            _vm.showConfirmModal && _vm.confirmData
              ? _c(
                  "yp-modal",
                  {
                    attrs: {
                      divider: "",
                      "confirm-btn": _vm.$t(
                        "payoutsPage.modals.submitModal.confirmBtn"
                      ),
                      "cancel-btn": _vm.$t(
                        "payoutsPage.modals.submitModal.cancelBtn"
                      ),
                      title: _vm.$t("payoutsPage.modals.submitModal.title"),
                    },
                    on: {
                      closeModal: function ($event) {
                        _vm.showConfirmModal = false
                      },
                      confirm: function ($event) {
                        return _vm.onSubmit(true, "Phone")
                      },
                    },
                  },
                  _vm._l(_vm.confirmData, function (card, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        class: { "mt-4": _vm.confirmData.length > 1 },
                      },
                      [
                        _c("yp-phone-input", {
                          style: { width: "360px" },
                          attrs: { "external-val": _vm.receiver, readonly: "" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center mt-2" }, [
                          _vm._v(
                            "\n          Amount: " +
                              _vm._s(
                                card.data.amount +
                                  _vm.currentAccount.currency_code
                              ) +
                              "\n          "
                          ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: card.data.express,
                                  expression: "card.data.express",
                                },
                              ],
                              staticClass: "text-info font-weight-bold",
                            },
                            [
                              _vm._v(
                                "\n            Express transfer\n          "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(card.errors, function (error, key) {
                          return card.errors
                            ? _c(
                                "div",
                                {
                                  key: `error-${key}`,
                                  staticClass: "text-danger text-center mt-2",
                                },
                                _vm._l(error, function (message, key) {
                                  return _c("div", { key: `message-${key}` }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(message) +
                                        "\n          "
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "yp-divider" }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }