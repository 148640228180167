var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("h1", { staticClass: "login__title" }, [
        _vm._v(_vm._s(_vm.$t("loginPage.title"))),
      ]),
      _vm._v(" "),
      _vm.$route.meta.type === "loginViaPassword"
        ? _c(
            "div",
            { staticClass: "login__inputs" },
            [
              _c("yp-input", {
                attrs: {
                  rounded: "",
                  placeholder: _vm.$t("loginPage.loginInput"),
                  "external-val": _vm.username,
                },
                on: {
                  update: function ($event) {
                    _vm.username = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("yp-input", {
                attrs: {
                  rounded: "",
                  type: "password",
                  "external-val": _vm.password,
                  placeholder: _vm.$t("loginPage.codeInput"),
                },
                on: {
                  update: function ($event) {
                    _vm.password = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errors
        ? _c(
            "div",
            { staticClass: "mt-1" },
            _vm._l(_vm.errors, function (err, key) {
              return _c("yp-msg", { key: key, attrs: { msg: err, size: "lg" } })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.type === "loginViaPassword"
        ? _c(
            "div",
            { staticClass: "login__actions" },
            [
              _c("yp-button", {
                attrs: {
                  size: "xl",
                  label: _vm.$t("loginPage.login"),
                  variant: "gradient-outline",
                  disabled: !_vm.username || !_vm.password,
                },
                on: { click: _vm.onSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.type === "loginViaTg"
        ? _c("yp-button", {
            staticClass: "mt-5",
            attrs: {
              size: "xl",
              variant: "primary-gradient",
              icon: "Telegram",
              label: _vm.$t("loginPage.loginTelegram"),
            },
            on: { click: _vm.onClickTelegramLogin },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }