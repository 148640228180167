<template>
  <yp-loader v-if="accounts.length === 0" class="yp-app__loader"/>
  <div v-else class="yp-account-deposit">
      <h2 class="text-center">
          {{ $t('accountDeposit.title') }}
      </h2>
      <yp-sheet class="yp-account-deposit__content yp-content mx-auto">
        <yp-account-select
            @accountSelected="onAccountSelected"
            :external-val="usdtAccount || accounts[0]"
            :options="accounts"
        />
        <div v-show="!isCryptoMethod" class="yp-account-deposit__form mt-3">
            <yp-input
                type="number"
                :label="`Amount (${currentAccount.currency_code}):`"
                placeholder="0.00"
                ref="amountInput"
                :external-val="amount"
                @update="amount = $event"
                @inputError="amount = null"
             />
             <b-form-select
                class="yp-account-deposit__selector"
                v-model="method"
                :options="availableMethods"
            />
        </div>
        <yp-msg
            v-show="!isCryptoMethod"
            class="mt-2"
            variant="info"
            msg="Funds will be credited to your ruble account"
        />
       <div
           v-if="commission && !isCryptoMethod"
           class="yp-commission-wrap yp-additional-text"
       >
         {{ $t('commission') + commission }}
       </div>
        <div v-if="qrSrc" class="yp-account-deposit__qr-wrap">
          <yp-qr :src="`data:image/svg+xml;base64,${qrSrc}`"/>
          <div class="text-info">
            Please scan the qr code to continue payment
          </div>
        </div>
        <yp-card
            class="yp-account-deposit__card"
            v-if="method === 'ClientCard'"
            @update="cardData = $event"
            @error="cardData = null"
        />
        <div v-if="currentAccount?.methods?.deposit?.includes('Crypto')  && currentAccount?.fee?.deposit?.percent+1" class="yp-account-deposit__crypto">
          <yp-qr :src="`data:image/svg+xml;base64,${currentAccount.address_qr}`"/>
          <div class="mt-2">{{ currentAccount.address }}</div>
         <div class="yp-commission-wrap yp-additional-text mx-auto">
           {{ `${$t('commission')}: ${currentAccount.fee.deposit.percent}%` }}
           <div v-if="currentAccount.limits.deposit['Crypto']">
             {{ `Min transaction: ${currentAccount.limits.deposit['Crypto'].min_transaction + currentAccount.currency_code}` }}
           </div>
           <div v-if="currentAccount.limits.deposit['Crypto']">
             {{ `Max transaction: ${currentAccount.limits.deposit['Crypto'].max_transaction + currentAccount.currency_code}`}}
           </div>
         </div>
        </div>
          <yp-button
              v-show="!isCryptoMethod"
              class="yp-account-deposit__btn"
              :label="$t('accountDeposit.submit')"
              :disabled="isSubmitDisabled"
              @click="onSubmit"
          />
      </yp-sheet>
      <yp-modal
          v-if="isConfirmModalVisible && cardData"
          :title="$t('accountDeposit.modal.title')"
          :confirm-btn="$t('accountDeposit.modal.confirm')"
          :cancel-btn="$t('accountDeposit.modal.cancel')"
          :errors="errors"
          @confirm="onConfirm"
          @closeModal="isConfirmModalVisible = false"
      >
        <yp-card-input
            label="Card Number:"
            :init-value="cardData.number"
            readonly
        />
        <div class="mt-2">
          <span class="text-info">{{ $t("amount") }}:</span>
          {{ amount + currentAccount.currency_code }}
        </div>
       <div class="mt-2">
         <span class="text-info">{{ $t("commission") }}:</span>
         {{ commission }}
       </div>
      </yp-modal>
  </div>
</template>

<script>
import YpSheet from "@/components/ui/Sheet"
import YpInput from "@/components/ui/YpInput"
import YpMsg from "@/components/ui/YpMsg"
import YpCard from "@/components/ui/YpCard"
import YpCardInput from "@/components/ui/YpCardInput"
import YpButton from "@/components/ui/Button"
import YpQr from "@/components/ui/YpQr"
import YpLoader from "@/components/ui/YpLoader"

import {mapActions, mapGetters, mapMutations} from "vuex"
import YpAccountSelect from "@/components/ui/YpSelect"
import YpModal from "@/components/ui/YpModal.vue"

export default {
  components: {
    YpModal,
    YpAccountSelect,
    YpSheet,
    YpInput,
    YpMsg,
    YpCard,
    YpButton,
    YpQr,
    YpCardInput,
    YpLoader
  },
  data() {
    return {
      amount: null,
      methods: [
        {
          value: null,
          text: 'Select payment method',
        },
        {
          value: 'Crypto',
          text: 'Crypto'
        },
        {
          value: 'C2C',
          text: 'C2C',
        },
      ],
      cardData: null,
      isConfirmModalVisible: false,
      qrSrc: null,
      method: null,
      errors: [],
    }
  },
  watch: {
    currentAccount() {
      if (this.availableMethods.flatMap(({value}) => value).includes("Crypto")) this.method = "Crypto"
      else this.method = this.availableMethods[0].value
    }
  },
  computed: {
        ...mapGetters(['currentAccount', 'accountsByMethods', 'isMobile']),

        accounts() {
          return this.accountsByMethods("deposit", ["Crypto", "C2C"])
        },
        usdtAccount() {
          const acc = this.accountsByMethods("deposit", ["Crypto", "C2C"]).filter(acc => acc.currency_code === "USDT-TRC20")[0]
          this.setCurrentAccount(acc)
          return acc
        },
        isCryptoMethod() {
          return this.method === "Crypto"
        },
        availableMethods() {
          return this.methods.filter(method => this.currentAccount.methods.deposit.includes(method.value))
        },
        commission() {
          if (this.currentAccount?.fee?.deposit) {
            const feeRules = this.currentAccount.fee.deposit;
            const feePercent = feeRules.percent;
            const feeMinimal = feeRules.minimal;
            const feeFixed = feeRules.fixed;
            const currency = this.currentAccount.currency_symbol
            const amount = Number(this.amount)
            const minCommission = `(${feeMinimal ? feeMinimal: feePercent+"%"} + ${feeFixed}): ${feeMinimal + feeFixed + currency}`
            const commission =  `(${feePercent}% + ${feeFixed}): ${((amount / 100 * feePercent) + feeFixed).toFixed(2) + currency}`
            return (amount / 100 * feePercent) <= feeMinimal ?
                minCommission :
                commission
          }
          return ''
        },
        isSubmitDisabled() {
          let enabled = false;
          if (this.amount && this.method) {
            enabled = ["ClientCard", "SBP", "C2C"].indexOf(this.method) > -1
          }
          return !enabled;
        }
  },
  created() {
    if (this.availableMethods.flatMap(({value}) => value).includes("Crypto")) this.method = "Crypto"
    else this.method = this.availableMethods[0].value
  },
  methods: {
      ...mapMutations(["setCurrentAccount"]),
      ...mapActions('deposits', ['doDeposit']),

      onSubmit() {
          if (this.cardData) {
              this.isConfirmModalVisible = true;
          } else {
              return this.onConfirm();
          }
      },
      onConfirm() {
        let data = {
          amount: this.amount,
          method: this.method,
          account: this.currentAccount.pk
        }
        if (this.method === 'ClientCard' && this.cardData) {
          data.pan = this.cardData.number
          data.exp_date = this.cardData.date
          data.cvv = this.cardData.cvv
        }
        this.doDeposit(data).then(response => {
          if(["SBP", "C2C"].indexOf(data.method) > -1) {
            if (this.isMobile) {
              window.location.href = response.url
            } else {
              this.qrSrc = response.qr
            }
          } else if(data.method === "ClientCard") {
            if(response.state) {
              const formData = document.createElement('form')
              formData.setAttribute("method", "POST")
              formData.setAttribute("action", response.url)
              const obj = response.data
              for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                  const field = document.createElement("input")
                  field.setAttribute("type", "hidden")
                  field.setAttribute("name", key)
                  field.setAttribute("value", obj[key])
                  formData.appendChild(field)
                }
              }
              document.body.appendChild(formData)
              formData.submit()
            }
          }
        })
      },
      onAccountSelected() {
        const input = this.$refs.amountInput
        if (this.availableMethods.flatMap(({value}) => value).includes("Crypto")) this.method = "Crypto"
        else this.method = this.availableMethods[0].value
        if (input) this.$nextTick(() => {
          input.validate()
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.yp-account-deposit {
  margin: 0 auto;
  width: 100%;

  &__form {
      display: flex;
      gap: 20px;
  }
  &__card {
      margin: 20px auto 0;
      width: 380px;
  }
  &__crypto {
    text-align: center;
    margin-top: 20px;
  }
  &__qr-wrap {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    align-items: center;
  }
  &__btn {
    margin: 20px auto 0;
  }
}
</style>
