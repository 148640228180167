<template>
  <div class="yp-input">
    <b-form-input
        v-model="value"
        v-mask="'+# (###) ###-##-##'"
        class="yp-input__input"
        :class="[{'yp-input__input--dark': mode === 'dark' }]"
        placeholder="+7 (111) 111-11-11"
        :readonly="readonly"
        :style="{ padding: '33.5px 20px 11.5px' }"
        @input="$emit('change', value)"
    />
    <icon
        class="yp-input__icon_phone-number"
        :name="icon"
    />
    <div class="yp-input__label">Phone number:</div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from "@/components/ui/Icon";

export default {
  name: "YpPhoneInput",
  components: {Icon},
  props: {
    qiwi: Boolean,
    externalVal: String,
    readonly: Boolean,
    initValue: String,
  },
  data() {
    return {
      value: null,
    }
  },
  watch: {
    externalVal: {
      immediate: true,
      handler(val) {
        if (val) this.value = val
      }
    }
  },
  created() {
    if(this.initValue) this.value = this.initValue
  },
  computed: {
    ...mapGetters(['mode']),

    icon() {
      return this.qiwi ? 'Qiwi' : 'DevicePhone'
    }
  }
}
</script>
<style lang="scss">
.yp-input {
  &__icon {
    &_phone-number {
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translate(0, -50%);
      width: 27px;
      height: 33px;
    }
  }
}
</style>