<template>
  <b-img class="yp-qr" :src="src" alt="QR" />
</template>

<script>
export default {
  name: "YpQr",
  props: {
    src: String,
  }
}
</script>

<style lang="scss" scoped>
.yp-qr {
  background: #fff;
  border-radius: 10px;
}
</style>
