<template>
  <div class="yp-progress">
    <div class="yp-progress__wrap" :style="wrapColor">
      <template v-if="!parts">
        <div class="yp-progress__text">
          {{ text[0] + text.slice(1).toLowerCase() }}
        </div>
        <div
            class="yp-progress__ride"
            :style="{ backgroundColor: progressColor, width: `${current}%` }"
        />
      </template>
      <template v-else>
        <div class="yp-progress__text" :class="{ 'yp-progress__ride--parts': parts }">
          {{ rideText() }}
        </div>
        <div
            class="yp-progress__ride"
            :style="{ backgroundColor: progressColor, width: `${totalPercent()}%` }"
        />
      </template>
    </div>
    <div v-if="!parts" class="yp-progress__percent">
      {{ current + '%' }}
    </div>
  </div>
</template>

<script>
export default {
  name: "YpProgress",
  props: {
    max: {
      type: Number,
      default: () => 100,
    },
    progressStyle: {
      type: String,
      default: () => 'D'
    },
    text: String,
    current: {
      type: Number,
      default: () => 0,
    },
    parts: Array,
    total: Number,
  },
  computed: {
    // фикс артефакта css
    wrapColor() {
      return this.current < 100 ? { background: '#1E2A3E' } : {}
    },
    progressColor() {
      const success = '#61D974'
      const error = '#FF7272'
      const pending = '#FFC554'
      switch (this.progressStyle) {
        case 'P':
        case 'O':
        case 'U':
          return pending
        case 'C':
        case 'D':
          return success
        case 'F':
          return error
      }
    }
  },
  methods: {
    totalPercent() {
      let percent = 0
      this.parts.forEach(el => percent += el.amount / this.total * 100)
      percent = percent.toFixed(2)
      return percent
    },
    rideText() {
      const amount = this.total
      let percent = 0
      let count = 0
      this.parts.forEach(el => {
        percent += el.amount / amount * 100
        count += el.amount
      })
      percent = percent.toFixed(2)
      return `${count}/${amount} ${this.parts[0].currency_code} (${percent}%)`
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  &__wrap {
    height: 35px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 30px;
  }
  &__ride {
    position: absolute;
    left: 0;
    z-index: 1;
    height: 100%;
  }
  &__text {
    width: 100%;
    z-index: 2;
    position: absolute;
    font-weight: 600;
    line-height: 35px;
    text-align: center;
  }
  &__percent {
    color: $info;
    font-size: 12px;
  }
}
</style>
