<template>
  <div class="yp-input">
    <icon v-if="icon" class="yp-input__icon" :class="{ 'yp-input__icon--append': appendIcon }" :name="icon"/>
    <div class="yp-input--wrap">
      <b-form-input
          v-model="value"
          v-mask="mask"
          :autofocus="autoFocus"
          class="yp-input__input"
          :class="[
          { 'yp-input__input--rounded': rounded },
          {'yp-input__input--dark': mode === 'dark' },
          {'yp-input__input--error': errors.length > 0 },
        ]"
          :disabled="disabled"
          :type="type"
          :step="step"
          :readonly="readonly"
          :placeholder="dynamicPlaceholder"
          :style="padding"
          @input="debouceHandler"
      />
      <yp-button v-if="isMaxMode && maxValue !== 0" class="yp-input__btn" :style="padding" ghost label="MAX" @click="onClickMaxBtn"/>
    </div>
    <div v-if="errors.length" class="yp-input__info">
      <icon id="info" name="Info"/>
      <b-tooltip class="yp-input__tooltip" target="info">
        <div class="font-weight-bold text-danger">
          Amount entered incorrectly.
        </div>
        <div class="font-weight-bold text-info" v-for="(error, key) in errors" :key="key">
          {{ error }}
        </div>
      </b-tooltip>
    </div>
    <div v-if="label" class="yp-input__label">{{ label }}</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import Icon from "@/components/ui/Icon"
import {debounce} from "utils/helpers"
import YpButton from "@/components/ui/Button.vue"
import {calcCommission, round} from "@/utils/math"


export default {
  name: "YpInput",
  components: {YpButton, Icon},
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    renderNegativeAmount: {
      type: Boolean,
      default: () => false
    },
    rules: {
      type: Array,
      default: []
    },
    email: Boolean,
    autoFocus: Boolean,
    appendIcon: Boolean,
    externalVal: [String, Number],
    zeroLength: [Number, String],
    type: String,
    step: String,
    isMaxMode: {
      type: Boolean,
      default: () => false,
    },
    feeRules: Object,
    readonly: Boolean,
    icon: String,
    limits: Object,
    maxValue: Number,
    rounded: Boolean,
    label: String,
    placeholder: String,
    mask: String,
    initValue: String,
  },
  data() {
    return {
      value: "",
      errors: [],
    }
  },
  watch: {
    externalVal: {
      immediate: true,
      handler(val) {
        if (val) this.value = val
      }
    },
  },
  created() {
    if(this.initValue) this.value = this.initValue
    this.debouceHandler = debounce((val) => {
      if (this.type === 'number') val = Number(val)
      if (this.email) {
        this.validateEmail(val)
      } else {
        this.validate(val)
      }
    }, 10)
  },
  beforeUnmount() {
    this.debouceHandler.cancel()
  },
  computed: {
    ...mapGetters(["mode", "currentAccount"]),

    dynamicPlaceholder() {
      return this.zeroLength ? '0.' + '0'.repeat(Number(this.zeroLength)) : this.placeholder
    },
    padding() {
      if (this.label) return {padding: '29.5px 20px 11.5px'}
      if (this.icon) return {paddingLeft: '50px'}
    }
  },
  methods: {
    onClickMaxBtn() {
      // console.log(this.limits)
      const digits = this.limits && this.limits.multiplicity === 1 ? 0 : 2
      if(this.feeRules && this.maxValue !== 0) {
        if (this.limits && this.limits.max_transaction) {
          if (this.maxValue >= this.limits.max_transaction) {
            this.value = parseFloat(round((this.maxValue / (1 + 0.01 * this.feeRules.percent)) - this.feeRules['fixed'], digits) - 0.01).toFixed(digits)
            const commission = calcCommission(this.feeRules, this.limits.max_transaction)
            this.value = this.limits.max_transaction - commission
          } else {
            this.value = parseFloat(round((this.maxValue / (1 + 0.01 * this.feeRules.percent)) - this.feeRules['fixed'], digits) - 0.01).toFixed(digits)
          }
        } else {
          this.value = parseFloat(round((this.maxValue / (1 + 0.01 * this.feeRules.percent)) - this.feeRules['fixed'], digits) - 0.01).toFixed(digits)
        }
      } else if (this.maxValue !== 0) {
        this.value = this.maxValue
      }
      this.debouceHandler(this.value)
    },
    validateEmail(email) {
      this.errors = []
      const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!emailFormat.test(email)) {
        this.errors.push('You have entered an invalid email address!')
        this.$emit('inputError')
      } else this.$emit('update', email)
    },
    validate(val = this.value) {
      this.errors = []
      // this.rules.forEach((item) => {
      //   if(item(val) !== true) {
      //     this.errors.push(item(val))
      //   }
      // });
      if (this.limits) {
        if (val < this.limits.min_transaction) {
          this.errors.push(`Min transaction: ${this.limits.min_transaction}`)
        } else if (val > this.limits.max_transaction) {
          this.errors.push(`Max transaction: ${this.limits.max_transaction}`)
        }
        if ((val%this.limits.multiplicity) > 1e-3) {
          this.errors.push(`Amount must be a multiple of ${this.limits.multiplicity}`)
        }
      }
      if (this.type === 'number') {
        if (val <= 0 && !this.renderNegativeAmount) this.errors.push('The amount must be positive value')
        if (this.zeroLength) {
          const chAfterDot = val.toString().split('.')
          if (chAfterDot[1] && chAfterDot[1].length > this.zeroLength) this.errors.push(`Maximum number of characters after dot: ${this.zeroLength}`)
        }
        if (this.maxValue !== undefined && Number(this.maxValue) >= 0 && val > Number(this.maxValue)) {
          this.errors.push(`The entered value is greater than the available: ${this.maxValue}`)
        }
      }
      if (this.errors.length === 0) this.$emit('update', val)
      else this.$emit('inputError')
    },
  }
}
</script>
