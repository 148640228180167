var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "auth-link" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isDone && !_vm.err && !_vm.isTfa,
            expression: "!isDone && !err && !isTfa",
          },
        ],
        staticClass: "auth-link__loading",
      },
      [
        _c("yp-loader"),
        _vm._v(" "),
        _c("h2", [_vm._v("\n      Log in...\n    ")]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isTfa
      ? _c(
          "div",
          { staticClass: "auth-link__tfa" },
          [
            _c("yp-input", {
              attrs: {
                label: _vm.$t("loginPage.tfaPlaceholder"),
                mask: "### ###",
                placeholder: "000 000",
              },
              on: {
                update: function ($event) {
                  _vm.tfaKey = $event
                },
                inputError: function ($event) {
                  _vm.tfaKey = null
                },
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.tfaErrors, function (err, key) {
              return _c("yp-message", {
                key: key,
                attrs: { msg: err, size: "lg" },
              })
            }),
            _vm._v(" "),
            _c("yp-button", {
              attrs: {
                variant: "primary-gradient",
                label: _vm.$t("submit"),
                size: "xl",
              },
              on: { click: _vm.onTfaSubmit },
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.err
      ? _c(
          "div",
          { staticClass: "container text-center" },
          [
            _c("icon", {
              attrs: { name: "LoginBlock", width: "120", height: "75" },
            }),
            _vm._v(" "),
            _c("h1", { staticClass: "mt-5" }, [
              _vm._v(_vm._s(_vm.$t("loginPage.loginError"))),
            ]),
            _vm._v(" "),
            _c("yp-button", {
              staticClass: "mx-auto mt-5",
              attrs: {
                variant: "gradient-outline",
                label: "Update",
                size: "xl",
              },
              on: { click: _vm.onUpdate },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }