<template>
  <PayoutLayout
      :limits="currentAccount.limits.withdraw['Phone']"
      :accounts-method="'Phone'"
      :have-file-input="true"
      :file="file"
      :current-account="currentAccount"
      :amount="amount"
      :is-form-sending="isFormSending"
      :comment="comment"
      @amount-update="amount = $event"
      @set-file="file = $event"
      @update-comment="comment = $event"
  >
    <template v-slot:sourceInput>
      <yp-phone-input
          @change="receiver = $event"
          @inputError="receiver = null"
      />
    </template>
    <template v-slot:cardActions>
      <yp-button
          size="xl"
          icon="Keeper"
          variant="primary-gradient"
          :label="$t('payoutsPage.sendBtn')"
          @click="onSubmit(false, 'Phone')"
          :disabled="submitDisabled"
      />
    </template>
    <template v-slot:modal>
      <yp-modal
          v-if="showConfirmModal && confirmData"
          divider
          :confirm-btn="$t('payoutsPage.modals.submitModal.confirmBtn')"
          :cancel-btn="$t('payoutsPage.modals.submitModal.cancelBtn')"
          :title="$t('payoutsPage.modals.submitModal.title')"
          @closeModal="showConfirmModal = false"
          @confirm="onSubmit(true, 'Phone')"
      >
        <div
            v-for="(card, key) in confirmData"
            :key="key"
            :class="{ 'mt-4': confirmData.length > 1 }"
        >
          <yp-phone-input
              :external-val="receiver"
              readonly
              :style="{ width: '360px' }"
          />
          <div class="text-center mt-2">
            Amount: {{ card.data.amount + currentAccount.currency_code }}
            <p
                class="text-info font-weight-bold"
                v-show="card.data.express"
            >
              Express transfer
            </p>
          </div>
          <div
              v-for="(error, key) in card.errors"
              v-if="card.errors"
              :key="`error-${key}`"
              class="text-danger text-center mt-2"
          >
            <div v-for="(message, key) in error" :key="`message-${key}`">
              {{ message }}
            </div>
          </div>
          <div class="yp-divider"/>
        </div>
      </yp-modal>
    </template>
  </PayoutLayout>
</template>

<script>
import YpButton from "@/components/ui/Button";
import YpPhoneInput from "@/components/ui/YpPhoneInput";
import YpModal from "@/components/ui/YpModal";
import PayoutLayout from "@/components/payouts/PayoutLayout";

import PayoutMixin from '@/mixins/payout';

export default {
  name: "PayoutPhone",
  mixins: [PayoutMixin],
  components: {
    YpPhoneInput,
    YpButton,
    YpModal,
    PayoutLayout
  },
  data() {
    return {}
  },
  computed: {
    submitDisabled() {
      return this.file ? false : this.settings.WITHDRAW_ENABLED === '0' || !this.receiver || !this.amount
    }
  },
}
</script>
