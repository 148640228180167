export default {
    loginPage: {
        title: "Personal account",
        login: "Log in",
        loginInput: "Username",
        codeInput: "Enter the password",
        getCode: "Get a password",
        loginTelegram: "Log in via Telegram",
        loginError: "The authorized data is outdated. You need to update in the bot",
        tfaPlaceholder: "Tfa key",
        errors: {
            login: {
                title: "An error occurred during authorization",
                text: "Check the entered data"
            }
        }
    },
    errorTemplate: {
        title: "Service",
        text: "is temporarily",
        footer: "unavailable",
    },
    email: "Email",
    submit: "Submit",
    cancelBtn: "Cancel",
    loaderText: "Please wait...",
    commentsBtn: {
        add: 'Add comment',
        remove: "Remove comment",
        change: "Change comment",
    },
    commission: "Commission",
    debt: "Debt",
    total: "Total",
    balance: "Balance",
    welcomePage: {
        title: "Welcome to YeezyPay!",
        text: "To top up your account with alternative method please contact the support:",
        supportText: "24/7 support",
        telegram: "Our Telegram:"
    },
    snackbarMessages: {
        success: "Operation success",
        error: "Operation error",
        serverError: "Server response error",
        newOperation: "New operation",
        statusChange: "Operation status changed",
        accountChange: "Account amount changed",
        googleAccCreated: "Google account created",
        virtualCards: {
            create: {
                success: "Virtual card created successfully",
                failed: "Virtual card was created failed"
            },
            block: {
                success: "Virtual card was blocked success",
                failed: "Virtual card was blocked failed"
            },
            topUp: {
                success: "Virtual card top up success",
                failed: "Virtual card top up failed"
            },
            restore: {
                success: "Virual card restore success",
                failed: "Virtual card restore failed"
            }
        },
        refund: "Google account refunded",
        update: "Google account topped up",
        invite: "Invite sent successfully",
        restore: "Google account restored"
    },
    nav: {
        home: "Home",
        exchange: "Exchange",
        history: "History",
        mobile: "Top up mobile",
        google: "Google Ad",
        payout: "Payouts",
        profile: "Profile",
        "card-to-card": "Card to card",
        "account-deposit": "Deposit",
        "virtual-cards": "Cards",
        "wallet-crypto": "Crypto Wallets",
        cards: "Cards",
        back: "Back",
        api: "Api",
    },
    cardInputLabel: "Card Number",
    phoneInputLabel: "Phone Number",
    cryptoInputLabel: "Wallet Address",
    cardExpireDate: "Date",
    cardCvv: "Cvv",
    cardOwner: "CardOwner",
    accountSelect: {
        empty: "Select account for filter"
    },
    footer: {
        text: "(c) 2024 YeezyPay.io, All rights reserved", agreement: "User Agreement", policy: "Privacy Policy"
    },
    payoutsPage: {
        title: "Send payouts to",
        cardModeTitle: "Withdrawal to bank cards",
        qiwiModeTitle: "Withdrawal to QIWI",
        cryptoModeTitle: "Withdrawal to",
        phoneModeTitle: "Withdrawal by phone number",
        mobileModeTitle: "Withdrawal to mobile number",
        expressCheck: "Express transfer",
        sendTokens: "Send Tokens",
        commentBtn: "Add Comment",
        cardNumBtn: "Card number",
        commission: "Commission",
        addPaymentBtn: "Add File",
        sendBtn: "Send Money",
        uploadFile: {
            new: "Upload file",
            another: "Upload another file",
            error: "File upload error! The file is corrupt or has an incorrect layout"
        },
        snacks: {
            error: {
              title: 'Payout failed',
            },
            order: {
                title: 'file ordered successfully'
            }
        },
        modals: {
            submitModal: {
                title: "Confirm money transfer",
                confirmBtn: "Confirm",
                cancelBtn: "Cancel payment",
                totalSuccess: "Ready to send",
                amountPayments: "Amount of payments",
            }, verify: {
                title: "Verifying information…",
            }, successPayout: "Transfer can be made!", fileLayout: {
                title: "File layout description",
                text: "Text files are supported.\n" + "Information for each payment should be on a separate line.\n" + "Payment parameters must be\n" + "separated by \";\"." + "Payment parameters:\n" + "Card number - 16 digits without spaces.\n" + "Only cards issued in Russia are permitted.\n" + "Amount - a whole number (multiple of 500).\n" + "Minimum amount - 1000 RUB.\n" + "Total amount of payments must not exceed the balance!\n" + "Comments - optional parameter"
            }
        }
    },
    profilePage: {
        title: "User Profile",
        twoFactor: "Enable two-factor authentication",
        sessions: {
            title: "Active Sessions",
            system: "Operating System",
            browser: "Browser",
            action: "Action",
        },
        accountInfo: {
            title: "Account Info",
            phone: "Phone number",
            username: "Username"
        },
        settingsSection: {
            title: "Settings",
            lightTheme: "Light Theme",
            darkTheme: "Dark Theme",
            lang: "Select language",
        },
        twoFactorModal: {
            title: "Setting up two-factor authentication",
            firstStep: "Open the Authenticator app and scan QR code",
            secondStep: "Enter the code generated by the Authenticator",
            lastStep: "Confirm it",
            confirmBtn: "Confirm",
            cancelBtn: "Cancel",
        },
        disableTwoFactorModal: {
            title: "Are you sure?",
            confirmBtn: "Yes",
            cancelBtn: "No"
        },
    },
    topUpMobilePage: {
        title: "Top up mobile"
    },
    operationType: {
        deposit: "Deposit", withdraw: "Withdraw", exchange: "Exchange", card_topup: "Card Topup", card_open: "Card Open"
    },
    amount: "Amount",
    historyPage: {
        title: "Payout history", searchPlaceholder: "Card Number / Transaction id / Amount", fileModal: {
            title: "Download transaction history",
            text: "Format",
            infoText: "Download filtered transaction list",
            confirmBtn: "Order file",
            cancelBtn: "Close",
        }, filterModal: {
            confirmBtn: "Apply",
            cancelBtn: "Reset",
            periodLabel: "Period",
            periodFrom: "from",
            periodTo: "to",
            paymentTypeLabel: "Payment Type",
            paymentStatusLabel: "Payment Status"
        }, tabs: {
            deposits: "Deposits", payouts: "Payouts", completed: "Completed", canceled: "Canceled",
        },
        accountAmountByDate: "Account amount by date",
        pleaseSelectAccount: "Please select account",
    },
    exchangePage: {
        title: "Transferring funds between accounts",
        fromLabel: "From",
        toLabel: "To",
        amountLabel: "Amount",
        rates: "Transfer rates",
        confirmBtn: "Transfers",
        swapBtn: "Swap accounts",
        confirmModal: {
            title: "Confirmation of the exchange between accounts",
            confirmBtn: "Confirm",
            cancelBtn: "Cancel",
            give: "Give",
            get: "Get",
            byRate: "By rate"
        }
    },
    walletCryptoPage: {
        title: "Manage Crypto Wallets",
        create: {
            title: "Create New Wallet",
            selectWalletType: "Select Currency",
            nameInputPlaceholder: "Account Name",
            addBtn: "Create",
            actionResult: {
                success: "Created a new wallet: ",
                failure: "Error creating a wallet."
            } 
        }
    },
    googleAdPage: {
        title: "Google AD",
        addNewAccount: "Add new google account",
        addBtn: "Add",
        topUp: "Top up",
        cancelBtn: "Cancel",
        startNewCampaign: "Start new campaign",
        refundAcc: "Refund",
        refunded: "Refunded",
        closed: "Closed",
        submit: "Submit",
        restoreAcc: "Restore",
        sendInvite: "Send invite",
        restored: "Restored",
        draft: "Account is being created",
        banned: "Account banned",
        showClosedAccounts: "Show closed google accounts",
        autoLoad: "Auto loading accounts",
        refundModal: {
            title: "Account refund confirmation",
            confirmBtn: "Close and refund",
            text: "Account will be closed",
            cancelBtn: "Cancel",
            balance: "Balance",
            account: "Google account",
        },
        emailModal: {
            title: 'Change email for account group',
            confirmBtn: 'Change email',
            cancelBtn: 'Cancel',
            text: 'The email of each account in this group will be changed'
        },
        createAccountModal: {
            title: "Create new google account", confirmBtn: "Create",
        },
        withOld: "With old",
    },
    operationPage: {
        title: "Operation",
        type: "Payment type",
        date: "Payment creation date",
        emptyList: "Operation list is empty",
        comment: 'Comment',
        status: {
            progress: "in progress", complete: "is completed", failed: "is failed",
        },
        tronscanLink: "Tronscan link",
        tonviewerLink: "TonViewer link",
        fee: "Comission",
        from: "From",
        to: "To",
    },
    cardToCard: {
        title: "Transfer from card to card", form: {
            fromTitle: "From", toTitle: "To", sendMoney: "Send money",
        }
    },
    virtualCards: {
        title: "My cards list",
        empty: "Card list is empty",
        addBtn: "Add New",
        createCard: "Create a new virtual card",
        modal: {
            title: 'Create virtual card',
            confirm: 'Create',
            cancel: 'Cancel',
            cardCount: 'Count of cards',
        }
    },
    virtualCard: {
        topUpModal: {
            title: "Top up Virtual Card",
            confirm: "Top up",
            cancel: "Cancel",
        },
        blockModal: {
            title: "Block Virtual Card",
            confirm: "Block",
            cancel: "Cancel",
        },
        restoreModal: {
            title: "Restore Virual Card",
            confirm: "Restore",
            cancel: "Cancel",
        }
    },
    virtualCardDetails: {
        title: "Card Info",
    },
    accountDeposit: {
        title: "Account Deposit",
        submit: "Top up account",
        modal: {
            title: "Create deposit",
            confirm: "Confirm",
            cancel: "Cancel",
        }
    },
    ypCustomInput: {
        invalidInput: "Invalid input",
        defaultPlaceholder: "Text Input"
    }
}
