<template>
  <PayoutLayout
      :limits="currentAccount.limits.withdraw['Cards']"
      :accounts-method="'Card'"
      :have-file-input="true"
      :file="file"
      :current-account="currentAccount"
      :amount="amount"
      :is-form-sending="isFormSending"
      :comment="comment"
      @amount-update="amount = $event"
      @set-file="file = $event"
      @update-comment="comment = $event"
  >
    <template v-slot:sourceInput>
      <yp-card-input
          :disabled="!!file"
          :label="`${$t('payoutsPage.cardNumBtn')}:`"
          @update="receiver = $event"
          @error="receiver = null"
      />
    </template>
    <template v-slot:cardActions>
      <yp-button
          size="xl"
          icon="Keeper"
          variant="primary-gradient"
          :label="$t('payoutsPage.sendBtn')"
          @click="onSubmit"
          :disabled="submitDisabled"
      />
    </template>
    <template v-slot:modal>
      <yp-modal
          v-if="showConfirmModal && confirmData"
          divider
          :confirm-btn="$t('payoutsPage.modals.submitModal.confirmBtn')"
          :cancel-btn="$t('payoutsPage.modals.submitModal.cancelBtn')"
          :title="$t('payoutsPage.modals.submitModal.title')"
          @closeModal="showConfirmModal = false"
          @confirm="onSubmit(true)"
      >
        <div class="yp-payout-card__confirm-modal-body">
          <div
              v-for="(card, key) in confirmData"
              :key="key"
              :class="{ 'mt-4': key > 0 }"
          >
            <yp-card-input
                class="yp-payout-card__input"
                label="Card number:"
                :init-value="receiver"
                readonly
            />
            <div class="text-center mt-2">
              Amount: {{ card.data.amount + currentAccount.currency_code }}
              <p class="text-info font-weight-bold" v-show="card.data.express">
                Express transfer
              </p>
            </div>
            <div
                v-for="(error, key) in card.errors"
                v-if="card.errors"
                :key="`error-${key}`"
                class="text-danger text-center mt-2"
            >
              <div v-for="(message, key) in error" :key="`message-${key}`">
                {{ message }}
              </div>
            </div>
            <div class="text-success text-center" v-if="!Object.keys(card.errors).length">
              {{ $t('payoutsPage.modals.successPayout') }}
            </div>
            <div class="yp-divider"/>
          </div>
        </div>
        <div v-if="confirmTotal" class="yp-payout-card__confirm-modal-footer text-center mt-4">
          <div>
            {{ `${$t('payoutsPage.modals.submitModal.totalSuccess')}: ${confirmTotal.count}/${confirmData.length}` }}
          </div>
          <div>
            {{
              `${$t('payoutsPage.modals.submitModal.amountPayments')}: ${confirmTotal.amount + currentAccount.currency_code}`
            }}
          </div>
        </div>
      </yp-modal>
    </template>
  </PayoutLayout>
</template>

<script>
import Icon from "@/components/ui/Icon";
import YpTextarea from "@/components/ui/YpTextarea";
import YpButton from "@/components/ui/Button";

import {mapActions, mapGetters} from 'vuex'
import YpModal from "@/components/ui/YpModal";
import YpUpload from "@/components/ui/YpUpload";

import PayoutMixin from '@/mixins/payout'
import YpAccountSelect from "@/components/ui/YpSelect";
import PayoutLayout from "@/components/payouts/PayoutLayout";
import YpCardInput from "@/components/ui/YpCardInput";

export default {
  name: "PayoutCard",
  components: {
    YpAccountSelect,
    YpUpload,
    YpModal,
    YpTextarea,
    Icon,
    YpButton,
    YpCardInput,
    PayoutLayout
  },
  mixins: [PayoutMixin],
  computed: {
    submitDisabled() {
      return this.file ? false : this.settings.WITHDRAW_ENABLED === '0' || !this.receiver || !this.amount
    },
  },
  methods: {
    ...mapActions({}),
  }
}
</script>
