<template>
  <div
    class="auth-layout"
    :style="{background: `linear-gradient(180deg, rgba(14, 20, 30, 0) 73.52%, #0E141E 100%), url(${require('@/assets/img/login-background.png').default}`}"
  >
    <yp-notification/>
    <div class="auth-layout__sphere auth-layout__sphere--left"></div>
    <div class="auth-layout__sphere auth-layout__sphere--primary-blur" />
    <div
        class="auth-layout__ball"
        :style="{backgroundImage: `url(${require('@/assets/img/BallBgBig.png').default})`}"
    />
    <logo big />
    <router-view v-if="isDataLoaded && isAuthCheckCompleted" :style="{ zIndex: 100 }" />
    <yp-loader class="auth-layout__loader" v-else />
    <div class="auth-layout__plug"></div>
    <Footer dark />
  </div>
</template>

<script>
import Logo from "@/components/common/Logo";
import Footer from "@/components/common/Footer";
import Login from "@/views/Login";
import {mapActions, mapMutations, mapGetters} from "vuex";
import YpLoader from "@/components/ui/YpLoader";
import YpNotification from "@/components/ui/YpNotification";


export default {
  name: "AuthLayout",
  components: {YpNotification, YpLoader, Login, Footer, Logo },
  data() {
    return {
      isAuthCheckCompleted: false,
    }
  },
  watch: {
    isAuth: {
      immediate: true,
      handler(val) {
        if(val) {
          if(this.$route.name === 'invite') {
            this.setClientGroup(this.$route.params.groupId)
          }
          if(this.$route.name !== 'auth') {
            this.$router.push({name: 'home'})
          }
        }
        setTimeout(() => {
          this.isAuthCheckCompleted = true;
        }, 3000)
      }
    }
  },
  computed: {
    ...mapGetters(['isDataLoaded', 'isAuth']),
  },
  created() {
    this.setMode('dark')
    this.getContext().then(response => {
      if(response) this.setDataLoaded(true)
    })
  },
  methods: {
    ...mapMutations(['setDataLoaded']),
    ...mapActions(['setMode', 'getContext', 'setClientGroup'])
  }
}
</script>

<style lang="scss" scoped>
.auth-layout {
  padding-top: 80px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  &__loader {
    margin-top: auto;
  }
  &__sphere {
    height: 50vh;
    filter: blur(150px);
    background: $primary;
    opacity: 0.1;
    position: absolute;

    @media (max-width: 1200px) {
      display: none;
    }

    &--left {
      left: 0;
      top: 30vh;
      width: 40vw;
    }
    &--primary-blur {
      bottom: 0;
      right: 0;
      width: 300px;
    }
  }
  &__plug {
    height: 70px;
  }
  &__ball {
    margin-left: auto;
    width: 290px;
    height: 370px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    position: absolute;
    right: 0;
    bottom: 0;

    @media (max-width: 1200px) {
      height: 300px;
    }
  }
}
</style>
