var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-input" },
    [
      _c("b-form-input", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: "+# (###) ###-##-##",
            expression: "'+# (###) ###-##-##'",
          },
        ],
        staticClass: "yp-input__input",
        class: [{ "yp-input__input--dark": _vm.mode === "dark" }],
        style: { padding: "33.5px 20px 11.5px" },
        attrs: { placeholder: "+7 (111) 111-11-11", readonly: _vm.readonly },
        on: {
          input: function ($event) {
            return _vm.$emit("change", _vm.value)
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("icon", {
        staticClass: "yp-input__icon_phone-number",
        attrs: { name: _vm.icon },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "yp-input__label" }, [_vm._v("Phone number:")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }