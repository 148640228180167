var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-checkbox",
    {
      staticClass: "yp-checkbox",
      class: [{ "yp-checkbox--btn": _vm.button }, { active: _vm.value }],
      attrs: { switch: _vm.switchMode, button: _vm.button },
      on: {
        change: function ($event) {
          return _vm.$emit("change", {
            isChecked: _vm.value,
            value: _vm.returnValue,
          })
        },
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }