var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-card" },
    [
      _c("div", { staticClass: "yp-card__top-block" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "yp-card__info-row",
          class: { "yp-card--blocked": _vm.cardData.status !== "A" },
        },
        [
          _c("div", { staticClass: "yp-card__info" }, [
            _vm._v("\n        Card number: " + _vm._s(_vm.number) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "yp-card__info" }, [
            _vm._v("\n        CVV: " + _vm._s(_vm.cvv) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "yp-card__info" }, [
            _vm._v(
              "\n        Static: " +
                _vm._s(this.cardData.credentials.secret_otp_password) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "yp-card__info" }, [
            _vm._v("\n        Expires: " + _vm._s(_vm.date) + "\n      "),
          ]),
          _vm._v(" "),
          _c("yp-button", {
            ref: "card-copy-btn",
            staticClass: "yp-card__copy-btn",
            attrs: {
              disabled: _vm.cardData.status !== "A",
              ghost: "",
              "is-icon-btn": "",
              icon: "CopyClip",
            },
            on: {
              click: function ($event) {
                return _vm.copyToClipboard({
                  date: _vm.date,
                  number: _vm.number,
                  cvv: _vm.cvv,
                })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "yp-card__bottom-block" }, [
        _c(
          "div",
          {
            staticClass: "yp-card__balance",
            class: { "yp-card--blocked": _vm.cardData.status !== "A" },
          },
          [
            _c("div", [_vm._v("Balance: ")]),
            _vm._v(" "),
            _c("div", { staticClass: "yp-card__balance-amount" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.currency.symbol) +
                  " " +
                  _vm._s(_vm.balance) +
                  "\n        "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "yp-card__actions" },
          [
            _c("yp-input", {
              staticClass: "yp-card__amount-input",
              class: { "yp-card--blocked": _vm.cardData.status !== "A" },
              attrs: {
                type: "string",
                label: "Name:",
                "external-val": _vm.cardData.name,
                placeholder: "Name",
              },
              on: {
                update: function ($event) {
                  _vm.cardData.name = $event
                },
                inputError: function ($event) {
                  _vm.cardData.name = null
                },
              },
            }),
            _vm._v(" "),
            _c("yp-input", {
              staticClass: "yp-card__amount-input",
              class: { "yp-card--blocked": _vm.cardData.status !== "A" },
              attrs: {
                type: "number",
                label: `Amount (${_vm.currentAccount.currency_code}):`,
                "external-val": _vm.topUpAmount,
                "max-value":
                  _vm.currentAccount.amount_with_overdraft ||
                  _vm.currentAccount.amount,
                "min-value":
                  _vm.currentAccount.limits.card_topup["min_transaction"],
                limits: _vm.currentAccount.limits.card_topup,
                "fee-rules": _vm.currentAccount.fee.card_topup,
                "is-max-mode": "",
                placeholder: "0.00",
              },
              on: {
                update: function ($event) {
                  _vm.topUpAmount = $event
                },
                inputError: function ($event) {
                  _vm.topUpAmount = null
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "yp-card-flex-name" },
              [
                _c("yp-button", {
                  class: { "yp-card--blocked": _vm.cardData.status !== "A" },
                  attrs: {
                    icon: "Plus",
                    ghost: _vm.$store.getters.isMobile,
                    disabled: _vm.cardData.status !== "A" || !_vm.topUpAmount,
                    label: _vm.$store.getters.isMobile ? "" : "Top up",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isTopUpModalVisible = true
                    },
                  },
                }),
                _vm._v(" "),
                _c("yp-button", {
                  class: { "yp-card--blocked": _vm.cardData.status !== "A" },
                  attrs: {
                    label: "Block Card",
                    disabled: _vm.cardData.status !== "A",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isBlockModalVisible = true
                    },
                  },
                }),
                _vm._v(" "),
                _c("yp-button", {
                  class: { "yp-card--restore": _vm.cardData.status !== "A" },
                  attrs: {
                    label: "Restore Card",
                    disabled: _vm.cardData.status !== "C",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isRestoreModalVisible = true
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isBlockModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("virtualCard.blockModal.title"),
                "confirm-btn": _vm.$t("virtualCard.blockModal.confirm"),
                "cancel-btn": _vm.$t("virtualCard.blockModal.cancel"),
                errors: _vm.topUpErrors,
              },
              on: {
                confirm: _vm.onBlockConfirm,
                closeModal: function ($event) {
                  _vm.isBlockModalVisible = false
                },
              },
            },
            [
              _c("yp-card-input", {
                attrs: { "init-value": _vm.number, readonly: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v("Current balance:"),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.balance + _vm.currentAccount.currency_code) +
                    "\n      "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isRestoreModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("virtualCard.restoreModal.title"),
                "confirm-btn": _vm.$t("virtualCard.restoreModal.confirm"),
                "cancel-btn": _vm.$t("virtualCard.restoreModal.cancel"),
                errors: _vm.topUpErrors,
              },
              on: {
                confirm: _vm.onRestoreConfirm,
                closeModal: function ($event) {
                  _vm.isRestoreModalVisible = false
                },
              },
            },
            [
              _c("yp-card-input", {
                attrs: { "init-value": _vm.number, readonly: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTopUpModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("virtualCard.topUpModal.title"),
                "confirm-btn": _vm.$t("virtualCard.topUpModal.confirm"),
                "cancel-btn": _vm.$t("virtualCard.topUpModal.cancel"),
                errors: _vm.topUpErrors,
              },
              on: {
                confirm: _vm.onTopUpConfirm,
                closeModal: function ($event) {
                  _vm.isTopUpModalVisible = false
                },
              },
            },
            [
              _c("yp-card-input", {
                attrs: { "init-value": _vm.number, readonly: "" },
              }),
              _vm._v(" "),
              _c("yp-input", {
                staticClass: "mt-2",
                attrs: {
                  type: "number",
                  label: `Amount (${_vm.currentAccount.currency_code}):`,
                  "external-val": _vm.topUpAmount,
                  "max-value":
                    _vm.currentAccount.limits.card_topup["max_transaction"],
                  "min-value":
                    _vm.currentAccount.limits.card_topup["min_transaction"],
                  limits: _vm.currentAccount.limits.card_topup,
                  placeholder: "0.00",
                },
                on: {
                  update: function ($event) {
                    _vm.topUpAmount = $event
                  },
                  inputError: function ($event) {
                    _vm.topUpAmount = null
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }