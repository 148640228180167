<template>
  <div class="yp-new-card">
    <div class="yp-new-card__form">
      <yp-input
          type="number"
          label="Count of cards:"
          placeholder="default count 1"
          :external-val="count"
          :min-value="1"
          :max-value="10"
          @update="count = $event"
      />
      <yp-input
          type="text"
          label="Name:"
          :external-val="name"
          placeholder="Name"
          @update="name = $event"
      />
      <yp-input
          type="number"
          :label="`Amount (${currentAccount.currency_code})`"
          :external-val="amount"
          :limits="currentAccount.limits.card_open"
          placeholder="0.00"
          :max-value="currentAccount.amount"
          @update="amount = $event"
      />
      <yp-button
          :disabled="!amount || !count"
          class="flex-shrink-0"
          :label="btnText"
          size="xl"
          variant="primary-gradient"
          @click="isModalVisible = true"
      />
    </div>
    <div v-show="commission" class="yp-new-card__commission yp-additional-text">
      {{ `${$t('commission')} (${currentAccount.fee.card_open.percent}%) ${commission + currentAccount.currency_code}`  }}
    </div>
    <yp-modal
        v-if="isModalVisible"
        :title="$t('virtualCards.modal.title')"
        :confirm-btn="$t('virtualCards.modal.confirm')"
        :cancel-btn="$t('virtualCards.modal.cancel')"
        :errors="errors"
        @confirm="onConfirm"
        @closeModal="isModalVisible = false"
    >
      <div>
        <span class="text-info">{{ $t('virtualCards.modal.cardCount') + ": " }}</span>
        {{ count }}
      </div>
      <div>
        <span class="text-info">{{ $t('amount') + ": " }}</span>
        {{ amount + currentAccount.currency_code }}
      </div>
      <div>
        <span class="text-info">{{ $t('commission') + ": " }}</span>
        {{ commission + currentAccount.currency_code }}
      </div>
      <div>
        <span class="text-info">{{ $t("total") + ": " }}</span>
        {{ total + currentAccount.currency_code }}
      </div>
    </yp-modal>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button.vue"
import YpInput from "@/components/ui/YpInput.vue"
import YpModal from "@/components/ui/YpModal.vue"

import { mapGetters, mapActions } from "vuex"

export default {
  name: "NewCard",
  components: {YpModal, YpInput, YpButton},
  data() {
    return {
      amount: null,
      count: 1,
      isModalVisible: false,
      isProcessing: false,
      errors: {},
      name: null
    }
  },
  watch: {
    isModalVisible: {
      handler(val) {
        if (val && this.currentAccount.amount < this.total) {
          this.errors = {
            amount: 'You don\'t have enough funds to complete the operation',
          }
        } else {
          this.errors = {}
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentAccount"]),

    btnText() {
      return this.count > 1 ? `Create ${this.count} cards` : "Create card"
    },
    total() {
      return this.count > 1 ?
          Number(this.amount) * this.count + Number(this.commission) :
          Number(this.amount) + Number(this.commission)
    },
    commission() {
      const commission = this.amount / 100 * this.currentAccount.fee['card_open'].percent + this.currentAccount.fee['card_open'].fixed
      return this.amount ?
          this.count > 1 ? commission * this.count : commission :
          null
    },
  },
  methods: {
    ...mapActions("cards", ["createCard"]),

    async onConfirm() {
      this.isModalVisible = false
      if (this.count > 1) {
        for(let i = 0; i < this.count; i++) {
          const response = await this.createCard({
            amount: this.amount,
            account_id: this.currentAccount.pk,
            name: this.name
          })
        }
      } else {
        this.$store.commit('snackbar/SET_SNACK', {
          title: "Operating",
          text: "Card is opening",
          duration: 10000,
        })
        const response = await this.createCard({
          amount: this.amount,
          account_id: this.currentAccount.pk,
          name: this.name
        })
      }
      this.$emit('update')
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-new-card {
  &__form {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    @media (max-width: 790px) {
      flex-direction: column;
    }
  }
  &__commission {
    margin-top: 20px;
    width: 290px;
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    padding: 5px 0;
    background: $light;
    text-align: center;
    border-radius: 20px;

    @media (max-width: 790px) {
      width: 100%;
    }
  }
}
</style>