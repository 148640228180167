<template>
  <b-checkbox
      v-model="value"
      class="yp-checkbox"
      :switch="switchMode"
      :button="button"
      :class="[{ 'yp-checkbox--btn' : button }, { 'active': value }]"
      @change="$emit('change', { isChecked: value, value: returnValue })"
  >
    {{ label }}
  </b-checkbox>
</template>

<script>
export default {
  name: "YpCheckbox",
  props: {
    label: String,
    switchMode: Boolean,
    button: Boolean,
    returnValue: [String, Number, Object],
    externalVal: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    externalVal: {
      immediate: true,
      handler(val) {
        this.value = val
      }
    }
  },
  data() {
    return {
      value: false,
    }
  },
}
</script>

<style lang="scss">
.custom-control-label {
  line-height: 23px;
}
</style>
<style lang="scss" scoped>
.yp-checkbox {
  color: $info;
  font-weight: 600;
  font-size: 14px;
}
</style>
