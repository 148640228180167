<template>
  <div class="yp-card">
    <div v-show="headTitle" class="yp-card__head">
      <div class="text-uppercase">
        {{ headTitle }}
      </div>
      <icon :name="icon"/>
    </div>
    <div class="yp-card__body">
      <yp-card-input
          :label="$t('cardInputLabel')"
          @update="cardData.number = $event"
          @error="cardData.number = null"
      />
      <template v-if="cardType === 'from'">
        <div class="yp-card__flex">
          <yp-input
              :label="`${$t('cardExpireDate').toUpperCase()}: `"
              mask="##/##"
              placeholder="00/00"
              @update="cardData.date = $event"
              @inputError="cardData.date = null"
            />
          <yp-input
              :label="`${$t('cardCvv').toUpperCase()}: `"
              mask="###"
              placeholder="000"
              @update="cardData.cvv = $event"
              @inputError="cerdData.cvv = null"
          />
        </div>
        <!-- <yp-input :label="`${$t('cardOwner')}: `" placeholder="IVAN IVANOV" /> -->
      </template>
      <template v-if="cardType === 'to'">
        <yp-input type="number" :label="`${$t('amount')}: `" placeholder="0.00" />
        <div class="yp-card__commission yp-additional-text text-primary">
          {{ $t('payoutsPage.commission') + '(2%):  20руб' }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import YpInput from "@/components/ui/YpInput";
import YpCardInput from "@/components/ui/YpCardInput";

export default {
  name: "YpCard",
  components: {YpInput, Icon, YpCardInput},
  props: {
    cardType: {
      type: String,
      default: () => 'from',
    },
    headTitle: String,
    icon: String,
  },
  data() {
    return {
      cardData: {
        number: null,
        date: null,
        cvv: null,
      }
    }
  },
  watch: {
    cardData: {
      deep: true,
      handler(val) {
        let countValid = 0
        for(let key in val) {
          if(val.hasOwnProperty(key) && val[key]) {
            countValid += 1
          }
        }
        if (countValid === 3) {
          this.$emit('update', val)
        } else {
          this.$emit('error')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yp-card {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 110px;
    height: 25px;
    background: $secondary;
    border-radius: 10px 10px 0 0;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
  }
  &__commission {
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 13px;
    font-weight: 600;
  }
  &__body {
    background: $black;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 255px;
  }
  &__flex {
    display: flex;
    gap: 15px;
  }
}
</style>
