var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-app-wrapper",
      class: [_vm.themeClasses],
      attrs: { id: "app" },
    },
    [
      _c("notifications", {
        attrs: {
          group: "internal_errors",
          classes: "yp-notification",
          position: _vm.position,
        },
      }),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasNecessaryData && _vm.$route.meta.authRequired,
              expression: "!hasNecessaryData && $route.meta.authRequired",
            },
          ],
          staticClass: "yp-app__loader__wrap",
        },
        [
          _c("logo"),
          _vm._v(" "),
          _c("yp-loader", {
            staticClass: "yp-app__loader yp-app__loader_main",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }