var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "yp-input" },
    [
      _vm.icon
        ? _c(_setup.Icon, {
            staticClass: "yp-input__icon",
            class: { "yp-input__icon--append": _vm.appendIcon },
            attrs: { name: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-input--wrap" },
        [
          _c("b-form-input", {
            staticClass: "yp-input__input",
            class: {
              "yp-input__input--rounded": _vm.rounded,
              "yp-input__input--dark": _vm.mode === "dark",
              "yp-input__input--error": _vm.errors.length > 0,
            },
            style: _vm.padding,
            attrs: {
              value: _vm.value,
              autofocus: _vm.autoFocus,
              type: "text",
              disabled: _vm.disabled,
              step: _vm.step,
              readonly: _vm.readonly,
              placeholder:
                _vm.placeholder || _vm.$t("ypCustomInput.defaultPlaceholder"),
            },
            on: {
              input: _setup.onInput,
              focus: function ($event) {
                _setup.showToSelect = true
              },
              blur: _setup.onBlur,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toSelect,
                  expression: "toSelect",
                },
              ],
              staticClass: "yp-input-select__wrapper",
              class: { showToSelect: _setup.showToSelect },
            },
            [
              _c(
                "ul",
                {
                  staticClass: "yp-input-select__list",
                  class: { showToSelect: _setup.showToSelect },
                },
                _vm._l(_setup.toSelectDynamic, function (toSelectProp) {
                  return _c(
                    "li",
                    { staticClass: "yp-input-select__list-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "propsList",
                          class: { showPropsList: _setup.showToSelect },
                          on: {
                            click: function ($event) {
                              _vm.value = toSelectProp
                              _setup.onInput(_vm.value)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(toSelectProp) +
                              "  \n                    "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errors.length
        ? _c(
            "div",
            { staticClass: "yp-input__info" },
            [
              _c(_setup.Icon, { attrs: { id: "info", name: "Info" } }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                { staticClass: "yp-input__tooltip", attrs: { target: "info" } },
                [
                  _c("div", { staticClass: "font-weight-bold text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.invalidInputHint ||
                            _vm.$t("ypCustomInput.invalidInput")
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function (error, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "font-weight-bold text-info" },
                      [
                        _vm._v(
                          "\n            " + _vm._s(error) + "\n            "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("div", { staticClass: "yp-input__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }