import { render, staticRenderFns } from "./YpMsg.vue?vue&type=template&id=19d0045e&scoped=true"
import script from "./YpMsg.vue?vue&type=script&lang=js"
export * from "./YpMsg.vue?vue&type=script&lang=js"
import style0 from "./YpMsg.vue?vue&type=style&index=0&id=19d0045e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d0045e",
  null
  
)

export default component.exports