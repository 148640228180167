<template>
  <div
      class="google-ad-item"
      :class="[{ 'google-ad-item--debt': hasDebt }, { 'opacity-03': disableItem && !hasDebt }]"
  >
    <div class="google-ad-item__main" :class="{ 'google-ad-item__main--closed': disableItem && !hasDebt }">
      <div class="google-ad-item__info">
        <div class="google-ad-item__info-text">
          <div
              v-if="itemData.external_id && itemData.external_id !== itemData.name"
              class="google-ad-item__id text-info yp-xs-text"
          >
            {{ externalId }}
          </div>
          <div> {{ itemText }} </div>
        </div>
        <div v-if="itemData.status === 'P'">
          {{ refundedAmount }}
        </div>
<!--        <div v-if="comment" class="google-ad-item__comment">{{ comment }}</div>-->
        <div v-show="itemData.external_id" class="google-ad-item__actions">
          <yp-button
              v-show="itemData.status !== 'P'"
              :label="$t('googleAdPage.sendInvite')"
              :disabled="isActionsDisabled || disableItem"
              @click="doAction('invite')"
          />
          <yp-button
              v-show="!disableItem"
              icon="Close"
              variant="danger"
              :label="$t('googleAdPage.refundAcc')"
              :disabled="isActionsDisabled || disableItem || Boolean(countdownValue)"
              :timer="countdownValue"
              @click="isModalVisible = true"
          />
          <yp-button
              v-show="disableItem"
              icon="Reload"
              :label="$t('googleAdPage.restoreAcc')"
              :disabled="isActionsDisabled || itemData.status !== 'P' || Boolean(countdownValue)"
              :timer="countdownValue"
              @click="doAction('restore')"
          />
        </div>
      </div>
      <div>Balance updated: {{ last_time_updated }}</div>
      <div class="google-ad-item__form" v-show="itemData.external_id">
        <yp-input
            type="number"
            :label="`Amount (${itemData.currency_code}):`"
            :placeholder="`min: ${minValue}`"
            :external-val="amount"
            :disabled="disableItem && !hasDebt"
            :render-negative-amount="true"
            class="google-ad-item__input"
            @update="amount = $event"
        />
        <yp-textarea
            class="google-ad-item__comment-input"
            placeholder="Enter a comment"
            outlined
            :external-value="comment"
            :max-length="50"
            @update="comment = $event"
        />
        <div class="google-ad-item__actions">
          <yp-button
              icon="Checkbox"
              :label="$t('submit')"
              :disabled="isActionsDisabled || isSubmitDisabled"
              @click="doAction()"
          />
        </div>
      </div>
    </div>
    <div v-show="commission" class="google-ad-item__commission yp-additional-text">
      {{ $t('commission') + ` (${itemData.fee.withdraw.percent}%) : ` + commission + itemData.currency_code }}
    </div>
    <div v-show="commission" class="google-ad-item__commission yp-additional-text" :class="{'google-ad-item__error-submit': amountWithCommission > currentAccount.amount}">
      {{ `Required balance: ${amountWithCommission}` }}
    </div>
    <yp-modal
        v-if="isModalVisible"
        :title="$t('googleAdPage.refundModal.title')"
        :confirm-btn="$t('googleAdPage.refundModal.confirmBtn')"
        :cancel-btn="$t('googleAdPage.refundModal.cancelBtn')"
        @confirm="doAction('refund')"
        @closeModal="isModalVisible = false"
    >
      <div>
        <span class="text-info">
          {{ $t('googleAdPage.refundModal.account') + ": " }}
        </span>
        {{ itemData.external_id }}
      </div>
      <div>
        <span class="text-info">
          {{ $t('googleAdPage.refundModal.balance') + ": " }}
        </span>
        {{ itemData.google_amount }}
        {{ itemData.currency_code }}
      </div>
      <div class="text-danger text-center">
        {{ $t('googleAdPage.refundModal.text') }}
      </div>
    </yp-modal>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button"
import YpInput from "@/components/ui/YpInput"
import YpModal from "@/components/ui/YpModal"
import {isActualTimestamp} from "utils/dateConverter"

import {mapActions, mapGetters} from "vuex"
import moment from "moment"
import YpTextarea from "@/components/ui/YpTextarea.vue";

export default {
  name: "GoogleAdItem",
  components: {YpTextarea, YpModal, YpInput, YpButton},
  props: {
    id: String,
    maxValue: Number,
    updatedAt: String,
  },
  data() {
    return {
      amount: null,
      countdownValue: 0,
      comment: null,
      isModalVisible: false,
      isActionsDisabled: false,
    }
  },
  // created() {
  //   this.isActionsDisabled = true
  //   this.getGoogleAccountBalance({
  //     kind: "G",
  //     currencies: this.itemData.currency_code,
  //     pk: this.itemData.pk
  //   })
  //   this.isActionsDisabled = false
  // },
  watch: {
    itemData: {
      immediate: true,
      deep: true,
      async handler(val) {
        this.checkTimestamp(val)
        if(val.description) this.comment = val.description
      },
    }
  },
  computed: {
    ...mapGetters("google", ["currentAccount","googleAccById", "partLoadingState"]),

    last_time_updated() {
      const startDate = new Date(this.updatedAt);
      const currentDate = new Date();
      var timeDiff = currentDate - startDate;

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      timeDiff -= days * 1000 * 60 * 60 * 24;
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      timeDiff -= hours * 1000 * 60 * 60;
      const minutes = Math.floor(timeDiff / (1000 * 60));
      timeDiff -= minutes * 1000 * 60;
      const seconds = Math.floor(timeDiff / 1000);

      if (days) return days + " days ago"
      if (hours) return hours + " hours ago"
      if (minutes) return minutes + " minutes ago"
      if (seconds) return seconds + " seconds ago"
    },
    updateDisabled() {
      return !this.amount || Number(this.amount) + Number(this.commission) > Number(this.maxValue)
    },
    minValue() {
      return this.itemData.google_amount >= 0 ?
          this.currentAccount.google_amount_min || this.$store.getters['google/minGoogleRate'] :
          Math.abs(this.itemData.google_amount)
    },
    externalId() {
      return this.itemData.external_id.replace(/\B(?=(\d{7})(?!\d))|\B(?=(\d{4})(?!\d))/g, "-")
    },
    itemData() {
      return this.googleAccById(this.id)
    },
    commission() {
      return this.amount ? (this.amount / 100 * this.itemData.fee.withdraw.percent).toFixed(2) : null
    },
    amountWithCommission() {
      return Number(this.amount) + Number(this.commission)
    },
    hasDebt() {
      return this.itemData.google_amount < 0
    },
    isSubmitDisabled() {
      return (Number(this.amount) + Number(this.commission) > this.currentAccount.amount) || (Number(this.amount) < 1 || (Number(this.amount) < this.minValue))
    },

    refundedAmount() {
      return this.itemData.google_refunded_amount ?
          `${this.itemData.google_refunded_amount + this.itemData.currency_code} ${this.$t("googleAdPage.refunded")}` :
          null
    },
    itemText() {
      const amount = this.itemData.google_amount + this.itemData.currency_code
      const defaultText = `${this.$t("balance")}: ${amount}`
      const debtText = `${this.$t("debt")}: ${amount}`
      let status = ""
      switch (this.itemData.status) {
        case "D":
          status = this.$t("googleAdPage.draft")
          break;
        case "P":
          status = this.$t("googleAdPage.closed")
          break;
        case "R":
          status = this.$t("googleAdPage.restored")
          break;
        case "B":
          status = this.$t("googleAdPage.banned")
          break;
      }
      return this.itemData.google_amount < 0 ?
          debtText + " " + status :
          defaultText + " " + status
    },
    disableItem() {
      const allowedStatuses = ["A", "D", "R"]
      return !allowedStatuses.includes(this.itemData.status)
    }
  },
  methods: {
    ...mapActions(["getAccounts"]),
    ...mapActions("google", [
        "updateGoogleAccountData",
        "setAccountData",
        "getGoogleAccountBalance",
        "setAccountDataById",
        "sendInvite"
    ]),

    async doAction(action) {
      this.isActionsDisabled = true
      this.isModalVisible = false
      let data
      if (action && action !== 'invite') {
        data = {
          pk: this.itemData.pk,
          action
        }
      } else if (this.amount && !action) {
        data = {
          pk: this.itemData.pk,
          new_amount: this.amount,
          currency_code: this.itemData.currency_code
        }
      }
      const accData = {
        comment: this.comment,
        account_id: this.itemData.pk
      }
      if(action === 'invite') this.sendInvite(this.itemData.pk)
      else {
        await this.setAccountDataById(accData).then(response => {
          this.$store.commit('snackbar/SET_SNACK', {
            title: response.data.message,
            duration: 5000,
          })
        })
        if (action || this.amount) await this.updateGoogleAccountData(data)
      }
      const dataForUpdate = {
        kind: "G",
        currencies: this.itemData.currency_code,
        pk: this.itemData.pk
      }
      this.amount = null
      await this.getAccounts(dataForUpdate)
      if (!action) {
        await this.getGoogleAccountBalance(dataForUpdate)
        this.isActionsDisabled = false
      }
      // this.isActionsDisabled = false
      this.amount = null
    },
    checkTimestamp(val) {
      if (val.status === "P" && val.refund_created_at) {
        if (isActualTimestamp(val.refund_created_at)) this.calcCountdown(val.refund_created_at)
      } else if (val.status !== "P" && val.restore_created_at) {
        if (isActualTimestamp(val.restore_created_at)) this.calcCountdown(val.restore_created_at)
      }
    },
    calcCountdown: function (timestamp) {
      const context = this
      const time = moment(moment.unix(timestamp)).add(7, "minutes")
      const interval = setInterval(() => {
        const currentTime = moment()
        const duration = moment.duration(time.diff(currentTime))
        if (duration.asSeconds() <= 0) {
          clearInterval(interval)
          context.countdownValue = null
        } else {
          context.countdownValue = `${duration.minutes()}m: ${duration.seconds()}s`
        }
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.google-ad-item {
  border: 1px solid $border-color;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;

  &--debt {
    color: $danger;
    border-color: $danger;
  }
  &__main {
    display: flex;
    //align-items: center;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 790px) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    //&--closed {
    //  flex-direction: row;
    //  justify-content: space-between;
    //  align-items: center;
    //}
  }

  &__id {
    width: 100px;
  }

  &__input {
    width: 160px;
    //margin-left: auto;
    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 790px) {
      gap: 10px;
      flex-direction: column;
    }
  }

  &__info-text {
    display: flex;
    gap: 10px;
  }

  &__form {
    //margin-left: auto;
    display: flex;
    gap: 15px;
    align-items: center;

    @media (max-width: 790px) {
      flex-direction: column;
      width: 100%;
      margin-left: 0;
      gap: 10px;
    }
  }

  //&__comment {
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  max-width: 200px;
  //  white-space: nowrap;
  //}

  &__comment-input {
    width: 350px;

    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__commission {
    margin-top: 20px;
    margin-left: auto;
    width: 290px;
    color: $primary;
    font-weight: 600;
    font-size: 13px;
    padding: 5px 0;
    background: $light;
    text-align: center;
    border-radius: 20px;

    @media (max-width: 790px) {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    gap: 20px;
    margin-left: auto;
    flex-shrink: 0;

    @media (max-width: 790px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__error-submit {
    border: 2px solid #f1263e;
    color: #f1263e;
  }
}
</style>
