var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "yp-card-to-card" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("cardToCard.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "yp-sheet",
        { staticClass: "yp-card-to-card__content yp-content" },
        [
          _c(
            "div",
            { staticClass: "yp-card-to-card__cards" },
            [
              _c("yp-card", {
                staticClass: "yp-card-to-card__card",
                attrs: {
                  "head-title": _vm.$t("cardToCard.form.fromTitle"),
                  icon: "FlagPlaceholder",
                },
              }),
              _vm._v(" "),
              _c("icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$store.getters.isMobile,
                    expression: "!$store.getters.isMobile",
                  },
                ],
                attrs: { name: "ChevronRight" },
              }),
              _vm._v(" "),
              _c("yp-card", {
                staticClass: "yp-card-to-card__card",
                attrs: {
                  "head-title": _vm.$t("cardToCard.form.toTitle"),
                  icon: "FlagPlaceholder",
                  "card-type": "to",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("yp-button", {
            staticClass: "yp-card-to-card__action",
            attrs: {
              variant: "primary-gradient",
              size: "xl",
              label: _vm.$t("cardToCard.form.sendMoney"),
              disabled: "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }