var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-input yp-input--crypto" },
    [
      _c("b-form-input", {
        staticClass: "yp-input__input",
        style: [{ padding: "29.5px 45px 11.5px 45px" }],
        attrs: {
          placeholder: _vm.placeholder,
          autofocus: _vm.autoFocus,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
        },
        on: {
          input: function ($event) {
            return _vm.debounceHandler()
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("icon", {
        staticClass: "yp-input__logo",
        attrs: { name: _vm.iconName },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "yp-input__label" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("cryptoInputLabel")) + ":\n  "),
      ]),
      _vm._v(" "),
      _vm.errors.length
        ? _c(
            "div",
            { staticClass: "yp-input__info" },
            [
              _c("icon", { attrs: { id: "infoAddress", name: "Info" } }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "yp-input__tooltip",
                  attrs: { target: "infoAddress" },
                },
                [
                  _c("div", { staticClass: "font-weight-bold text-danger" }, [
                    _vm._v("\n        Amount entered incorrectly.\n      "),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function (error, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "font-weight-bold text-info" },
                      [_vm._v("\n        " + _vm._s(error) + "\n      ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }