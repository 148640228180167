import i18n from "@/plugins/i18n";

export default api => ({
    namespaced: true,
    state: {
        operationsList: [],
        selectedOperations: [],
        reportsList: [],
        repeatOperation: {},
        isRepeat: false,
    },
    getters: {
        lastOperation: state => state.operationsList[state.operationsList.length - 1],
        operations: state => {
            const operations = state.operationsList
            const filteredArr = []
            //формируем массив в красивом формате: дата, операции
            operations.forEach((el, i, arr) => {
                if(i === 0) {
                    filteredArr.push({
                        date: el.date,
                        list: [el]
                    })
                }
                if (i > 0) {
                    if (el.date === arr[i - 1].date) {
                        filteredArr.find(item => item.date === el.date).list.push(el)
                    } else {
                        filteredArr.push({
                            date: el.date,
                            list: [el]
                        })
                    }
                }
            })
            return filteredArr
        },
        repeatOperation: state => state.repeatOperation,
        isRepeat: state => state.isRepeat,
        reports: state => state.reportsList,
        selectedOperations: state => state.selectedOperations
    },
    mutations: {
        SET_REPORTS(state, payload) {
            state.reportsList = payload
        },
        SET_OPERATIONS(state, payload) {
            state.operationsList = payload
        },
        ADD_OPERATIONS(state, payload) {
            const operations = [...state.operationsList]
            state.operationsList = Array.prototype.concat(operations, payload)
        },
        ADD_OPERATION(state, payload) {
            const indexOperation = state.operationsList.findIndex(el => el.pk === payload.pk)
            if (indexOperation === -1) state.operationsList.unshift(payload)
            else state.operationsList.splice(indexOperation, 1, payload)
        },
        SET_SELECTED_OPERATION(state, payload) {
            state.selectedOperations.push(payload)
        },
        REMOVE_SELECTED_OPERATION(state, payload) {
            const index = state.selectedOperations.findIndex(el => el === payload)
            if (index !== -1) state.selectedOperations.splice(index, 1)
        },
        SET_REPEAT_OPERATION(state, payload) {
            state.repeatOperation = payload
        },
        SET_IS_REPEAT_OPERATION(state, payload) {
            state.isRepeat = payload
        }
    },
    actions: {
        setRepeatOperation({ commit }, payload) {
            commit('SET_REPEAT_OPERATION', payload)
            commit('SET_IS_REPEAT_OPERATION', true)
        },
        sendPayout({ commit }, payload) {
            return api
                .post('client/finance/payouts/', payload)
                .then(response => {
                    if (!response.data.state)
                    commit("snackbar/SET_SNACK", {
                        title: i18n.t("payoutsPage.snacks.error.title"),
                        type: "error",
                        text: `Reason: ${response.data.message || ""}`,
                        duration: 5000,
                    }, { root: true })
                    return response.data
                })
                .catch(err => {
                    commit("snackbar/SET_SNACK", {
                        title: i18n.t("payoutsPage.snacks.error.title"),
                        type: "error",
                        duration: 5000,
                    }, { root: true })
                })
        },
        getReports({commit}) {
            return api
                .get('client/reports/list')
                .then(response => commit('SET_REPORTS', response.data.items))
        },
        getReportByName(_, name) {
            return api
                .get(`client/reports/list/${name}`)
                .then(response => response.data)
        },
        orderReport({commit}, fileFilters) {
            return api
                .post('client/reports', fileFilters)
                .then(response => {
                    if(response.status) {
                        commit("snackbar/SET_SNACK", {
                            title: i18n.t("payoutsPage.snacks.order.title"),
                            duration: 5000,
                        }, { root: true })
                    }
                })
        },
        getPayouts({commit}, query = null) {
            return api
                .get('client/payouts/', {params: query})
                .then(response => {
                    // для того чтоб не создавать дубликаты и вызывать нужную мутацию, если count есть
                    // значит пользователь скролит стр и нам нужно добавить новые операции к уже имеющимся
                    if(response.data.items) {
                        if (query.offset) commit('ADD_OPERATIONS', response.data.items)
                        else commit('SET_OPERATIONS', response.data.items)
                        return { status: response.data.state, length: response.data.items.length }
                    } else if(response.data.errors) {
                        commit('SET_OPERATIONS', [])
                        const error = new Error()
                        error.message = 'method "get operations" return error, check filter'
                        throw error
                    } else {
                        return response.data
                    }
                })
        },
        getOperation({ commit }, id) {
            commit('setDataLoaded', false, {root: true})
            return api
                .get(`client/payouts/${id}`)
                .then(response => {
                    commit('setDataLoaded', true, {root: true})
                    return response.data.item
                })
        }
    }
});
