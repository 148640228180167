<template>
  <div class="yp-reports-page">
    <h2 class="text-center">
      Reports
    </h2>
    <yp-sheet class="yp-reports-page__content yp-content">
      <h3 class="text-center">Click to download </h3>
      <div class="yp-reports-page__list">
<!--        <yp-button-->
<!--            v-for="(report, key) in reports"-->
<!--            :key="key"-->
<!--            ghost-->
<!--            :label="report.link.slice(report.link.lastIndexOf('/')+1, report.link.length)"-->
<!--            @click="onReportClick(report.link.slice(report.link.lastIndexOf('/')+1, report.link.length))"-->
<!--        />-->
        <a v-for="(report, key) in reports" :key="key" :href="report.link">
          {{ report.link.slice(report.link.lastIndexOf('/')+1, report.link.length) }}
        </a>
      </div>
    </yp-sheet>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import YpSheet from "@/components/ui/Sheet.vue";
import YpButton from "@/components/ui/Button.vue";

export default {
  name: "Reports",
  components: {YpButton, YpSheet},
  computed: {
    ...mapGetters("payouts", ["reports"])
  },
  methods: {
    ...mapActions("payouts", ["getReports", "getReportByName"]),

    async onReportClick(name) {
      // const file = await this.getReportByName(name)
      // const blob = new Blob([file])
      // const link = document.createElement('a')
      // link.href = URL.createObjectURL(blob)
      // link.download = `history.${name.substr(name.indexOf('.'), name.length - 1)}`
      // link.click()
      // URL.revokeObjectURL(link.href)
    }
  },
  created() {
    this.getReports()
  }
}
</script>

<style lang="scss" scoped>
.yp-reports-page {
  margin: 0 auto;

  &__list {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
</style>