<template>
  <div class="yp-select" v-click-outside="onClickedOutside">
    <div
        class="yp-select__selector"
        :class="{ 'yp-select__selector--open': isOptionsVisible }"
        @click="onSelectorClicked"
    >
      <template v-if="selectedOption">
        <icon  class="yp-select__icon" :name="selectedOption.icon" width="20" height="20"></icon>
        <div class="yp-select__text"><span>{{getOptionText(selectedOption)}}</span></div>
      </template>
      <div v-else>{{ placeholder }}</div>
      <icon v-show="options.length > 1" class="yp-select__chevron" name="ChevronDown"/>
    </div>
    <div v-show="isOptionsVisible && options.length > 1" class="yp-select__options">
      <div
          v-for="(option) in options"
          v-if="option.pk !== value"
          class="yp-select__option"
          :key="option.pk"
          @click="onOptionSelected(option)"
      >
        <icon class="yp-select__icon" :name="option.icon"></icon>
        <div class="yp-select__text"><span>{{getOptionText(option)}}</span></div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import Icon from "@/components/ui/Icon";
import { ref, computed, onMounted } from 'vue';
import { onClickOutside as vClickOutside } from '@/utils/directives';

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: "Select one"
  },
  value: {
    required: false,
    default: null
  }
});

const emit = defineEmits(['input']);

onMounted(() => {
  if (selectedOption.value != null) {
    return;
  }
  if (props.options.length < 1) {
    return;
  }
  onOptionSelected(props.options[0]);
});

const selectedOption = computed(() => {
  if (props.value == null) {
    return null;
  }
  for (const option of props.options) {
    if (option.pk == props.value) {
      return option;
    }    
  }
  return null;
});

const isOptionsVisible = ref(false);

const onOptionSelected = ({ pk }) => {
  console.log(pk);
  emit('input', pk);
  isOptionsVisible.value = false;
};

const getOptionText = ({ pk, text }) => {
  return text || pk;
};

const onSelectorClicked = () => {
  if (
      (selectedOption.value == null && props.options.length > 0) 
      || 
      (props.options.length > 1)
  ) {
    isOptionsVisible.value = !isOptionsVisible.value;
  }
};

const onClickedOutside = () => {
  isOptionsVisible.value = false;
};

</script>

<style lang="scss" scoped>
.yp-select {
  font-weight: 700;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  position: relative;

  &__icon {
    stroke: transparent;
    position: absolute;
    left: 20px;
    fill: #fff;
  }

  &__chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    stroke: #fff;
    fill: #fff;
  }

  &__text {
    color: #fff;
    user-select: none;
  }

  &__options {
    z-index: 99;
    background: $black;
    border-radius: 0 0 10px 10px;
    position: absolute;
    width: 100%;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 400px;
    overflow: overlay;
  }

  &__option, &__selector {
    position: relative;
    border-radius: 10px;
    padding: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 65px;
    background: linear-gradient(75.21deg, #0E141E 0%, #394A65 100%);
  }

  &__selector {
    &--open {
      border-radius: 10px 10px 0 0;
    }
  }

  &__option {
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}
</style>
