<template>
  <section class="yp-profile">
    <h2 class="text-center">
      {{ $t('profilePage.title') }}
    </h2>
    <yp-sheet class="yp-profile__content yp-content mx-auto">
      <div v-if="account" class="mb-2">
        <h3>{{ $t('profilePage.accountInfo.title') + ': ' }}</h3>
        <div>{{ $t('profilePage.accountInfo.phone') + ': ' + account.phone }}</div>
        <div>{{ 'Telegram name: ' + account.telegram_username }}</div>
        <div>{{ 'Login: ' + account.username }}</div>
      </div>
      <yp-checkbox
          class="mt-3"
          :label="$t('profilePage.twoFactor')"
          switch-mode
          :external-val="tfaCheckbox"
          @change="onTFAChange"
      />
      <div class="profile-account-settings">
        <h3 class="mt-3 text-center">
          Authorziation Settings
        </h3>
        <yp-loader style="left: 50%; transform: translateX(-50%);" v-if="isLoading" class="align-self-center yp-page-content" />
        <div v-else class="profile-account-settings__theme">
          <div class="profile-account-settings__theme-block">
            <p>Login:</p>
            <input class="yp-input__input form-control yp-input__input--dark" v-model="account.username">
            <p style="margin-top: 15px;">New password:</p>
            <div style="display: flex;">
              <input v-if="showPassword" type="text" class="yp-input__input form-control yp-input__input--dark" v-model="password" placeholder="********">
              <input v-else type="password" class="yp-input__input form-control yp-input__input--dark" v-model="password" placeholder="********">
              <yp-button
                :icon="toggleEye"
                variant="primary-gradient"
                class="cancelFill"
                @click="toggleShowPassword"
                style="height: auto; border-radius: 10px; display: block;"
              />
            </div>
            <p style="margin-top: 15px;">Repeat password:</p>
            <div style="display: flex;">
              <input v-if="showRepeatPassword" type="text" class="yp-input__input form-control yp-input__input--dark" v-model="repeatPassword" placeholder="********">
              <input v-else type="password" class="yp-input__input form-control yp-input__input--dark" v-model="repeatPassword" placeholder="********">
              <yp-button
                :icon="toggleEyeRepeat"
                variant="primary-gradient"
                class="cancelFill"
                @click="toggleShowRepeatPassword"
                style="height: auto; border-radius: 10px; display: block;"
              />
            </div>
            <yp-button
                :disabled="isSubmitDisabled"
                :class="{ 'w-100': true, 'mt-3': true}"
                label="Save"
                variant="primary-gradient"
                @click="onSubmit"
            />
          </div>
        </div>
      </div>
      <h3 class="mt-3 text-center">{{ $t('profilePage.sessions.title') }}</h3>
      <div v-if="account.sessions" class="yp-profile__sessions">
        <div class="yp-profile__sessions-head">
          <div>
            {{ $t('profilePage.sessions.system') }}
          </div>
          <div>
            {{ $t('profilePage.sessions.browser') }}
          </div>
          <div>
            {{ $t('profilePage.sessions.action') }}
          </div>
        </div>
        <yp-session
            v-for="(session, key) in account.sessions"
            :session="session"
            :key="key"
            @endSession="onEndSession"
        />
      </div>
      <!--      <div v-if="accountInfo">-->
      <!--        <h3>{{ $t('profilePage.settings') }}:</h3>-->
      <!--        <div>{{ $t('profilePage.accountReplenishment.title') + ': ' + accountInfo.address }}</div>-->
      <!--        <div>{{ $t('profilePage.accountReplenishment.amount') + ': ' + accountInfo.amount + accountInfo.currency_code }}</div>-->
      <!--        <div>{{ $t('profilePage.accountReplenishment.currency') + ': ' + accountInfo.currency_code }}</div>-->
      <!--      </div>-->
    </yp-sheet>
    <yp-modal
        v-if="showQrModal && twoFactorQR"
        class="yp-profile__qr-modal"
        :title="$t('profilePage.twoFactorModal.title')"
        :confirm-btn="$t('profilePage.twoFactorModal.confirmBtn')"
        :cancel-btn="$t('profilePage.twoFactorModal.cancelBtn')"
        @closeModal="onCloseQrModal"
        @confirm="onQrModalConfirm"
    >
      <div class="yp-profile__qr-modal-content">
        <div>
          <div class="text-info">1.{{ $t('profilePage.twoFactorModal.firstStep') }}</div>
          <div class="text-info">2.{{ $t('profilePage.twoFactorModal.secondStep') }}</div>
          <div class="text-info">2.{{ $t('profilePage.twoFactorModal.lastStep') }}</div>
        </div>
        <yp-qr :src="qrSrc"/>
        <yp-input
            label="Code"
            placeholder="000 000"
            mask="### ###"
            @update="twoFactorKey = $event"
        />
      </div>
    </yp-modal>
    <yp-modal
        v-if="showDisableTfaModal && isTfaEnable"
        :title="$t('profilePage.disableTwoFactorModal.title')"
        :confirm-btn="$t('profilePage.disableTwoFactorModal.confirmBtn')"
        :cancel-btn="$t('profilePage.disableTwoFactorModal.cancelBtn')"
        @confirm="onConfirmDisableTFA"
        @closeModal="onCloseDisableTfaModal"
    />
  </section>
</template>

<script>
import YpCheckbox from "@/components/ui/YpCheckbox";
import YpQr from "@/components/ui/YpQr";

import {mapActions, mapGetters, mapMutations} from 'vuex'
import YpModal from "@/components/ui/YpModal";
import YpInput from "@/components/ui/YpInput";
import YpSheet from "@/components/ui/Sheet";
import YpButton from "@/components/ui/Button";
import YpLoader from "@/components/ui/YpLoader";
import YpSession from "@/components/profile/Session";
import ProfileSettings from "@/components/profile/Settings";

export default {
  name: "Profile",
  components: {ProfileSettings, YpSession, YpSheet, YpInput, YpModal, YpQr, YpCheckbox, YpButton, YpLoader},
  data() {
    return {
      tfaCheckbox: false,
      twoFactorKey: null,
      twoFactorQR: null,
      showDisableTfaModal: false,
      showQrModal: false,
      accountInfo: null,
      password: "",
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
      isLoading: false,
    }
  },
  watch: {
    isTfaEnable: {
      immediate: true,
      handler(val) {
        this.tfaCheckbox = val
      }
    }
  },
  created() {
    if (this.permissions.includes('Payment')) this.getAccountInfo().then(response => this.accountInfo = response.items[0])
  },
  computed: {
    ...mapGetters(['isTfaEnable', 'permissions', 'account']),

    toggleEye() {
      if (this.showPassword){
        return "EyeSlashed"
      } else {
        return "Eye"
      }
    },
    toggleEyeRepeat() {
      if (this.showRepeatPassword){
        return "EyeSlashed"
      } else {
        return "Eye"
      }
    },
    qrSrc() {
      return this.twoFactorQR ? `data:image/svg+xml;base64,${this.twoFactorQR}` : null
    },
    isSubmitDisabled() {
      return (this.account.username.length <= 4 || this.account.username.length >= 40) || (0 == this.password.length || this.password.length <= 8 || this.password.length >= 30) || (this.password != this.repeatPassword)
    },
  },
  methods: {
    ...mapMutations(['setTfaState']),
    ...mapActions('profile', ['endSession']),
    ...mapActions([
      'getTwoFactorQr', 
      'setTwoFactorAuth', 
      'getAccountInfo', 
      "setUsernameAndPassword", 
      "deleteCookie", 
      "logout", 
      "createNewCryptoWallet"
    ]),

    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowRepeatPassword() {
      this.showRepeatPassword = !this.showRepeatPassword;
    },
    onLogout() {
      this.$store.dispatch('wsEvents/closeConnection')
      this.deleteCookie("sessionid")
      document.location.pathname = '/login'
    },
    onSubmit() {
      this.isLoading = true
      this.setUsernameAndPassword({
        username: this.account.username,
        password: this.password,
      }).then(response => {
        this.isLoading = false
        if (response.state) {
          this.$store.commit('snackbar/SET_SNACK', {
            title: "Success",
            text: "You've changed credentials, you'll be logged out",
            duration: 5000,
          })
          this.isLoading = true
          setTimeout(() => this.onLogout(), 5000)
        }
      })
    },
    onTFAChange(event) {
      if (event.isChecked) {
        this.getTwoFactorQr().then(response => {
          this.showQrModal = true
          this.tfaCheckbox = true
          this.twoFactorQR = response
        })
      } else if (!event.isChecked && this.isTfaEnable) {
        this.showDisableTfaModal = true
      }
    },
    async onEndSession(id) {
      await this.endSession(id)
      this.getAccountInfo()
    },
    onCloseDisableTfaModal() {
      this.showDisableTfaModal = false
      this.tfaCheckbox = true
    },
    onCloseQrModal() {
      this.showQrModal = false
      this.tfaCheckbox = false
      // this.setTfaState(false)
    },
    onConfirmDisableTFA() {
      this.setTwoFactorAuth({'2fa_off': true})
          .then(() => this.showDisableTfaModal = false)
    },
    onQrModalConfirm() {
      const key = this.twoFactorKey.split(' ').join('')
      this.setTwoFactorAuth({tfakey: key}).then(response => {
        if (response.state === true) this.showQrModal = false
      })
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/mixins";

.cancelFill svg{
  fill: none !important;
}

.profile-account-settings {
  &__theme {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &__theme-block {
    @include border-style($radius: 10px);
    flex-grow: 1;
    padding: 20px;
  }
}

.yp-profile {
  width: 100%;

  &__qr-modal-content {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  &__sessions-head {
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.33%);
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
    color: $info;
    font-weight: 600;
  }

  &__sessions, &__wallets {
    @include border-style($radius: 10px);

    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
