function setIconName(currencyName) {
    switch (currencyName) {
        default:
            return ''
        case 'usdc':
        case 'usdc-trc20':
        case 'usdc-erc20':
            return 'usdc'
        case 'trx':
            return 'Trx'
        case 'kzt':
            return 'Kzt'
        case 'rub':
            return 'Ruble'
        case 'usd':
            return 'Dollar'
        case 'usdt':
        case 'usdt-trc20':
        case 'usdt-erc20':
            return 'Tether'
        case 'eth':
            return 'Ethereum'
        case 'btc':
            return 'Bitcoin'
        case 'eur':
            return 'Euro'
        case 'bnb':
            return 'Bnb';
        case 'ton':
            return 'Ton';
        case 'uah':
            return 'Uah';
        case 'cny':
            return 'Cny';
        case 'uzs':
            return 'Uzs';
        case 'byn':
            return 'Byn';
    }
}
export default api => ({
    namespaced: true,
    state: {
        currencies: [],
    },
    getters: {
        currencies: state => state.currencies,
        currencyById: state => id => {
            return state.currencies.find(currency => Number(currency.id) === id)
        },
        currenciesWithIcons: state => id => {
            const catalog = state.currencies
            catalog.forEach(currency => {
                currency.iconName = setIconName(currency.code.toLowerCase())
            })
            return id ? catalog.find(currency => Number(currency.id) === id) : catalog
        },
        isCrypto: state => id => {
            const cryptoArr = ['USDT-TRC20', 'USDT-ERC20', 'USDC-ERC20', 'ETH', 'TRX', 'BTC']
            const cryptoCurrencies = state.currencies.filter(currency => cryptoArr.includes(currency.code))
            return Boolean(cryptoCurrencies.find(currency => currency.id === id))
        }
    },
    mutations: {
        SET_CURRENCY_CATALOG(state, payload) {
            state.currencies = payload
        }
    },
    actions: {
        getCurrencyCatalog({ commit }) {
            return api
                .get('client/finance/currencies/')
                .then(response => {
                    if(response.data.state) {
                        commit('SET_CURRENCY_CATALOG', response.data.items)
                        return response.data.items
                    }
                })
        }
    }
})