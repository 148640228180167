var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-new-card" },
    [
      _c(
        "div",
        { staticClass: "yp-new-card__form" },
        [
          _c("yp-input", {
            attrs: {
              type: "number",
              label: "Count of cards:",
              placeholder: "default count 1",
              "external-val": _vm.count,
              "min-value": 1,
              "max-value": 10,
            },
            on: {
              update: function ($event) {
                _vm.count = $event
              },
            },
          }),
          _vm._v(" "),
          _c("yp-input", {
            attrs: {
              type: "text",
              label: "Name:",
              "external-val": _vm.name,
              placeholder: "Name",
            },
            on: {
              update: function ($event) {
                _vm.name = $event
              },
            },
          }),
          _vm._v(" "),
          _c("yp-input", {
            attrs: {
              type: "number",
              label: `Amount (${_vm.currentAccount.currency_code})`,
              "external-val": _vm.amount,
              limits: _vm.currentAccount.limits.card_open,
              placeholder: "0.00",
              "max-value": _vm.currentAccount.amount,
            },
            on: {
              update: function ($event) {
                _vm.amount = $event
              },
            },
          }),
          _vm._v(" "),
          _c("yp-button", {
            staticClass: "flex-shrink-0",
            attrs: {
              disabled: !_vm.amount || !_vm.count,
              label: _vm.btnText,
              size: "xl",
              variant: "primary-gradient",
            },
            on: {
              click: function ($event) {
                _vm.isModalVisible = true
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.commission,
              expression: "commission",
            },
          ],
          staticClass: "yp-new-card__commission yp-additional-text",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                `${_vm.$t("commission")} (${
                  _vm.currentAccount.fee.card_open.percent
                }%) ${_vm.commission + _vm.currentAccount.currency_code}`
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isModalVisible
        ? _c(
            "yp-modal",
            {
              attrs: {
                title: _vm.$t("virtualCards.modal.title"),
                "confirm-btn": _vm.$t("virtualCards.modal.confirm"),
                "cancel-btn": _vm.$t("virtualCards.modal.cancel"),
                errors: _vm.errors,
              },
              on: {
                confirm: _vm.onConfirm,
                closeModal: function ($event) {
                  _vm.isModalVisible = false
                },
              },
            },
            [
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("virtualCards.modal.cardCount") + ": ")),
                ]),
                _vm._v("\n      " + _vm._s(_vm.count) + "\n    "),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("amount") + ": ")),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.amount + _vm.currentAccount.currency_code) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("commission") + ": ")),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.commission + _vm.currentAccount.currency_code) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.$t("total") + ": ")),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.total + _vm.currentAccount.currency_code) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }