import { getCurrentInstance } from 'vue'


export const useStore = () => {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');
  return vm.proxy.$store;
};


export const useSnacks = (store, defaultDuration = 5000) => {
  const showSnack = (title, text, duration = defaultDuration) => {
    return store.commit('snackbar/SET_SNACK', {
      title,
      text,
      duration,
    });
  }

  const showError = (text, duration = defaultDuration) => {
    return showSnack('Error', text, duration);
  }

  const showSuccess = (text, duration = defaultDuration) => {
    return showSnack('Success', text, duration);
  }

  return {
    showSnack,
    showError,
    showSuccess
  }
};
