import Vue from "vue";
import VueI18n from "vue-i18n";
import transition from '@/locale/index'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    messages: transition,
})

export default i18n
