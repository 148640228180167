export default api => ({
    namespaced: true,
    state: {
        isTwoFactorEnable: false,
    },
    actions: {
        endSession({ commit }, sessionId) {
            return api
                .post('/clients/sessions/', { session_id: sessionId })
                .then(response => {
                    commit('snackbar/SET_SNACK', {
                        title: 'Session closed successfully',
                        duration: 5000,
                    }, {root: true})
                    return response.data
                })
        }
    }
})
