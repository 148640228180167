<template>
    <svg
        v-if="iconData"
        class="icon"
        :viewBox="iconData.viewBox"
        :width="width"
        :height="height"
        :style="[styles, isUnfillIcon]"
        :class="[{ 'icon--primary': color === 'primary' }]"
    >
      <use :xlink:href="`${iconData.url}`" />
    </svg>
</template>

<script>

export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
    width: {
      type: String,
      required: false,
      default: '20px'
    },
    height: {
      type: String,
      required: false,
      default: '20px'
    }
  },
  watch: {
    name: {
      immediate: true,
      handler(val) {
        if(val) {
          this.$nextTick(()=> {
            this.iconData = require(`@/assets/icons/${val}.svg`).default
          })
        }
      }
    }
  },
  computed: {
    styles() {
      let styles = {}
      if (this.color) {
        styles.stroke = this.color
        styles.fill = this.color
      }
      return styles
    },
    isUnfillIcon() {
      const unfillArrIcons = [
        'DevicePhone',
        'WeatherSun',
        'Upload',
        'FileDownload',
        'Info',
        'Dollar',
        'Profile',
        'WeatherMoon',
        'Home',
        'CopyClip',
        'Mail'
      ]
      return unfillArrIcons.includes(this.name) ? { fill: 'none' } : {}
    }
  },
  data() {
    return {
      iconData: null,
    }
  },
}
</script>
