var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-session",
      class: { "yp-session--dark": _vm.$store.getters.mode === "dark" },
    },
    [
      _c(
        "div",
        { staticClass: "yp-session__device" },
        [
          _c("icon", {
            staticClass: "yp-session__icon",
            attrs: { name: _vm.session.device },
          }),
          _vm._v("\n    " + _vm._s(_vm.session.device) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-session__browser" },
        [
          _c("icon", {
            staticClass: "yp-session__icon",
            attrs: { name: _vm.session.browser },
          }),
          _vm._v("\n    " + _vm._s(_vm.session.browser) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "yp-session__action" },
        [
          _c("yp-button", {
            attrs: {
              "is-icon-btn": "",
              ghost: "",
              icon: "Close",
              "icon-color": "red",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("endSession", _vm.session.session_id)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }