var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "yp-modal",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "yp-modal__body" },
        [
          _c("yp-button", {
            staticClass: "yp-modal__close",
            attrs: {
              icon: "Close",
              "icon-size": "16px",
              "is-icon-btn": "",
              ghost: "",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _vm._v(" "),
          !_vm.withoutTitle
            ? _c("div", { staticClass: "yp-modal__title" }, [
                _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.errors
            ? _c(
                "div",
                _vm._l(_vm.errors, function (err, key) {
                  return _c("yp-msg", { key: key, attrs: { msg: err } })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.withoutActions
            ? _c(
                "div",
                { staticClass: "yp-modal__actions" },
                [
                  _c("yp-button", {
                    attrs: {
                      size: "xl",
                      variant: "primary-gradient",
                      label: _vm.confirmBtn,
                      disabled: _vm.isConfirmDisabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("confirm")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("yp-button", {
                    attrs: { size: "xl", label: _vm.cancelBtn },
                    on: {
                      click: function ($event) {
                        return _vm.onCancelBtnClick()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }