var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-input" },
    [
      _c("b-form-input", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: "#### #### #### ####",
            expression: "'#### #### #### ####'",
          },
        ],
        staticClass: "yp-input__input",
        class: [
          { "yp-input__input--rounded": _vm.rounded },
          { "yp-input__input--dark": _vm.mode === "dark" },
        ],
        style: [
          _vm.inputStyle,
          _vm.borderColor,
          { padding: "29.5px 20px 11.5px" },
        ],
        attrs: {
          disabled: _vm.disabled,
          placeholder: "0000 0000 0000 0000",
          readonly: _vm.readonly,
        },
        on: { input: _vm.onChange },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _vm.cardBank
        ? _c("icon", {
            staticClass: "yp-input__logo",
            attrs: { name: `bank-${_vm.cardBank}` },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "yp-input__label" }, [
        _vm._v(_vm._s(_vm.$t("cardInputLabel")) + ":"),
      ]),
      _vm._v(" "),
      _vm.cardType
        ? _c("icon", {
            staticClass: "yp-input__card-type",
            attrs: { name: `card-type-${_vm.cardType}` },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }