<template>
<div class="yp-wallet-crypto">
  <h2 class="text-center">
    {{ $t('walletCryptoPage.title') }}
  </h2>
  <yp-sheet>
    <h3 class="mt-3 text-center">
      {{ $t('walletCryptoPage.create.title') }}
    </h3>
    <div class="yp-wallet-crypto__block">
      <yp-loader v-if="loadingWalletTypes" class="align-self-center" />
      <template v-else>
        <yp-custom-select 
          :options="walletOptions"  
          :placeholder="$t('walletCryptoPage.create.selectWalletType')"
          v-model="selectedWalletType"
        />
        <yp-custom-input 
          v-model="walletName"
          :placeholder="$t('walletCryptoPage.create.nameInputPlaceholder') + walletTypeHint"
        />
        <yp-button
          class="yp-wallet-crypto__create-wallet"
          v-show="selectedWalletType != null"
          icon="Plus"
          :label="$t('walletCryptoPage.create.addBtn')"
          variant="primary-gradient"
          :disabled="!canAddWallet"
          @click="onAddNewWalletClicked"
        />
      </template>
    </div>
  </yp-sheet>
</div>
</template>
  
<script setup>
import YpSheet from "@/components/ui/Sheet";
import YpLoader from "@/components/ui/YpLoader";
import YpCustomSelect from "@/components/ui/YpCustomSelect";
import YpCustomInput from "@/components/ui/YpCustomInput";
import YpButton from '@/components/ui/Button';
import { ref, onMounted, computed, watch } from 'vue';
import { useStore, useSnacks } from '@/composition/store';
import { useI18n } from "@/composition/i18n";
import { getCryptoIconName } from '@/utils/ui.js';

const store = useStore();
const { $t } = useI18n();
const { showSuccess, showError } = useSnacks(store);
const loadingWalletTypes = ref(true);
const walletTypes = ref([]);
const walletName = ref('');
const selectedWalletType = ref(null);

const canAddWallet = computed(() => {
  return walletName.value && selectedWalletType.value;
});

onMounted(async () => {
  const result = await store.dispatch('getAvailableCryptoWalletTypes');
  // result.push('TON');
  // result.push('BTC');
  walletTypes.value = result;
  loadingWalletTypes.value = false;
});

const walletOptions = computed(() => {
  const options = [];
  for (const walletType of walletTypes.value) {
    const option = {
      icon: getCryptoIconName(walletType),
      pk: walletType
    };
    options.push(option);
  }
  return options;
});

const walletTypeHint = computed(() => {
  if (selectedWalletType.value) {
    return ` (${selectedWalletType.value})`;
  } else {
    return '';
  }
});

const onAddNewWalletClicked = async () => {
  if (!canAddWallet.value) {
    return;
  }

  const walletNameTemp = walletName.value;
  walletName.value = '';
  try {
    const response = await store.dispatch('createNewCryptoWallet', {
      currency_code: selectedWalletType.value, 
      wallet_name: walletNameTemp
    });

    if (response == null) {
      throw new Error('could not create wallet (address is empty)');
    }
    
    const { wallet_address: newWalletAddress, wallet_name: newWalletName } = response;
    const walletInfo = `${newWalletName} (${newWalletAddress})`;

    showSuccess($t('walletCryptoPage.create.actionResult.success') + walletInfo);
  } catch (err) {
    console.error('Error creating wallet:', err);
    showError($t('walletCryptoPage.create.actionResult.failure'));
  }
};

</script>
  
<style lang="scss" scoped>
@import "src/assets/scss/mixins";

.yp-wallet-crypto {
  margin: 0 auto;

  &__block {
    @include border-style($radius: 10px);

    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__create-wallet {
    padding: 30px;
    margin-top: 17px;
  }
}

</style>
