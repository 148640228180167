export const getCryptoIconName = (code) => {
  const value = code.toLowerCase()
  switch (value) {
    default:
      return '';
    case 'usdc':
    case 'usdc-trc20':
    case 'usdc-erc20':
      return 'usdc';
    case 'trx':
      return 'Trx';
    case 'kzt':
      return 'Kzt';
    case 'rub':
      return 'Ruble';
    case 'usd':
      return 'Dollar';
    case 'usdt':
    case 'usdt-trc20':
    case 'usdt-erc20':
      return 'Tether';
    case 'eth':
      return 'Ethereum';
    case 'btc':
      return 'Bitcoin';
    case 'eur':
      return 'Euro';
    case 'ton':
      return 'Ton';
    case 'bnb':
      return 'Bnb';
  }
};