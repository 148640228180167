var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "profile-settings" }, [
    _c("h3", { staticClass: "text-center" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("profilePage.settingsSection.title")) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "profile-settings__theme" }, [
      _c(
        "div",
        { staticClass: "profile-settings__theme-block" },
        [
          _c(
            "b-radio",
            {
              attrs: { disabled: "", value: "light" },
              on: {
                change: function ($event) {
                  return _vm.setMode($event)
                },
              },
              model: {
                value: _vm.selectedTheme,
                callback: function ($$v) {
                  _vm.selectedTheme = $$v
                },
                expression: "selectedTheme",
              },
            },
            [
              _c("icon", { attrs: { name: "WeatherSun" } }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("profilePage.settingsSection.lightTheme")) +
                  "\n      "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "profile-settings__theme-block" },
        [
          _c(
            "b-radio",
            {
              attrs: { value: "dark" },
              on: {
                change: function ($event) {
                  return _vm.setMode($event)
                },
              },
              model: {
                value: _vm.selectedTheme,
                callback: function ($$v) {
                  _vm.selectedTheme = $$v
                },
                expression: "selectedTheme",
              },
            },
            [
              _c("icon", { attrs: { name: "WeatherMoon" } }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("profilePage.settingsSection.darkTheme")) +
                  "\n      "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }