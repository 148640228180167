export default api => ({
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        doDeposit(_, data) {
            return api
            .post('/client/deposit', data)
            .then(response => { if(response.data.state) return response.data })
        }
    },
})