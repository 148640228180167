import { render, staticRenderFns } from "./YpCustomSelect.vue?vue&type=template&id=225fd2db&scoped=true"
import script from "./YpCustomSelect.vue?vue&type=script&setup=true&lang=js"
export * from "./YpCustomSelect.vue?vue&type=script&setup=true&lang=js"
import style0 from "./YpCustomSelect.vue?vue&type=style&index=0&id=225fd2db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225fd2db",
  null
  
)

export default component.exports