<template>
  <div class="yp-ticker" :class="{ 'yp-ticker--dark': $store.getters.mode === 'dark' }">
    <div class="yp-ticker__list">
      <div class="yp-ticker__item" v-for="(item, key) in items" :key="key">
        {{ `${item.currency_from}/${item.currency_to} ${item.human_rate.toFixed(item.denominator_from)}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YpTicker",
  props: {
    items: Array,
  },
}
</script>

<style lang="scss" scoped>
$duration: 70s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.yp-ticker {
  height: 30px;
  width: 100%;
  background: $border-color-light;
  overflow: hidden;
  padding-left: 100%;

  &--dark {
    background: $border-color;
  }

  &__list {
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
  }
  &__item {
    display: inline-block;
    padding: 0 20px;
    line-height: 30px;
  }
}
</style>
