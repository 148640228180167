<template>
  <div class="yp-input">
    <icon v-if="icon" class="yp-input__icon" :class="{ 'yp-input__icon--append': appendIcon }" :name="icon"/>
    <div class="yp-input--wrap">
      <b-form-input
        :value="value"
        :autofocus="autoFocus"
        class="yp-input__input"
        :class="{
          'yp-input__input--rounded': rounded,
          'yp-input__input--dark': mode === 'dark',
          'yp-input__input--error': errors.length > 0 
        }"
        type="text"
        :disabled="disabled"
        :step="step"
        :readonly="readonly"
        :placeholder="placeholder || $t('ypCustomInput.defaultPlaceholder')"
        :style="padding"
        @input="onInput"
      />
    </div>
    <div v-if="errors.length" class="yp-input__info">
      <icon id="info" name="Info"/>
      <b-tooltip class="yp-input__tooltip" target="info">
        <div class="font-weight-bold text-danger">
          {{ invalidInputHint || $t('ypCustomInput.invalidInput') }}
        </div>
        <div class="font-weight-bold text-info" v-for="(error, key) in errors" :key="key">
          {{ error }}
        </div>
      </b-tooltip>
    </div>
    <div v-if="label" class="yp-input__label">{{ label }}</div>
  </div>
</template>

<script setup>
import Icon from "@/components/ui/Icon";

const props = defineProps({
  value: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  errors: {
    type: Array,
    required: false,
    default: () => []
  },
  mode: {
    type: String,
    required: false,
    default: "dark"
  },
  icon: {
    type: String,
    required: false,
    default: null
  },
  placeholder: {
    type: String,
    required: false,
  },
  invalidInputHint: {
    type: String,
    required: false,
    default: null
  },
  autoFocus: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
});

const emit = defineEmits(['input']);

const onInput = (newValue) => {
  emit('input', newValue);
};

</script>
