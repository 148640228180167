<template>
  <div class="yp-exchange">
    <h2 class="text-center">
      {{ $t('exchangePage.title') }}
    </h2>
    <yp-sheet class="yp-exchange__content yp-content">
      <div v-show="!isLoading" class="yp-exchange__loader">
        <yp-loader  />
        <h3 class="text-info">
          {{ $t('loaderText') }}
        </h3>
      </div>
      <div v-if="!(accounts.length === 0) && isLoading">
        <div class="yp-exchange__row">
          <div class="yp-exchange__col yp-exchange__col--left">
            <div>{{ $t('exchangePage.fromLabel') }}:</div>
            <yp-account-select
                class="yp-exchange__selector"
                manual
                :options="fromAccountList"
                :external-val="exchangeForm.fromPart.account"
                @accountSelected="onFromSelected"
                :filter="account => !account.has_parent"
            />
          </div>
          <div class="yp-exchange__col">
            <div>{{ $t('exchangePage.amountLabel') }}:</div>
            <yp-input
                type="number"
                ref="amountFrom"
                class="yp-exchange__input"
                :zero-length="currentRate.denominator_from"
                :external-val="exchangeForm.fromPart.amount"
                is-max-mode
                :max-value="exchangeForm.fromPart.account.amount"
                @update="onChangeAmountFrom"
            />
          </div>
        </div>
        <yp-button
            class="yp-exchange__swap-btn"
            :class="{'w-100': isMobile }"
            size="xl"
            icon="Transfers"
            :label="$t('exchangePage.swapBtn')"
            @click="onClickSwapBtn"
        />
        <div class="yp-exchange__row">
          <div class="yp-exchange__col yp-exchange__col--left">
            <div>{{ $t('exchangePage.toLabel') }}:</div>
            <yp-account-select
                manual
                class="yp-exchange__selector"
                :options="toAccountList"
                :external-val="exchangeForm.toPart.account"
                @accountSelected="onToSelected"
                :filter="account => !account.has_parent"
            />
          </div>
          <div class="yp-exchange__col">
            <div>{{ $t('exchangePage.amountLabel') }}:</div>
            <yp-input
                class="yp-exchange__input"
                type="number"
                :zero-length="currentRate.denominator_to"
                :external-val="exchangeForm.toPart.amount"
                @update="onChangeAmountTo"
            />
          </div>
        </div>
        <div class="yp-divider"></div>
        <div class="yp-exchange__rate">
          <div>{{ $t('exchangePage.rates') }}:</div>
          <yp-input
              type="number"
              class="yp-exchange__input"
              :external-val="currentRate.human_rate.toFixed(2)"
              readonly
          />
          <yp-button
              icon="Refresh"
              icon-color="primary"
              is-icon-btn
              :disabled="isUpdateRatesBtnDisabled"
              @click="onUpdateRates"
          />
        </div>
        <yp-button
            class="mt-3 mx-auto"
            :class="{'w-100': isMobile }"
            size="xl"
            :label="$t('exchangePage.confirmBtn')"
            variant="primary-gradient"
            :disabled="isSubmitDisabled"
            @click="onSubmit"
        />
      </div>
    </yp-sheet>
    <yp-modal
        v-if="isConfirmModalVisible"
        :title="$t('exchangePage.confirmModal.title')"
        :confirm-btn="$t('exchangePage.confirmModal.confirmBtn')"
        :cancel-btn="$t('exchangePage.confirmModal.cancelBtn')"
        @confirm="onConfirm"
        @closeModal="isConfirmModalVisible = false"
    >
      <div class="yp-divider" />
      <div class="yp-exchange__confirm-modal-content">
        <div>
          <span class="text-info">
            {{ $t('exchangePage.confirmModal.give') + ': ' }}
          </span>
          <strong>
            {{ exchangeForm.fromPart.amount + exchangeForm.fromPart.account.currency_symbol }}
          </strong>
        </div>
        <div>
          <span class="text-info">
            {{ $t('exchangePage.confirmModal.get') + ': ' }}
          </span>
          <strong>{{ exchangeForm.toPart.amount + exchangeForm.toPart.account.currency_symbol }}</strong>
        </div>
        <div>
          <span class="text-info">
            {{ $t('exchangePage.confirmModal.byRate') + ': ' }}
          </span>
          <strong> {{ currentRate.human_rate.toFixed(currentRate.denominator_from) }} </strong>
        </div>
      </div>
      <div class="yp-divider" />
    </yp-modal>
  </div>
</template>

<script>
import YpSheet from "@/components/ui/Sheet";
import YpAccountSelect from "@/components/ui/YpSelect";
import YpInput from "@/components/ui/YpInput";
import YpButton from "@/components/ui/Button";

import {mapActions, mapGetters} from 'vuex'
import YpLoader from "@/components/ui/YpLoader";
import YpModal from "@/components/ui/YpModal";

export default {
  name: "Exchange",
  components: {YpModal, YpLoader, YpButton, YpInput, YpAccountSelect, YpSheet},
  data() {
    return {
      isLoading: false,
      isConfirmModalVisible: false,
      isUpdateRatesBtnDisabled: false,
      masterField: 'fromPart',
      exchangeForm: {
        fromPart: {
          account: null,
          amount: null,
        },
        toPart: {
          account: null,
          amount: null,
        }
      },
    }
  },
  async created() {
    this.isLoading = false
    const lsFrom = localStorage.getItem('exchange_account_from')
    const lsTo = localStorage.getItem('exchange_account_to')
    const accountFrom = this.exchangeAccounts.find(el => el.pk === lsFrom)
    const accountTo = this.exchangeAccounts.find(el => el.pk === lsTo)
    this.exchangeForm.fromPart.account = accountFrom || this.exchangeAccounts[0]
    this.exchangeForm.toPart.account = accountTo || this.exchangeAccounts[1]
    if (this.exchangeForm.fromPart.account && this.exchangeForm.toPart.account) {
      this.isLoading = true
    }
  },
  watch: {
    'exchangeForm.fromPart.account': {
      handler() {
        const amount = this.exchangeForm.fromPart.amount
        this.masterField = 'fromPart'
        if(amount) this.onChangeAmountFrom(amount)
      }
    },
    'exchangeForm.toPart.account': {
      handler() {
        const amount = this.exchangeForm.toPart.amount
        this.masterField = 'toPart'
        if(amount) this.onChangeAmountTo(amount)
      }
    },
    rates() {
      if (this.exchangeForm.fromPart.account == null) this.exchangeForm.fromPart.account = this.exchangeAccounts[0]
      if (this.exchangeForm.toPart.account == null) this.exchangeForm.toPart.account = this.exchangeAccounts[1]
      if (this.exchangeForm.fromPart.account && this.exchangeForm.toPart.account) {
        this.isLoading = true
      }
    },
    accounts() {
      const lsFrom = localStorage.getItem('exchange_account_from')
      const lsTo = localStorage.getItem('exchange_account_to')
      const accountFrom = this.exchangeAccounts.find(el => el.pk === lsFrom)
      const accountTo = this.exchangeAccounts.find(el => el.pk === lsTo)
      this.exchangeForm.fromPart.account = accountFrom || this.exchangeAccounts[0]
      this.exchangeForm.toPart.account = accountTo || this.exchangeAccounts[1]
      if (this.exchangeForm.fromPart.account && this.exchangeForm.toPart.account) {
        this.isLoading = true
      }
    }
  },
  computed: {
    ...mapGetters(['accounts', 'accountsByMethods', 'rates', 'isMobile']),

    exchangeAccounts() {
      const accounts = this.accountsByMethods('withdraw', 'Exchange')
      return accounts.filter(account => {
        return this.rates.find(rate => {
          // console.log('Account: ', account.currency_code, 'Rate: ', rate.currency_from , '-', rate.currency_to, 'condition: ', rate.currency_from === account.currency_code || rate.currency_to === account.currency_code)
          return rate.currency_from === account.currency_code || rate.currency_to === account.currency_code
        })
      })
    },
    fromAccountList() {
      return this.exchangeForm.toPart.account ?
          this.exchangeAccounts.filter(account => account.pk !== this.exchangeForm.toPart.account.pk) :
          this.exchangeAccounts
    },
    isSubmitDisabled() {
      return !this.exchangeForm.fromPart.amount || !this.exchangeForm.toPart.amount
    },
    currentRate() {
      if(this.exchangeForm.fromPart.account.currency_code === this.exchangeForm.toPart.account.currency_code) {
        return {
          rate: 1,
          human_rate: 1,
          denominator_from: 2,
          denominator_to: 2,
        }
      } else {
        return this.rates.find(rate => {
          return rate.currency_from === this.exchangeForm.fromPart.account.currency_code &&
              rate.currency_to === this.exchangeForm.toPart.account.currency_code
        })
      }
    },
    toAccountList() {
      return this.exchangeForm.fromPart.account ?
          this.exchangeAccounts.filter(account => account.pk !== this.exchangeForm.fromPart.account.pk) :
          this.exchangeAccounts
    }
  },
  methods: {
    ...mapActions(['getRates', 'doExchange']),
    onChangeAmountTo(event) {
      this.exchangeForm.toPart.amount = event
      this.exchangeForm.fromPart.amount =  parseFloat(event / this.currentRate.rate).toFixed(this.currentRate.denominator_from)
    },
    onChangeAmountFrom(event) {
      this.exchangeForm.fromPart.amount = event
      this.exchangeForm.toPart.amount = parseFloat(event * this.currentRate.rate).toFixed(this.currentRate.denominator_to)
    },
    onClickSwapBtn() {
      const fromData = this.exchangeForm.fromPart
      this.exchangeForm.fromPart = this.exchangeForm.toPart
      this.exchangeForm.toPart = fromData
    },
    onSubmit() {
      this.isConfirmModalVisible = true
    },
    onFromSelected(event) {
      this.exchangeForm.fromPart.account = event
      localStorage.setItem('exchange_account_from', event.pk)
      this.$nextTick(() =>{
        this.$refs.amountFrom.validate()
      })
    },
    onToSelected(event) {
      this.exchangeForm.toPart.account = event
      localStorage.setItem('exchange_account_to', event.pk)
    },
    async onUpdateRates() {
      this.isUpdateRatesBtnDisabled = true
      await this.getRates()
      setTimeout(() => {
        this.isUpdateRatesBtnDisabled = false
      }, 5000)
    },
    onConfirm() {
      const data = {
        account_from: this.exchangeForm.fromPart.account.pk,
        account_to: this.exchangeForm.toPart.account.pk,
        amount_to: this.exchangeForm.toPart.amount,
        amount_from: this.exchangeForm.fromPart.amount,
        human_rate: this.currentRate.human_rate,
        rate: this.currentRate.rate
      }
      this.doExchange(data).then(response => {
        if (response.state) {
          this.isConfirmModalVisible = false
          this.$router.push({ name: 'history' })
        }
      })
      this.isConfirmModalVisible = false
    }
  }
}
</script>

<style lang="scss">
.yp-exchange__swap-btn {
  margin: 30px auto;

  .icon {
    transform: rotate(90deg);
  }
}
</style>
<style lang="scss" scoped>
.yp-exchange {
  margin: 0 auto;

  &__row {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1400px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  &__selector {
    width: 330px;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }

  &__confirm-modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  &__col {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    &--left {
      width: 55%;

      @media (max-width: 1400px)  {
        width: 100%;
      }
    }
  }

  &__input {
    width: 200px;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }

  &__loader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &__rate {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
</style>
