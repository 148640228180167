<template>
  <div class="login">
    <h1 class="login__title">{{ $t('loginPage.title') }}</h1>
    <div v-if="$route.meta.type === 'loginViaPassword'" class="login__inputs">
      <yp-input
          rounded
          :placeholder="$t('loginPage.loginInput')"
          :external-val="username"
          @update="username = $event"
      />
      <yp-input
          rounded
          type="password"
          :external-val="password"
          :placeholder="$t('loginPage.codeInput')"
          @update="password = $event"
      />
    </div>
    <div v-if="errors" class="mt-1">
      <yp-msg v-for="(err, key) in errors" :key="key" :msg="err" size="lg" />
    </div>
    <div v-if="$route.meta.type === 'loginViaPassword'" class="login__actions">
      <yp-button
          size="xl"
          :label="$t('loginPage.login')"
          variant="gradient-outline"
          :disabled="!username || !password"
          @click="onSubmit"
      />
    </div>
    <yp-button
        v-if="$route.meta.type === 'loginViaTg'"
        class="mt-5"
        size="xl"
        variant="primary-gradient"
        icon="Telegram"
        :label="$t('loginPage.loginTelegram')"
        @click="onClickTelegramLogin"
    />
  </div>
</template>

<script>
import YpInput from "@/components/ui/YpInput";
import {mapActions, mapGetters} from 'vuex'
import YpButton from "@/components/ui/Button";
import YpMsg from "@/components/ui/YpMsg.vue";

export default {
  name: "Login",
  components: {YpMsg, YpButton, YpInput},
  data() {
    return {
      username: null,
      password: null,
      errors: null,
    }
  },
  computed: {
    ...mapGetters(['botName']),
  },
  methods: {
    ...mapActions(['login']),

    onSubmit() {
      this.login({
        username: this.username,
        password: this.password
      }).then(response => {
        if(response.errors) this.errors = response.errors
      })
    },
    onClickTelegramLogin() {
      let tgLink = `https://telegram.me/${this.botName}`
      if (this.$route.params.groupId) tgLink = tgLink + `?start=${this.$route.params.groupId}`
      window.location.href = tgLink
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  &__inputs {
    margin-top: 50px;
    display: flex;
    gap: 15px;

    @media (max-width: 790px) {
      flex-direction: column;
    }
  }

  &__actions {
    margin-top: 15px;
    display: flex;
    gap: 15px;
    justify-content: center;
  }

  &__title {
    font-size: 4.375rem;

    @media (max-width: 1200px) {
      font-size: 2.5rem;
    }
  }
}
</style>
