var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("notifications", {
    attrs: { classes: "yp-notification", position: _vm.position },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function (props) {
          return [
            _c("div", { staticClass: "yp-notification" }, [
              _c("div", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(props.item.title) },
                }),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(props.item.text) } }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("yp-button", {
                    staticClass: "yp-notification__close",
                    attrs: { icon: "Close" },
                    on: { click: props.close },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }