var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "auth-layout",
      style: {
        background: `linear-gradient(180deg, rgba(14, 20, 30, 0) 73.52%, #0E141E 100%), url(${
          require("@/assets/img/login-background.png").default
        }`,
      },
    },
    [
      _c("yp-notification"),
      _vm._v(" "),
      _c("div", {
        staticClass: "auth-layout__sphere auth-layout__sphere--left",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "auth-layout__sphere auth-layout__sphere--primary-blur",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "auth-layout__ball",
        style: {
          backgroundImage: `url(${
            require("@/assets/img/BallBgBig.png").default
          })`,
        },
      }),
      _vm._v(" "),
      _c("logo", { attrs: { big: "" } }),
      _vm._v(" "),
      _vm.isDataLoaded && _vm.isAuthCheckCompleted
        ? _c("router-view", { style: { zIndex: 100 } })
        : _c("yp-loader", { staticClass: "auth-layout__loader" }),
      _vm._v(" "),
      _c("div", { staticClass: "auth-layout__plug" }),
      _vm._v(" "),
      _c("Footer", { attrs: { dark: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }