var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yp-app" },
    [
      _c("yp-notification"),
      _vm._v(" "),
      _c("Header"),
      _vm._v(" "),
      _c("yp-ticker", { attrs: { items: _vm.rates } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "mt-5 container yp-app__content" },
        [
          _c("router-view", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isContextLoaded,
                expression: "isContextLoaded",
              },
            ],
            style: _vm.contentIndex,
          }),
          _vm._v(" "),
          !_vm.isContextLoaded
            ? _c("yp-loader", { staticClass: "yp-app__loader" })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$store.getters.isMobile ? _c("Footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }