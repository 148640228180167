<template>
  <div class="yp-session" :class="{ 'yp-session--dark': $store.getters.mode === 'dark' }">
    <div class="yp-session__device">
      <icon :name="session.device" class="yp-session__icon" />
      {{ session.device }}
    </div>
    <div class="yp-session__browser">
      <icon :name="session.browser" class="yp-session__icon" />
      {{ session.browser }}
    </div>
    <div class="yp-session__action">
      <yp-button is-icon-btn ghost icon="Close" icon-color="red" @click="$emit('endSession', session.session_id)" />
    </div>
  </div>
</template>

<script>
import YpButton from "@/components/ui/Button";
import Icon from "@/components/ui/Icon";
export default {
  name: "YpSession",
  components: {Icon, YpButton},
  props: {
    session: Object,
  },
}
</script>

<style lang="scss" scoped>
.yp-session {
  //display: flex;
  //gap: 20px;
  //align-items: center;
  //padding: 10px;
  //border: 1px solid $border-color-light;
  //border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 33.33%);
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;
  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &--dark {
    border-color: $border-color;
  }

  &__icon {
    fill: $secondary;
    stroke: none;
  }

  &__device, &__browser {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__action {
    margin-left: auto;
  }
}
</style>
